import nexus from 'nexus'
import { removeDeviceUser } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callRevokeDeviceAccess = async (dispatch, deviceId, userId, groupName) => {
  await nexus.device.revokeAccess(deviceId, { userId, groupName })
  dispatch(removeDeviceUser({ userId, deviceId }))
}

export default thunkWrapper(callRevokeDeviceAccess)
