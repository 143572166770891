import React from 'react'
import { Form, Popup } from 'semantic-ui-react'
import { convertFrom, convertManyFrom } from 'utils/units'
import Stream from 'utils/Stream'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'
import Unit from '../Unit'


/* This component is used in the process builder to receive target values for the streams of a
 * process, for executable processes and for running processes (then via ad-hoc API)
 * The single source of truth should be the process description, from where the target is loaded
 * and to where it is saved. But because we need intermediate storage for unit conversion, the
 * entered value is stored in local state while the user is entering an input ('takingInput')
 * and then applied to the process on blur. The intermediate storage is flushed everytime to
 * prevent overwriting the process target during a running process after ad-hoc changes were
 * dismissed but the local state still holds the value that has been dismissed from the process
 */

export default class FreeValueStream extends React.Component {

  constructor(props) {
    super(props)
    const { stream, getStreamDisplayedUnit } = this.props
    this.state = {
      takingInput: false,
      currentInput: '',
      nameHovered: false,
      selectedUnit: getStreamDisplayedUnit(stream.id),
    }
  }

  updateStreamUnit = newUnit => {
    const { changeDisplayedUnit, stream } = this.props
    this.setState({ selectedUnit: newUnit, takingInput: false })
    changeDisplayedUnit(newUnit, stream.id)
  }

  submitTargetValue = event => {
    const newTarget = event.target.value
    if (isNaN(newTarget) && newTarget !== '-') return

    this.setState({ currentInput: newTarget, takingInput: true })
    event.stopPropagation()
  }

  getConvertedTarget = () => {
    const { activeDevice, stream } = this.props
    const { selectedUnit, currentInput } = this.state

    const customConversion = CustomConversions
      .getCustomConversionsByTargetUnit(activeDevice, stream.id, selectedUnit)

    if (customConversion) {
      return CustomConversions.revertWithRatio(parseFloat(currentInput), customConversion)
    }

    /* disabled rounding for the stored value in the process
     * to have the conversion as accurate as possible, otherwise
     * when user types '101' in Fahrenheit, conversion will make it
     * 100.999 after saving due to rounding
     */

    return convertFrom(selectedUnit, { round: false }).to(stream.unit)(parseFloat(currentInput))
  }

  save = async () => {
    const { stream, streamTarget, updateStreamTarget } = this.props

    const convertedTarget = this.getConvertedTarget()

    if (streamTarget === convertedTarget || Number.isNaN(convertedTarget)) {
      this.setState({ takingInput: false })
      return
    }

    updateStreamTarget(stream.id, convertedTarget)
    /* always deleting the curentInput after it was applied
     * to prevent it from overwriting the process target on blur
     * after an ad-hoc change has been dismissed
     */
    this.setState({ takingInput: false, currentInput: '' })
  }

  getDisplayedTargetValue = target => {
    const { stream, activeDevice } = this.props
    const { currentInput, takingInput, selectedUnit } = this.state

    if (takingInput) return currentInput

    const customConversion = CustomConversions
      .getCustomConversionsByTargetUnit(activeDevice, stream.id, selectedUnit)

    if (customConversion) {
      return CustomConversions.convertWithRatio(target, customConversion)
    }

    return convertFrom(stream.unit).to(selectedUnit)(target)
  }

  renderTextBoxWithUnit = () => {
    const { streamTarget, disabledInput, stream, activeDevice } = this.props
    const { selectedUnit } = this.state
    const displayedTarget = this.getDisplayedTargetValue(streamTarget)

    return (
      <Form.Field error={Stream.streamTargetOutOfLimits(stream, streamTarget)}>
        <Form.Input action>
          <input
            value={displayedTarget}
            type='text'
            onChange={this.submitTargetValue}
            onBlur={this.save}
            onClick={this.submitTargetValue}
            readOnly={disabledInput}
          />
          <Unit
            stream={stream}
            displayedUnit={selectedUnit}
            activeDevice={activeDevice}
            onChange={this.updateStreamUnit}
          />
        </Form.Input>
      </Form.Field>
    )
  }

  getBoundariesText = () => {
    const { stream, activeDevice } = this.props
    const { selectedUnit } = this.state
    const { unit, min, max } = stream

    const customConversion = CustomConversions
      .getCustomConversionsByTargetUnit(activeDevice, stream.id, selectedUnit)

    if (customConversion) {
      const displayedMin = CustomConversions.convertWithRatio(min, customConversion)
      const displayedMax = CustomConversions.convertWithRatio(max, customConversion)
      return `min: ${displayedMin} ${customConversion.to} | max: ${displayedMax} ${customConversion.to}`
    }

    const [ displayedMin, displayedMax ] = convertManyFrom(unit).to(selectedUnit)([min, max])
    return `min: ${displayedMin} ${selectedUnit} | max: ${displayedMax} ${selectedUnit}`
  }

  toggleModal(modalTrigger) {
    this.setState(prevState => ({ [modalTrigger]: !prevState[modalTrigger] }))
  }

  render() {
    const { stream } = this.props
    const { takingInput, nameHovered } = this.state

    return (
      <div>
        <Popup
          trigger={(
            <span
              style={{ fontWeight: 'bold' }}
              onMouseEnter={() => this.setState({ nameHovered: true })}
              onMouseLeave={() => this.setState({ nameHovered: false })}
            >
              {stream.name}
            </span>
          )}
          content={this.getBoundariesText()}
          open={takingInput || nameHovered}
        />
        <Form.Group>
          {this.renderTextBoxWithUnit()}
        </Form.Group>
      </div>
    )
  }
}
