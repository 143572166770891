import { findAndUpdate } from 'redux/helper/updateFunctions'
import PhaseGroupHandler from 'utils/process/PhaseGroupHandler'

export default (state, action) => {
  const { processes } = state
  const { processId, oldGroupName, newGroupName } = action.payload

  const updateFn = process => PhaseGroupHandler.renameGroup(process, oldGroupName, newGroupName)

  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }
}
