import nexus from 'nexus'
import React, { useState, useEffect } from 'react'
import { Card, Image, Container, Label } from 'semantic-ui-react'

import Time from 'utils/Time'

import bioreactorOnlineImage from 'images/bioreactor_online_cropped.png'
import bioreactorOfflineImage from 'images/bioreactor_offline_cropped.png'

import LEDCircle from 'components/device/spinners/LEDCircle'
import EditableHeader, { EditableHeaderButtonClassName } from 'components/utility/EditableHeader'
import callUpdateDevice from 'redux/thunks/device/callUpdateDevice'
import { resetActiveDevice } from 'redux/actions/actions'

import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import injectSheet from 'react-jss'
import ProcessRunningState from './DeviceProcess'

const editableHeaderClassName = `&:hover .${EditableHeaderButtonClassName}`
const styles = { parent: { marginLeft: '10px' } }
styles.parent[editableHeaderClassName] = { opacity: '1' }

const DEFAULT_DISPLAY = { image: bioreactorOfflineImage, statusText: 'Offline', textColor: 'status-grey' }

const displayMap = {
  online: {
    idle: { image: bioreactorOnlineImage, statusText: 'Online', textColor: 'status-green' },
    running: { image: bioreactorOnlineImage, statusText: 'Running', textColor: 'status-blue' },
    paused: { image: bioreactorOnlineImage, statusText: 'Paused', textColor: 'status-orange' },
  },
  offline: {
    idle: { image: bioreactorOfflineImage, statusText: 'Offline', textColor: 'status-grey' },
    running: { image: bioreactorOfflineImage, statusText: 'Offline', textColor: 'status-grey' },
    paused: { image: bioreactorOfflineImage, statusText: 'Offline', textColor: 'status-grey' },
  },
}
const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateDevice: (deviceId, params) => dispatch(callUpdateDevice(deviceId, params)),
  dispatchResetActiveDevice: () => dispatch(resetActiveDevice()),
})

const renderVirtual = () => (
  <div>
    <Label style={{ position: 'absolute', right: '16px', bottom: '16px' }} color='blue' basic size='small'>
      Virtual
    </Label>
  </div>
)

const resetDevice = (event, dispatchResetActiveDevice) => {
  event.stopPropagation()
  dispatchResetActiveDevice()
}

const handleClick = (event, history, dispatchResetActiveDevice, deviceId) => {
  resetDevice(event, dispatchResetActiveDevice)
  history.push(`/device/${deviceId}/processes`)
}

const serializeUserAndDate = async process => {
  const user = await nexus.user.getPublic(process.startedBy)

  const { startedAt, name, hasAccess } = process

  return {
    name,
    hasAccess,
    startedBy: user.userName,
    startedAtDay: Time.getDisplayDay(startedAt),
    startedAtTime: Time.getDisplayTime(startedAt),
    duration: (Date.now() - startedAt),
    startedAtSinceEpoch: startedAt,
  }
}

const fetchAndSetRunningData = async (id, setRunningData) => {
  const process = await nexus.process.getPublic(id)
  const serialized = await serializeUserAndDate(process)
  return setRunningData(serialized)
}

const continuouslyUpdateRunningDuration = setRunningData => (
  setInterval(() => {
    setRunningData(prevRunningData => ({
      ...prevRunningData,
      duration: (Date.now() - prevRunningData.startedAtSinceEpoch),
    }))
  }, 15000)
)

const DeviceWidget = props => {
  const { history, dispatchResetActiveDevice, dispatchCallUpdateDevice, classes } = props
  const { device: { connectionState, isVirtual, name, runningProcessId, type, id } } = props
  const { image, statusText, textColor } = displayMap['online']['idle'] || DEFAULT_DISPLAY
  const [runningData, setRunningData] = useState(null)
  const [hovered, setHover] = useState(false)

  const isActive = (statusText === 'Running' || statusText === 'Paused')

  useEffect(() => {
    if (isActive) {
      fetchAndSetRunningData(runningProcessId, setRunningData)
    } else {
      setRunningData(null)
    }
  }, [isActive, runningProcessId])

  useEffect(() => {
    if (isActive && runningData && runningData.startedAtSinceEpoch) {
      const interval = continuouslyUpdateRunningDuration(setRunningData)
      return () => { clearInterval(interval) }
    }
  }, [isActive, runningData])

  return (
    <Card
      onClick={e => handleClick(e, history, dispatchResetActiveDevice, id)}
      className={classes.parent}
    >
      <Card.Content>
        <Card.Header>
          <Image floated='left' size='tiny' src={image} />
          <EditableHeader
            name={name}
            changeNameHandler={newName => dispatchCallUpdateDevice(id, { name: newName })}
            fontSize='1em'
            clickable
            hover
            isHoverable
          />
        </Card.Header>
        <Card.Meta>{type}</Card.Meta>
        <ProcessRunningState
          runningData={runningData}
          hovered={hovered}
          setHover={setHover}
          history={history}
          dispatchResetActiveDevice={dispatchResetActiveDevice}
          id={id}
          runningProcessId={runningProcessId}
          connectionState={connectionState}
          resetDevice={resetDevice}
        />
      </Card.Content>

      <Card.Content extra className='center aligned'>
        <div className='position-relative'>
          <Container
            id='device-widget-status-text'
            className={`${textColor} position-absolute center-vertical`}
          >
            {statusText}
          </Container>
          <LEDCircle status={statusText} />
        </div>
        {isVirtual ? renderVirtual() : null}
      </Card.Content>

    </Card>
  )
}

export default withRouter(connect(null, mapDispatchToProps)(injectSheet(styles)(DeviceWidget)))
