import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'

const UnfoldGroupButton = ({ addPhase, IconSize, widgetSize, icon = 'plus' }) => (
  <Segment
    color='blue'
    textAlign='center'
    onClick={addPhase}
    size={widgetSize}
    className='process-tree-button'
    style={{ paddingTop: '5px', paddingBottom: '5px' }}
  >
    <Icon
      size={IconSize}
      name={icon}
      color='blue'
    />
  </Segment>
)

export default UnfoldGroupButton
