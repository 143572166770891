import PhaseGroupHandler from 'utils/process/PhaseGroupHandler'
import { getFirstPhaseId } from 'utils/process'

export default (state, action) => {
  const { processes } = state
  const { payload: { phaseId, processId, groupName } } = action

  let displayedPhaseId = ''

  const updatedProcesses = processes.map(process => {
    if (process.id === processId) {
      const { updatedProcess, deletedPhaseNextId } = PhaseGroupHandler
        .deletePhaseWithinGroup(process, groupName, phaseId)

      displayedPhaseId = deletedPhaseNextId !== -1
        ? deletedPhaseNextId
        : getFirstPhaseId(updatedProcess)

      return updatedProcess
    }
    return process
  })

  return { ...state, processes: updatedProcesses, displayedPhaseId }
}
