import React, { useState } from 'react'
import { Card } from 'semantic-ui-react'
import ProcessTile from './ProcessTile'


const ProcessesTiles = props => {

  const { filteredProcesses, openModalHandler, processClickHandler } = props
  const [ selectedIndex, setSelectedIndex ] = useState(false)

  const onToggleSelectedTile = index => {
    setSelectedIndex(index)
  }

  return (
    <Card.Group>
      {filteredProcesses
        .map(process => (
          <ProcessTile
            key={process.id}
            process={process}
            selectedIndex={selectedIndex}
            onToggleOverlay={onToggleSelectedTile}
            processClickHandler={processClickHandler}
            openModalHandler={openModalHandler}
          />
        ))}
    </Card.Group>
  )
}

export default ProcessesTiles
