import DescriptionParser from 'utils/process/DescriptionParser'
import Streams from 'utils/Streams'
import DataManager from 'utils/DataManager'
import { setStreamTarget } from 'utils/process'

/* Amends the process description when streams of the deviceDescription within
 * a process do not match the current device description in the device streams.
 * Deletes all streams from the process which are not present in the device
 * anymore and adds all missing device streams with default values
 * we need to update at two places: process.description and process.deviceDescription
 */

class ProcessRectifier {

  static addMissingDownstreamTargets(process, missingStreams) {
    missingStreams.forEach(stream => {
      const phaseIds = DescriptionParser.getPhaseIds(process)
      phaseIds.forEach(phaseId => {
        if (!Streams.isDownstream(stream)) return

        if (!(stream.id in process.description[phaseId].downstreams)) {
          process.description[phaseId].downstreams[stream.id] = {}
        }

        setStreamTarget(process, phaseId, stream.id, stream.default)
      })
    })
  }

  static addStreamsToDeviceDescription(process, missingStreams) {
    const { deviceDescription } = process
    process.deviceDescription = [ ...deviceDescription, ...missingStreams ]
  }

  static removeDownstreamTargets(process, streamIds) {
    const phaseIds = DescriptionParser.getPhaseIds(process)
    phaseIds.forEach(phaseId => {
      const phase = DescriptionParser.getPhaseById(process, phaseId)
      for (const streamId of Object.keys(phase.downstreams)) {
        if (streamIds.includes(streamId)) {
          delete phase.downstreams[streamId]
        }
      }
    })
  }

  static removeStreamsFromDeviceDescription(process, streamIds) {
    const filteredDeviceDescription = process.deviceDescription
      .filter(stream => !streamIds.includes(stream.id))

    process.deviceDescription = filteredDeviceDescription
  }

  static amendDescriptionMismatch(process, device, missMatchObj) {
    const amendedProcess = { ...process }
    const { missingInProcess, missingInDevice } = missMatchObj

    ProcessRectifier.addMissingDownstreamTargets(amendedProcess, missingInProcess)
    ProcessRectifier.addStreamsToDeviceDescription(amendedProcess, missingInProcess)

    const streamsToDeleteIds = missingInDevice.map(stream => stream.id)

    ProcessRectifier.removeDownstreamTargets(amendedProcess, streamsToDeleteIds)
    ProcessRectifier.removeStreamsFromDeviceDescription(amendedProcess, streamsToDeleteIds)

    amendedProcess.data = DataManager.initStreamData(amendedProcess)
    return amendedProcess
  }

}

export default ProcessRectifier
