import  nexus from 'nexus'
import { updateProcess, updateProcessDescriptionSnapshot } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callUpdateProcessOptimistic = (dispatch, processId, params) => {
  nexus.process.update(processId, params)
  dispatch(updateProcess({ processId, params }))
  if (params.description) {
    dispatch(updateProcessDescriptionSnapshot({ processId, params }))
  }
}

export default thunkWrapper(callUpdateProcessOptimistic)
