import EventTrigger from 'utils/EventTrigger/EventTrigger'
import Streams from 'utils/Streams'

class ProcessEventTriggers {

  static getByStreamId(process, streamId) {
    return process.eventTriggers[streamId]
  }

  static getByStreamIdAndType(process, streamId, type) {
    const eventTriggers = ProcessEventTriggers.getByStreamId(process, streamId)
    if (eventTriggers === undefined) return
    return eventTriggers[type]
  }

  static hasDisplayableEventTrigger(process, streamId) {
    const eventTriggers = ProcessEventTriggers.getByStreamId(process, streamId)
    if (!eventTriggers) return false
    return Object.keys(eventTriggers).some(type => EventTrigger.getByType(type).isDisplayable)
  }

  static getStreamsWithEvenTriggersDenormalized(process) {
    const streamIds = Object.keys(process.eventTriggers)
    return streamIds.map(streamId => {
      const eventTriggerTypes = Object.keys(process.eventTriggers[streamId])
      const denormalizedTriggers = eventTriggerTypes.map(triggerType => (
        ProcessEventTriggers.getByStreamIdAndType(process, streamId, triggerType)
      ))

      return {
        ...Streams.getById(process.deviceDescription, streamId),
        eventTriggers: denormalizedTriggers,
      }
    })
  }

  static add(process, streamId, newTrigger) {
    const { eventTriggers } = process
    if (!(streamId in eventTriggers)) {
      eventTriggers[streamId] = {}
    }
    eventTriggers[streamId][newTrigger.type] = newTrigger
    return eventTriggers
  }

  static removeByType(process, streamId, type) {
    const { eventTriggers } = process
    if (streamId in process.eventTriggers) {
      delete eventTriggers[streamId][type]
      if (Object.keys(eventTriggers[streamId]).length === 0) {
        delete eventTriggers[streamId]
      }
    }
    return eventTriggers
  }
}

export default ProcessEventTriggers
