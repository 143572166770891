import nexus from 'nexus'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callDownloadProcessData= async (dispatch, processId, upStreams) => {
  const { url } = await nexus.datapoints.requestProcessData({
    processId,
    streams: upStreams.map(stream => ({
      id: stream.id,
      name: stream.name,
    })),
  })
  const a = global.document.createElement('a')
  a.href = url
  a.setAttribute('download', 'download')
  a.click()
}

export default thunkWrapper(callDownloadProcessData)
