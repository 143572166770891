import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'

import callUpdateDevice from 'redux/thunks/device/callUpdateDevice'
import Authorizer from 'utils/Authorizer'
import DeletePopup from './DeletePopup'
import SetUnitModal from '../modals/setunitmodal/SetUnitModal'
import IconButton from 'components/utility/button/IconButton'
import TableHeader from 'components/utility/table/TableHeader'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateDevice: (deviceId, params) => dispatch(
    callUpdateDevice(deviceId, params),
  ),
})

class UnitsTable extends React.Component {

  state = { showSetUnitModal: false }

  toggleShowSetUnitModal = () => {
    this.setState(prevState => ({ showSetUnitModal: !prevState.showSetUnitModal }))
  }

  async deleteStreamUnit(streamId) {

    const { activeDevice, dispatchCallUpdateDevice } = this.props

    const updatedConfig = DeviceConfig.removeStreamDefaultUnit(activeDevice, streamId)
    const updateParams = { config: updatedConfig }

    await dispatchCallUpdateDevice(activeDevice.id, updateParams)
  }

  render() {

    const { activeDevice, user } = this.props
    const { showSetUnitModal } = this.state

    const configuredStreams = DeviceConfig.getConfiguredStreamsWithDisplayedUnit(activeDevice)

    return (
      <>
        <IconButton
          text='Set Unit'
          clickHandler={this.toggleShowSetUnitModal}
        />
        {configuredStreams.length !== 0 && <Table key={1} className='ospin-red' celled collapsing>
          <TableHeader items={['Stream', 'Device Default', 'Custom', 'Info', '']} />
          <Table.Body>
            {configuredStreams.map(stream => (
              <Table.Row key={stream.id} warning={!stream.connected}>
                <Table.Cell>
                  {stream.displayedName}
                </Table.Cell>
                <Table.Cell>
                  {stream.unit}
                </Table.Cell>
                <Table.Cell>
                  {stream.displayedUnit}
                </Table.Cell>
                <Table.Cell>
                  {!stream.connected ? 'stream not connected' : 'Ok'}
                </Table.Cell>
                <Table.Cell>
                  <DeletePopup
                    confirmHandler={() => this.deleteStreamUnit(stream.id)}
                    disabled={!Authorizer.user(user).hasEditRights(activeDevice)}
                    iconName='close'
                    position='right center'
                  />
                </Table.Cell>
              </Table.Row>)
            )}
          </Table.Body>
        </Table>}
        <SetUnitModal
          closeHandler={this.toggleShowSetUnitModal}
          activeDevice={activeDevice}
          open={showSetUnitModal}
        />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnitsTable)
