import React from 'react'
import { Modal, Form, Button, Divider, Icon, Message, Header } from 'semantic-ui-react'
import { connect } from 'react-redux'

import { groupByControllerName } from 'utils/process'
import DescriptionParser from 'utils/process/DescriptionParser'
import callSetStreamDefault from 'redux/thunks/device/callSetStreamDefault'

import ControllerStream from './ControllerStream'

const mapDispatchToProps = dispatch => ({
  dispatchCallSetStreamDefault: (streamId, deviceId, value) => dispatch(
    callSetStreamDefault(streamId, deviceId, value)
  ),
})

class ControllerModal extends React.Component {

  state = {
    savingDefault: false,
    isError: false,
    isSuccess: false,
    errorMessage: '',
  }

  close() {
    this.props.closeHandler()
  }

  resetSuccessMessage () {
    this.setState({ isSuccess: false })
  }

  async handleSaveDefault() {

    this.setState({ savingDefault: true })

    const {
      activeProcess,
      activeDevice,
      phaseId,
      controllerStreams,
      dispatchCallSetStreamDefault,
    } = this.props

    const errors = []

    for (const stream of controllerStreams) {

      const newDefault = DescriptionParser.getStreamTarget(activeProcess, phaseId, stream.id)
      const parsedDefault = parseFloat(newDefault)

      const existingDefault = activeDevice.streamDefaults
        .find(defaultEntry => defaultEntry.streamId === stream.id)

      if (existingDefault && existingDefault.value === parsedDefault) continue
      if (!existingDefault && parsedDefault === stream.default) continue
      if (isNaN(parsedDefault) || parsedDefault > stream.max || parsedDefault < stream.min) {
        errors.push({ id: stream.id, name: stream.name })
        continue
      }

      const res = await dispatchCallSetStreamDefault(
        stream.id, activeProcess.deviceId, parsedDefault
      )
      if (!res.success) {
        errors.push({ id: stream.id, name: stream.name })
      }
    }

    if (errors.length > 0) {
      const errorMessage = errors.reduce((acc, stream) => `${acc}${stream.name}, `, 'Could not set default for: ')
      this.setState({ isError: true, errorMessage, isSuccess: false, savingDefault: false })
    } else {
      this.setState({ isSuccess: true, isError: false, errorMessage: '', savingDefault: false })
    }
  }

  render() {

    const { savingDefault, isError, errorMessage, isSuccess } = this.state
    const { controllerStreams, phaseId, activeProcess, activeDevice, open } = this.props

    const controllerStreamGroups = groupByControllerName(controllerStreams)

    return (
      <Modal
        size='tiny'
        open={open}
        onClose={() => this.close()}
        onClick={event => event.stopPropagation()}
      >
        <Modal.Header>
          <Icon name='setting' />
          Controller
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              error={isError}
              success={isSuccess}
              style={{ marginBottom: '15px', paddingBottom: '15px' }}
            >
              {Object.keys(controllerStreamGroups).map(controllerName => (
                <div key={controllerName}>
                  <Header>{controllerName}</Header>
                  {controllerStreamGroups[controllerName].map(stream => (
                    <ControllerStream
                      key={stream.id}
                      stream={stream}
                      phaseId={phaseId}
                      activeProcess={activeProcess}
                      activeDevice={activeDevice}
                      resetSuccessMessage={() => this.resetSuccessMessage()}
                    />
                  ))}
                  <Divider />
                </div>
              ))}
              <Message
                error
                header='Something went wrong.'
                content={errorMessage}
                icon='warning circle'
              />
              <Message
                success
                header='Success.'
                content='Controller defaults saved.'
                icon='check circle'
              />
              <Button
                type='button'
                floated='left'
                primary
                loading={savingDefault}
                onClick={() => this.handleSaveDefault()}
              >
                Save as Default
              </Button>
              <Button
                floated='right'
                type='button'
                onClick={() => this.close()}
              >
                Close
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(null, mapDispatchToProps)(ControllerModal)
