import React from 'react'
import { Form, Label, Input, TextArea } from 'semantic-ui-react'
import formalize from 'components/utility/modal/formalize'
import ProcessValidator from 'utils/validation/ProcessValidator'

const { VALID_PROCESS_DATA: { nameLength, commentLength } } = ProcessValidator

const renderNameInput = (processName, updateHandler) => (
  <Form.Field error={!ProcessValidator.isValidProcessName(processName)}>
    <label>
      Process Name
    </label>
    <Input
      fluid
      placeholder='new process name...'
      name='processName'
      onChange={updateHandler}
      value={processName}
    />
    <Label>
      {processName.length}/{nameLength}
    </Label>
  </Form.Field>
)

const renderCommentInput = (processComment, updateHandler) => (
  <Form.Field error={!ProcessValidator.isValidProcessComment(processComment)}>
    <label>
      Process Comment (optional)
    </label>
    <TextArea
      name='processComment'
      onChange={updateHandler}
      value={processComment}
    />
    <Label>
      {processComment.length}/{commentLength}
    </Label>
  </Form.Field>
)

const CreateProcessModalForm = ({ processName, processComment, handleUpdate }) => (
  <>
    {renderNameInput(processName, handleUpdate)}
    {renderCommentInput(processComment, handleUpdate)}
  </>
)

export default formalize(CreateProcessModalForm)
