import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Process } from 'utils/process'
import { loadProcessViewer, addProcessToProcessesList } from 'redux/actions/actions'
import callCreateProcess from 'redux/thunks/process/callCreateProcess'
import DeviceConfig from 'utils/DeviceConfig'
import modalize from 'components/utility/modal/modalize'
import CreateProcessModalForm from 'components/device/modals/CreateProcessModalForm'
import ProcessValidator from 'utils/validation/ProcessValidator'


const { VALID_PROCESS_DATA: { nameLength, commentLength } } = ProcessValidator

const mapDispatchToProps = dispatch => ({
  dispatchAddProcessToProcessesList: process => dispatch(addProcessToProcessesList(process)),
  dispatchLoadProcessViewer: process => dispatch(loadProcessViewer(process)),
})

class CreateProcessModal extends React.Component {

  state = {
    processName: `MyProcess-${(new Date(Date.now()).toString().substr(4, 20))}`,
    processComment: '',
    loading: false,
    isError: false,
    errorMessage: '',
  }

  handleUpdate = (_, { name, value }) => {
    this.setState({ [name]: value })
  }

  setErrorMessage = msg => (
    this.setState({ isError: true, errorMessage: msg })
  )

  invalidData = (name, comment) => {
    if (!ProcessValidator.isValidProcessName(name)) {
      this.setErrorMessage(`Process name must be shorter then ${nameLength + 1} characters`)
      return true
    }

    if (!ProcessValidator.isValidProcessComment(comment)) {
      this.setErrorMessage(`Process comment must be shorter then ${commentLength + 1} characters`)
      return true
    }

    return false
  }

  createNewProcess = async () => {

    const { processName, processComment } = this.state
    const {
      activeDevice,
      dispatchLoadProcessViewer,
      dispatchAddProcessToProcessesList,
      location,
      history,
    } = this.props

    if (this.invalidData(processName, processComment)) return

    this.setState({ loading: true })

    let processParams = {
      deviceName: activeDevice.name,
      name: processName,
      comment: processComment,
      description: {},
      entryPhaseId: 0,
      eventTriggers: DeviceConfig.getStreamEventTriggersForProcess(activeDevice),
    }

    /* name aliases are stored within the process deviceDescription;
    /* a mistake was done by overwriting the name prop of a stream
    /* within the deviceDescription instead of simply adding a new prop
    /* which means stream.name in processes is always overwritten with
    /* the customer name alias if there is one - unlike for the device, where
     * stream.name always refer to the device provided default name
     */

    processParams.deviceDescription = activeDevice.streams.map(stream => {
      const displayedName = DeviceConfig.getDisplayedStreamName(activeDevice, stream.id)
      return { ...stream, name: displayedName }
    })

    processParams = Process.appendNewPhase(processParams, activeDevice)

    const res = await callCreateProcess(activeDevice.id, processParams)

    if (res.success) {
      const newProcess = res.process
      dispatchAddProcessToProcessesList(newProcess)
      dispatchLoadProcessViewer(newProcess)

      const path = location.pathname.replace('processes', 'process')
      this.setState({ loading: false }, () => history.push(`${path}/${newProcess.id}`))

    } else {
      this.setState({ loading: false, isError: true, errorMessage: res.message })
    }
  }

  render() {

    const { processName, processComment, loading, isError, errorMessage } = this.state
    const { closeHandler } = this.props

    return (
      <CreateProcessModalForm
        isError={isError}
        errorMessage={errorMessage}
        closeHandler={closeHandler}
        submitHandler={this.createNewProcess}
        loading={loading}
        processName={processName}
        processComment={processComment}
        handleUpdate={this.handleUpdate}
      />
    )
  }
}

export default withRouter(connect(null, mapDispatchToProps)(modalize(CreateProcessModal)))
