import nexus from 'nexus'
import { updateUser } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callUpdateUser = async (dispatch, userId, userData) => {
  const updatedUser = await nexus.user.update(userId, userData)
  dispatch(updateUser(updatedUser))
}

export default thunkWrapper(callUpdateUser)
