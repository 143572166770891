import React from 'react'

const NoDataMessage = ({ text }) => (
  <span
    className='no-data-text'
  >
    {text || 'No data found'}
  </span>
)

export default NoDataMessage
