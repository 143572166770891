import Amplify from 'aws-amplify'
import Auth from './src/auth'
import Device from './src/device'
import Process from './src/process'
import Command from './src/command'
import User from './src/user'
import Log from './src/log'
import DataPoints from './src/datapoints'
import Utils from './src/utils'
import Event from './src/event'
import UIConfig from './src/uIConfig'
import createConfig from './src/amplify/config'

const connectToEnv = env => {
  const config = createConfig(env)
  Amplify.configure(config)
  Device.setIotConfig(config)
}

export default {
  auth: Auth,
  device: Device,
  process: Process,
  command: Command,
  log: Log,
  datapoints: DataPoints,
  user: User,
  utils: Utils,
  event: Event,
  uIConfig: UIConfig,
  connectToEnv,
}
