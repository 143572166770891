import React from 'react'
import { connect } from 'react-redux'
import { Loader } from 'semantic-ui-react'

const mapStateToProps = ({ collapsedSidebar }) => ({ collapsedSidebar })

const DynamicLoader = ({ collapsedSidebar }) => (
  <div style={{ position: 'fixed', top: '50%', left: `calc(50% + ${collapsedSidebar ? 28 : 90}px)` }}>
    <Loader active size='big' />
  </div>
)

export default connect(mapStateToProps)(DynamicLoader)
