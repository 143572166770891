import React from 'react'
import { Menu, Icon, Responsive, Dropdown } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import './DeviceMenu.css'


const goTo = (tab, history) => {
  const pathSplitted = history.location.pathname.split('/')
  const index = pathSplitted.findIndex(el => el === 'device') + 1
  const newPath = `${pathSplitted.filter((_, i) => i <= index).join('/')}/${tab}`
  history.push(newPath)
}

const readActiveTabFromUrl = history => {
  const pathSplitted = history.location.pathname.split('/')
  const index = pathSplitted.findIndex(el => el === 'device') + 2
  return pathSplitted[index]
}

const getDisplayData = activeDevice => {
  const { state, connectionState } = activeDevice

  switch (true) {
    case (connectionState === 'online' && state === 'idle'):
      return ['status-green', 'circle' ]
    case (connectionState === 'online' && state === 'running'):
      return ['status-blue', 'circle' ]
    case (connectionState === 'online' && state === 'paused'):
      return ['status-orange', 'circle' ]
    case connectionState === 'offline':
      return ['status-grey', 'circle outline' ]
    default:
      return ['status-grey', 'circle outline' ]
  }
}

const menuItemsLeft = [
  { tab: 'processes', icon: 'angle double down', text: 'My Processes' },
]

const menuItemsRight = [
  { tab: 'dashboard', icon: 'dashboard', text: 'Dashboard' },
  { tab: 'log', icon: 'book', text: 'Log' },
  { tab: 'setup', icon: 'th', text: 'Setup' },
  { tab: 'access', icon: 'lock', text: 'Access' },
]

const renderMenuItem = ({ tab, icon, text }, activeTab, history) => (
  <Menu.Item
    className='no-border-radius-item'
    active={activeTab === tab}
    onClick={() => goTo(tab, history)}
    key={tab}
  >
    <Icon name={icon} />
    {text}
  </Menu.Item>
)

const renderDropedownItem = ({ tab, icon, text }, activeTab, history) => (
  <Dropdown.Item
    active={activeTab === tab}
    onClick={() => goTo(tab, history)}
    key={tab}
  >
    <Icon name={icon} />
    {text}
  </Dropdown.Item>
)

const renderDropdownMenu = (items, activeTab, history) => items
  .map(item => renderDropedownItem(item, activeTab, history))

const renderMenuItems = (items, activeTab, history) => items
  .map(item => renderMenuItem(item, activeTab, history))

const renderDeviceStats = activeDevice => {
  const [ statusColor, iconType ] = getDisplayData(activeDevice)
  const { name } = activeDevice

  return (
    <>
      <Menu.Item className='max-w-48'>
        <Icon className={statusColor} name={iconType} />
      </Menu.Item>
      <Responsive as={Menu.Item} minWidth={700}>
        <span style={{ fontSize: '16px', fontWeight: '700' }}>
          {name}
        </span>
      </Responsive>
    </>
  )
}

const DeviceMenu = ({ activeDevice, history }) => {
  const activeTab = readActiveTabFromUrl(history)

  return (
    <Menu fluid>
      <Menu.Menu position='left'>
        {renderDeviceStats(activeDevice)}
      </Menu.Menu>
      <Menu.Menu position='left'>
        {renderMenuItems(menuItemsLeft, activeTab, history)}
      </Menu.Menu>
      <Menu.Menu position='right'>
        <Responsive
          as={Menu}
          minWidth={1000}
          className='no-borders-device-menu'
        >
          {renderMenuItems(menuItemsRight, activeTab, history)}
        </Responsive>
        <Responsive
          as={Menu}
          maxWidth={1000}
          className='no-borders-device-menu'
        >
          <Menu.Item
            style={{ cursor: 'pointer', padding: '0px' }}
          >
            <Dropdown
              icon='align justify'
              className='padding-13-16'
            >
              <Dropdown.Menu>
                {renderDropdownMenu(menuItemsRight, activeTab, history)}
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Responsive>
      </Menu.Menu>
    </Menu>
  )
}

export default withRouter(DeviceMenu)
