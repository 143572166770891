import { ACTIONS } from 'redux/actions/actions'
import setSession from 'redux/reducers/user/setSession'
import logout from 'redux/reducers/user/logout'
import removeNotifications from 'redux/reducers/user/removeNotifications'
import removeNotificationsByTopic from 'redux/reducers/user/removeNotificationsByTopic'
import addUserAlert from 'redux/reducers/user/addUserAlert'
import deleteUserAlert from 'redux/reducers/user/deleteUserAlert'
import updateUser from 'redux/reducers/user/updateUser'

export default {
  [ACTIONS.SET_SESSION]: setSession,
  [ACTIONS.LOGOUT]: logout,
  [ACTIONS.REMOVE_NOTIFICATIONS]: removeNotifications,
  [ACTIONS.REMOVE_NOTIFICATIONS_BY_TOPIC]: removeNotificationsByTopic,
  [ACTIONS.ADD_USER_ALERT]: addUserAlert,
  [ACTIONS.DELETE_USER_ALERT]: deleteUserAlert,
  [ACTIONS.UPDATE_USER]: updateUser,
}
