import React from 'react'
import { connect } from 'react-redux'

import callAuthenticateUser from 'redux/thunks/auth/callAuthenticateUser'
import Routes from 'components/routing/Routes'
import DynamicLoader from 'components/utility/DynamicLoader'

const mapStateToProps = state => ({ authenticated: state.authenticated })

const mapDispatchToProps = dispatch => ({
  authenticateUser: () => dispatch(callAuthenticateUser())
})

class App extends React.Component {

  componentDidMount() { this.props.authenticateUser() }

  render() {
    return (
      this.props.authenticated === undefined
        ? <DynamicLoader />
        : <Routes />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
