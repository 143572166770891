import React from 'react'
import { Button, Dropdown, Pagination, Menu } from 'semantic-ui-react'


const getPageOptions = () => ([
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '20', value: 20 },
  { text: '50', value: 50 },
])

const LogViewTableControls = ({
  activePage,
  totalPages,
  resultsPerPage,
  loadingMore,
  updateActivePage,
  updateResultsPerPage,
  loadMoreLogs,
}) => (
  <Menu fluid>
    <Menu.Menu>
      <Menu.Item>
        <Pagination
          defaultActivePage={activePage}
          totalPages={totalPages}
          onPageChange={updateActivePage}
        />
        <span>
          &nbsp;&nbsp;&nbsp;Results per page:
          {' '}
          <Dropdown
            defaultValue={resultsPerPage}
            selection
            options={getPageOptions()}
            compact
            onChange={updateResultsPerPage}
          />
        </span>
      </Menu.Item>
    </Menu.Menu>
    <Menu.Menu position='right'>
      <Menu.Item>
        <Button
          onClick={loadMoreLogs}
          loading={loadingMore}
        >
          Load More
        </Button>
      </Menu.Item>
    </Menu.Menu>
  </Menu>
)

export default LogViewTableControls
