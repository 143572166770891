import React from 'react'
import { Container, Sidebar, Menu, Icon, Image, Divider, Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { capitalize } from 'utils/string'
import callLogout from 'redux/thunks/auth/callLogout'
import callDeleteEphemeralNotifications from 'redux/thunks/user/callDeleteEphemeralNotifications'
import { collapseSidebar } from 'redux/actions/actions'
import logoIcon2 from 'images/logo_icon2.png'
import { getEphemeralNotifications, getInvitationNotifications } from 'utils/user'
import ConciergeModal from './ConciergeModal'
import 'components/main/OspinSidebar.css'

const mapStateToProps = ({ user, collapsedSidebar }) => ({ user, collapsedSidebar })

const mapDispatchToProps = dispatch => ({
  dispatchLogout: () => dispatch(callLogout()),
  dispatchDeleteEphemeralNotifications: (userId, notifications) => dispatch(
    callDeleteEphemeralNotifications(userId, notifications),
  ),
  dispatchCollapseSidebar: boolean => dispatch(collapseSidebar(boolean)),
})

class OspinSidebar extends React.Component {

  state = {
    showConciergeModal: !!getEphemeralNotifications(this.props.user).length,
  }

  getCurrentTabFromUrl() {
    const { location: { pathname } } = this.props
    return pathname.substr(1) || ''
  }

  changeMenuTabHandler = tab => {
    const { history } = this.props
    history.push(`/${tab}`)
  }

  logoutHandler = async () => {
    const { dispatchLogout } = this.props
    await dispatchLogout()
  }

  toggleModal = modalTrigger => {
    this.setState(prevState => ({ [modalTrigger]: !prevState[modalTrigger] }))
  }

  getMenuItems = () => ([
    'devices', 'profile', 'alerts', 'messages', 'changelog',
  ])

  getIcon = tabName => {
    switch (tabName) {
      case 'devices':
        return <Icon name='hdd' />
      case 'profile':
        return <Icon name='user' />
      case 'alerts': {
        const { user } = this.props
        return user.events.length ? <Icon name='bell' color='red' /> : <Icon name='bell' />
      }
      case 'messages': {
        const { user } = this.props
        return getInvitationNotifications(user).length
          ? <Icon name='mail' color='red' />
          : <Icon name='mail' />
      }
      case 'changelog':
        return <Icon name='question circle' color='red' />
      default:
        return null
    }
  }

  getSidebarStyleClasses = () => {
    const { collapsedSidebar } = this.props
    if (collapsedSidebar) return 'medium-collapsed'
    return 'medium'
  }

  getImageClass = () => {
    const { collapsedSidebar } = this.props
    if (collapsedSidebar) return 'shrink-on-collapse'
    return 'shrink-on-small-screen'
  }

  renderCollapseIcon = () => {
    const { dispatchCollapseSidebar, collapsedSidebar } = this.props

    return (
      <Button
        className='toggle-collapse-icon'
        size='mini'
        style={{
          float: 'right',
          padding: '4px',
          marginTop: '4px',
          marginRight: '4px',
          backgroundColor: 'white',
        }}
        icon
        onClick={() => dispatchCollapseSidebar(!collapsedSidebar)}
      >
        <Icon name={collapsedSidebar ? 'chevron right' : 'chevron left'} />
      </Button>
    )
  }

  renderSidebarItemsText = text => {
    const { collapsedSidebar } = this.props
    return (
      <span className={collapsedSidebar ? 'hide-on-collapse' : 'hide-on-small-screen'}>
        {capitalize(text)}
      </span>
    )
  }

  renderMenuItem = (tabName, activeTab) => (
    <Menu.Item
      key={tabName}
      active={activeTab === tabName}
      onClick={() => this.changeMenuTabHandler(tabName)}
      as='a'
    >
      {this.getIcon(tabName)}
      {this.renderSidebarItemsText(tabName)}
    </Menu.Item>
  )

  renderLogoutItem = () => (
    <Menu.Item onClick={this.logoutHandler} as='a'>
      <Icon name='log out' />
      {this.renderSidebarItemsText('logout')}
    </Menu.Item>
  )

  renderEntryModal = () => {
    const { user, dispatchDeleteEphemeralNotifications } = this.props
    const { showConciergeModal } = this.state
    return (
      <ConciergeModal
        user={user}
        show={showConciergeModal}
        closeHandler={() => this.toggleModal('showConciergeModal')}
        deleteEphemeralNotificationsHandler={dispatchDeleteEphemeralNotifications}
        ephemeralNotifications={getEphemeralNotifications(user)}
      />
    )
  }

  render() {
    const activeTab = this.getCurrentTabFromUrl()
    return (
      <>
        <Container>
          <Sidebar
            as={Menu}
            visible
            vertical
            className={this.getSidebarStyleClasses()}
          >
            {this.renderCollapseIcon()}
            <Divider hidden style={{ marginBottom: '24px' }} />
            <Image src={logoIcon2} centered className={this.getImageClass()} />
            <Divider hidden />
            {this.getMenuItems().map(tabName => this.renderMenuItem(tabName, activeTab))}
            {this.renderEntryModal()}
          </Sidebar>
        </Container>
      </>
    )
  }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OspinSidebar))
