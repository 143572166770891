import React from 'react'
import { Message, Icon } from 'semantic-ui-react'

const OutdatedProcessInfoBanner = ({ activeProcess }) => (
  activeProcess.outdated
    ? (
      <Message
        warning
      >
        <Icon name='warning' />
        This process is outdated. Another user may have edited it.
        <span
          role='link'
          className='process-link'
          onClick={() => global.window.location.reload()}
        >
        &nbsp;Refresh
        </span>
      </Message>
    )
    : null
)

export default OutdatedProcessInfoBanner
