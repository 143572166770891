import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'
import { convertFrom, hasUnit } from 'utils/units'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'
import callDeleteStreamDefault from 'redux/thunks/device/callDeleteStreamDefault'
import Authorizer from 'utils/Authorizer'
import IconButton from 'components/utility/button/IconButton'
import TableHeader from 'components/utility/table/TableHeader'
import DeletePopup from './DeletePopup'
import SetStreamDefaultModal from '../modals/SetStreamDefaultModal'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  callDeleteStreamDefault: (streamId, deviceId) => dispatch(callDeleteStreamDefault(streamId, deviceId))
})

class StreamDefaultsTable extends React.Component {

  state = { showSetStreamDefaultModal: false }

  toggleSetStreamDefaultModal = () => {
    this.setState(prevState => ({ showSetStreamDefaultModal: !prevState.showSetStreamDefaultModal }))
  }

  deleteStreamDefault = async streamId => {
    await this.props.callDeleteStreamDefault(streamId, this.props.activeDevice.id)
  }

  renderNameAndValue = (name, value) => (
    <>
      <Table.Cell>
        {name}
      </Table.Cell>
      <Table.Cell>
        {value}
      </Table.Cell>
    </>
  )

  renderFloatStream = ({ targetDefault, unit, displayedUnit, displayedName, id }) => {
    const { activeDevice } = this.props
    const streamIsConvertible = hasUnit(unit)
    const formattedName = streamIsConvertible ? `${displayedName} [${displayedUnit}]` : displayedName
    const customConversion = CustomConversions
      .getCustomConversionsByTargetUnit(activeDevice, id, displayedUnit)

    const displayedValue = customConversion
      ? CustomConversions.convertWithRatio(targetDefault, customConversion)
      : convertFrom(unit).to(displayedUnit)(targetDefault)

    return this.renderNameAndValue(formattedName, displayedValue)
  }

  renderBooleanStream = ({ targetDefault, displayedName }) => {
    const displayedCustomTarget = targetDefault ? 'on' : 'off'
    return this.renderNameAndValue(displayedName, displayedCustomTarget)
  }

  renderStreamByExpectedValueType = configuredStream => {
    switch (configuredStream.expectedValueType) {
      case 'boolean':
        return this.renderBooleanStream(configuredStream)
      default:
        return this.renderFloatStream(configuredStream)
    }
  }

  renderStream = configuredStream => {

    const { id, connected } = configuredStream
    const { activeDevice, user } = this.props

    return (
      <Table.Row key={id} warning={!connected}>
        {this.renderStreamByExpectedValueType(configuredStream)}
        <Table.Cell>
          {!connected ? 'stream not connected' : 'Ok'}
        </Table.Cell>
        <Table.Cell>
          <DeletePopup
            confirmHandler={() => this.deleteStreamDefault(id)}
            disabled={!Authorizer.user(user).hasEditRights(activeDevice)}
            iconName='close'
            position='right center'
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  render() {
    const { activeDevice } = this.props

    const { showSetStreamDefaultModal } = this.state

    const configuredStreams = DeviceConfig.getConfiguredStreamsWithCustomDefault(activeDevice)

    return (
      <>
        <IconButton
          text='Set Default'
          clickHandler={this.toggleSetStreamDefaultModal}
        />
        {configuredStreams.length !== 0 && (
          <Table key={1} className='ospin-red' celled collapsing>
            <TableHeader items={['Stream', 'Default', 'Info', '']} />
            <Table.Body>
              {configuredStreams.map(this.renderStream)}
            </Table.Body>
          </Table>
        )}
        <SetStreamDefaultModal
          closeHandler={this.toggleSetStreamDefaultModal}
          activeDevice={activeDevice}
          open={showSetStreamDefaultModal}
        />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamDefaultsTable)
