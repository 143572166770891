/* eslint-disable no-undef */
import React from 'react'
import { Modal } from 'semantic-ui-react'
import ModalHeader from 'components/utility/modal/ModalHeader'


function modalize(ModalBody) {

  return props => {
    const { closeHandler, open, headerIcon, headerText, size = 'tiny' } = props
    return (
      <Modal
        size={size}
        open={open}
        onClose={closeHandler}
        onClick={event => event.stopPropagation()}
      >
        <ModalHeader icon={headerIcon} header={headerText} />
        <Modal.Content>
          <Modal.Description>
            <ModalBody
              {...props}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default modalize
