import React from 'react'
import { Button } from 'semantic-ui-react'

const TimeFrameBar = ({ activeProcess, mode, setMode }) => (
  <>
    {(activeProcess.state === 'running' || activeProcess.state === 'paused') && (
      <Button
        onClick={() => setMode('live')}
        positive={mode === 'live'}
      >
      Live
      </Button>
    )}
    <Button
      onClick={() => setMode('phase')}
      positive={mode === 'phase'}
    >
      Phase
    </Button>
    <Button
      onClick={() => setMode('hour')}
      positive={mode === 'hour'}
    >
      1h
    </Button>
    <Button
      onClick={() => setMode('hour8')}
      positive={mode === 'hour8'}
    >
      8h
    </Button>
    <Button
      onClick={() => setMode('day')}
      positive={mode === 'day'}
    >
      1d
    </Button>
    <Button
      onClick={() => setMode('full')}
      positive={mode === 'full'}
    >
      Process
    </Button>
  </>
)
export default TimeFrameBar
