import React from 'react'
import { List, Grid, Button, Divider, Image } from 'semantic-ui-react'
import deviceimg from 'images/logo3.png'

const features = [
  {
    header: 'Live statistics on processes data and results',
    description: 'Multiple views and charts for a user to get a in depth understanding of an experiments in a aesthetic presentation.',
    icon: 'chart line',
  },
  {
    header: 'True control with phases',
    description: 'Create a process from phases with individual sets of parameters and transitions to have optimal conditions for every single step of your bioprocess.',
    icon: 'tasks',
  },
  {
    header: 'Start a process from outside the lab',
    description: 'The web applications allows you to run, monitor and edit processes from any device with an internet connection.',
    icon: 'coffee',
  },
  {
    header: 'User profiles',
    description: 'Personalize your experience, save your favorite processes and benefit from a customizable user interface.',
    icon: 'user',
  },
  {
    header: 'Future proof your company',
    description: 'Scalable solutions that will grow with your team and company.',
    icon: 'industry',
  },
  {
    header: 'Instant feedback',
    description: 'Receive messages and alerts to keep up to date with your team, processes and devices.',
    icon: 'envelope',
  },
]
const ListItem = ({ header, desc, icon }) => (
  <List.Item style={{ margin: '12px 0px', cursor: 'default' }}>
    <List.Icon
      style={{
        float: 'left',
        marginRight: '20px !important',
        color: '#900e2c',
      }}
      name={icon}
      size='big'
    />
    <List.Content>
      <List.Header as='h3' style={{ marginBottom: '0.4rem' }}>
        {header}
      </List.Header>
      <List.Description>
        {desc}
      </List.Description>
    </List.Content>
  </List.Item>
)

const renderFeatureList = () => (
  <>
    <List animated verticalAlign='middle'>
      {features.map(el => (
        <ListItem
          key={el.icon}
          header={el.header}
          desc={el.description}
          icon={el.icon}
        />
      ))}
    </List>
  </>
)

const renderDemoButtons = (closeHandler, goToSignUp) => (
  <div>
    <Button
      floated='right'
      positive
      onClick={() => window.open('https://ospin.de/contact/', '_blank')}
    >
      Contact Ospin!
    </Button>
    <Button floated='left' onClick={closeHandler}>
      Close
    </Button>
  </div>
)
const DemoEndModalForm = ({ closeHandler, goToSignUp }) => (
  <>
    <Grid>
      <Grid.Column width={8}>
        {renderFeatureList()}
      </Grid.Column>
      <Grid.Column width={8} textAlign='center' verticalAlign='middle'>
        <Image spaced='right' src={deviceimg} size='large' />
      </Grid.Column>
    </Grid>
    <Divider />
    {renderDemoButtons(closeHandler, goToSignUp)}
    <div style={{ height: '30px' }} />
  </>
)

export default DemoEndModalForm
