import React from 'react'
import { Process } from 'utils/process'
import ProcessesTable from './ProcessesTable'
import ProcessesTiles from './ProcessesTiles'

const ProcessesArea = ({
  viewMode,
  deviceProcesses,
  activeDevice,
  filteredProcesses,
  resultsPerPage,
  activePage,
  totalPages,
  updateResultsPerPage,
  updateActivePage,
  processClickHandler,
  toggleModal,
}) => {
  if (viewMode === 'table') {
    return (
      <ProcessesTable
        deviceProcesses={deviceProcesses}
        filteredProcesses={Process.sortPinnedFirst(filteredProcesses)}
        activeDevice={activeDevice}
        resultsPerPage={resultsPerPage}
        activePage={activePage}
        totalPages={totalPages}
        updateActivePageHandler={updateActivePage}
        updateResultsPerPageHandler={updateResultsPerPage}
        processClickHandler={processClickHandler}
        openModalHandler={toggleModal}
      />
    )
  }

  return (
    <ProcessesTiles
      filteredProcesses={Process.sortPinnedFirst(filteredProcesses)}
      resultsPerPage={resultsPerPage}
      activePage={activePage}
      totalPages={totalPages}
      processClickHandler={processClickHandler}
      openModalHandler={toggleModal}
    />
  )
}

export default ProcessesArea
