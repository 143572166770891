export const initialState = {
  user: { notifications: [], events: [] },
  authenticated: undefined,
  processes: [],
  processDescriptionSnapshots: [],
  devicesInitialized: false,
  noDevices: false,
  devices: [],
  heartbeats: { devices: {} }, // i.e. { heartbeats: { devices: { id: <lastHeartbeatTimeInMS> } }
  deviceUsers: [],
  logs: [],
  displayedPhaseId: 0,
  processBuilderValidationErrors: [],
  flashMessages: [],
  uIConfigs: [],
  collpasedSidebar: false,
}
