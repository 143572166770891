import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import callModifyDeviceAccess from 'redux/thunks/device/callModifyDeviceAccess'
import User from './User'

const mapStateToProps = state => ({
  user: state.user,
  deviceUsers: state.deviceUsers,
})

const mapDispatchToProps = dispatch => ({
  dispatchCallModifyDeviceAccess: (deviceId, userId, newGroup, oldGroup) => dispatch(
    callModifyDeviceAccess(deviceId, userId, newGroup, oldGroup),
  ),
})

const UserTable = ({
  activeDevice,
  dispatchCallModifyDeviceAccess,
  user,
  deviceUsers,
}) => {

  const modifyAccess = async (userId, newGroup) => {

    const oldGroup = activeDevice.access.find(group => group.users.includes(userId)).name

    if (newGroup === oldGroup || newGroup === '') {
      return
    }
    await dispatchCallModifyDeviceAccess(activeDevice.id, userId, newGroup, oldGroup)
  }

  let me
  if (deviceUsers.length) {
    me = deviceUsers.find(deviceUser => deviceUser.id === user.id)
  }

  const exludingSupportUsers = deviceUsers.filter(deviceUser => deviceUser.accessGroup !== 'support')
  return (
    <Table className='ospin-red' celled selectable padded sortable>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell>Username</Table.HeaderCell>
          <Table.HeaderCell>Group</Table.HeaderCell>
          <Table.HeaderCell />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {exludingSupportUsers.map(deviceUser => (
          <User
            activeDevice={activeDevice}
            key={deviceUser.id}
            user={deviceUser}
            me={me}
            modifyAccessHandler={modifyAccess}
          />
        ))}
      </Table.Body>
    </Table>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(UserTable)
