import store from 'redux/store'
import callLoadHistoricStreamData from 'redux/thunks/process/callLoadHistoricStreamData'
import DataManager from 'utils/DataManager'
import LineGenerator from 'utils/LineGenerator'
import { applyUnitConversionOnData, convertFrom } from 'utils/units'

class GraphTools {

  static CONSTANTS = {
    PAN_DEBOUNCE_INTERVAL: 2000,
    MINIMUM_POINTS_IN_VIEWPORT: 600,
    RELOAD_COUNT: 3000,
    DEFAULT_NUMBER_POINTS: 3000,
    POINTS_IN_LIVE_VIEW: 120,
    MANUAL_ZOOM_Y_MIN: 0,
    MANUAL_ZOOM_Y_MAX: 100,
  }

  static getZoomParams = (
    mode,
    XviewportMaximum = null,
    XviewportMinimum = null,
    YviewportMaximum = null,
    YviewportMinimum = null,
  ) => {

    const viewportParams = {
      XviewportMaximum,
      XviewportMinimum,
      YviewportMaximum,
      YviewportMinimum,
    }

    if (mode === 'auto') viewportParams.type = 'xy'
    else if (mode === 'fixed-y-axis') viewportParams.type = 'x'
    return viewportParams
  }

  static changeZoomMode = ({ data }) => {

    const mode = data.value
    const zoomParams = this.getZoomParams(mode)
    let Ymin
    let Ymax

    if (mode === 'auto') {
      Ymin = null
      Ymax = null
    } else if (mode === 'fixed-y-axis') {
      Ymin = this.CONSTANTS.MANUAL_ZOOM_Y_MIN
      Ymax = this.CONSTANTS.MANUAL_ZOOM_Y_MAX
    }
    return ({
      zoomMode: data.value,
      zoomParams,
      Ymin,
      Ymax,
    })
  }

  static eventTriggersDataFetch = ({ event, lastPan }) => {
    const newPan = Date.now()

    if (event.trigger === 'zoom') return { triggersFetch: true, lastPan }

    if (event.trigger === 'pan') {
      if (!lastPan || newPan - lastPan > this.CONSTANTS.PAN_DEBOUNCE_INTERVAL) {
        return { triggersFetch: true, lastPan: newPan }
      }
    }

    return { triggersFetch: false, lastPan }
  }

  static async fetchDataInViewport({
    event,
    zoomMode,
    activeProcess,
    stream,
    completeStreamData,
  }) {

    /*
     * basic idea:
     *  determine how many datapoints are visible - if not enough,
     *  load x datapoints in the given range,plus additional
     *  datapoints to the left and right for panning;
     *  because panning triggers rangeChange-event multiple times,
     *  its limited on once every 2 seconds
     *  Override 'reset'-event to move back to current selected
     *  view (otherwise no render is triggered)
     */

    const minTime = event.axisX[0].viewportMinimum
    const maxTime = event.axisX[0].viewportMaximum

    const datapointsInViewPort = completeStreamData
      .filter(dp => dp.x >= minTime && dp.x <= maxTime).length

    const minValue = event.axisY[0].viewportMinimum
    const maxValue = event.axisY[0].viewportMaximum

    const zoomParams = this.getZoomParams(zoomMode, maxTime, minTime, maxValue, minValue)

    if (datapointsInViewPort < this.CONSTANTS.MINIMUM_POINTS_IN_VIEWPORT) {

      // augment the selected viewport on the x-Axis
      // by width = maxTime - minTime to the left and right
      // to have one 'width' of current viewport with the
      // right density loaded to the right and left for
      // panning without reloading immediately

      const width = maxTime - minTime
      const start = minTime - width < 0 ? 0 : minTime - width
      const end = maxTime + width

      await store.dispatch(callLoadHistoricStreamData(
        activeProcess.id, stream.id, this.CONSTANTS.RELOAD_COUNT, start, end,
      ))
    }
    return ({ zoomParams })
  }

  static computeSlice({ streamData, mode, selectedPhaseId, activeProcess, zoomParams }) {
    const slicedStreamData = DataManager.sliceStreamData(
      activeProcess, streamData, mode, selectedPhaseId, this.CONSTANTS, zoomParams
    )
    return DataManager.generatePhaseSeriesData(activeProcess, slicedStreamData)
  }

  static getLastDataPoint({ streamData, unitConverter }) {
    return streamData.length ? unitConverter(streamData[streamData.length - 1].y) : 'No Data'
  }

  static getFirstDisplayedTime(displayedGraphData) {
    if (displayedGraphData.length === 0) return 0
    const { dataPoints } = displayedGraphData[0]
    return dataPoints[0] ? dataPoints[0].x : 0
  }

  static getLastDisplayedTime(displayedGraphData) {
    if (displayedGraphData.length === 0) return 0
    const { dataPoints } = displayedGraphData[displayedGraphData.length - 1]
    return dataPoints[dataPoints.length - 1] ? dataPoints[dataPoints.length - 1].x : 0
  }

  static getDisplayedRange({ zoomParams, displayedGraphData }) {
    const { XviewportMinimum, XviewportMaximum } = zoomParams

    const start = XviewportMinimum !== null
      ? XviewportMinimum
      : this.getFirstDisplayedTime(displayedGraphData)
    const end = XviewportMaximum !== null
      ? XviewportMaximum
      : this.getLastDisplayedTime(displayedGraphData)

    return { start, end }
  }

  static createLinesByType({
    streamData,
    displayedGraphData,
    stream,
    activeProcess,
    zoomParams,
    lineType,
  }) {
    const { start, end } = this.getDisplayedRange({ zoomParams, displayedGraphData })

    switch (lineType) {
      case 'target':
        return LineGenerator.getTargetLines(stream, activeProcess, { start, end }, streamData)
      case 'eventTrigger':
        return LineGenerator.getEventTriggerLines(stream, activeProcess, { start, end }, streamData)
      default:
        return []
    }
  }

  static getDisplayedGraphData({
    streamData,
    mode,
    selectedPhaseId,
    activeProcess,
    unitConverter,
    stream,
    showTargetLine,
    zoomParams,
    showEventTriggerLines,
  }) {
    const displayedGraphData = this.computeSlice({
      streamData, mode, selectedPhaseId, activeProcess, zoomParams,
    })

    if (showTargetLine) {
      const targetLine = this.createLinesByType({
        streamData,
        displayedGraphData,
        stream,
        activeProcess,
        zoomParams,
        lineType: 'target',
      })

      if (targetLine) displayedGraphData.push(targetLine)
    }

    if (showEventTriggerLines) {
      const triggerLines = this.createLinesByType({
        streamData,
        displayedGraphData,
        stream,
        activeProcess,
        zoomParams,
        lineType: 'eventTrigger',
      })

      if (triggerLines && triggerLines.length) {
        displayedGraphData.push(...triggerLines)
      }
    }

    applyUnitConversionOnData(displayedGraphData, unitConverter)
    return displayedGraphData
  }

  static getDisplayedStreamData({
    stream,
    streamData,
    selectedUnit,
    mode,
    selectedPhaseId,
    activeProcess,
    showTargetLine,
    zoomParams,
    showEventTriggerLines,
  }) {
    const unitConverter = convertFrom(stream.unit).to(selectedUnit)
    const lastDataPoint = GraphTools.getLastDataPoint({ streamData, unitConverter })
    const displayedGraphData = GraphTools.getDisplayedGraphData({
      streamData,
      mode,
      selectedPhaseId,
      activeProcess,
      unitConverter,
      stream,
      showTargetLine,
      zoomParams,
      showEventTriggerLines,
    })

    return { lastDataPoint, displayedGraphData }
  }
}

export default GraphTools
