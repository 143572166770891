import React from 'react'
import { Transition, Button, Icon } from "semantic-ui-react"
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import { Dispatch, AnyAction } from 'redux'

import { deleteFlashMessage } from 'redux/actions/actions'

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => ({
  dispatchDeleteFlashMessage: messageId => dispatch(deleteFlashMessage({ messageId }))
})

export type Message = {
  type: 'warning' | 'error' | 'info' | 'success' | 'alarm',
  text: string,
  persistTime: number,
  id: number,
}

type FlashMessageState = {
  visible: boolean,
  persistTimeout: any,
}

type OwnProps = {
  message : Message,
}

type DispatchProps = {
  dispatchDeleteFlashMessage: (messageId: number) => void
}

type IconType = 'check' | 'info' | 'warning' | 'ban' | 'alarm'

type MessageIcons = {
  [key : string]: IconType,
}

const messageIcons : MessageIcons = {
  error: 'ban',
  warning:'warning',
  info:  'info',
  success: 'check',
  alarm: 'alarm',
}

type Props = OwnProps & DispatchProps & StyleProps

class FlashMessage extends React.Component<Props, FlashMessageState> {

  state = {
    visible: false,
    persistTimeout: undefined,
  }

  ANIMATION_DURATION = 700

  componentDidMount() {
      this.setState({ visible: true })
      const { message: { persistTime, id }, dispatchDeleteFlashMessage} = this.props

      const persistTimeout = setTimeout(() =>
        this.setState({ visible: false }, () => {
        setTimeout(() => dispatchDeleteFlashMessage(id), this.ANIMATION_DURATION)
      }), persistTime)
      this.setState({ persistTimeout })
  }

  componentWillUnmount() {
    const { persistTimeout } = this.state
    if (persistTimeout) clearTimeout(persistTimeout)
  }

  deleteMessage = () => {
    const { message: { id }, dispatchDeleteFlashMessage} = this.props
    dispatchDeleteFlashMessage(id)
  }

  getMessageIcon = (type: keyof typeof messageIcons) : IconType => (messageIcons[type])

  render() {

    const { visible } = this.state
    const { message : { type, text }, classes } = this.props

    const icon = this.getMessageIcon(type)

    return (
      <Transition
        visible={visible}
        animation='fly left'
        duration={this.ANIMATION_DURATION}
      >
        <div
          id='flex-override'
          className={classes.messageWrapper}
        >
          <Icon
            name={icon}
            size='big'
            className={classes.messageIcon}
          />
          <div>
            {text}
          </div>
          <Button
            icon
            circular
            basic
            inverted
            onClick={() => this.deleteMessage()}
            className={classes.closeButton}
          >
            <Icon name='close' />
          </Button>
        </div>
      </Transition>
    )
  }
}

// STYLES

type FlashMessageStyleType = {
  messageWrapper: any,
  messageIcon: any,
  closeButton: any,
}

type StyleProps = {
  classes: FlashMessageStyleType
}

const messageBackgrounds = {
  error: 'red',
  alarm: 'red',
  warning:'#fc9403',
  info:  '#1678c2',
  success: '#21ba45',
}

const getMessageBackground = (type: keyof typeof messageBackgrounds) => (messageBackgrounds[type])

const styles = {
  messageWrapper: {
    backgroundColor: (props : Props) => getMessageBackground(props.message.type),
    padding: '24px',
    color: 'white',
    fontSize: '1.15rem',
    fontWeight: 'bold',
    marginBottom: '16px',
    boxShadow: '0px 0px 10px 2px rgba(0,0,0,0.35)',
    width: '400px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  messageIcon: {
    marginRight: '16px !important',
  },
  closeButton: {
    marginLeft: '16px !important',
  }
}


export default connect<FlashMessageState, DispatchProps, OwnProps>(null, mapDispatchToProps)(injectSheet<string,any>(styles)(FlashMessage))
