import React from 'react'
import { Grid, Menu, Input } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import callGetDevices from 'redux/thunks/device/callGetDevices'
import DynamicLoader from 'components/utility/DynamicLoader'
import DeviceList from './DeviceList'

const mapStateToProps = state => ({
  user: state.user,
  devices: state.devices,
  processes: state.processes,
  noDevices: state.noDevices,
  devicesInitialized: state.devicesInitialized,
})

const mapDispatchToProps = dispatch => (
  { dispatchCallGetDevices: (cachedDevices, userId) => dispatch(callGetDevices(cachedDevices, userId)) }
)

class Devices extends React.Component {

  state = {
    initializing: true,
    filterQuery: '',
  }

  async componentDidMount() {
    const { history, dispatchCallGetDevices, devicesInitialized, devices, user } = this.props

    history.replace('devices')
    if (!devicesInitialized) {
      await dispatchCallGetDevices(devices, user.id)
    }
    this.setState({ initializing: false })
  }

  filterDevicesByName = event => {
    const filterQuery = event.target.value.trim()
    this.setState({ filterQuery })
  }

  render() {

    const { initializing, filterQuery } = this.state
    const { devices, noDevices } = this.props

    if (initializing) return <DynamicLoader />

    return (
      <Grid>
        <Grid.Row>
          <Menu position='right'>
            <Menu.Item>
              <Input
                icon='search'
                iconPosition='left'
                placeholder='Search by name'
                onChange={this.filterDevicesByName}
              />
            </Menu.Item>
          </Menu>
        </Grid.Row>
        <Grid.Row>
          <DeviceList
            devices={devices.sort((deviceA, deviceB) => deviceA.name.localeCompare(deviceB.name))}
            noDevices={noDevices}
            filterQuery={filterQuery}
          />
        </Grid.Row>
      </Grid>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Devices))
