import ArrayTools from 'utils/ArrayTools'

export default class StreamsUIConfig {

  static toggleFolded(streams, streamId) {
    const targetStream = streams.find(stream => stream.streamId === streamId)
    targetStream.folded = !targetStream.folded

    return streams
  }

  static moveStream(group, sourceIndex, destinationIndex) {
    return ArrayTools.moveFromTo(group.streams, sourceIndex, destinationIndex)
  }
}
