import OffsetOnlyMode from 'utils/calibration/OffsetOnlyMode'
import LinearCalibrationDirectMode from 'utils/calibration/LinearCalibrationDirectMode'
import LinearCalibrationSelectionMode from 'utils/calibration/LinearCalibrationSelectionMode'


class Calibration {

  constructor(component) {
    this.component = component
  }

  get mode() {
    const { type } = this.component.state

    switch (type) {
      case 'offset-man': {
        return OffsetOnlyMode
      }
      case 'offset-slope-man': {
        return LinearCalibrationDirectMode
      }
      case 'offset-slope-avrg2p': {
        return LinearCalibrationSelectionMode
      }
      default:
        throw new Error(`Unknown calibration type: ${type}`)
    }
  }

  getParams = () => (
    this.mode.calculateParams(this.component.state)
  )

  getUpdatedPointSelection = time => (
    this.mode.getUpdatedPointSelection(this.component.state, time)
  )

  getUpdatedAverage = rawData => {
    const { mode, component } = this
    const { state, props } = component
    return mode.getUpdatedAverage(state, props, rawData)
  }

  attachGraphSlice = (streamData, rawData) => {
    const { state, props } = this.component
    this.mode.attachGraphSlice(streamData, rawData, state, props)
  }
}

export default Calibration
