import React from 'react'
import { Grid, Card } from 'semantic-ui-react'

import DeviceWidget from 'components/device/widget/DeviceWidget'

const renderDeviceWidget = (device, idx) => (
  <DeviceWidget
    key={idx}
    device={device}
  />
)

const DeviceList = props => {
  const { filterQuery, devices, noDevices } = props
  const queryDeviceByName = name => new RegExp(filterQuery, 'i').test(name)
  const filteredDevices = (filterQuery !== undefined && filterQuery !== '') ? devices.filter(({ name }) => queryDeviceByName(name)) : devices
  return (
    <Grid>
      <div>
        {noDevices
          ? <p className='text-center margin-top-50'>You don't have access to any device yet.</p>
          : (
            <Card.Group>
              { filteredDevices.map((device, idx) => renderDeviceWidget(device, idx)) }
            </Card.Group>
          )}
      </div>
    </Grid>
  )
}

export default DeviceList
