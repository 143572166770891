import React from 'react'
import { Message } from 'semantic-ui-react'

import DeviceConfig from 'utils/DeviceConfig'
import { convertFrom, replaceNoUnitCharacter } from 'utils/units'

const ExistingCalibrationMessage = ({
  activeDevice,
  stream,
}) => {

  const existingCalibration = DeviceConfig.getStreamCalibration(activeDevice, stream.id)

  if (!existingCalibration) return null

  const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)
  const unitConverter = convertFrom(stream.unit).to(displayedUnit)
  const displayedOffset = unitConverter(existingCalibration.offset) - unitConverter(0)

  return (
    <Message
      info
      header='This module has a set calibration:'
      content={`Current Offset: ${displayedOffset.toFixed(3)} ${replaceNoUnitCharacter(displayedUnit, '')}
        | Current Slope: ${existingCalibration.slope.toFixed(3)}`}
    />)
}

export default ExistingCalibrationMessage
