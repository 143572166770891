import React from 'react'
import { connect } from 'react-redux'
import { Header, List, Ref } from 'semantic-ui-react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { Process, PROCESS_STATES } from 'utils/process'
import DescriptionParser from 'utils/process/DescriptionParser'
import PhaseGroupRenderer from 'utils/render/PhaseGroupRenderer'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import FlashMessenger from 'utils/FlashMessenger'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import {
  addProcessPhase,
  moveProcessPhase,
  addPhaseWithinGroup,
  setDisplayedProcessPhase,
} from 'redux/actions/actions'
import PhaseAddition from './PhaseAddition'
import PhaseTreeBody from './PhaseTreeBody'

const mapDispatchToProps = dispatch => ({
  dispatchAddProcessPhase: (processId, deviceId) => dispatch(addProcessPhase(
    { processId, deviceId },
  )),
  dispatchAddPhaseWithinGroup: (processId, groupName) => dispatch(addPhaseWithinGroup(
    { processId, groupName },
  )),
  dispatchMoveProcessPhase: (processId, src, dest, srcArea, destArea, srcPhaseInGroup) => dispatch(moveProcessPhase(
    {
      processId, src, dest, srcArea, destArea, srcPhaseInGroup,
    },
  )),
  setDisplayedProcessPhase: phaseId => dispatch(
    setDisplayedProcessPhase(phaseId),
  ),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

class PhasesNavbarWrapper extends React.Component {

  messagesEndRef = React.createRef()

  constructor() {
    super()
    this.state = { isGroupingDisabled: false }
  }

  onToggleGroupButton = () => {
    this.setState(({ isGroupingDisabled }) => ({ isGroupingDisabled: !isGroupingDisabled }))
  }

  scrollToBottom = () => {
    const { y } = this.messagesEndRef.current.getBoundingClientRect()
    if (y > global.window.innerHeight) {
      this.messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }

 // onBeforeDrag = result => {
 //   const { draggableId } = result
 //   if (draggableId.includes('group-')) {
 //     this.onToggleGroupButton()
 //   }
 // }

  isSupportedMove = (source, destination) => {
    if (destination.droppableId === 'process-tree' && source.droppableId === 'process-tree') return true
    return false
  }

  getDeepestSourceElement = (renderedTreeElements, source) => {
    const srcElement = renderedTreeElements[source.index]
    return srcElement.isGroup ? srcElement.group[0] : srcElement
  }

  getSourcePhaseId = (renderedTreeElements, source) => (
    this.getDeepestSourceElement(renderedTreeElements, source).id
  )

  isBetweenGroups = (source, destination, renderedTreeElements) => {
    const testDest = renderedTreeElements[destination.index]
    const elementAfterDest = renderedTreeElements[destination.index + 1]

    if (!elementAfterDest) return false

    if (testDest.isGroup && elementAfterDest.isGroup) return true
    return false
  }

  isMoveDownwards = (source, destination) => source.index < destination.index

  getDestinationOffset = (source, destination, sourceElement) => {
    if (source.droppableId === 'process-tree' && destination.droppableId === 'process-tree') {
      return sourceElement.isGroup && this.isMoveDownwards(source, destination) ? 1 : 0
    }

    return 0
  }

  getDestPhaseId = (renderedTreeElements, source, destination) => {
    const { activeProcess } = this.props

    if (destination.index >= renderedTreeElements.length) {
      return -1
    }

    const srcElement = renderedTreeElements[source.index]
    const offset = this.getDestinationOffset(source, destination, srcElement, renderedTreeElements)
    const destElement = renderedTreeElements[destination.index + offset]

    if (!destElement) return -1

    if (destElement.isGroup && !srcElement.isGroup && this.isMoveDownwards(source, destination)) {
      return PhaseGroupParser.getLastPhaseIdOfGroup(activeProcess, destElement.groupName)
    }

    return destElement.isGroup ? destElement.group[0].id : destElement.id
  }

  updateProcess = result => {
    const { activeProcess, dispatchMoveProcessPhase, phases } = this.props

    if (activeProcess.state === PROCESS_STATES.finished) return

    const { destination, source } = result

    if (!destination) return

    if (!this.isSupportedMove(source, destination)) {
      FlashMessenger.warning('Support for drag and drop for groups will be provided soon!')
      return
    }

    const renderedTreeElements = PhaseGroupRenderer.createProcessTreeElements(phases)
    const srcElement = renderedTreeElements[source.index]

    const srcPhaseId = this.getSourcePhaseId(renderedTreeElements, source)
    const destPhaseId = this.getDestPhaseId(renderedTreeElements, source, destination)

    if (!Process.isValidPhaseOrderChange(activeProcess, srcPhaseId, destPhaseId, destination)) {
      return
    }

    const srcPhaseInGroup = srcElement.isGroup

    const srcIndex = DescriptionParser.getPhaseExecutionIndex(activeProcess, srcPhaseId)
    const destIndex = destPhaseId !== -1
      ? DescriptionParser.getPhaseExecutionIndex(activeProcess, destPhaseId)
      : DescriptionParser.getPhaseCount(activeProcess)

    if (srcIndex === destIndex && destination.droppableId === source.droppableId) {
      return
    }

    dispatchMoveProcessPhase(
      activeProcess.id,
      srcIndex,
      destIndex,
      source.droppableId,
      destination.droppableId,
      srcPhaseInGroup,
    )

    /* had to wrap save here, otherwise it was overriding the process
     * with the phases moved with the process as it was before moving a phase
     */

    setTimeout(() => this.save(), 10)
  }

  onDragEnd = result => {
    //const { draggableId } = result
    this.updateProcess(result)
    //if (draggableId.includes('group-')) {
    //  this.onToggleGroupButton()
    //}
  }

  addPhaseWithinGroup = async (phaseIdToCopy, groupName) => {
    const { dispatchAddPhaseWithinGroup } = this.props
    dispatchAddPhaseWithinGroup(phaseIdToCopy, groupName)
    await this.save()
  }

  setPhaseGroupIterationCount = async (groupName, count) => {
    const { activeProcess, dispatchSetPhaseGroupIterationsCount } = this.props
    dispatchSetPhaseGroupIterationsCount(activeProcess.id, groupName, count)
    await this.save()
  }

  async save() {
    const { activeProcess, dispatchCallUpdateProcess } = this.props
    if (activeProcess.state === PROCESS_STATES.executable) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  render() {

    const {
      activeProcess,
      runningPhase,
      activeDevice,
      dispatchAddProcessPhase,
      phases,
      dispatchAddPhaseWithinGroup,
    } = this.props

    const { isGroupingDisabled } = this.state

    const addPhase = async () => {
      dispatchAddProcessPhase(activeProcess.id, activeDevice.id)
      await this.save()
      this.scrollToBottom()
    }

    const addPhaseToGroup = async groupName => {
      dispatchAddPhaseWithinGroup(activeProcess.id, groupName)
      await this.save()
    }

    return (
      <>
        <Header textAlign='center' as='h3' style={{ marginTop: '0px', marginRight: '16px' }}>
          Process Tree
        </Header>
        <DragDropContext
          onDragEnd={e => this.onDragEnd(e)}
          //onBeforeCapture={e => this.onBeforeDrag(e)}
        >
          <Droppable
            droppableId='process-tree'
            type='phase-area'
          >
            {(provided, snapshot) => (
              <Ref innerRef={provided.innerRef}>
                <div
                  {...provided.droppableProps}
                  className='ospin-scrollbar'
                >
                  <List
                    relaxed
                    verticalAlign='middle'
                    size='big'
                    style={{ marginRight: '16px', maxHeight: '80vh' }}
                  >
                    <PhaseTreeBody
                      runningPhase={runningPhase}
                      saveProcessState={this.save}
                      addPhaseToGroup={addPhaseToGroup}
                      activeProcess={activeProcess}
                      groupedPhaseData={PhaseGroupRenderer.createProcessTreeElements(phases)}
                      isGroupingDisabled={isGroupingDisabled}
                      isDraggingOver={snapshot.isDraggingOver}
                      activeDevice={activeDevice}
                    />
                    {provided.placeholder}
                    <List.Item>
                      <PhaseAddition
                        processState={activeProcess.state}
                        addPhase={addPhase}
                        IconSize='large'
                        widgetSize='large'
                        isDisabled={isGroupingDisabled}
                      />
                    </List.Item>
                    <div ref={this.messagesEndRef} />
                  </List>
                </div>
              </Ref>
            )}
          </Droppable>
        </DragDropContext>
      </>
    )
  }
}

export default connect(null, mapDispatchToProps)(PhasesNavbarWrapper)
