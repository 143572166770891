import  nexus from 'nexus'
import { addProcessToProcessesList } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callCloneProcess = async (dispatch, processId, processName) => {
  const processCloneData = await nexus.process.clone(processId, { processName })
  dispatch(addProcessToProcessesList(processCloneData))
}

export default thunkWrapper(callCloneProcess)
