import React from 'react'
import { withRouter } from 'react-router-dom'
import nexus from 'nexus'

import config from 'react-global-configuration'
import { connect } from 'react-redux'
import StackTrace from 'stacktrace-js'

function logClientError(errorObject) {
  nexus.utils.logClientError(errorObject)
}

const mapStateToProps = state => ({ user: state.user })

class ErrorWrapperProvider extends React.Component {

  async componentDidCatch(error) {
    const errorObject = await this.serializeErrorData(error)

    const reportClientErrors = config.get('reportClientErrors')
    if (reportClientErrors) {
      logClientError(errorObject)
      this.redirectToReport(errorObject)
    }
  }

  redirectToReport(errorObject) {
    this.props.history.push({
      pathname: '/report',
      errorObject,
    })
  }

  async serializeErrorData(error) {
    const {
      user,
      history: { location: { pathname } },
    } = this.props

    const userName = user ? user.userName : 'unauthenticated user'
    const stacktraceArray = await StackTrace.fromError(error)
    const stackTraceObject = stacktraceArray.length !== 0 ? stacktraceArray[0] : { lineNumber: '', functionName: '', fileName: '' }
    const { lineNumber, functionName, fileName } = stackTraceObject

    return {
      errorString: String(error),
      errorTime: new Date(),
      lineNumber: String(lineNumber),
      functionName: String(functionName),
      fileName: String(fileName),
      userName,
      pathname,
    }
  }

  render() {
    return this.props.children
  }
}
export default withRouter(connect(mapStateToProps, null)(ErrorWrapperProvider))
