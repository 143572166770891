import DataTools from 'utils/DataTools'

class CalibrationMode {
  static getAverage = (rawData, start, end) => {
    if (!start || !end) return
    const slice = DataTools.valuesWithinTimeRange(rawData, start, end)
    return DataTools.averageValues(slice)
  }

  static getUpdatedPointSelection() {
    return { params: null }
  }

  static attachGraphSlice() { }

  static createGraphSlice = (dataPoints, {
    color = 'red',
    label = 'Interval 1',
    markerSize = 3,
    markerType = 'circle',
  } = {}) => ({
    markerSize,
    type: 'line',
    markerType,
    showInLegend: true,
    legendText: label,
    color,
    dataPoints,
  })

}

export default CalibrationMode
