import nexus from 'nexus'
import { setStreamConfigurationProperty } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callSetStreamNameAlias = async (dispatch, streamId, deviceId, alias) => {
  await nexus.device.setStreamNameAlias(deviceId, { streamId, alias })
  dispatch(setStreamConfigurationProperty({
    deviceId,
    streamId,
    prop: 'streamNameAliases',
    entryValues: alias,
  }))
}

export default thunkWrapper(callSetStreamNameAlias)
