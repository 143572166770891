import { titleCase } from 'title-case'
import { sentenceCase } from 'sentence-case'

class Event {

  static deriveDisplayName(event) {
    if (!event.name && !event.displayName) return 'Unnamed Event'
    const derivedName = event.displayName ? event.displayName : event.name

    return titleCase(sentenceCase(derivedName))
  }

}

export default Event
