import React, { useState } from 'react'
import { Segment, Table, Button, Image, Message, Icon } from 'semantic-ui-react'
import Stream from 'utils/Stream'

const MissingStreams = ({ missingStreams, removeStreamFromDashboardByStreamId }) => {

  const [ showMissingStreams, toggleMissingStreamsView ] = useState(false)

  const toggleText = showMissingStreams ? 'hide' : 'show'

  return (
    <div>
      <Message attached='top' warning>
        <Icon name='warning' />
        Some streams from your dashboard are not connected anymore:
        <span
          className='toggle-text'
          onClick={() => toggleMissingStreamsView(!showMissingStreams)}
        >
          {toggleText}
        </span>
      </Message>
      {showMissingStreams && (
        <Segment attached>
          <Table className='ospin-red' celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell>
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Actions
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {missingStreams.map(missingStream => (
                <Table.Row key={missingStream.streamId}>
                  <Table.Cell>
                    <Image size='mini' src={Stream.getDefaultImage()} />
                  </Table.Cell>
                  <Table.Cell>
                    {missingStream.streamId}
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      inverted
                      color='red'
                      icon='times'
                      onClick={() => removeStreamFromDashboardByStreamId(missingStream.streamId)}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      )}
    </div>
  )
}

export default MissingStreams
