import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Button, Popup, Icon } from 'semantic-ui-react'
import CalibrationModal from 'components/processviewer/modals/calibration/CalibrationModal'
import callDownloadStreamData from 'redux/thunks/process/callDownloadStreamData'

const mapStateToProps = ({ displayedPhaseId }) => ({ displayedPhaseId })

const mapDispatchToProps = dispatch => ({
  dispatchDownloadStreamData: (streamId, streamName, processId) => dispatch(
    callDownloadStreamData(streamId, streamName, processId),
  ),
})

const downloadChannel = async ({ stream, activeProcess, dispatchDownloadStreamData }) => {
  await dispatchDownloadStreamData(stream.id, stream.name, activeProcess.id)
}

const GraphDataOptions = ({
  stream,
  activeProcess,
  activeDevice,
  dispatchDownloadStreamData,
  displayedPhaseId,
}) => {
  const [showCalibrationModal, setCalibrationModelVisibility] = useState(false)
  return (
    <Button.Group compact className='margin-b-16'>
      <Popup
        trigger={(
          <Button
            icon
            primary
            onClick={() => downloadChannel({ stream, activeProcess, dispatchDownloadStreamData })}
          >
            <Icon name='download' />
          </Button>
        )}
        content={<span>Download channel data</span>}
      />
      {activeProcess.state !== 'executable' && (
        <Popup
          trigger={(
            <Button
              icon
              primary
              onClick={() => setCalibrationModelVisibility(!showCalibrationModal)}
            >
              <Icon name='crosshairs' />
            </Button>
          )}
          content={<span>Calibrate</span>}
        />
      )}
      <CalibrationModal
        size='large'
        open={showCalibrationModal}
        closeHandler={() => setCalibrationModelVisibility(!showCalibrationModal)}
        headerIcon='crosshairs'
        headerText={`Calibration of ${stream.name}`}
        stream={stream}
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        displayedPhaseId={displayedPhaseId}
      />
    </Button.Group>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(GraphDataOptions)
