import React from 'react'
import { Button, Divider, Icon, Header, Form, Modal, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Authorizer from 'utils/Authorizer'

import AccessDropdown from 'components/utility/dropdown/FormAccessDropdown'
import callModifyProcessAccess from 'redux/thunks/process/callModifyProcessAccess'
import callGrantProcessAccess from 'redux/thunks/process/callGrantProcessAccess'
import callRevokeProcessAccess from 'redux/thunks/process/callRevokeProcessAccess'
import GroupAccessInformation from './GroupAccessInformation'
import ProcessUserTable from './ProcessUserTable'

const mapStateToProps = ({ user, deviceUsers }) => ({ user, deviceUsers })

const mapDispatchToProps = dispatch => ({
  dispatchCallGrantProcessAccess: (processId, userId, groupName) => dispatch(
    callGrantProcessAccess(processId, userId, groupName),
  ),
  dispatchCallRevokeProcessAccess: (processId, userId, groupName) => dispatch(
    callRevokeProcessAccess(processId, userId, groupName),
  ),
  dispatchCallModifyProcessAccess: (processId, userId, oldGroup, newGroup) => dispatch(
    callModifyProcessAccess(processId, userId, oldGroup, newGroup),
  ),
})

class AccessProcessModal extends React.Component {

  initialResponseState = {
    isError: false,
    errorMessage: '',
    isSuccess: false,
    successMessage: '',
    loading: false,
  }

  initialState = {
    assignedGroup: undefined,
    assignedGroupData: undefined,
    assignedUser: undefined,
    usersLoaded: false,
    processUsers: [],
    userSource: undefined,
    ...this.initialResponseState,
  }

  state = { ...this.initialState }

  handleUserSourceChange = (event, { value }) => {
    this.setState({
      userSource: value,
      ...this.initialResponseState,
    })
  }

  handleUserChange = (event, { value }) => {
    this.setState({
      assignedUser: value,
      ...this.initialResponseState,
    })
  }

  handleGroupChange = (event, { value }) => {
    this.setState({
      assignedGroup: value,
      assignedGroupData: this.props.process.access.find(group => group.name === value),
      ...this.initialResponseState,
    })
  }

  handleAPIResponse = (res, successMessage) => {
    if (res.success) {
      this.setState({
        ...this.initialResponseState,
        isSuccess: true,
        successMessage,
      })
    } else {
      this.setState({
        ...this.initialResponseState,
        isError: true,
        errorMessage: res.message,
      })
    }
  }

  shareProcess = async () => {
    const { assignedGroup, assignedUser } = this.state

    if (!assignedUser) {
      this.setState({
        ...this.initialResponseState,
        isError: true,
        errorMessage: 'Please choose a user',
      })
      return
    }

    if (!assignedGroup) {
      this.setState({
        ...this.initialResponseState,
        isError: true,
        errorMessage: 'Please provide a group for the user.',
      })
      return
    }

    const { deviceUsers, process: { id }, dispatchCallGrantProcessAccess } = this.props

    const newProcessUser = deviceUsers.find(user => user.id === assignedUser)

    this.setState({ loading: true })
    const res = await dispatchCallGrantProcessAccess(id, newProcessUser.id, assignedGroup)
    this.handleAPIResponse(res, 'User has been added to the process')

    this.setState({ assignedUser: undefined })
  }

  withDrawAccess = async (event, userId, groupName) => {
    event.preventDefault()
    const { process: { id }, dispatchCallRevokeProcessAccess } = this.props
    const res = await dispatchCallRevokeProcessAccess(id, userId, groupName)
    this.handleAPIResponse(res, 'User access has been revoked')
  }

  modifyAccess = async (userId, newGroup) => {
    if (!newGroup) return

    const { process: { id, access }, dispatchCallModifyProcessAccess } = this.props

    const oldGroup = access.find(group => group.users.includes(userId)).name

    if (oldGroup === newGroup) return

    const res = await dispatchCallModifyProcessAccess(id, userId, oldGroup, newGroup)
    this.handleAPIResponse(res, 'User access has been modified')
  }

  itsMe = id => (
    this.props.user.id === id
  )

  itsTheOwner = id => (
    this.props.process.ownerId === id
  )

  clearStateAndClose = () => {
    const { closeHandler } = this.props
    this.setState(this.initialState)
    closeHandler()
  }

  getUserSourceOptions = () => ([
    { value: 'device', text: 'device users', key: 1 },
  ])

  getDeviceUserOptions = processUserIds => {
    const { deviceUsers } = this.props

    return deviceUsers
      .filter(user => !this.itsMe(user.id) && !processUserIds.includes(user.id))
      .map((user, i) => ({
        value: user.id,
        text: user.userName,
        key: i,
      }))
  }

  getProcessUsers = processUserIds => {
    const { deviceUsers, process: { access } } = this.props
    return processUserIds.map(userId => {

      const name = deviceUsers.find(user => user.id === userId).userName
      const groupName = access.find(group => group.users.includes(userId)).name

      return {
        name,
        groupName,
        id: userId,
      }
    })
  }

  getGroupOptions = () => {
    const { process: { access } } = this.props

    return access.map(group => ({
      value: group.name,
      text: group.name,
      key: group.name,
    }))
  }

  render() {

    const {
      isError,
      errorMessage,
      assignedGroupData,
      isSuccess,
      successMessage,
      loading,
      assignedUser,
      assignedGroup,
      userSource,
    } = this.state

    const { process, open } = this.props

    const processUserIds = Authorizer.getAllUserIds(process)

    const processUsers = this.getProcessUsers(processUserIds)
    const userSourceOptions = this.getUserSourceOptions()

    return (
      <Modal
        size='tiny'
        open={open}
        onClose={this.clearStateAndClose}
        onClick={event => event.stopPropagation()}
        onOpen={this.message}
      >
        <Modal.Header>
          <Icon name='users' />
          Manage Process Access
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              error={isError}
              success={isSuccess}
              onSubmit={this.shareProcess}
              style={{ marginBottom: '15px', paddingBottom: '15px' }}
            >
              <Form.Group widths='equal'>
                <Form.Select
                  fluid
                  label='Source'
                  options={userSourceOptions}
                  defaultValue={userSourceOptions[0].value}
                  value={userSource}
                  onChange={this.handleUserSourceChange}
                />
                <Form.Select
                  fluid
                  label='User'
                  options={this.getDeviceUserOptions(processUserIds)}
                  value={assignedUser}
                  placeholder='Choose User'
                  onChange={this.handleUserChange}
                />
                <AccessDropdown
                  groupOptions={this.getGroupOptions()}
                  label='Group'
                  placeholder='Choose group'
                  selected={assignedGroup}
                  onUpdate={this.handleGroupChange}
                />
              </Form.Group>
              <Divider />
              <Header as='h4'>Group Rights</Header>
              <GroupAccessInformation
                assignedGroupData={assignedGroupData}
              />
              <Divider />
              <Button
                primary
                type='submit'
                loading={loading}
              >
                Share
              </Button>
              <Message
                error
                header='Something went wrong.'
                content={errorMessage}
                icon='warning circle'
              />
              <Message
                success
                header='Success.'
                content={successMessage}
                icon='warning circle'
              />
              <Divider />
              <Header as='h4'>Users with Access</Header>
              <ProcessUserTable
                processUsers={processUsers}
                itsMe={this.itsMe}
                itsTheOwner={this.itsTheOwner}
                modifyAccess={this.modifyAccess}
                withDrawAccess={this.withDrawAccess}
                groupOptions={this.getGroupOptions()}
              />
              <Button
                floated='right'
                onClick={this.clearStateAndClose}
              >
                Close
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessProcessModal)
