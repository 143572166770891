import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Segment, Header, Button } from 'semantic-ui-react'
import ProcessValidator from 'utils/validation/ProcessValidator'

import callStartProcess from 'redux/thunks/command/callStartProcess'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import {
  setProcessBuilderValidationErrors,
  updateProcess,
  setDisplayedProcessPhase,
} from 'redux/actions/actions'
import PDVC from 'utils/PDVC'
import { Process } from 'utils/process'
import { Device } from 'utils/device'
import DescriptionParser from 'utils/process/DescriptionParser'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import PhaseProgression from 'utils/process/PhaseProgression'
import Validator from 'utils/validation/Validator'
import ChangesTable from './ChangesTable'
import ConfirmAdhocChangesModal from './ConfirmAdhocChangesModal'

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
  displayedPhaseId: state.displayedPhaseId,
})

const mapDispatchToProps = dispatch => ({
  dispatchUpdateProcess: (processId, params) => dispatch(updateProcess({ processId, params })),
  dispatchDispatchUpdateProcess: (processId, params) => dispatch(
    callUpdateProcess(processId, params),
  ),
  dispatchCallStartProcess: (deviceId, elapsedTime, update, entryPhaseId) => dispatch(
    callStartProcess(deviceId, elapsedTime, update, entryPhaseId),
  ),
  dispatchSetProcessBuilderValidationErrors: errors => dispatch(
    setProcessBuilderValidationErrors({ errors }),
  ),
  dispatchSetDisplayedProcessPhase: phaseId => dispatch(
    setDisplayedProcessPhase(phaseId),
  ),
})

const ChangesFooter = ({
  activeProcess,
  activeDevice,
  processDescriptionSnapshots,
  displayedPhaseId,
  dispatchDispatchUpdateProcess,
  dispatchCallStartProcess,
  dispatchUpdateProcess,
  dispatchSetProcessBuilderValidationErrors,
  dispatchSetDisplayedProcessPhase,
}) => {

  const [ showConfirmationModal, triggerConfirmationModal ] = useState(false)
  const [ applyingChanges, setApplyingChanges ] = useState(false)

  const renderLatestPhase = () => {
    const latestPhase = PhaseProgression.getLatest(activeProcess)
    const newDisplayedPhaseId = latestPhase ? latestPhase.phaseId : displayedPhaseId
    dispatchSetDisplayedProcessPhase(newDisplayedPhaseId)
  }

  const amendDisplayedPhaseId = revertedDescription => {
    const phase = DescriptionParser.getPhaseById(activeProcess, displayedPhaseId)

    if (!phase) {
      renderLatestPhase()
      return
    }

    const { iterationOf, groupName } = phase

    if (!groupName || Validator.isUndefinedOrNull(iterationOf)) {
      renderLatestPhase()
      return
    }

    const phaseIterationIds = PhaseGroupParser.getPhaseIterationIds(activeProcess, iterationOf)
    phaseIterationIds.unshift(iterationOf)

    const prevDisplayedIndex = phaseIterationIds
      .findIndex(aPhaseId => aPhaseId === displayedPhaseId)

    const prevPhaseIds = phaseIterationIds.slice(0, prevDisplayedIndex)
    prevPhaseIds.reverse()

    const firstPossiblePhaseId = prevPhaseIds.find(aPhaseId => aPhaseId in revertedDescription)

    if (firstPossiblePhaseId !== undefined) {
      dispatchSetDisplayedProcessPhase(firstPossiblePhaseId)
      return
    }

    renderLatestPhase()
  }

  const revertChanges = () => {
    const revertedDescription = PDVC.revertToSnapshot(activeProcess, processDescriptionSnapshots)

    if (!DescriptionParser.hasPhaseWithId(revertedDescription, displayedPhaseId)) {
      amendDisplayedPhaseId(revertedDescription)
    }

    dispatchUpdateProcess(activeProcess.id, { description: revertedDescription })
    dispatchSetProcessBuilderValidationErrors([])
  }

  const confirmChanges = async diffs => {
    setApplyingChanges(true)

    const { updatedProcess, elapsedTime, entryPhaseId } = PDVC
      .applyDescriptionModification(activeProcess, processDescriptionSnapshots, diffs)

    const updateParams = { description: updatedProcess.description }
    await dispatchDispatchUpdateProcess(updatedProcess.id, updateParams)

    await dispatchCallStartProcess(activeProcess, elapsedTime, true, entryPhaseId)
    setApplyingChanges(false)
  }

  const evaluateChanges = async diffs => {

    const errors = ProcessValidator.validateDescription(activeProcess, activeDevice)
    dispatchSetProcessBuilderValidationErrors(errors)

    if (errors.length) return

    const runningPhaseId = Process.getRunningPhase(activeProcess).phaseId
    const insertPhase = PDVC.hasStreamTargetChange(diffs, runningPhaseId)

    if (insertPhase) {
      triggerConfirmationModal(true)
      return
    }
    await confirmChanges(diffs)
  }

  const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

  if (!diffs.length) return null

  const snapshot = PDVC.getSnapshot(activeProcess.id, processDescriptionSnapshots)

  return (
    <Segment
      style={{ backgroundColor: '#FFFFFF' }}
    >
      {showConfirmationModal && (
        <ConfirmAdhocChangesModal
          open
          closeHandler={() => triggerConfirmationModal(false)}
          confirmHandler={() => confirmChanges(diffs)}
          process={activeProcess}
          applyingChanges={applyingChanges}
        />
      )}
      <Header as='h3'>Process Changes</Header>
      <ChangesTable
        diffs={diffs}
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        snapshot={snapshot}
      />
      <Button.Group>
        <Button
          icon='check'
          labelPosition='left'
          content='Apply'
          primary
          onClick={() => evaluateChanges(diffs)}
          disabled={(
            activeProcess.outdated
            || applyingChanges
            || Device.hasInconsistentState(activeDevice)
          )}
        />
        <Button.Or />
        <Button
          icon='undo'
          labelPosition='right'
          content='Undo'
          disabled={applyingChanges || Device.hasInconsistentState(activeDevice)}
          onClick={() => revertChanges()}
        />
      </Button.Group>
    </Segment>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangesFooter)
