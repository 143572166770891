import React from 'react'
import { Container, Accordion, Ref, Button } from 'semantic-ui-react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import GraphsUIConfig from 'utils/UIConfig/GraphsUIConfig'
import StreamGroups from 'utils/UIConfig/StreamGroups'
import Streams from 'utils/Streams'

import callDownloadProcessData from 'redux/thunks/process/callDownloadProcessData'
import NoDataMessage from 'components/utility/NoDataMessage'
import Group from './Group'

const mapStateToProps = state => ({ userId: state.user.id })

const mapDispatchToProps = dispatch => ({
  dispatchCallDownloadProcessData: (processId, upStreams) => dispatch(callDownloadProcessData(processId, upStreams)),
})

class DataGraphs extends React.Component {

  downloadProcess = async () => {
    const { activeProcess, dispatchCallDownloadProcessData } = this.props
    await dispatchCallDownloadProcessData(activeProcess.id, Streams.getUpstreams(activeProcess.deviceDescription))
  }

  render() {

    const {
      activeProcess,
      activeDevice,
    } = this.props

    const { graphs: { groups } } = activeDevice.uIConfig

    if (activeProcess.state === 'executable') return <NoDataMessage />

    return (
      <Container fluid>
        <DragDropContext onDragEnd={(result) => GraphsUIConfig.onDragEnd(result, 'graphs', this.props)}>
          <Droppable
            droppableId='graph-group-id'
            type={{ type: 'Group' }}
          >
            {
              provided => (
                <Ref innerRef={provided.innerRef}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                    <Button
                      compact
                      onClick={() => GraphsUIConfig.foldall('graphs', this.props)}
                      style={{
                        marginLeft: 'auto', padding: '5px 10px', marginBottom: '10px', marginRight: '16px',
                      }}
                    >
                    Collapse All
                    </Button>
                    <Button
                      primary
                      compact
                      onClick={this.downloadProcess}
                      style={{ padding: '5px 10px', marginBottom: '10px', marginRight: '23px' }}
                    >
                    Download All
                    </Button>
                    <Accordion
                      {...provided.droppableProps}
                      fluid
                      styled
                      exclusive={false}
                    >
                      {groups.map((group, i) => {
                        const isGroupEmpty = StreamGroups.isGroupEmptyOnActiveProcess(group, activeProcess)
                        if (isGroupEmpty) return null
                        return (
                          <Group
                            key={group.name}
                            clickHandler={this.handleClick}
                            index={i}
                            group={group}
                            toggleFold={() => GraphsUIConfig.toggleGroupFolded(group.name, 'graphs', this.props)}
                            toggleStreamFold={streamId => GraphsUIConfig.toggleStreamFolded(group.name, streamId, this.props)}
                            activeProcess={activeProcess}
                            activeDevice={activeDevice}
                            handleOnDrag={(result) => GraphsUIConfig.onDragEnd(result, 'graphs', this.props)}
                          />
                        )
                      })}
                      {provided.placeholder}
                    </Accordion>
                  </div>
                </Ref>
              )
            }
          </Droppable>
        </DragDropContext>
      </Container>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataGraphs)
