import CalibrationMode from 'utils/calibration/CalibrationMode'


class LinearCalibrationDirectMode extends CalibrationMode {

  static calculateParams(componentState) {
    const { offset, slope } = componentState

    const parsedOffset = parseFloat(offset)
    const parsedSlope = parseFloat(slope)

    if (Number.isNaN(parsedOffset)) {
      return { error: 'Offset is not a number.' }
    }

    if (Number.isNaN(parsedSlope)) {
      return { error: 'Slope is not a number.' }
    }

    if (parsedSlope === 0) {
      return { error: 'Slope may not be 0.' }
    }

    return { offset: parsedOffset, slope: parsedSlope }
  }
}

export default LinearCalibrationDirectMode
