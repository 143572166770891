import React from 'react'
import { convertFrom, hasUnit } from 'utils/units'
import DeviceConfig from 'utils/DeviceConfig'
import Streams from 'utils/Streams'
import { Table } from 'semantic-ui-react'
import DescriptionParser from 'utils/process/DescriptionParser'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import Validator from 'utils/validation/Validator'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'

const renderPhaseCountChangeRow = (change, idx) => (
  <Table.Row
    negative={change.action === 'deleted'}
    positive={change.action === 'added'}
    key={idx}
  >
    <Table.Cell>{change.phaseName}</Table.Cell>
    <Table.Cell>
      {change.action}
      {' '}
      phase
    </Table.Cell>
    <Table.Cell disabled>N/A</Table.Cell>
    <Table.Cell disabled>N/A</Table.Cell>
  </Table.Row>
)

const renderChangedProp = (property, value, process) => {
  // handle corner cases when phases have been re-ordered/deleted
  if (property === 'next') {

    if (value === -1) return 'END'

    const phase = DescriptionParser.getPhaseById(process, parseInt(value, 10))
    const { groupName, iterationOf, name } = phase

    if (!groupName) return name

    if (Validator.isUndefinedOrNull(iterationOf)) {
      return `${groupName}: ${name} (1. cycle)`
    }

    const iterationIds = PhaseGroupParser.getPhaseIterationIds(process, phase.iterationOf)
    const iterationIndex = iterationIds.findIndex(aPhaseId => aPhaseId === parseInt(value, 10))
    return `${groupName}: ${name} (${iterationIndex + 2}. cycle)`
  }
  return value
}

const renderPhasePropChangeRow = (change, idx, process, snapshot) => {
  const {
    property,
    snapshot: oldValue,
    edited: newValue,
    phaseName,
    action,
  } = change

  return (
    <Table.Row warning key={idx}>
      <Table.Cell>{phaseName}</Table.Cell>
      <Table.Cell>{`${action} ${property}`}</Table.Cell>
      <Table.Cell>{renderChangedProp(property, oldValue, snapshot)}</Table.Cell>
      <Table.Cell>{renderChangedProp(property, newValue, process)}</Table.Cell>
    </Table.Row>
  )
}

const renderPhasePropAddChangeRow = (change, idx) => {
  const {
    property,
    phaseName,
    action,
  } = change

  return (
    <Table.Row
      negative={change.action === 'deleted'}
      positive={change.action === 'added'}
      key={idx}
    >
      <Table.Cell>{phaseName}</Table.Cell>
      <Table.Cell>{`${action} ${property}`}</Table.Cell>
      <Table.Cell disabled>N/A</Table.Cell>
      <Table.Cell disabled>N/A</Table.Cell>
    </Table.Row>
  )
}

const getConvertedValue = (stream, displayedUnit, conversion, value) => (
  conversion
    ? CustomConversions.convertWithRatio(value, conversion)
    : convertFrom(stream.unit).to(displayedUnit)(value)
)

const renderFloatStreamRow = (activeDevice, stream, change, idx) => {
  const { phaseName, action, snapshot, edited } = change
  const displayedUnit = hasUnit(stream.unit) ? DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id) : ''

  const customConversion = CustomConversions
    .getCustomConversionsByTargetUnit(activeDevice, stream.id, displayedUnit)

  const convertedOldTarget = getConvertedValue(stream, displayedUnit, customConversion, snapshot)
  const convertedNewTarget = getConvertedValue(stream, displayedUnit, customConversion, edited)

  return (
    <Table.Row warning key={idx}>
      <Table.Cell>{phaseName}</Table.Cell>
      <Table.Cell>{`${action} ${stream.name} in ${stream.groupName}`}</Table.Cell>
      <Table.Cell>
        {convertedOldTarget}
        {' '}
        {displayedUnit}
      </Table.Cell>
      <Table.Cell>
        {convertedNewTarget}
        {' '}
        {displayedUnit}
      </Table.Cell>
    </Table.Row>
  )
}

const renderBooleanStream = (activeDevice, stream, change, idx) => {
  const { phaseName, action, snapshot, edited } = change

  const convertedOldTarget = snapshot ? 'on' : 'off'
  const convertedNewTarget = edited ? 'on' : 'off'

  return (
    <Table.Row warning key={idx}>
      <Table.Cell>{phaseName}</Table.Cell>
      <Table.Cell>{`${action} ${stream.name} in ${stream.groupName}`}</Table.Cell>
      <Table.Cell>{convertedOldTarget}</Table.Cell>
      <Table.Cell>{convertedNewTarget}</Table.Cell>
    </Table.Row>
  )
}

const renderStreamTargetChangeRow = (change, idx, process, _, activeDevice) => {
  const streamId = change.path[2]
  const stream = Streams.getById(process.deviceDescription, streamId)

  switch (stream.expectedValueType) {
    case 'boolean':
      return renderBooleanStream(activeDevice, stream, change, idx)
    case 'float':
      return renderFloatStreamRow(activeDevice, stream, change, idx)
    default:
      return renderFloatStreamRow(activeDevice, stream, change, idx)
  }
}

const renderUnknownChange = () => null

const ROW_RENDER_DISPATCH = {
  phaseCountChange: renderPhaseCountChangeRow,
  phasePropChange: renderPhasePropChangeRow,
  phasePropAdd: renderPhasePropAddChangeRow,
  phasePropRemoved: renderPhasePropAddChangeRow,
  streamTargetChange: renderStreamTargetChangeRow,
  unknownChange: renderUnknownChange,
}

const ChangesTable = ({ diffs, activeProcess, activeDevice, snapshot }) => (
  <Table style={{ width: '100%' }} size='large' celled className='ospin-red' collapsing>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Phase</Table.HeaderCell>
        <Table.HeaderCell>Action</Table.HeaderCell>
        <Table.HeaderCell>Previous</Table.HeaderCell>
        <Table.HeaderCell>New</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {diffs.map((change, idx) => ROW_RENDER_DISPATCH[change.type](change, idx, activeProcess, snapshot, activeDevice))}
    </Table.Body>
  </Table>
)

export default ChangesTable
