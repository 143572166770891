import { logAndExtractError } from 'utils/error'
import DeviceConfig from 'utils/DeviceConfig'
import Streams from 'utils/Streams'
import DescriptionParser from 'utils/process/DescriptionParser'
import PhaseProgression from 'utils/process/PhaseProgression'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import PhaseGroupHandler from 'utils/process/PhaseGroupHandler'
import Validator from 'utils/validation/Validator'

export const EXECUTED_PROCESS_STATES = {
  running: 'running',
  finished: 'finished',
  paused: 'paused',
}

export const PROCESS_STATES = {
  ...EXECUTED_PROCESS_STATES,
  executable: 'executable',
}

export function phaseIsModifiable(process, phaseId) {
  if (process.state === PROCESS_STATES.finished) return false
  if (process.state === PROCESS_STATES.executable) return true


  const lastExecutedPhaseId = PhaseProgression.getLatest(process).phaseId
  if (phaseId === lastExecutedPhaseId) {
    return true
  }

  return !PhaseProgression.contains(process, phaseId)
}

export function getFirstPhaseId(process) {
  return process.entryPhaseId
}

export function setPhaseProps(process, phaseId, props) {
  process.description[phaseId] = {
    ...process.description[phaseId],
    ...props,
  }
}

function updatePhaseProps(process, phaseIds, props) {
  phaseIds.forEach(aPhaseId => setPhaseProps(process, aPhaseId, props))
}

export function setStreamTarget(process, targetPhaseId, streamId, target) {
  process.description[targetPhaseId].downstreams[streamId].value = target
}

const updatePhaseTargets = (process, phaseIds, streamId, target) => (
  phaseIds.forEach(aPhaseId => {
    setStreamTarget(process, aPhaseId, streamId, target)
  })
)

const updateGroup = (process, phaseId, updateFn, params, isTargetUpdate = false) => {

  const phase = DescriptionParser.getPhaseById(process, phaseId)
  const { groupName } = phase

  const iterationData = PhaseGroupParser.getIterationData(process, groupName)
  const { phaseIds, iteration: targetIteration } = iterationData
    .find(({ phaseIds }) => phaseIds.includes(phaseId))
  const modifiedPhasePosition = phaseIds.findIndex(aPhaseId => aPhaseId === phaseId)

  const idsOfPhasesToUpdate = iterationData
    .filter(({ iteration }) => iteration >= targetIteration)
    .map(({ phaseIds }) => phaseIds[modifiedPhasePosition])

  updateFn(process, idsOfPhasesToUpdate, ...params)

  /*
  * a small explantion:
  * we need to update the group structure in two cases: Any modification coming after
  * the first iteration of the groups or when a stream target is modifed for a
  * running phase in the first iteration because it requires a split of the
  * running phase. Thats the condition in line 95. Then we decide between two cases:
  * 1. When we are not modifying a running phase or when we do but we don't modify
  * any stream targets, we don't need to create a bridge group (condition line 97).
  * If we are modyfing the first iteration under this conditions, we actually don't need
  * to do anything (the modified phase never finished before so we don't need to
  * perserve its history) - line 98.
  * The offset is a bit janky and serves which phases are chosen to be extracted
  * into a future group. Sometimes we want to include the running iteration
  * (e.g. when the modified phase is the running one), sometimes we don't need that.
  * 2. Code from line 111 is a bit easier and should be self-explanatory.
  */

  const runningPhase = PhaseProgression.getLatest(process)
  const isRunningPhase = runningPhase.phaseId === phaseId

  if (targetIteration > 1 || (isRunningPhase && isTargetUpdate)) {

    if (!isRunningPhase || (isRunningPhase && !isTargetUpdate)) {
      if (targetIteration <= 1) return

      const { iteration: runningIteration } = iterationData
        .find(({ phaseIds }) => phaseIds.includes(runningPhase.phaseId))

      const offset = runningIteration < targetIteration
        ? 1
        : isRunningPhase ? 1 : 0

      PhaseGroupHandler.createRemainderGroup(process, iterationData, targetIteration - offset)
      return
    }

    if (isRunningPhase) {
      const offset = isTargetUpdate ? 0 : 1
      if (isTargetUpdate) {
        PhaseGroupHandler.createBridgeGroup(process, iterationData, targetIteration)
      }
      PhaseGroupHandler.createRemainderGroup(process, iterationData, targetIteration - offset)
    }
  }
}

const updateGroupTargets = (process, phaseId, streamId, newTarget) => {
  updateGroup(process, phaseId, updatePhaseTargets, [ streamId, newTarget ], true)
}

const updateGroupPhaseProps = (process, phaseId, props) => {
  updateGroup(process, phaseId, updatePhaseProps, [ props ])
}

export function setPhasePropsWithGroupConsideration(process, phaseId, props) {
  const isPureNameChange = 'name' in props && Object.keys(props).length === 1
  const phaseIterationIds = PhaseGroupParser.getPhaseIterationIds(process, phaseId)

  if (process.state === PROCESS_STATES.executable || isPureNameChange) {
    updatePhaseProps(process, [ ...phaseIterationIds, phaseId ], props)
    return
  }

  if (process.state === PROCESS_STATES.finished) {
    return
  }

  const phase = DescriptionParser.getPhaseById(process, phaseId)

  if (!phaseIsModifiable(process, phaseId)) {
    return
  }

  if (!phase.groupName) {
    updatePhaseProps(process, [ phaseId ], props)
    return
  }

  updateGroupPhaseProps(process, phaseId, props)
}

export function setStreamTargetWithGroupConsideration(process, phaseId, streamId, newTarget) {

  if (process.state === PROCESS_STATES.finished) {
    return
  }

  const phase = DescriptionParser.getPhaseById(process, phaseId)
  const phaseIterationIds = PhaseGroupParser.getPhaseIterationIds(process, phaseId)

  if (process.state === PROCESS_STATES.executable) {
    updatePhaseTargets(process, [ ...phaseIterationIds, phaseId ], streamId, newTarget)
    return
  }

  // process is either running or paused
  // only phases that didn't finish yet or have queued iterations will allow modifications
  // because the inputs are disabled otherwise

  if (!phaseIsModifiable(process, phaseId)) {
    return
  }

  if (!phase.groupName) {
    updatePhaseTargets(process, [ phaseId ], streamId, newTarget)
    return
  }

  updateGroupTargets(process, phaseId, streamId, newTarget)
}

export function groupByControllerName(controllerStreams) {
  const controllers = {}

  for (const stream of controllerStreams) {
    const controllerName = stream.controller.name
    if (!(controllerName in controllers)) controllers[controllerName] = []
    controllers[controllerName].push(stream)
  }
  return controllers
}

export function amendPhasesNextProps(process, deletedPhaseId, deletedPhaseNextKey) {
  const amendedProcess = { ...process }
  const prevPhaseId = DescriptionParser.getPreviousPhaseId(amendedProcess, deletedPhaseId)
  if (prevPhaseId !== undefined) {
    amendedProcess.description[prevPhaseId].next = deletedPhaseNextKey
  }
  return amendedProcess
}

export class Process {

  static hasProcess = (processes, processId) => (
    processes.some(process => process.id === processId)
  )

  static getById = (processes, processId) => (
    processes.find(process => process.id === processId)
  )

  static isValidPhaseOrderChange = (process, srcPhaseId, destPhaseId, destination) => {
    if (process.state === 'executable') return true
    if (process.state === 'finished') return false

    if (PhaseProgression.contains(process, srcPhaseId)) return false
    if (PhaseProgression.contains(process, destPhaseId)) return false

    // forbid dragging a phase before a running group
    // because future iterations of the group dont block dropping a
    // phsae before them

    if (destPhaseId !== -1) {
      const phase = DescriptionParser.getPhaseById(process, destPhaseId)
      const runningGroup = Process.getRunningGroup(process)
      if (!runningGroup) return true

      const { groupName } = phase

      if (!groupName || groupName !== runningGroup) return true

      const iterationData = PhaseGroupParser.getIterationData(process, groupName)
      const iteration = iterationData.find(({ phaseIds }) => phaseIds.includes(destPhaseId))
      const index = iteration.phaseIds.findIndex(aPhaseId => aPhaseId === destPhaseId)

      if (index === 0 && destination.droppableId === 'process-tree') return false
    }

    return true
  }

  static isValidPhaseProgression = (process, nextPhase) => {
    const existingPhaseInProcess = DescriptionParser.getPhaseById(process, nextPhase.phaseId)
    const runningPhaseId = this.getRunningPhase(process).phaseId

    const expectedNextPhaseId = process.progression.length
      ? DescriptionParser.getNextPhaseId(process, runningPhaseId)
      : process.entryPhaseId

    return (existingPhaseInProcess !== undefined && expectedNextPhaseId === nextPhase.phaseId)
  }

  static hasBeenStarted(process) {
    return (!(Number.isNaN(process.startedAt) || typeof process.startedAt !== 'number'))
  }

  static isFinished(process) {
    return (!(Number.isNaN(process.finishedAt) || typeof process.finishedAt !== 'number'))
  }

  static isRunningOnDevice(process, device) {
    return process.id === device.runningProcessId
  }

  static deriveProcessState(process, device) {
    if (Process.isFinished(process)) return PROCESS_STATES.finished
    if (!Process.hasBeenStarted(process)) return PROCESS_STATES.executable
    if (device && Process.isRunningOnDevice(process, device)) return device.state

    // We shouldn't be here, but in the interest of not cascading errors:
    return PROCESS_STATES.finished
  }

  static progressToPhase = (process, phase) => {
    process.progression = [...process.progression, phase]
  }

  static markProcessAsOutdated = process => {
    Object.assign(process, { outdated: true })
  }

  static manageProcessProgression = (process, nextPhase) => {
    if (this.isValidPhaseProgression(process, nextPhase)) {
      this.progressToPhase(process, nextPhase)
    } else {
      this.markProcessAsOutdated(process)
    }
  }

  static async save(process, saveFn) {
    const updatedProcess = {
      description: process.description,
      entryPhaseId: process.entryPhaseId,
    }

    try {
      await saveFn(process.id, updatedProcess)
      return { success: true, message: 'OK.' }
    } catch (e) {
      return { success: false, message: logAndExtractError(e) }
    }
  }

  static getRunningPhase(process) {
    if (process.state === PROCESS_STATES.finished) {
      return PhaseProgression.noPhaseObject()
    }
    return PhaseProgression.getLatest(process) || PhaseProgression.noPhaseObject()
  }

  static getRunningGroup(process) {
    if (process.state === PROCESS_STATES.finished) return
    const { phaseId } = PhaseProgression.getLatest(process) || PhaseProgression.noPhaseObject()

    if (phaseId !== -1) {
      const { groupName } = DescriptionParser.getPhaseById(process, phaseId)
      return groupName
    }
  }

  static sortPinnedFirst(processes) {
    const samePinnedState = (a, b) => (a.pinned === b.pinned)
    const byCreatedAt = (a, b) => (b.createdAt - a.createdAt)
    const isPinned = a => (a.pinned ? -1 : 1)

    return processes.sort((a, b) => {
      if (samePinnedState(a, b)) {
        return byCreatedAt(a, b)
      }
      return isPinned(a)
    })
  }

  static getUniquePhaseId(process, snapshot) {
    let phaseId = 0
    const allPhaseIds = snapshot && snapshot.description
      ? [ ...Object.keys(process.description), ...Object.keys(snapshot.description) ]
      : Object.keys(process.description)

    while (allPhaseIds.includes(String(phaseId))) {
      phaseId += 1
    }
    return phaseId
  }

  static getNextUnusedPhaseName(phases) {
    const numberOfIterationPhases = phases
      .filter(({ iterationOf }) => !Validator.isUndefinedOrNull(iterationOf)).length
    let phaseNameCounter = phases.length - numberOfIterationPhases
    let phaseName = `Phase ${phaseNameCounter}`
    const phaseNames = phases.map(phase => phase.name)
    while (phaseNames.includes(phaseName)) {
      phaseNameCounter += 1
      phaseName = `Phase ${phaseNameCounter}`
    }
    return phaseName
  }

  static getDownstreamData(downstreams) {
    return downstreams
      .reduce((acc, stream) => ({ ...acc, [stream.id]: { value: stream.default } }), {})
  }

  static getPreviousPhaseDownstreams(process, lastPhaseId) {
    const prevDownstreams = process.description[lastPhaseId].downstreams
    return JSON.parse(JSON.stringify(prevDownstreams))
  }

  static getDefaultPhaseDownstreams(process, activeDevice) {
    const processDownStreams = Streams.getDownstreamsWrapper(process, 'process', 'process.js')
    const downstreams = Process.getDownstreamData(processDownStreams)

    Object.keys(downstreams).forEach(streamId => {
      const configuredStream = DeviceConfig.getConfiguredStreamById(activeDevice, streamId)
      const { targetDefault } = configuredStream

      downstreams[streamId].value = targetDefault
    })

    return downstreams
  }

  static createPhase(process, device, copyFromPhaseId = undefined, snapshot) {
    const phases = DescriptionParser.getPhasesArray(process)

    const phaseData = {
      name: Process.getNextUnusedPhaseName(phases),
      transition: 'manual',
      duration: 0,
      next: -1,
    }

    const prevPhaseId = copyFromPhaseId === undefined
      ? DescriptionParser.getLastPhaseId(process)
      : copyFromPhaseId

    phaseData.downstreams = phases.length > 0
      ? Process.getPreviousPhaseDownstreams(process, prevPhaseId)
      : Process.getDefaultPhaseDownstreams(process, device)

    if (copyFromPhaseId !== undefined) {
      const { transition, duration } = DescriptionParser.getPhaseById(process, copyFromPhaseId)
      phaseData.transition = transition
      phaseData.duration = duration
    }

    const phaseId = Process.getUniquePhaseId(process, snapshot)
    return { phaseId, phaseData }
  }

  static attachPhase(process, newPhase, attachAfterPhaseId = undefined) {
    const { phaseId, phaseData } = newPhase
    const prevPhaseId = DescriptionParser.getLastPhaseId(process)
    const phases = DescriptionParser.getPhasesArray(process)

    if (phases.length > 0) {
      process.description[prevPhaseId].next = phaseId
    }

    process.description[phaseId] = phaseData

    if (attachAfterPhaseId !== undefined) {
      const destinationIndex = DescriptionParser
        .getPhaseIds(process).findIndex(aPhaseId => aPhaseId === attachAfterPhaseId)
      Process.movePhase(process, phases.length, destinationIndex + 1)
    }

    return process
  }

  static appendNewPhase(process, device, snapshot) {
    const phase = Process.createPhase(process, device, undefined, snapshot)
    return Process.attachPhase(process, phase)
  }

  static movePhase(process, sourceIndex, destinationIndex) {

    const moveInvalid = (srcIdx, destIdx) => (
      srcIdx === destIdx || srcIdx === undefined || destIdx === undefined
    )

    if (moveInvalid(sourceIndex, destinationIndex)) return process

    const phaseSequence = DescriptionParser.getPhasesSequence(process)

    if (destinationIndex === 0) {
      Process.setEntryPhaseId(process, phaseSequence[sourceIndex].id)
    } else if (sourceIndex === 0) {
      Process.setEntryPhaseId(process, phaseSequence[sourceIndex + 1].id)
    }

    const removedPhase = phaseSequence.splice(sourceIndex, 1)
    phaseSequence.splice(destinationIndex, 0, removedPhase[0])

    phaseSequence.forEach((phase, i) => {
      const nextPhaseId = i < phaseSequence.length - 1 ? phaseSequence[i + 1].id : -1
      process.description[phase.id] = { ...phase.data, next: nextPhaseId }
    })
  }

  static deletePhase(process, phaseId) {
    const deletedPhaseNextKey = process.description[phaseId].next
    delete process.description[phaseId]

    if (phaseId === getFirstPhaseId(process)) {
      process.entryPhaseId = deletedPhaseNextKey
    }

    const updatedProcess = amendPhasesNextProps(
      process, phaseId, deletedPhaseNextKey,
    )

    return { updatedProcess, deletedPhaseNextKey }
  }

  static setEntryPhaseId(process, phaseId) {
    process.entryPhaseId = phaseId
  }
}
