import create from './create'
import remove from './remove'
import get from './get'
import getPublic from './public/get'
import list from './list'
import update from './update'
import clone from './clone'
import getUsers from './getUsers'
import grantAccess from './grantAccess'
import revokeAccess from './revokeAccess'
import modifyAccess from './modifyAccess'

export default {
  create,
  remove,
  get,
  getPublic,
  list,
  update,
  clone,
  getUsers,
  grantAccess,
  revokeAccess,
  modifyAccess,
}
