import React, { useState } from 'react'
import { Card, Image, Container, Button, Label } from 'semantic-ui-react'
import { withRouter } from "react-router-dom"
import { HashLink as Link } from 'react-router-hash-link'

import Stream from 'utils/Stream'
import DataManager from 'utils/DataManager'
import DeviceConfig from 'utils/DeviceConfig'
import { convertFrom, replaceNoUnitCharacter } from 'utils/units'


const renderRedirectionButton = (process, stream, history) => {

  if (!process) return null

  const updatedPath = `${history.location.pathname.replace('dashboard', 'process')}/${process.id}#${stream.id}`

  return (
    <Link to={updatedPath}>
      <Button
        className='float-right margin-l-4'
        data-testid='streamwidget-forward'
        size='mini'
        basic
        circular
        icon='line graph'
      />
    </Link>
  )
}

const renderMinMax = (min, max) => (
  <Label size='large'>
    {`min: ${min} | max: ${max}`}
  </Label>
)

const StreamWidget = ({
  runningProcess,
  stream,
  activeDevice,
  removeStreamFromDashboardByStreamId,
  history,
}) => {
  const [ showIcons, toggleIconContainer ] = useState(false)

  const value = runningProcess
    ? DataManager.getLastStreamDataPoint(runningProcess, stream.id) : null
  const max = runningProcess
    ? DataManager.getMaxStreamValue(runningProcess, stream.id) : null
  const min = runningProcess
    ? DataManager.getMinStreamValue(runningProcess, stream.id) : null

  const { displayedName, displayedUnit, unit } = DeviceConfig
    .getConfiguredStream(activeDevice, stream)
  const displayedValue = convertFrom(unit).to(displayedUnit)(value)

  const iconContainerClass = showIcons ? '' : 'opacity-0'

  return (
    <Card
      onMouseEnter={() => toggleIconContainer(true)}
      onMouseLeave={() => toggleIconContainer(false)}
    >
      <Card.Content>
        <Image floated='left' size='tiny' src={Stream.getImage(stream)} />
        <Card.Header>{displayedName}</Card.Header>
        <Card.Meta>
          Group: {stream.groupName}
        </Card.Meta>
        <Card.Meta>
          <div className='margin-t-8'>
            <span className='dashboard-value'>
              {value === null ? 'No Data' : displayedValue}
            </span>
            <span className='dashboard-unit'>
              {value ? replaceNoUnitCharacter(displayedUnit, '') : null}
            </span>
          </div>
        </Card.Meta>
      </Card.Content>
      <Card.Content extra>
        <Container className='float-left'>
          {renderMinMax(min, max)}
          <div className='float-right space-between-items'>
            <div className={`${iconContainerClass} transition-opacity`}>
              <Button
                className='float-right margin-l-4'
                data-testid='streamwidget-delete'
                size='mini'
                basic
                circular
                icon='times'
                onClick={() => removeStreamFromDashboardByStreamId(stream.id)}
              />
              {renderRedirectionButton(runningProcess, stream, history)}
            </div>
          </div>
        </Container>
      </Card.Content>
    </Card>
  )
}

export default withRouter(StreamWidget)
