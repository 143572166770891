import nexus from 'nexus'

export default class Streams {

  static EXPECTED_VALUE_TYPE = new Set([
    'float',
    'integer',
    'boolean',
    'oneOf',
    undefined, //backwards compatibility for streams that don't have the prop yet
  ])

  static getById(streams, streamId) {
    return streams.find(stream => stream.id === streamId)
  }

  static contains(streams, streamId) {
    return !!Streams.getById(streams, streamId)
  }

  static isUpstream({ type }) {
    return type === 'upstream'
  }

  static isDownstream({ type }) {
    return type === 'downstream'
  }

  static getUpstreams(streams) {
    return streams.filter(Streams.isUpstream)
  }

  static getDownstreamsWrapper(resource, resourceType, originFile) {
    try {
      if (resourceType === 'device') {
        return Streams.getDownstreams(resource.streams)
      }

      return Streams.getDownstreams(resource.deviceDescription)
    } catch (e) {
      const customError = {
        errorString: `Error in ${originFile} getDownstreams. ${resourceType}: ${JSON.stringify(resource)}`,
        errorTime: new Date(),
        lineNumber: '-',
        functionName: 'getDownStreamsWrapper',
        fileName: originFile,
        userName: 'check next error',
        pathname: 'check next error',
      }
      nexus.utils.logClientError(customError)

      // throw error to have a normal error report on top
      throw new Error(e)
    }
  }

  static getDownstreams(streams) {
    return streams.filter(Streams.isDownstream)
  }

  static hasRelatedStreams({ relatedDownstreams }) {
    return relatedDownstreams && relatedDownstreams.length
  }

  static hasRelatedTargetWithId({ relatedDownstreams }, targetStreamId) {
    return relatedDownstreams.some(({ streamId, streamID, relationType }) => (
      (streamId === targetStreamId || streamID === targetStreamId) && relationType === 'target'))
  }

  static getAllLinkedStreamRelations(streams) {
    return streams
      .filter(stream => stream.type === 'upstream')
      .filter(stream => stream.relatedDownstreams && stream.relatedDownstreams.length)
      .map(stream => stream.relatedDownstreams)
      .flat()
  }

  // streamID is for backwards compatibility

  static getAllLinkedTargetStreams(streams) {
    return Streams
      .getAllLinkedStreamRelations(streams)
      .filter(relation => relation.relationType === 'target')
      .map(({ streamId, streamID }) => Streams.getById(streams, streamId || streamID))
      .filter(stream => stream)
  }

  static isTrackedByUpstream(streams, streamId) {
    return streams
      .filter(Streams.isUpstream)
      .filter(Streams.hasRelatedStreams)
      .some(stream => Streams.hasRelatedTargetWithId(stream, streamId))
  }

  static getGroups(streams) {
    const groups = {}

    streams.forEach(stream => {
      const { groupName } = stream
      if (!(groupName in groups)) groups[groupName] = []
      groups[groupName].push(stream)
    })

    return Object.entries(groups)
      .map(([ name, streams ]) => ({ name, streams }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }

  static getStreamsByUnit(streams, unit) {
    return streams.filter(stream => stream.unit === unit)
  }

  static getGroupStreams(streams, groupName) {
    return streams.filter(stream => stream.groupName === groupName)
  }

  static getPumpStreams(streams) {
    return streams.filter(stream => Streams.isDownstream(stream) && stream.unit === 'rpm')
  }
}
