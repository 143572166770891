import { getFirstPhaseId, Process } from 'utils/process'

export default (state, action) => {
  const { processes } = state
  const { payload: { phaseId, processId } } = action

  let displayedPhaseId = ""

  const updatedProcesses = processes.map(process => {
    if (process.id === processId) {
      const { updatedProcess, deletedPhaseNextKey } = Process.deletePhase(process, phaseId)

      displayedPhaseId = deletedPhaseNextKey !== -1
        ? deletedPhaseNextKey
        : getFirstPhaseId(updatedProcess)

      return updatedProcess
    }
    return process
  })
  return { ...state, processes: updatedProcesses, displayedPhaseId }
}
