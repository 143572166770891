import nexus from 'nexus'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callDownloadStreamData = async (dispatch, streamId, streamName, processId) => {
  const { url } = await nexus.datapoints.requestStreamData({ streamId, streamName, processId })
  const a = global.document.createElement('a')
  a.href = url
  a.setAttribute('download', 'download')
  a.click()
}

export default thunkWrapper(callDownloadStreamData)
