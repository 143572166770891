import React from 'react'
import { Button } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'

const Footer = ({
  deleteCalibrationEntry,
  resetInputFields,
  activeDevice,
  stream,
  calibrating,
  resetting,
  closeHandler,
}) => {

  const hasCalibration = DeviceConfig.hasStreamCalibration(activeDevice, stream.id)

  return (
    <div>
      <Button
        floated='left'
        color='red'
        loading={resetting}
        disabled={!hasCalibration}
        onClick={deleteCalibrationEntry}
      >
        Delete Calibration
      </Button>
      <Button
        floated='left'
        onClick={resetInputFields}
      >
        Reset Inputs
      </Button>
      <Button
        floated='right'
        primary
        type='submit'
        loading={calibrating}
        disabled={hasCalibration}
      >
        Apply
      </Button>
      <Button
        floated='right'
        onClick={closeHandler}
      >
        Close
      </Button>
    </div>)
}

export default Footer
