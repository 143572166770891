import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Card, Image, Popup } from 'semantic-ui-react'
import { createUseStyles } from 'react-jss'

import callDeleteProcesses from 'redux/thunks/process/callDeleteProcesses'
import callUpdateProcessOptimistic from 'redux/thunks/process/callUpdateProcessOptimistic'
import ProcessTileOverlay from 'components/device/processes/ProcessTileOverlay'
import ProcessTileIconBar from 'components/device/processes/ProcessTileIconBar'
import Time from 'utils/Time'
import FlashMessenger from 'utils/FlashMessenger'
import { PROCESS_STATES } from 'utils/process'

import processFinished from 'images/ProcessFinished.png'
import processExecutable from 'images/ProcessExecutable.png'
import processRunning from 'images/ProcessRunning.png'
import processPaused from 'images/ProcessPaused.png'

const useStyles = createUseStyles({
  processTileMetaData: {
    margin: 0,
    padding: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

const DISPLAY_MAP = {
  [PROCESS_STATES.running]: {
    image: processRunning,
    displayedStatus: 'running',
  },
  [PROCESS_STATES.paused]: {
    image: processPaused,
    displayedStatus: 'paused',
  },
  [PROCESS_STATES.executable]: {
    image: processExecutable,
    displayedStatus: 'executable',
  },
  [PROCESS_STATES.finished]: {
    image: processFinished,
    displayedStatus: 'finished',
  },
}

const continuouslyUpdateRunningDuration = (startedAt, setRunningDuration) => (
  setInterval(() => {
    setRunningDuration(Date.now() - startedAt)
  }, 1000)
)

const renderStateTransitionDate = (dateAndTime, transitionText) => (
  <>
    {`${transitionText} ${dateAndTime}`}
  </>
)

const renderRunning = (startedAt, duration, styleClassName) => {
  const dateAndTime = new Date(startedAt).toString().slice(4, 21)

  const durationString = duration !== null ? Time.stringFromDuration(duration / 1000) : 'loading...'

  return (
    <>
      <div className={styleClassName}>
        {renderStateTransitionDate(dateAndTime, 'Started')}
        <div style={{ color: '#1578c2' }}>
          Duration:
          {' '}
          {durationString}
        </div>
      </div>
    </>
  )
}

const renderExecutable = (createdAt, styleClassName) => {
  const dateAndTime = new Date(createdAt).toString().slice(4, 21)

  return (
    <div className={styleClassName}>
      {renderStateTransitionDate(dateAndTime, 'Created')}
    </div>
  )
}

const renderFinished = (createdAt, startedAt, finishedAt, styleClassName) => {

  // legacy for processes which don't have the finishedAt property set
  if (!finishedAt) return renderExecutable(createdAt, styleClassName)

  const processDurationInSeconds = (finishedAt - startedAt) / 1000

  const durationString = Time.stringFromDuration(processDurationInSeconds)
  const dateAndTime = new Date(finishedAt).toString().slice(4, 21)

  return (
    <div className={styleClassName}>
      {renderStateTransitionDate(dateAndTime, 'Finished')}
      <div>
        Duration:
        {' '}
        {durationString}
      </div>
    </div>
  )
}

const renderNotRunning = (state, createdAt, startedAt, finishedAt, styleClassName) => (
  state === PROCESS_STATES.finished
    ? renderFinished(createdAt, startedAt, finishedAt, styleClassName)
    : renderExecutable(createdAt, styleClassName)
)


const ProcessTile = props => {
  const classes = useStyles()
  const {
    process: {
      name,
      comment,
      state,
      createdAt,
      startedAt,
      finishedAt,
      pinned,
    },
    process,
    selectedIndex,
    openModalHandler,
    processClickHandler,
    onToggleOverlay,
    dispatchCallDeleteProcesses,
    dispatchcallUpdateProcessOptimistic,
  } = props

  const [ showIcons, toggleIconContainer ] = useState(false)
  const [ runningDuration, setRunningDuration ] = useState(null)
  const isActive = state === 'running' || state === 'paused'

  useEffect(() => {
    if (!isActive) return

    setRunningDuration(Date.now() - startedAt)
    const interval = continuouslyUpdateRunningDuration(startedAt, setRunningDuration)
    return () => { clearInterval(interval) }
  }, [isActive, startedAt])

  async function handleProcessClick(clickedProcess) {
    await processClickHandler(clickedProcess)
  }

  function toggleModal(event, modalTrigger) {
    event.stopPropagation()
    openModalHandler(modalTrigger, process)
  }

  function handleToggleFavorite(event) {
    event.stopPropagation()
    dispatchcallUpdateProcessOptimistic(process.id, { pinned: !pinned })
  }

  async function confirmDeleteProcess(event, confirmed) {
    event.stopPropagation()
    onToggleOverlay(false)

    if (confirmed) {
      const result = await dispatchCallDeleteProcesses([ process.id ])
      if (result.status === 403) {
        FlashMessenger.error('Operation failed. No sufficient access rights to one or more selected processes.')
      }
    }
  }

  const onToggleOverlayToDeleteProcess = (event) => {
    onToggleOverlay(process.id)
    event.stopPropagation()
  }

  const renderName = () => (
    <Popup
      content={name}
      basic
      trigger={
        <h4
          style={{
            width: '188px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {name}
        </h4>
      }
    />
  )

  const { image, displayedStatus } = DISPLAY_MAP[state]

  return (
    <Card
      onMouseEnter={() => toggleIconContainer(true)}
      onMouseLeave={() => toggleIconContainer(false)}
      onClick={() => handleProcessClick(props.process)}
      style={{ width: '310px' }}
    >
      <Card.Content style={{ paddingBottom: '8px' }}>
        <Image floated='left' size='tiny' src={image} style={{ marginBottom: '0px' }}/>
        <Card.Header
          className='process-tile-header'
          style={{ paddingTop: '8px' }}
        >
          {renderName()}
        </Card.Header>
        <Card.Meta>
          {isActive
            ? renderRunning(startedAt, runningDuration, classes.processTileMetaData)
            : renderNotRunning(
              state, createdAt, startedAt, finishedAt, classes.processTileMetaData,
            )}
        </Card.Meta>
      </Card.Content>
      <ProcessTileIconBar
        isActive={isActive}
        showIcons={showIcons}
        displayedStatus={displayedStatus}
        toggleModal={toggleModal}
        handleToggleFavorite={handleToggleFavorite}
        onToggleOverlayToDeleteProcess={onToggleOverlayToDeleteProcess}
        isPinned={pinned}
        comment={comment}
      />
      {(process.id === selectedIndex) &&
        <ProcessTileOverlay confirmDeleteProcess={confirmDeleteProcess} />
      }
    </Card>
  )
}

const mapDispatchToProps = dispatch => ({
  dispatchCallDeleteProcesses: ids => dispatch(callDeleteProcesses(ids)),
  dispatchcallUpdateProcessOptimistic: (processId, params) => dispatch(callUpdateProcessOptimistic(processId, params)),
})

const mapStateToProps = ({ user }) => ({ user })

export default connect(mapStateToProps, mapDispatchToProps)(ProcessTile)
