import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Grid, Image } from 'semantic-ui-react'
import { Switch, Route, withRouter, Redirect } from 'react-router-dom'

import ProcessViewer from 'components/processviewer/ProcessViewer'
import DynamicLoader from 'components/utility/DynamicLoader'
import { Device as DeviceUtils } from 'utils/device'
import { Process } from 'utils/process'
import ProcessesUtils from 'utils/process/Processes'
import UrlValidator from 'utils/validation/UrlValidator'
import LogView from 'components/log/LogView'
import Logs from 'utils/Logs'
import callLoadDevice from 'redux/thunks/device/callLoadDevice'
import callLoadProcess from 'redux/thunks/process/callLoadProcess'
import { updateProcess } from 'redux/actions/actions'
import OopsinNotFound from 'images/404.png'
import DeviceMenu from './menu/DeviceMenu'
import ManageAccess from './manageaccess/ManageAccess'
import Processes from './processes/Processes'
import DeviceSetup from './setup/DeviceSetup'
import Dashboard from './dashboard/Dashboard'
import ErrorPage, { OspinError } from '../utility/ErrorPage'
import InconsistentDeviceStateBanner from './InconsistentDeviceStateBanner'

const mapStateToProps = state => ({
  user: state.user,
  processes: state.processes,
  devices: state.devices,
  logs: state.logs,
  deviceUsers: state.deviceUsers,
  activeDeviceInitialized: state.activeDeviceInitialized,
})

const mapDispatchToProps = dispatch => ({
  dispatchCallLoadDevice: (device, userId, deviceId) => (
    dispatch(callLoadDevice(device, userId, deviceId))
  ),
  dispatchUpdateProcess: (processId, process) => (
    dispatch(updateProcess({ processId, params: process }))
  ),
})

class Device extends React.Component {

  state = { error: { isError: false, code: null } }

  async componentDidMount() {
    const {
      devices,
      match,
      dispatchCallLoadDevice,
      user,
    } = this.props

    const requestedDevice = devices.find(device => device.id === match.params.deviceId)
    const res = await dispatchCallLoadDevice(
      requestedDevice,
      user.id,
      match.params.deviceId,
    )

    if (res.status === 404) {
      this.setState({ error: { isError: true, code: 404 } })
    }

    if (res.status === 403) {
      this.setState({ error: { isError: true, code: 403 } })
    }
  }

  getActiveDeviceFromUrl = () => {
    const { devices, match: { params: { deviceId } } } = this.props
    return DeviceUtils.getById(devices, deviceId)
  }

  getActiveProcessFromUrl= () => {
    const { processes, match: { params: { processId } } } = this.props
    return Process.getById(processes, processId)
  }

  renderProcesses = () => {
    const { user, processes, deviceUsers } = this.props
    const activeDevice = this.getActiveDeviceFromUrl()
    const activeProcesses = ProcessesUtils.getByDeviceId(processes, activeDevice.id)
    return (
      <Processes
        activeDevice={activeDevice}
        deviceProcesses={activeProcesses}
        deviceUsers={deviceUsers}
        user={user}
      />
    )
  }

  renderLog = () => {
    const { logs } = this.props
    const activeDevice = this.getActiveDeviceFromUrl()
    return (
      <LogView
        activeDevice={activeDevice}
        showProcessSelect
        logs={Logs.getDeviceLogs(logs, activeDevice.id)}
      />
    )
  }

  renderAccess = () => <ManageAccess activeDevice={this.getActiveDeviceFromUrl()} />

  renderSetup = () => <DeviceSetup activeDevice={this.getActiveDeviceFromUrl()} />

  renderDashboard = () => <Dashboard activeDevice={this.getActiveDeviceFromUrl()} />

  render404Error = message => {
    const { history } = this.props
    return (
      <OspinError history={history} errorCode={404} text={message}>
        <Image src={OopsinNotFound} size='large' centered style={{ paddingTop: '30px' }} />
      </OspinError>
    )
  }

  renderProcessViewer = () => {
    const { processes, history, match: { params: { processId } }, dispatchUpdateProcess } = this.props
    const [processIsEnriched, enrichProcess] = useState(false)
    const activeProcess = this.getActiveProcessFromUrl()
    const activeDevice = this.getActiveDeviceFromUrl()

    const userHasReadAccessToProcess = Process.hasProcess(processes, processId)

    if (!userHasReadAccessToProcess) {
      return <ErrorPage history={history} errorCode={403} />
    }

    // the UrlParser checks if we actually have a valid format
    // for the processId - otherwise the backend would return a 422 instead of 404!

    if (!activeProcess || !UrlValidator.isUuidv4(processId)) {
      return this.render404Error(`process: ${processId} not found!`)
    }

    useEffect(() => {
      const fetchAndEnrichProcess = async () => {
        const process = await callLoadProcess(processId)
        process.state = Process.deriveProcessState(process, activeDevice)
        dispatchUpdateProcess(processId, process)
        enrichProcess(true)
      }
      if (!processIsEnriched) fetchAndEnrichProcess()
    }, [activeDevice, processId, processIsEnriched, dispatchUpdateProcess])

    if (!processIsEnriched) return (<DynamicLoader />)
    return <ProcessViewer activeProcess={activeProcess} activeDevice={activeDevice} />
  }

  renderDeviceRoutes = () => {
    const { match, history } = this.props
    const { params: { deviceId } } = match

    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <Switch>
            <Route exact path='/device/:deviceId'>
              <Redirect to={`/device/${deviceId}/processes`} />
            </Route>
            <Route exact path='/device/:deviceId/processes' component={this.renderProcesses} />
            <Route exact path='/device/:deviceId/process/:processId' component={this.renderProcessViewer} />
            <Route exact path='/device/:deviceId/dashboard' component={this.renderDashboard} />
            <Route exact path='/device/:deviceId/log' component={this.renderLog} />
            <Route exact path='/device/:deviceId/setup' component={this.renderSetup} />
            <Route exact path='/device/:deviceId/access' component={this.renderAccess} />
            <Route>
              <ErrorPage history={history} errorCode={404} />
            </Route>
          </Switch>
        </Grid.Column>
      </Grid.Row>
    )
  }

  render() {
    const { match, history, devices, activeDeviceInitialized } = this.props
    const { params: { deviceId } } = match
    const { error } = this.state

    if (error.code === 404 || !UrlValidator.isUuidv4(deviceId)) {
      return this.render404Error(`device: ${deviceId} not found!`)
    }

    if (error.code === 403) {
      return <ErrorPage history={history} errorCode={403} />
    }

    if (error.isError) {
      return <ErrorPage history={history} />
    }

    if (!activeDeviceInitialized) return <DynamicLoader />
    const activeDevice = DeviceUtils.getById(devices, deviceId)

    return (
      <div>
        <Grid columns={2}>
          <Grid.Row>
            <DeviceMenu
              activeDevice={activeDevice}
            />
          </Grid.Row>
          <InconsistentDeviceStateBanner activeDevice={activeDevice} />
          {this.renderDeviceRoutes()}
        </Grid>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Device))
