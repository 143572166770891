import nexus from 'nexus'
import { addProcessUser } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callGrantProcessAccess = async (dispatch, processId, userId, groupName) => {
  await nexus.process.grantAccess(processId, { groupName, userId })
  dispatch(addProcessUser({ processId, groupName, userId }))
}

export default thunkWrapper(callGrantProcessAccess)
