import { findAndUpdate } from 'redux/helper/updateFunctions'

export default (state, action) => {
  const { processes } = state
  const { processId, params } = action.payload

  const updateFn = process => Object.assign(process, params)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  return { ...state, processes: updatedProcesses }
}
