import nexus from 'nexus'
import { setStreamConfigurationProperty } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callSetStreamDefault = async (dispatch, streamId, deviceId, value) => {
  await nexus.device.setStreamDefault(deviceId, { streamId, value })
  dispatch(setStreamConfigurationProperty({
    deviceId,
    streamId,
    prop: 'streamDefaults',
    entryValues: value,
  }))
}

export default thunkWrapper(callSetStreamDefault)
