import React from 'react'
import {Route, Redirect, withRouter} from 'react-router-dom'
import {connect} from "react-redux"

const mapStateToProps = state => ({authenticated: state.authenticated})

const PrivateRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props => (
      rest.authenticated
        ? <Component {...props} />
        : <Redirect to="/login"/>
    )}
  />
)

export default withRouter(connect(mapStateToProps)(PrivateRoute))
