import nexus from 'nexus'
import { removeUserAlertById } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callDeleteUserAlert = async (dispatch, alertId) => {
  nexus.event.remove(alertId)
  dispatch(removeUserAlertById({ alertId }))
}

export default thunkWrapper(callDeleteUserAlert)
