import Streams from 'utils/Streams'


export class Device {

  static removeDeviceUser(device, userId) {
    device.access = device.access.map(group => {
      if (group.users.includes(userId)){
        group.users = group.users.filter(id => id !== userId)
      }
      return group
    })
  }

  static updateDeviceUserGroup(deviceUser, newGroup) {
    deviceUser.accessGroup = newGroup
  }

  static ALLOWED_STREAM_CONFIG_PROPS = {
    calibration: ['offset', 'slope'],
    streamNameAliases: ['alias'],
    streamDefaults: ['value'],
  }

  static validateAllowedProps(prop) {
    if (!(prop in Device.ALLOWED_STREAM_CONFIG_PROPS)) {
      throw new Error(`stream property ${prop} cannot be modified`)
    }
  }

  static applyConfigurationValues(entry, prop, values) {
    Device.ALLOWED_STREAM_CONFIG_PROPS[prop]
      .forEach((key, i) => { entry[key] = Array.isArray(values) ? values[i] : values })
  }

  static deleteStreamConfigurationProperty(device, streamId, prop) {
    Device.validateAllowedProps(prop)
    device[prop] = device[prop].filter(entry => entry.streamId !== streamId)
  }

  static updateStreamConfigurationEntry(device, streamId, prop, values) {
    device[prop] = device[prop].map(entry => {
      if (entry.streamId === streamId) Device.applyConfigurationValues(entry, prop, values)
      return entry
    })
  }

  static createStreamConfigurationEntry(device, streamId, prop, values) {
    const newEntry = { streamId }
    Device.applyConfigurationValues(newEntry, prop, values)
    device[prop].push(newEntry)
  }

  static setStreamConfigurationProperty(device, streamId, prop, values) {
    Device.validateAllowedProps(prop)

    const existingConfig = device[prop].some(entry => entry.streamId === streamId)
    if (existingConfig) {
      Device.updateStreamConfigurationEntry(device, streamId, prop, values)
      return
    }

    Device.createStreamConfigurationEntry(device, streamId, prop, values)
  }

  static getById(devices, deviceId) {
    return devices.find(device => device.id === deviceId)
  }

  // TODO rename this function (missleading)

  static getStreams(device, streamRefs) {
    return streamRefs
      .map(streamRef => Streams.getById(device.streams, streamRef.streamId))
      .filter(stream => stream)
  }

  static findDisconnectedStreams(device, streamRefs) {
    return streamRefs.filter(streamRef => !Streams.getById(device.streams, streamRef.streamId))
  }

  static isRunningProcess(device, processId) {
    return processId === device.runningProcessId
  }

  static getUniqueNonEmptyDeviceUnits(device) {
    return [...new Set(device.streams
      .map(stream => stream.unit)
      .filter(unit => unit !== '-' && unit !== '')) ]
  }

  static isOffline(device) {
    return device.connectionState === 'offline'
  }

  static isOnline(device) {
    return device.connectionState === 'online'
  }

  static isRunning(device) {
    return device.state === 'running'
  }

  static isPaused(device) {
    return device.state === 'paused'
  }

  static isIdle(device) {
    return device.state === 'idle'
  }

  static hasInconsistentState(device) {
    const isRunningOrPaused = Device.isPaused(device) || Device.isRunning(device)
    return Device.isOffline(device) && isRunningOrPaused
  }

  static isReadyForProcess(device) {
    return Device.isOnline(device) && Device.isIdle(device)
  }
}
