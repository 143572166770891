class DataTools {

  static averageValues = slice => (
    slice.reduce((total, dp) => total + dp.y, 0) / slice.length
  )

  static valuesWithinTimeRange = (slice, min, max) => (
    slice.filter(p => p.x >= min && p.x <= max)
  )
}

export default DataTools
