import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import formalize from 'components/utility/modal/formalize'
import Dropdown from 'components/utility/dropdown/FormAccessDropdown'

const AccessProcessModalForm = ({
  groupOptions,
  handleUserUpdate,
  handleGroupChange,
  selected,
}) => {

  const onUpdate = (e, { value }) => handleGroupChange(value)

  return (
    <>
      <Form.Field>
        <label>User Email</label>
        <Input
          fluid
          placeholder='user email...'
          type='email'
          required
          onChange={handleUserUpdate}
        />
      </Form.Field>
      <Dropdown
        groupOptions={groupOptions}
        label='Group'
        placeholder='Choose group'
        selected={selected}
        onUpdate={onUpdate}
      />
    </>
  )
}

export default formalize(AccessProcessModalForm)
