import { findAndUpdate } from 'redux/helper/updateFunctions'
import { removeUserFromGroup } from 'utils/access'

export default (state, action) => {
  const { processes } = state
  const { processId, groupName, userId } = action.payload

  const updateFn = process => removeUserFromGroup(process, groupName, userId)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  return { ...state, processes: updatedProcesses }
}
