import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'
import EventTrigger from 'utils/EventTrigger/EventTrigger'
import Streams from 'utils/Streams'
import { convertFrom } from 'utils/units'


const ParameterMenu = ({
  activeDevice,
  handleParameterUpdate,
  targetStreamId,
  type,
  parameterStore,
  currentEventTrigger,
}) => {

  const getDisplayedParameter = (parameter, displayedUnit) => {
    const stream = Streams.getById(activeDevice.streams, targetStreamId)
    if (!currentEventTrigger) return { displayedUnit }

    const value = currentEventTrigger[parameter.name]
    return convertFrom(stream.unit).to(displayedUnit)(value)
  }

  if (!targetStreamId || !type) return null

  const { parameters } = EventTrigger.getByType(type)
  const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, targetStreamId)

  return (
    <div>
      {parameters.map(parameter => {
        const displayedParam = getDisplayedParameter(parameter, displayedUnit)
        return (
          <Form.Group widths='equal' key={`${targetStreamId}-${parameter.name}`}>
            <Form.Field>
              <label>
                Current {parameter.label} [{displayedUnit}]
              </label>
              <Input
                fluid
                type='text'
                readOnly
                value={currentEventTrigger ? displayedParam : 'None'}
              />
            </Form.Field>
            <Form.Field>
              <label>
                New {parameter.label} [{displayedUnit}]
              </label>
              <Input
                fluid
                name={parameter.name}
                value={parameterStore[type][parameter.name] || ''}
                type='text'
                onChange={handleParameterUpdate}
              />
            </Form.Field>
          </Form.Group>
        )})}
      </div>
  )
}

export default ParameterMenu
