import React from 'react'
import { connect } from 'react-redux'
import modalize from 'components/utility/modal/modalize'
import EditProcessModalForm from 'components/device/modals/EditProcessModalForm'
import ProcessValidator from 'utils/validation/ProcessValidator'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'


const { VALID_PROCESS_DATA: { nameLength, commentLength } } = ProcessValidator

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

class EditProcessModal extends React.Component {

  state = {
    newName: this.props.process.name,
    newComment: this.props.process.comment,
    isError: false,
    errorMessage: '',
    loading: false,
  }

  handleUpdate = (_, { name, value }) => {
    this.setState({ [name]: value, isError: false, errorMessage: '' })
  }

  setErrorMessage = msg => (
    this.setState({ isError: true, errorMessage: msg })
  )

  invalidData = (newName, newComment) => {
    if (!ProcessValidator.isValidProcessName(newName)) {
      this.setErrorMessage(`Process name must be shorter then ${nameLength + 1} characters`)
      return true
    }

    if (!ProcessValidator.isValidProcessComment(newComment)) {
      this.setErrorMessage(`Process comment must be shorter then ${commentLength + 1} characters`)
      return true
    }

    return false
  }

  updateProcess = async () => {
    const { newName, newComment } = this.state
    const { process, closeHandler, dispatchCallUpdateProcess } = this.props

    if (this.invalidData(newName, newComment)) return

    this.setState({ loading: true })

    const res = await dispatchCallUpdateProcess(process.id, { name: newName, comment: newComment })
    if (res.success) this.setState({ loading: false }, () => closeHandler())
    else this.setState({ isError: true, errorMessage: res.message, loading: false })
  }

  render() {
    const { isError, errorMessage, loading, newName, newComment } = this.state
    const { closeHandler } = this.props
    return (
      <EditProcessModalForm
        isError={isError}
        errorMessage={errorMessage}
        closeHandler={closeHandler}
        submitHandler={this.updateProcess}
        loading={loading}
        newName={newName}
        newComment={newComment}
        handleUpdate={this.handleUpdate}
      />
    )
  }
}

export default modalize(connect(null, mapDispatchToProps)(EditProcessModal))
