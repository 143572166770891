import React, { useRef } from 'react'
import { Ref } from 'semantic-ui-react'
import { Draggable } from 'react-beautiful-dnd'

const Div = props => (<div {...props} />)

const DraggableComponent = ({
  children,
  draggableId,
  index,
  style,
  As = Div,
  isDragDisabled = false,
  onClick,
  ...AsProps
}) => {
  const myRef = useRef()
  const focusAndClick = () => {
    myRef.current.focus({ preventScroll: true })
    if (onClick) onClick()
  }

  return (
    <Draggable
      draggableId={draggableId}
      index={index}
      key={draggableId}
      isDragDisabled={isDragDisabled}
    >
      { provided => (
        <Ref innerRef={provided.innerRef}>
          <As
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...AsProps}
            onClick={focusAndClick}
          >
            <div style={{ width: '0', height: '0', overflow: 'hidden' }}>
              <input ref={myRef} type='text' />
            </div>
            <div style={style}>
              {children}
              {provided.placeholder}
            </div>
          </As>
        </Ref>
      )}
    </Draggable>
  )
}
export default DraggableComponent
