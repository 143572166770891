import React from 'react'
import { Form, Button } from 'semantic-ui-react'
import Time from 'utils/Time'

const getTimeFormattedValue = seconds => {
  if (seconds === '') return seconds
  const derivedTimeDisplayFormat = Time.deriveTimeDisplayFormat(seconds)
  return Time.timeAxisLabelFormat(seconds, derivedTimeDisplayFormat)
}

const getSelectedTimeRangesArray = pointSelectionOffset => ([
  pointSelectionOffset.startTime,
  pointSelectionOffset.endTime,
])

const isSelectedField = (fieldName, selectedField) => (
  fieldName === selectedField
)

const getPlaceHolder = (fieldName, selectedField) => {
  const isSelected = isSelectedField(fieldName, selectedField)
  return isSelected ? 'click point' : 'activate to set'
}

const OffsetMenu = ({
  menuParams,
  handleRefUpdateOffset,
  setSelectedField,
}) => {

  const {
    displayedUnit,
    pointSelectionOffset,
    selectedField,
  } = menuParams

  const [
    startTime,
    endTime,
  ] = getSelectedTimeRangesArray(pointSelectionOffset).map(getTimeFormattedValue)

  const { offsetRangeAvrg, offsetRangeReference } = pointSelectionOffset

  return (
    <div>
      <Form.Group widths='equal'>
        <Form.Input
          fluid
          label='Interval start time'
          placeholder={getPlaceHolder('startTime', selectedField)}
          focus={isSelectedField('startTime', selectedField)}
          onFocus={e => setSelectedField(e, 'startTime')}
          value={startTime}
          action={
            <Button
              icon='crosshairs'
              primary={isSelectedField('startTime', selectedField)}
              onClick={e => setSelectedField(e, 'startTime')}
            />
          }
          actionPosition='left'
        />
        <Form.Input
          fluid
          label='Interval end time'
          placeholder={getPlaceHolder('endTime', selectedField)}
          focus={isSelectedField('endTime', selectedField)}
          onFocus={e => setSelectedField(e, 'endTime')}
          value={endTime}
          action={
            <Button
              icon='crosshairs'
              primary={isSelectedField('endTime', selectedField)}
              onClick={e => setSelectedField(e, 'endTime')}
            />
          }
          actionPosition='left'
        />
        <Form.Input
          fluid
          label={`Average [${displayedUnit}]`}
          placeholder='average...'
          value={offsetRangeAvrg}
          readOnly
        />
        <Form.Input
          fluid
          label={`Correct value [${displayedUnit}]`}
          placeholder='enter correct value'
          name='offsetRangeReference'
          value={offsetRangeReference}
          onChange={handleRefUpdateOffset}
        />
      </Form.Group>
    </div>
  )
}

export default OffsetMenu
