import OutOfBoundaryTrigger from 'utils/EventTrigger/OutOfBoundaryTrigger'


class UpperLimitTrigger extends OutOfBoundaryTrigger {

  static get type() {
    return 'UPPER_LIMIT'
  }

  static get description() {
    return 'upper boundary'
  }

  static get parameters() {
    return [
      { name: 'threshold', label: 'upper boundary', type: 'number' },
    ]
  }

  static invalidParameters = (trigger, target) => {
    const { threshold } = trigger
    return target >= threshold
  }

  static create = ({ threshold }, eventType) => ({
    type: UpperLimitTrigger.type,
    threshold,
    eventType,
  })
}

export default UpperLimitTrigger
