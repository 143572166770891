import { findAndUpdate } from 'redux/helper/updateFunctions'
import DataManager from 'utils/DataManager'
import { Process } from 'utils/process'

export default (state, action) => {
  const { data } = action.payload
  const { processes } = state
  const process = Process.getById(processes, data.processId)
  if (!process || !process.data) return state

  const updateFn = targetProcess => DataManager.parseLiveStreamData(targetProcess, data)
  const updatedProcesses = findAndUpdate(data.processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }

}
