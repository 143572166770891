import React from 'react'
import { Message, Icon } from 'semantic-ui-react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import Event from 'utils/Event'

const renderDeviceName = (deviceName, deviceId) => (
  deviceName ? (
    <Message.Item
      style={{ display: 'inline-block', marginTop: '7px', marginRight: '8px' }}
      as={Link}
      to={`/device/${deviceId}`}
    >
      {deviceName}
    </Message.Item>
  )
    : <Message.Item style={{ display: 'inline-block', marginTop: '7px', minHeight: '1em' }} />
)

//const renderStreamQuickAccess = alert => {
//
//  // deactivated this link because it causes our process to try to load data
//  // infinitely when loaded via the quick access link
//  // once our loading process flow has been fixed this can be re-implemented
//
//  const { deviceId, processId } = alert
//
//  if (!processId || !deviceId) return null
//
//  return (
//    <span>
//      |
//      <Message.Item
//        style={{ display: 'inline-block', marginTop: '7px', marginLeft: '8px' }}
//        as={Link}
//        to={`/device/${deviceId}/process/${processId}`}
//      >
//        View Process
//      </Message.Item>
//    </span>
//  )
//}

const Alert = ({ alert, deleteAlert }) => (
  <Message icon style={{ paddingTop: '25px' }}>
    <Icon name='close' size='large' onClick={() => deleteAlert(alert.id)} />
    <Icon name='warning sign' color='red' style={{ position: 'absolute', top: '30px' }} />
    <i className='icon' style={{ width: '42px' }} />
    <Message.Content>
      <Message.Header>
        { Event.deriveDisplayName(alert) }
      </Message.Header>
      <div>
        {renderDeviceName(alert.deviceName, alert.deviceId)}
      </div>
      <span style={{ marginTop: '10px', float: 'left' }}>
        <span>
          {alert.displayMsg}
        </span>
      </span>
      <span style={{ float: 'right', textAlign: 'right', marginTop: '10px', marginLeft: '10px' }}>
        <span style={{ fontWeight: 'bold', display: 'block' }}>{moment(alert.createdAt).format('HH:mm:ss')}</span>
        <span style={{ display: 'block' }}>{moment(alert.createdAt).format('DD/MM/YYYY')}</span>
      </span>
    </Message.Content>
  </Message>
)

export default Alert
