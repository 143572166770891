import React from 'react'
import { connect } from 'react-redux'
import { Divider, Accordion, Icon, Button, Ref } from 'semantic-ui-react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { phaseIsModifiable, Process, PROCESS_STATES } from 'utils/process'
import ProcessBuilderUIConfig from 'utils/UIConfig/ProcessBuilderUIConfig'
import StreamGroups from 'utils/UIConfig/StreamGroups'
import PhaseProgression from 'utils/process/PhaseProgression'

import PDVC from 'utils/PDVC'
import FlashMessenger from 'utils/FlashMessenger'
import {
  setPhaseProps,
  deletePhase,
  setStreamTarget,
  deletePhaseWithinGroup,
  setDisplayedProcessPhase,
} from 'redux/actions/actions'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import EditableHeader from 'components/utility/EditableHeader'
import callUpdateDevice from 'redux/thunks/device/callUpdateDevice'
import DescriptionParser from 'utils/process/DescriptionParser'
import Group from './Group'
import TransitionFooter from './TransitionFooter'

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
  displayedPhaseId: state.displayedPhaseId,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  dispatchDeletePhase: (processId, phaseId) => dispatch(deletePhase({ processId, phaseId })),
  dispatchRenamePhase: (processId, phaseId, name) => dispatch(
    setPhaseProps({ processId, phaseId, update: { name } }),
  ),
  dispatchDeletePhaseWithinGroup: (processId, groupName, phaseId) => dispatch(deletePhaseWithinGroup(
    { processId, groupName, phaseId },
  )),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
  dispatchSetStreamTarget: (processId, phaseId, streamId, value) => dispatch(setStreamTarget({
    processId,
    phaseId,
    streamId,
    value,
  })),
  dispatchCallUpdateDevice: (deviceId, params) => dispatch(callUpdateDevice(deviceId, params)),
  dispatchSetDisplayedProcessPhase: phaseId => dispatch(
    setDisplayedProcessPhase(phaseId),
  ),
})

class PhaseTabContent extends React.Component {

  deletePhase = async () => {
    const {
      activeProcess,
      phaseId,
      dispatchDeletePhase,
      dispatchDeletePhaseWithinGroup,
    } = this.props
    const phase = DescriptionParser.getPhaseById(activeProcess, phaseId)
    const { groupName } = phase
    if (groupName) {
      dispatchDeletePhaseWithinGroup(activeProcess.id, groupName, phaseId)
    } else {
      dispatchDeletePhase(activeProcess.id, phaseId)
    }

    if (activeProcess.state === PROCESS_STATES.executable) {
      await this.save()
    }
  }

  submitNewPhaseName = async newPhaseName => {
    const { activeProcess, phaseId, dispatchRenamePhase, processDescriptionSnapshots } = this.props

    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

    if (diffs.length) {
      FlashMessenger.warning('Please revert or apply your process changes before renaming a phase')
      return
    }

    dispatchRenamePhase(activeProcess.id, phaseId, newPhaseName)
    await this.save()
  }

  toggleFold = groupName => {
    const { user, activeDevice } = this.props
    ProcessBuilderUIConfig.toggleGroupFolded(groupName, 'processBuilder', { activeDevice, userId: user.id })
  }

  async save() {
    const { activeProcess, dispatchCallUpdateProcess } = this.props
    await Process.save(activeProcess, dispatchCallUpdateProcess)
  }

  renderDeleteButton = () => {
    const {
      activeProcess,
      phasesLength,
      phaseId,
    } = this.props

    const phaseNeverRan = !PhaseProgression.contains(activeProcess, phaseId)
    const disableDeleteButton = phasesLength === 1 || (!phaseNeverRan && activeProcess.state !== 'executable')
    return (
      <Button
        icon
        circular
        size='tiny'
        color='red'
        style={{ backgroundColor: 'white' }}
        disabled={disableDeleteButton}
        onClick={this.deletePhase}
        floated='right'
      >
        <Icon name='trash' color='red' />
      </Button>
    )
  }

  renderHeader = () => {
    const { phase: { name } } = this.props
    return (
      <>
        <div style={{ display: 'flex' }}>
          <EditableHeader
            name={name}
            changeNameHandler={this.submitNewPhaseName}
            fontSize='1rem'
            fluid
            iconSize='tiny'
          />
          {this.renderDeleteButton()}
        </div>
        <Divider />
      </>
    )
  }

  renderFooter = () => {
    const { activeProcess, phaseId, phase, activeDevice } = this.props
    const phaseCanBeModified = phaseIsModifiable(activeProcess, phaseId)

    return (
      <TransitionFooter
        phase={phase}
        phaseId={phaseId}
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        phaseCanBeModified={phaseCanBeModified}
      />
    )
  }

  render() {
    const {
      processDownStreams,
      activeProcess,
      phaseId,
      activeDevice,
      displayedPhaseId,
      dispatchCallUpdateProcess,
      dispatchCallUpdateDevice,
      dispatchSetStreamTarget,
      user,
    } = this.props

    const { uIConfig } = activeDevice
    const { processBuilder: { groups } } = uIConfig
    const userId = user.id

    if (phaseId !== displayedPhaseId) return null

    return (
      <div>
        {this.renderHeader()}
        <DragDropContext onDragEnd={result => ProcessBuilderUIConfig.onDragEnd(result, 'processBuilder', { activeDevice, activeProcess, userId })}>
          <Droppable droppableId='graph-group-id' type={{ type: 'Group' }}>
            {
              provided => (
                <Ref innerRef={provided.innerRef}>
                  <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                    <Button
                      compact
                      onClick={() => ProcessBuilderUIConfig.foldall('processBuilder', { activeDevice, activeProcess, userId })}
                      style={{
                        marginLeft: 'auto', padding: '5px 10px', marginBottom: '10px', marginRight: '16px',
                      }}
                    >
                      Collapse All
                    </Button>
                    <Accordion
                      {...provided.droppableProps}
                      fluid
                      styled
                      exclusive={false}
                    >
                      {groups.map((group, i) => {
                        const isGroupEmpty = StreamGroups
                          .isGroupEmptyOnActiveProcess(group, activeProcess)
                        if (isGroupEmpty) return null

                        return (
                          <Group
                            key={group.name}
                            index={i}
                            phaseId={phaseId}
                            activeProcess={activeProcess}
                            activeDevice={activeDevice}
                            processDownStreams={processDownStreams}
                            group={group}
                            toggleFold={this.toggleFold}
                            dispatchCallUpdateProcess={dispatchCallUpdateProcess}
                            dispatchSetStreamTarget={dispatchSetStreamTarget}
                            dispatchCallUpdateDevice={dispatchCallUpdateDevice}
                            user={user}
                          />
                        )
                      })}
                      {provided.placeholder}
                    </Accordion>
                  </div>
                </Ref>
              )
            }
          </Droppable>
        </DragDropContext>
        {this.renderFooter()}
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseTabContent)
