import React from 'react'
import OopsinNotFound from 'images/404.png'
import OopsinCantAccess from 'images/403.png'
import Oopsin from 'images/OOPSIN.png'
import {
  Container,
  Segment,
  Grid,
  Image,
  Button,
} from 'semantic-ui-react'

const ErrorText = ({ text }) => (
  <svg viewBox='0 0 400 70' style={{ transform: 'scale(0.8)' }}>
    <polyline
      fill='none'
      stroke='#92312d'
      strokeWidth='2'
      points='0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 400,45.486'
    />
    <text style={{ fontSize: '9px' }} fill='#92312d' x='115' y='35'>{text}</text>
  </svg>
)

export const OspinError = ({ history, text, children }) => (
  <Container>
    <ErrorContainer text={text} history={history}>
      {children}
    </ErrorContainer>
  </Container>
)

const ErrorPage = ({ history, errorCode }) => {
  switch (errorCode) {
    case 500:
      return (
        <OspinError history={history} text='Something went wrong! The error has been reported to our developers.'>
          <Image src={Oopsin} size='large' centered style={{ paddingTop: '30px' }} />
        </OspinError>
      )
    case 404:
      return (
        <OspinError history={history} text='Oh no! You took a wrong turn!'>
          <Image src={OopsinNotFound} size='large' centered style={{ paddingTop: '30px' }} />
        </OspinError>
      )
    case 403:
      return (
        <OspinError history={history} text='You do not have permission to access this resource!'>
          <Image src={OopsinCantAccess} size='large' centered style={{ paddingTop: '30px' }} />
        </OspinError>
      )
    default:
      return (
        <OspinError history={history} text='Oh no! You took a wrong turn!'>
          <Image src={OopsinNotFound} size='large' centered style={{ paddingTop: '30px' }} />
        </OspinError>
      )
  }
}

export const ErrorContainer = ({ children, history, text }) => (
  <Segment>
    <Grid>
      <Grid.Row>
        <Grid.Column>
          { children }
        </Grid.Column>
      </Grid.Row>

      <Grid.Row centered>
        <Grid.Column verticalAlign='middle'>
          <br />
          <ErrorText text={text} />
          <br />
          <Button onClick={() => history.push('/devices')} primary size='big'>Go to devices</Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Segment>
)

export default ErrorPage
