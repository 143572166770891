import React from 'react'
import { connect } from 'react-redux'
import modalize from 'components/utility/modal/modalize'
import { Message } from 'semantic-ui-react'
import callNextProcessPhase from 'redux/thunks/command/callNextProcessPhase'
import PDVC from 'utils/PDVC'
import NextPhaseModalForm from 'components/processviewer/modals/NextPhaseModalForm'

const mapDispatchToProps = dispatch => ({
  dispatchCallNextProcessPhase: (processId, deviceId) => dispatch(
    callNextProcessPhase(processId, deviceId),
  ),
})

const mapStateToProps = ({ processDescriptionSnapshots }) => ({ processDescriptionSnapshots })

class NextPhaseModal extends React.Component {

  state = {
    isError: false,
    errorMessage: '',
  }

  moveToNextPhase = async () => {
    const { activeProcess, closeHandler, dispatchCallNextProcessPhase } = this.props

    const res = await dispatchCallNextProcessPhase(activeProcess.id, activeProcess.deviceId)
    if (res.success) {
      this.setState({ isError: false, errorMessage: '' }, () => closeHandler())
    } else {
      this.setState({ isError: true, errorMessage: res.message })
    }
  }

  renderUnappliedChangesWarning = diffs => {
    if (diffs.length === 0) return null
    return (
      <Message
        className='max-width-528'
        warning
        content={'You have unapplied changes in your process.'
          + 'The changes will be lost when you transition into a new phase.'}
      />
    )
  }

  render() {

    const { isError, errorMessage } = this.state
    const { activeProcess, processDescriptionSnapshots, closeHandler } = this.props
    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

    return (
      <>
        {this.renderUnappliedChangesWarning(diffs)}
        <NextPhaseModalForm
          isError={isError}
          errorMessage={errorMessage}
          closeHandler={closeHandler}
          submitHandler={this.moveToNextPhase}
          submitText='Confirm'
        />
      </>
    )
  }
}

export default modalize(connect(mapStateToProps, mapDispatchToProps)(NextPhaseModal))
