import  nexus from 'nexus'
import { createProcessObject } from 'redux/helper/objectMapper'
import { logAndExtractError } from 'utils/error'

const callCreateProcess = async (deviceId, processParams) => {
  try {
    const newProcessData = await nexus.process.create(deviceId, processParams)
    const process = createProcessObject(newProcessData)
    return { success: true, process }
  } catch (e) {
    return { success: false, message: logAndExtractError(e) }
  }
}

export default callCreateProcess
