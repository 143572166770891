import React from "react"
import {BrowserRouter, Route, Switch} from "react-router-dom"

import Main from '../main/Main'
import Login from "../authentication/Login"
import PrivateRoute from "./PrivateRoute"
import Report from 'components/utility/Report'
import BoardConfigBuilder from "components/admin/board-config-builder/Main"
import ErrorWrapperProvider from "components/utility/ErrorWrapperProvider";
import FlashMessagesContainer from "components/utility/FlashMessagesContainer";

const Routes = () => (

  <BrowserRouter forceRefresh={false}>
    <ErrorWrapperProvider>
      <FlashMessagesContainer/>
      <div>
        <Switch>
          <PrivateRoute exact path="/" component={Main}/>
          <Route exact path="/login" component={Login}/>
          <Route exact path='/report' component={Report} />
          <PrivateRoute exact path="/admin" component={BoardConfigBuilder}/>
          <PrivateRoute path="/" component={Main}/>
        </Switch>
      </div>
    </ErrorWrapperProvider>
  </BrowserRouter>
)

export default Routes
