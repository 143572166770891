/* eslint react/jsx-boolean-value: 0 */
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { Segment, List, Ref } from 'semantic-ui-react'
import CacheGroup from 'utils/CacheGroup'

import { PROCESS_STATES } from 'utils/process'
import { Droppable } from 'react-beautiful-dnd'
import DraggableComponent from 'components/utility/DraggableComponent'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import DeleteGroupModal from 'components/processviewer/modals/DeleteGroupModal'
import PhaseTabDraggable from './PhaseTabDraggable'
import PhaseGroupHeader from './PhaseGroupHeader'
import UnfoldGroupButton from './UnfoldGroupButton'
import PhaseAddition from './PhaseAddition'

const mapStateToProps = state => ({ displayedPhaseId: state.displayedPhaseId })

const PhaseGroupDraggable = props => {
  const {
    addPhaseWithinGroup,
    displayedPhaseId,
    phaseId,
    index,
    runningPhase,
    phases,
    groupName,
    activeProcess,
    activeDevice,
  } = props

  const initialExpandState = CacheGroup.getIsGroupExpanded(activeProcess, groupName)
  const [isExpanded, setExpand ] = useState(initialExpandState)
  const [isDragDisabled, setDisableDrag ] = useState(true)
  const ref = useRef()
  const [showDeleteGroupModal, setShowDeleteGroupModal ] = useState(false)

  const usePrevPhasesSize = () => {
    useEffect(() => {
      ref.current = phases.length
    })
    return ref.current
  }

  const prevPhases = usePrevPhasesSize()
  useEffect(() => {
    if (!prevPhases) {
      return
    }

    if (prevPhases !== phases.length) {
      const openOnPhaseUpdate = true
      CacheGroup.setIsGroupExpanded(activeProcess, groupName, openOnPhaseUpdate)
      setExpand(openOnPhaseUpdate)
    }
  }, [activeProcess, groupName, phases.length, prevPhases])

  const toggleModal = () => {
    setShowDeleteGroupModal(!showDeleteGroupModal)
  }

  const addPhaseToGroup = async () => {
    await addPhaseWithinGroup(groupName)
  }

  const toggleGroupView = () => {
    const newIsExapnded = !isExpanded
    CacheGroup.setIsGroupExpanded(activeProcess, groupName, newIsExapnded)
    setExpand(newIsExapnded)
  }

  const renderPhaseAddition = () => {
    const totalIterations = PhaseGroupParser.getGroupIterationsCount(activeProcess, groupName)
    const finishedIterations = PhaseGroupParser.getFinishedIterationsCount(activeProcess, groupName)

    if (totalIterations === finishedIterations) return null

    return (
      <List.Item style={{ padding: '8px' }}>
        <PhaseAddition
          processState={activeProcess.state}
          addPhase={addPhaseToGroup}
          IconSize='large'
          widgetSize='mini'
        />
      </List.Item>
    )
  }

  return (
    <>
      <DeleteGroupModal
        open={showDeleteGroupModal}
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        closeHandler={toggleModal}
        groupName={groupName}
        headerIcon='trash'
        headerText={`Delete ${groupName}?`}
      />
      <DraggableComponent
        index={index}
        draggableId={`group-${groupName}`}
        As={List.Item}
        active={phaseId === displayedPhaseId}
        isDragDisabled={activeProcess.state === PROCESS_STATES.finished || isDragDisabled}
      >
        <Segment.Group>
          <Segment style={{ borderBottom: '2.2px solid #2D85D0' }}>
            <PhaseGroupHeader
              groupName={groupName}
              activeProcess={activeProcess}
              setDisableDrag={setDisableDrag}
              deleteGroup={toggleModal}
              activeDevice={activeDevice}
            />
          </Segment>
          <Droppable droppableId={`${groupName}`}>
            {provided => (
              <Ref innerRef={provided.innerRef}>
                <div style={{ height: isExpanded ? '100%' : '0px', backgroundColor: '#f5f5f5' }}>
                  <List
                    verticalAlign='middle'
                    size='small'
                    style={{ display: isExpanded ? '' : 'none', padding: '8px 8px' }}
                    {...provided.droppableProps}
                  >
                    {phases.map((phase, i) => (
                      <div key={phase.id} style={{ padding: '8px' }}>
                        <PhaseTabDraggable
                          phase={phase.data}
                          index={phase.idx}
                          phaseId={phase.id}
                          activeProcess={activeProcess}
                          runningPhase={runningPhase}
                          isDragDisabled
                        />
                      </div>
                    ))}
                    {provided.placeholder}
                    {renderPhaseAddition()}
                  </List>
                </div>
              </Ref>
            )}
          </Droppable>
          <UnfoldGroupButton
            addPhase={toggleGroupView}
            IconSize='large'
            widgetSize='small'
            icon={isExpanded ? 'angle up' : 'angle down'}
          />
        </Segment.Group>
      </DraggableComponent>
    </>
  )
}

export default connect(
  mapStateToProps,
  null,
)(PhaseGroupDraggable)
