import React from 'react'
import { Container, Segment, Item, Grid, Header, Divider } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { getInvitationNotifications } from 'utils/user'

import Invitation from './Invitation'

const mapStateToProps = state => ({
  user: state.user,
})

const renderInvitation = (inv, i) => <Invitation inv={inv} key={i} />

const Notifications = ({ user }) => (
  <Grid>
    <Grid.Row>
      <Container fluid text>
        <Segment>
          <Header as='h2'>Invitations</Header>
          <Divider />
          <Item.Group divided>
            {getInvitationNotifications(user).map(renderInvitation)}
          </Item.Group>
        </Segment>
      </Container>
    </Grid.Row>
  </Grid>
)

export default withRouter(connect(mapStateToProps)(Notifications))

