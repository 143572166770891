import React, { useState } from 'react'
import { Form, Menu, Accordion, Button, Icon, Segment } from 'semantic-ui-react'
import DraggableComponent from 'components/utility/DraggableComponent'
import { phaseIsModifiable, Process, PROCESS_STATES } from 'utils/process'
import DescriptionParser from 'utils/process/DescriptionParser'
import NoDataMessage from 'components/utility/NoDataMessage'
import Authorizer from 'utils/Authorizer'
import DeviceConfig from 'utils/DeviceConfig'
import FreeValueStream from './StreamTypes/FreeValueStream'
import BooleanStream from './StreamTypes/BooleanStream'
import OneOfStream from './StreamTypes/OneOfStream'
import ControllerModal from './ControllerModal'

const Group = ({
  activeProcess, activeDevice, phaseId, processDownStreams,
  index, toggleFold,
  group: { folded, name, streams },
  dispatchSetStreamTarget,
  dispatchCallUpdateProcess,
  dispatchCallUpdateDevice,
  user,
}) => {

  const [ showControllerModal, toggleControllerModal ] = useState(false)
  const [isDragDisabled, setDisableDrag ] = useState(true)

  const updateStreamTarget = async (streamId, newTarget) => {
    dispatchSetStreamTarget(activeProcess.id, phaseId, streamId, newTarget)

    // update remote process ( in case it hasn't started yet)
    if (activeProcess.state === PROCESS_STATES.executable) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const changeDisplayedUnit = async (newUnit, streamId) => {
    // set remotely the chosen unit as a default if user has correct access
    if (!Authorizer.user(user).hasEditRights(activeDevice)) {
      return
    }

    const updatedConfig = DeviceConfig
      .updateStreamConfig(activeDevice, streamId, { defaultUnit: newUnit })

    const updateParams = { config: updatedConfig }

    await dispatchCallUpdateDevice(activeDevice.id, updateParams)
  }

  const getStreamDisplayedUnit = streamId => DeviceConfig
    .getDisplayedStreamUnit(activeDevice, streamId)

  const renderSettingsDropdown = () => (
    <Button
      onClick={() => toggleControllerModal(true)}
      labelPosition='left'
      icon
      type='button'
      style={{ position: 'absolute', right: '8px', top: '8px' }}
    >
      <Icon name='setting' />
      Controller
    </Button>
  )

  const renderControllerModal = controllerStreams => (
    <ControllerModal
      open={showControllerModal}
      closeHandler={() => toggleControllerModal(false)}
      controllerStreams={controllerStreams}
      activeDevice={activeDevice}
      phaseId={phaseId}
      processDownStreams={processDownStreams}
      activeProcess={activeProcess}
    />
  )

  const renderController = controllerStreams => (
    <>
      { renderSettingsDropdown() }
      { renderControllerModal(controllerStreams) }
    </>
  )

  const renderBooleanStream = (stream, streamTarget, disabledInput, idx) => (
    <BooleanStream
      key={idx}
      stream={stream}
      streamTarget={streamTarget}
      disabledInput={disabledInput}
      updateStreamTarget={updateStreamTarget}
    />
  )

  const renderFreeValueStream = (stream, streamTarget, disabledInput, idx) => (
    <FreeValueStream
      key={idx}
      stream={stream}
      streamTarget={streamTarget}
      disabledInput={disabledInput}
      updateStreamTarget={updateStreamTarget}
      getStreamDisplayedUnit={getStreamDisplayedUnit}
      changeDisplayedUnit={changeDisplayedUnit}
      activeDevice={activeDevice}
    />
  )

  const renderOneOfStream = (stream, streamTarget, disabledInput, idx) => {
    if (!stream.expectedValueOptions) {
      console.warn('stream expected values are missing')
      return null
    }
    return (
      <OneOfStream
        key={idx}
        stream={stream}
        streamTarget={streamTarget}
        disabledInput={disabledInput}
        updateStreamTarget={updateStreamTarget}
        getStreamDisplayedUnit={getStreamDisplayedUnit}
        changeDisplayedUnit={changeDisplayedUnit}
        activeDevice={activeDevice}
      />
    )
  }

  const renderStreamByExpectedValueType = (stream, idx) => {
    const streamTarget = DescriptionParser.getStreamTarget(activeProcess, phaseId, stream.id)
    const disabledInput = !phaseIsModifiable(activeProcess, phaseId)


    switch (stream.expectedValueType) {
      case 'boolean':
        return renderBooleanStream(stream, streamTarget, disabledInput, idx)
      case 'oneOf':
        return renderOneOfStream(stream, streamTarget, disabledInput, idx)
      default:
        return renderFreeValueStream(stream, streamTarget, disabledInput, idx)
    }
  }

  const renderOnlyControllerParametersGroup = (controllerStreams, targetStreamId) => (
    <Segment style={{ minHeight: '82px' }}>
      <Form style={{ height: '52px', paddingTop: '18px' }}>
        <NoDataMessage
          text='This group contains only controller parameters'
        />
        { controllerStreams.length > 0 && renderController(controllerStreams, targetStreamId)}
      </Form>
    </Segment>
  )

  const createGroup = groupFolded => {
    if (groupFolded) return null

    const controllerStreams = processDownStreams
      .filter(stream => stream.controller)
      .filter(stream => streams.find(uiStream => uiStream.streamId === stream.id) !== undefined)
    const standardStreams = processDownStreams
      .filter(stream => !stream.controller)
      .filter(stream => streams.find(uiStream => uiStream.streamId === stream.id) !== undefined)

    // TODO: find the controller downstream with a linked upstream
    // for now just grab the controller target down stream

    const targetStreamId = controllerStreams.length
      ? standardStreams.find(stream => stream.groupName === controllerStreams[0].groupName)?.id
      : null

    if (standardStreams.length === 0) {
      return renderOnlyControllerParametersGroup(controllerStreams, targetStreamId)
    }

    return (
      <Segment>
        <Form>
          { controllerStreams.length > 0 && renderController(controllerStreams, targetStreamId)}
          { standardStreams.map(renderStreamByExpectedValueType) }
        </Form>
      </Segment>
    )
  }

  const handleClick = () => {
    toggleFold(name)
  }

  return (
    <DraggableComponent draggableId={name} index={index} isDragDisabled={showControllerModal || isDragDisabled}>
      <Menu.Item key={index}>
        <Accordion.Title
          active={!folded}
          onClick={handleClick}
          content={name}
          index={index}
          style={{ fontSize: '18px', backgroundColor: '#f5f5f5' }}
          className='accordion-menu-title'
          onMouseOver={() => setDisableDrag(false)}
          onFocus={() => setDisableDrag(false)}
          onMouseLeave={() => setDisableDrag(true)}
        />
        <Accordion.Content
          active={!folded}
          content={createGroup(folded)}
          style={{
            backgroundColor: '#f5f5f5',
            padding: '24px',
          }}
        />
      </Menu.Item>
    </DraggableComponent>
  )
}
export default Group
