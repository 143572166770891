import React from 'react'
import { Button, Card } from 'semantic-ui-react'
import { ellipseString } from 'utils/string'
import DurationDisplay from 'components/misc/DurationDisplay'

const processMetaDataStyle = {
  width: '50%',
  margin: '0px',
  padding: '0px',
  boxShadow: '2px 0px 0px #eee',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}

const RenderNotRunning = ({ connectionState }) => (
  <Card.Description>
    <div
      className='meta'
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '57px',
        display: 'flex',
      }}
    >
      <span style={{ fontSize: '15px' }}>
        {connectionState === 'online' ? 'ready for process' : 'ready to connect'}
      </span>
    </div>
  </Card.Description>
)


const RenderViewProcess = ({
  setHover,
  history,
  dispatchResetActiveDevice,
  deviceId,
  runningProcessId,
  resetDevice,
}) => {
  const runningProcessClickHandler = event => {
    resetDevice(event, dispatchResetActiveDevice)
    history.push(`/device/${deviceId}/process/${runningProcessId}`)
  }

  return (
    <>
      <div
        onMouseLeave={() => setHover(false)}
        className='meta'
        style={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '57px',
          display: 'flex',
        }}
      >
        <Button
          onClick={event => runningProcessClickHandler(
            event,
            history,
            dispatchResetActiveDevice,
            deviceId,
            runningProcessId,
          )}
          primary
        >
          View running process
        </Button>
      </div>
    </>
  )
}

const RenderProcessDetails = (
  {
    setHover,
    runningData: {
      startedAtDay,
      startedAtTime,
      startedBy,
      name,
      duration,
    },
  },
) => (
  <Card.Content onMouseEnter={() => setHover(true)} extra className='meta ui internally celled grid'>
    <div style={processMetaDataStyle}>
      <strong>{ellipseString(name, 17)}</strong>
      <br />
      started by
      <strong>
        {' '}
        {ellipseString(startedBy, 8)}
      </strong>
      <br />
      on
      <strong>
        {' '}
        {startedAtDay}
      </strong>
      at
      <strong>
        {' '}
        {startedAtTime}
      </strong>
    </div>
    <DurationDisplay ms={duration} />
  </Card.Content>
)

const RenderRunning = ({
  runningData,
  hovered,
  setHover,
  history,
  dispatchResetActiveDevice,
  deviceId,
  runningProcessId,
  resetDevice,
}) => (
  <Card.Description>
    {(hovered && runningData.hasAccess)
      ? (
        <RenderViewProcess
          setHover={setHover}
          history={history}
          dispatchResetActiveDevice={dispatchResetActiveDevice}
          deviceId={deviceId}
          runningProcessId={runningProcessId}
          resetDevice={resetDevice}
        />
      )
      : <RenderProcessDetails setHover={setHover} runningData={runningData} />}
  </Card.Description>
)

const ProcessRunningState = ({
  runningData,
  hovered,
  setHover,
  history,
  dispatchResetActiveDevice,
  id,
  runningProcessId,
  connectionState,
  resetDevice,
}) => (
  <>
    {runningData
      ? (
        <RenderRunning
          runningData={runningData}
          hovered={hovered}
          setHover={setHover}
          history={history}
          dispatchResetActiveDevice={dispatchResetActiveDevice}
          deviceId={id}
          runningProcessId={runningProcessId}
          resetDevice={resetDevice}
        />
      )
      : (
        <RenderNotRunning
          connectionState={connectionState}
        />
      )}
  </>
)

export default ProcessRunningState
