import nexus from 'nexus'
import { removeProcessUser } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callRevokeProcessAccess = async (dispatch, processId, userId, groupName) => {
  await nexus.process.revokeAccess(processId, { groupName, userId })
  dispatch(removeProcessUser({ processId, groupName, userId }))
}

export default thunkWrapper(callRevokeProcessAccess)
