import React from 'react'
import formalize from 'components/utility/modal/formalize'

const PauseProcessModalForm = () => (
  <>
    Are you sure you want to pause the process?
  </>
)

export default formalize(PauseProcessModalForm)
