import React, { Fragment } from 'react'
import { Modal, Button, Icon, Form, Message } from 'semantic-ui-react'
import { Process } from 'utils/process'
import PDVC from 'utils/PDVC'
import DescriptionParser from 'utils/process/DescriptionParser'

const getTransitionText = phase => {
  const tranistionTextTimeBased = <span><strong>{PDVC.createInsertedPhaseName(phase.name)}</strong> will run for the remaining time of <strong>{phase.name}</strong>.</span>
  const transitionText = phase.transition === 'manual' ? null : tranistionTextTimeBased

  return <span>{transitionText}<strong><br/> {phase.name}</strong> will be recorded as a time-based phase with a duration of its elapsed time.</span>
}

const renderWarningMessage = phase => (
  <Fragment>
    <p>
      To preserve the process execution history, a new phase
      named <strong>{PDVC.createInsertedPhaseName(phase.name)}</strong> will be inserted with your edited
      parameters and the device will transition to it.</p>
    <p>{getTransitionText(phase)}</p>
  </Fragment>
)

const ConfirmAdhocChangesModal = ({ open, closeHandler, confirmHandler, process, applyingChanges }) => {

  const phaseId = Process.getRunningPhase(process).phaseId
  const phase = DescriptionParser.getPhaseById(process, phaseId)

  return (
    <Modal size='tiny' open={open}
      onClose={closeHandler}
      onClick={event => event.stopPropagation()}>
      <Modal.Header>
        <Icon name='edit'/>Running Phase Modification</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Form
            onSubmit={confirmHandler}
            style={{ marginBottom: '15px', paddingBottom: '15px'}}
          >
            <Message
              info
              header='You are about to modify a running phase!'
              content={renderWarningMessage(phase)}
              icon='warning circle'
              className='max-width-528'
            />
            <Button
              type='submit'
              floated='right'
              disabled={applyingChanges}
              primary
            >Confirm</Button>
            <Button floated='right'
              onClick={closeHandler}
            >Close</Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default ConfirmAdhocChangesModal
