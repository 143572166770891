import nexus from 'nexus'
import { removeNotifications, resetDeviceInitialisation } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callAcceptDeviceInvitation = async (dispatch, userId, invitationId) => {
  await nexus.user.acceptDeviceInvitation(userId, { invitationId })
  nexus.device.resetIotProvider()
  dispatch(removeNotifications({ ids: [invitationId] }))
  dispatch(resetDeviceInitialisation({ devicesInitialized: false }))
}

export default thunkWrapper(callAcceptDeviceInvitation)
