import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

const IconButton = ({ text, icon = 'edit', clickHandler }) => (
  <Button
    primary
    icon
    labelPosition='left'
    onClick={clickHandler}
  >
    <Icon name={icon}/>
    {text}
  </Button>
)

export default IconButton
