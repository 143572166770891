import { API } from 'aws-amplify'

export default async (deviceId,
  { limit = undefined, processId = undefined, startTime = undefined, endTime = undefined } = {}) => {
  const queryParams = [
    { name: 'limit', val: limit },
    { name: 'processId', val: processId },
    { name: 'startTime', val: startTime },
    { name: 'endTime', val: endTime },
  ]

  const queryStringParameters = {}
  queryParams.forEach(param => {
    if (param.val !== undefined) queryStringParameters[param.name] = param.val
  })

  return API.get('log', `device/${deviceId}`, { queryStringParameters })
}
