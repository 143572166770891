import nexus from 'nexus'
import { removeNotifications } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callDeleteEphemeralNotifications = async (dispatch, userId, notifications) => {
  for (const notifaction of notifications) {
    if (notifaction.topic === 'device/revocation') {
      await nexus.user.revokeIotPolicy(userId, { revocationId: notifaction.id })
      nexus.device.resetIotProvider()
    }
    await nexus.user.deleteNotification(userId, notifaction.id)
  }
  dispatch(removeNotifications({ ids: [notifications.map(notification => notification.id)] }))
}

export default thunkWrapper(callDeleteEphemeralNotifications)
