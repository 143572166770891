import nexus from 'nexus'
import { deleteProcesses } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callDeleteProcesses = async (dispatch, ids) => {
  await nexus.process.remove(ids)
  dispatch(deleteProcesses(ids))
}

export default thunkWrapper(callDeleteProcesses)
