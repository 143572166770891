export default class DeviceDashboardUIConfig {
  static addStreamToDashboard(deviceDashboard, stream) {
    deviceDashboard.push({ streamId: stream.id })
    return deviceDashboard
  }

  static hasDashboardStream(deviceDashboard, streamId) {
    return deviceDashboard.some(stream => stream.streamId === streamId)
  }

  static removeStreamFromDashboard(deviceDashboard, streamId) {
    const updatedDeviceDashboard = deviceDashboard.filter(dashboardStream => dashboardStream.streamId !== streamId)
    return updatedDeviceDashboard
  }
}
