import { Process } from 'utils/process'
import { createListProcessObject } from 'redux/helper/objectMapper'
import { Device } from 'utils/device'

export default (state, action) => {
  const { devices } = state
  const newProcess = createListProcessObject(action.payload)

  const processDevice = Device.getById(devices, newProcess.deviceId)
  newProcess.state = Process.deriveProcessState(newProcess, processDevice)

  return { ...state, processes: [newProcess, ...state.processes] }
}
