import { ACTIONS } from 'redux/actions/actions'
import resumeProcess from 'redux/reducers/command/resumeProcess'
import startProcess from 'redux/reducers/command/startProcess'
import pauseProcess from 'redux/reducers/command/pauseProcess'
import endProcess from 'redux/reducers/command/endProcess'

export default {
  [ACTIONS.RESUME_PROCESS]: resumeProcess,
  [ACTIONS.START_PROCESS]: startProcess,
  [ACTIONS.PAUSE_PROCESS]: pauseProcess,
  [ACTIONS.END_PROCESS]: endProcess,
}
