import Streams from 'utils/Streams'
import { convertFrom, replaceNoUnitCharacter } from 'utils/units'

class OutOfBoundaryTrigger {

  static isDisplayable = true

  static suitableStreams = streams => Streams.getUpstreams(streams)

  static displayParameters = (trigger, streamUnit, displayedUnit) => {
    const converter = convertFrom(streamUnit).to(displayedUnit)

    return `${converter(trigger.threshold)} ${replaceNoUnitCharacter(displayedUnit, '')}`
  }
}

export default OutOfBoundaryTrigger
