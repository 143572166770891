import React from 'react'
import { connect } from 'react-redux'

import FlashMessage, { Message } from './FlashMessage'

const mapStateToProps = (state: any): StoreProps => ({
  flashMessages: state.flashMessages,
})

type StoreProps = {
  flashMessages: Array<Message>,
}

type Props = StoreProps

class FlashMessagesContainer extends React.Component<Props> {

  render() {

    const { flashMessages } = this.props

    return (
      <div
        style={{
          position: 'fixed',
          bottom: '5%',
          right: '5%',
          display: 'flex',
          flexDirection: 'column',
          zIndex: 1000,
        }}
      >
        {flashMessages.map(message => (
          <FlashMessage
            key={message.id}
            message={message}
          />
        ))}
      </div>
    )
  }
}

export default connect<StoreProps>(mapStateToProps)(FlashMessagesContainer)
