import React from 'react'
import { connect } from 'react-redux'
import modalize from 'components/utility/modal/modalize'
import callPauseProcess from 'redux/thunks/command/callPauseProcess'
import { updateDevice } from 'redux/actions/actions'
import Streams from 'utils/Streams'
import PauseProcessModalForm from 'components/processviewer/modals/PauseProcessModalForm'

const mapDispatchToProps = dispatch => ({
  dispatchCallPauseProcess: (processId, deviceId, downStreamIds) => dispatch(
    callPauseProcess(processId, deviceId, downStreamIds),
  ),
  dispatchUpdateDevice: (deviceId, params) => dispatch(
    updateDevice({ deviceId, params: { ...params } }),
  ),
})

class PauseProcessModal extends React.Component {

  state = { isError: false, errorMessage: '' }

  pauseProcess = async () => {
    const {
      activeDevice,
      activeProcess,
      dispatchUpdateDevice,
      dispatchCallPauseProcess,
      closeHandler,
    } = this.props

    if (!activeDevice.readyForCommand) return

    const downStreamIds = Streams.getDownstreams(activeProcess.deviceDescription)
      .map(stream => stream.id)

    dispatchUpdateDevice(activeDevice.id, { readyForCommand: false })
    const res = await dispatchCallPauseProcess(
      activeProcess.id, activeProcess.deviceId, downStreamIds,
    )
    if (res.success) {
      this.setState({ isError: false, errorMessage: '' }, () => closeHandler())
    } else {
      this.setState({ isError: true, errorMessage: res.message })
    }
  }

  render() {
    const { isError, errorMessage } = this.state
    const { closeHandler } = this.props

    return (
      <PauseProcessModalForm
        isError={isError}
        errorMessage={errorMessage}
        closeHandler={closeHandler}
        submitHandler={this.pauseProcess}
        submitText='Confirm'
      />
    )
  }
}

export default modalize(connect(null, mapDispatchToProps)(PauseProcessModal))
