import nexus from 'nexus'
import { transferDeviceOwnership } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callTransferDeviceOwnership = async (dispatch, deviceId, newOwnerId) => {
  await nexus.device.transferOwnership(deviceId, { newOwnerId })
  dispatch(transferDeviceOwnership({ deviceId, newOwnerId }))
}

export default thunkWrapper(callTransferDeviceOwnership)
