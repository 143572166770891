import nexus from 'nexus'
import { deleteStreamConfigurationProperty } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callDeleteStreamNameAlias = async (dispatch, streamId, deviceId) => {
  await nexus.device.deleteStreamNameAlias(deviceId, { streamId })
  dispatch(deleteStreamConfigurationProperty({ deviceId, streamId, prop: 'streamNameAliases' }))
}

export default thunkWrapper(callDeleteStreamNameAlias)
