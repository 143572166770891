import nexus from 'nexus'
import { setStreamConfigurationProperty } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callSetStreamCalibration = async (dispatch, deviceId, streamId, slope, offset) => {
  await nexus.device.setCalibration(deviceId, { streamId, params: { offset, slope } })
  dispatch(setStreamConfigurationProperty({
    deviceId,
    streamId,
    prop: 'calibration',
    entryValues: [ offset, slope ],
  }))
}

export default thunkWrapper(callSetStreamCalibration)
