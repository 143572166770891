import { findAndUpdate } from 'redux/helper/updateFunctions'
import { Device } from 'utils/device'

export default (state, action) => {
  const { devices } = state
  const { deviceId, streamId, prop, entryValues } = action.payload

  const updateFn = device => Device
    .setStreamConfigurationProperty(device, streamId, prop, entryValues)
  const updatedDevices = findAndUpdate(deviceId, devices, [ updateFn ])

  return { ...state, devices: updatedDevices }
}
