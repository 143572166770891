import React, { useState } from 'react'
import { Segment, Header, Divider, Form, Select, Button } from 'semantic-ui-react'
import { connect } from 'react-redux'

import DeviceDashboardUIConfig from 'utils/UIConfig/DeviceDashboardUIConfig'
import UIConfig from 'utils/UIConfig/UIConfig'
import Streams from 'utils/Streams'


const mapStateToProps = state => ({ userId: state.user.id })

const addStreamToDashboard = async (activeDevice, streamId, userId) => {
  const stream = Streams.getById(activeDevice.streams, streamId)
  if (!stream) return
  const { uIConfig, uIConfig: { deviceDashboard } } = activeDevice
  uIConfig.deviceDashboard = DeviceDashboardUIConfig.addStreamToDashboard(deviceDashboard, stream)
  await UIConfig.persistUIConfig(userId, activeDevice.id, uIConfig)
}

const createStreamOptions = (activeDevice, groupName) => (groupName ? Streams.getGroupStreams(activeDevice.streams, groupName)
  .filter(stream => stream.type === 'upstream' && !DeviceDashboardUIConfig.hasDashboardStream(activeDevice.uIConfig.deviceDashboard, stream.id))
  .map((stream, i) => ({ key: i, text: stream.name, value: stream.id })) : [])


const DashboardHeader = ({ activeDevice, userId }) => {

  const [ selectedGroup, selectGroup ] = useState(null)
  const [ selectedStream, selectStream ] = useState(null)

  const upStreamGroups = Streams.getGroups(Streams.getUpstreams(activeDevice.streams))

  const groupOptions = upStreamGroups.map((group, i) => (
    { key: i, text: group.name, value: group.name }))

  const streamOptions = createStreamOptions(activeDevice, selectedGroup)
  return (
    <Segment>
      <Header textAlign='left'>Dashboard</Header>
      <Divider />
      <Form>
        <Form.Group inline>
          <Form.Field
            control={Select}
            label='Group:'
            placeholder='Choose group..'
            options={groupOptions}
            onChange={(_, { value }) => selectGroup(value)}
          />
          <Form.Field
            control={Select}
            label='Stream:'
            disabled={!selectedGroup}
            placeholder='Choose stream..'
            options={streamOptions}
            onChange={(_, { value }) => selectStream(value)}
          />
          <Form.Field
            control={Button}
            primary
            onClick={() => addStreamToDashboard(activeDevice, selectedStream, userId)}
          >
            {' '}
            Add
            {' '}
          </Form.Field>
        </Form.Group>
      </Form>
    </Segment>
  )
}

export default connect(mapStateToProps, null)(DashboardHeader)
