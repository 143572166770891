import React from 'react'
import { withRouter } from 'react-router-dom'
import { Container, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import UserTable from 'components/profile/UserTable'

import callChangePassword from 'redux/thunks/auth/callChangePassword'
import callUpdateUser from 'redux/thunks/user/callUpdateUser'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallChangePassword: (oldpassword, newpassword) => dispatch(
    callChangePassword(oldpassword, newpassword),
  ),
  dispatchCallUpdateUser: (userId, userData) => dispatch(
    callUpdateUser(userId, userData),
  ),
})

class Profile extends React.Component {

  updateUser = async update => {
    const { user: { id: userId }, dispatchCallUpdateUser } = this.props
    await dispatchCallUpdateUser(userId, update)
  }

  render() {

    const { user } = this.props
    return (
      <Container fluid>
        <Grid>
          <UserTable user={user} updateUser={this.updateUser} />
        </Grid>
      </Container>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Profile))
