/* eslint-disable no-undef */
import React from 'react'
import { Table, Button } from 'semantic-ui-react'
import FlashMessenger from 'utils/FlashMessenger'
import Validator from 'utils/validation/Validator'

const SupportUser = ({ supportUser, subject }) => {
  const { userName, email, phoneNumber } = supportUser
  const copyEmail = () => {
    const dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = email
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    FlashMessenger.success(`copied ${email}`)
  }

  const renderEmailCellContent = () => {
    if (Validator.isUndefinedOrNull(email)) return null

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>{email}</div>
        <Button
          size='small'
          icon='copy'
          onClick={copyEmail}
        />
      </div>
    )
  }

  return (
    <Table.Row>
      <Table.Cell>
        {userName}
      </Table.Cell>
      <Table.Cell>
        {renderEmailCellContent()}
      </Table.Cell>
      <Table.Cell>
        {phoneNumber}
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          primary
          inverted
          color='blue'
          icon='mail'
          href={`mailto:${email}?subject=${subject}`}
          className='blue-inverted-button'
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default SupportUser
