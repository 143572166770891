import { findAndUpdate } from 'redux/helper/updateFunctions'
import { addUserToGroup, removeUserFromGroup } from 'utils/access'
import { Device } from 'utils/device'

export default (state, action) => {
  const { devices, deviceUsers } = state
  const { deviceId, newOwnerId } = action.payload

  const newOwnersNewGroupName = 'admins'
  const newOwnersOldGroupName = deviceUsers.find(user => user.id === newOwnerId).accessGroup

  const updateFnAddToGroup = device => addUserToGroup(device, newOwnersNewGroupName, newOwnerId)
  const updateFnRemoveFromGroup = device => removeUserFromGroup(
    device, newOwnersOldGroupName, newOwnerId,
  )

  const updateOwnerId = device => Object.assign(device, { ownerId: newOwnerId })
  const updatedDevices = findAndUpdate(
    deviceId, devices, [ updateFnRemoveFromGroup, updateFnAddToGroup, updateOwnerId ],
  )

  const updateFnDeviceUsers = deviceUser => Device.updateDeviceUserGroup(
    deviceUser, newOwnersNewGroupName,
  )

  const updatedDeviceUsers = findAndUpdate(newOwnerId, deviceUsers, [ updateFnDeviceUsers ])

  return { ...state, devices: updatedDevices, deviceUsers: updatedDeviceUsers }
}
