const AWS_REGION = 'eu-central-1'

/*************************************************************/
/*                          COMMON                           */
/*************************************************************/

const getCommonConfig = () => ({
  PubSub: {
    region: AWS_REGION,
    endPoint: 'wss://a2q06p6h18ey04-ats.iot.eu-central-1.amazonaws.com/mqtt',
  },
  Analytics: { disabled: true },
})

/*************************************************************/
/*                            API                            */
/*************************************************************/

const getAPIEndPointsConfig = env => ({
  API: {
    endpoints: [
      {
        name: 'device',
        endpoint: `https://api-${env}.ospin-services.com/devices/`,
        region: AWS_REGION,
      },
      {
        name: 'event',
        endpoint: `https://api-${env}.ospin-services.com/events/`,
        region: AWS_REGION,
      },
      {
        name: 'process',
        endpoint: `https://api-${env}.ospin-services.com/processes/`,
        region: AWS_REGION,
      },
      {
        name: 'user',
        endpoint: `https://api-${env}.ospin-services.com/users/`,
        region: AWS_REGION,
      },
      {
        name: 'utils',
        endpoint: `https://api-${env}.ospin-services.com/utils/`,
        region: AWS_REGION,
      },
      {
        name: 'log',
        endpoint: `https://api-${env}.ospin-services.com/logs/`,
        region: AWS_REGION,
      },
      {
        name: 'uiconfig',
        endpoint: `https://api-${env}.ospin-services.com/uiconfigs/`,
        region: AWS_REGION,
      },
    ],
  },
})

/*************************************************************/
/*                            AUTH                           */
/*************************************************************/

const ENV_CONFIGS = {
  demo: {
    userPoolId: 'eu-central-1_ZklcHEhtJ',
    userPoolWebClientId: '6hikiufjugkgaodlal41tvqn95',
    identityPoolId: 'eu-central-1:b3dc7363-722c-4f4d-b011-0ff933811e51',
  },
}

const getAuthConfig = env => ({
  Auth: {
    mandatorySignIn: false,
    region: AWS_REGION,
    userPoolId: ENV_CONFIGS[env].userPoolId,
    userPoolWebClientId: ENV_CONFIGS[env].userPoolWebClientId,
    identityPoolId: ENV_CONFIGS[env].identityPoolId,
  },
})

/*************************************************************/
/*                          MERGING                          */
/*************************************************************/

const createConfig = env => ({
  ...getCommonConfig(),
  ...getAuthConfig(env),
  ...getAPIEndPointsConfig(env),
  stage: env,
})

export default createConfig
