import React from 'react'
import { Segment, Icon } from 'semantic-ui-react'

const PhaseAddition = ({ addPhase, processState, IconSize, widgetSize, icon = 'plus', isDisabled = false }) => {

  if (processState === 'finished') return null

  return (
    <Segment
      color='blue'
      textAlign='center'
      onClick={addPhase}
      size={widgetSize}
      className='process-tree-button'
      disabled={isDisabled}
    >
      <Icon
        size={IconSize}
        name={icon}
        color='blue'
      />
    </Segment>
  )
}

export default PhaseAddition
