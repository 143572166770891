import nexus from 'nexus'
import { addHistoricStreamData } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callLoadHistoricStreamData = async (
  dispatch, processId, streamId, numberOfPoints, start, end,
) => {
  const data = await nexus.datapoints.get({
    processId,
    streamId,
    numberOfPoints,
    start,
    end,
  })
  dispatch(addHistoricStreamData({ streamId, data, processId }))
}

export default thunkWrapper(callLoadHistoricStreamData)
