import ReactDOM from 'react-dom'
import React from 'react'
import store from './redux/store'
import { Provider } from 'react-redux'

import './styles/semanticoverrides.css'
import './styles/device-spinner.css'
import { createConfig } from 'env.config'
import { getDeploymentEnv } from 'config/envDetector'
import nexus from 'nexus'
import App from './App'

createConfig()
nexus.connectToEnv(getDeploymentEnv())

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('app'),
)
