export function ellipseString(string, max) {
  const numberOfEllipses = 3
  if (max < numberOfEllipses) return '...'

  return string.length > max ? `${string.slice(0, max - numberOfEllipses)}...` : string
}

export function capitalize(string) {
  return string[0].toUpperCase() + string.substr(1)
}
