import React from 'react'
import { connect } from 'react-redux'
import { Menu, Grid, Dropdown, Input, Button, Icon } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

import ProcessesArea from './ProcessesArea'
import DateQueryMenu from './DateQueryMenu'
import CreateProcessModal from '../modals/CreateProcessModal'
import CloneProcessModal from '../modals/CloneProcessModal'
import EditProcessModal from '../modals/EditProcessModal'
import AccessProcessModal from '../modals/AccessProcessModal/AccessProcessModal'
import './Processes.css'

const mapStateToProps = ({ user, deviceUsers }) => ({ user, deviceUsers })

class Processes extends React.Component {

  viewOptions = [
    { text: 'Table', value: 'table' },
    { text: 'Tiles', value: 'tiles' },
  ]

  searchOptions = [
    { text: 'in Name', value: 'name' },
    { text: 'in Comment', value: 'comment' },
    { text: 'in State', value: 'state' },
    { text: 'in Owner', value: 'ownerId' },
  ]

  state = {
    viewMode: 'tiles',
    queryString: '',
    filterProperty: 'name',
    filteredProcessIds: '',
    resultsPerPage: 10,
    activePage: 1,
    startDate: null,
    endDate: null,
    timeQueryProperty: 'createdAt',
    showDateQueryMenu: false,
    showCreateProcessModal: false,
    currentProcess: null,
    showCloneModal: false,
    showEditModal: false,
    showAccessProcessModal: false,
  }

  handleUpdate = (event, { value, name }) => {
    this.setState({ [name]: value })
  }

  updateQueryString = event => {
    this.setState({ queryString: event.target.value }, () => this.applyFilterByQuery())
  }

  updateActivePage = (_, data) => (
    this.setState({ activePage: data.activePage })
  )

  updateResultsPerPage = (_, { value: resultsPerPage }) => {
    this.setState({ resultsPerPage, activePage: 1 })
  }

  updateFilteredProcessIds = filteredProcessIds => (
    this.setState({ filteredProcessIds }, () => {
      this.updateActivePage(null, { activePage: 1 })
    })
  )

  toggleDateQueryMenu = () => {
    this.setState(({ showDateQueryMenu }) => ({ showDateQueryMenu: !showDateQueryMenu }))
  }

  applyFilterByOwnerName = () => {
    const { queryString } = this.state
    const { deviceProcesses } = this.props

    if (queryString.trim() === '') {
      this.updateFilteredProcessIds([])
      return
    }

    const { deviceUsers } = this.props
    const re = new RegExp(queryString, 'i')

    const userIds = deviceUsers
      .filter(user => re.test(user.userName))
      .map(user => user.id)

    const filteredIds = deviceProcesses
      .filter(process => !userIds.includes(process.ownerId))
      .map(process => process.id)

    this.updateFilteredProcessIds(filteredIds)
  }

  applyFilterByQueryString = () => {
    const { queryString, filterProperty } = this.state
    const { deviceProcesses } = this.props

    if (queryString.trim() === '') {
      this.updateFilteredProcessIds([])
      return
    }

    const re = new RegExp(queryString, 'i')
    const filteredIds = deviceProcesses
      .filter(process => !re.test(process[filterProperty]))
      .map(process => process.id)

    this.updateFilteredProcessIds(filteredIds)
  }

  applyFilterByQuery = () => {
    const { filterProperty } = this.state

    switch (filterProperty) {
      case 'ownerId':
        this.applyFilterByOwnerName()
        break
      default:
        this.applyFilterByQueryString()
    }
  }

  filterByAppliedQuery = process => {
    const { filteredProcessIds } = this.state
    return !filteredProcessIds.includes(process.id)
  }

  filterByStartTime = process => {
    const { startDate, timeQueryProperty } = this.state
    if (startDate === null) return true
    return process[timeQueryProperty] && process[timeQueryProperty] > startDate.getTime()
  }

  filterByEndTime = process => {
    const { endDate, timeQueryProperty } = this.state
    if (endDate === null) return true
    return process[timeQueryProperty] && process[timeQueryProperty] <= endDate.getTime()
  }

  getFilteredProcesses = () => {
    const { deviceProcesses } = this.props
    return deviceProcesses
      .filter(this.filterByStartTime)
      .filter(this.filterByEndTime)
      .filter(this.filterByAppliedQuery)
  }

  processClickHandler = async process => {
    const { history } = this.props
    const newPath = history.location.pathname.replace('processes', 'process')
    history.push(`${newPath}/${process.id}`)
  }

  toggleModal = (modalTrigger, process) => {
    this.setState(prevState => (
      {
        [modalTrigger]: !prevState[modalTrigger],
        currentProcess: process,
      }))
  }

  toggleDemoEndModal = () => {
    this.setState(prevState => (
      {
        showCreateProcessModal: false,
        showDemoEndModal: !prevState.showDemoEndModal,
      }))
  }

  render() {

    const {
      viewMode,
      resultsPerPage,
      activePage,
      filteredProcessIds,
      showCreateProcessModal,
      showCloneModal,
      currentProcess,
      showEditModal,
      showAccessProcessModal,
      startDate,
      endDate,
      timeQueryProperty,
      showDateQueryMenu,
    } = this.state

    const { deviceProcesses, activeDevice } = this.props

    const totalPages = Math.ceil(
      (deviceProcesses.length - filteredProcessIds.length) / resultsPerPage,
    )

    const filteredProcesses = this.getFilteredProcesses()

    return (
      <div>
        <div>
          <CreateProcessModal
            open={showCreateProcessModal}
            activeDevice={activeDevice}
            deviceProcesses={deviceProcesses}
            closeHandler={() => this.toggleModal('showCreateProcessModal')}
            headerIcon='angle double down'
            headerText='Create Process'
          />
          <Grid.Row>
            <Menu style={{ marginBottom: '14px' }}>
              <Menu.Menu>
                <Menu.Item className='no-right-border'>
                  <Button
                    primary
                    icon
                    labelPosition='left'
                    onClick={() => this.toggleModal('showCreateProcessModal')}
                  >
                    <Icon name='plus'/>
                    Create Process
                  </Button>
                </Menu.Item>
              </Menu.Menu>
              <Menu.Menu position='right'>
                <Menu.Item style={{ padding: '0px' }}>
                  <Dropdown
                    options={this.viewOptions}
                    defaultValue={viewMode}
                    name='viewMode'
                    onChange={this.handleUpdate}
                    className='padding-13-16'
                  />
                </Menu.Item>
                <Menu.Item>
                  <Button icon labelPosition='left' onClick={this.toggleDateQueryMenu}>
                    <Icon name={showDateQueryMenu ? 'close' : 'calendar'} />
                    {showDateQueryMenu ? 'Hide ' : 'Show '}
                    Calendar
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Input
                    action={(
                      <Dropdown
                        button
                        basic
                        options={this.searchOptions}
                        name='filterProperty'
                        onChange={this.handleUpdate}
                        defaultValue={this.searchOptions[0].value}
                      />
                    )}
                    onChange={this.updateQueryString}
                    icon='search'
                    iconPosition='left'
                    placeholder='Search...'
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
            <DateQueryMenu
              timeQueryProperty={timeQueryProperty}
              startDate={startDate}
              endDate={endDate}
              updateTimeQueryProperty={this.handleUpdate}
              setTimeLimit={this.handleUpdate}
              showDateQueryMenu={showDateQueryMenu}
            />
          </Grid.Row>
          <Grid.Row>
            <ProcessesArea
              viewMode={viewMode}
              deviceProcesses={deviceProcesses}
              filteredProcesses={filteredProcesses}
              activeDevice={activeDevice}
              resultsPerPage={resultsPerPage}
              activePage={activePage}
              totalPages={totalPages}
              updateActivePage={this.updateActivePage}
              updateResultsPerPage={this.updateResultsPerPage}
              processClickHandler={this.processClickHandler}
              toggleModal={this.toggleModal}
            />
          </Grid.Row>
          {showCloneModal && (
            <CloneProcessModal
              open
              process={currentProcess}
              closeHandler={() => this.toggleModal('showCloneModal', null)}
              headerIcon='clone'
              headerText='Clone a process'
            />
          )}
          {showEditModal && (
            <EditProcessModal
              open
              process={currentProcess}
              closeHandler={() => this.toggleModal('showEditModal', null)}
              headerIcon='edit'
              headerText='Edit a process'
            />
          )}
          {showAccessProcessModal && (
            <AccessProcessModal
              open
              process={currentProcess}
              closeHandler={() => this.toggleModal('showAccessProcessModal', null)}
            />
          )}
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps)(withRouter(Processes))
