import React, { Component } from 'react'
import { Select } from 'semantic-ui-react'
import { getUnitOptions } from 'utils/units'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'

export default class Unit extends Component {

  constructor(props) {
    super(props)
    const { displayedUnit } = this.props
    this.state = { selectedUnit: displayedUnit, openUnitDropDown: false }
  }

  async update(event, { value: newUnit }) {
    this.setState({ selectedUnit: newUnit })
    const { onChange } = this.props
    onChange(newUnit)
  }

  render() {
    const { selectedUnit, openUnitDropDown } = this.state
    const { stream, activeDevice } = this.props
    const standardOptions = getUnitOptions(stream.unit).map(unit => ({ text: unit, value: unit }))

    const conversions = CustomConversions.getCustomConversions(activeDevice, stream.id)
    const customOptions = conversions.map(({ to }) => ({ text: to, value: to }))

    const unitOptions = [ ...standardOptions, ...customOptions ]

    const uniqueUnit = unitOptions.length === 1

    return (
      <Select
        compact
        open={uniqueUnit ? false : openUnitDropDown}
        options={unitOptions}
        value={selectedUnit}
        closeOnChange
        onClick={() => this.setState({ openUnitDropDown: !openUnitDropDown })}
        onClose={() => this.setState({ openUnitDropDown: false })}
        onChange={(e, data) => this.update(e, data)}
        style={{ borderRadius: '0 .28571429rem .28571429rem 0' }}
      />
    )
  }
}
