import React from 'react'
import { Input, Label, Button } from 'semantic-ui-react'
import injectSheet from 'react-jss'

export const EditableHeaderButtonClassName = 'editable-btn'

const styles = {
  parent: {
    display: 'flex',
    width: ({ fluid }) => (fluid ? '100%' : undefined),
    opacity: '1',
    justifyContent: 'space-between',
  },
  nameInput: {
    width: '100%',
    marginTop: '4px',
    marginBottom: '6px',
    '& input': {
      padding: '0 !important',
      fontSize: ({ fontSize }) => fontSize || '1.28rem',
      lineHeight: ({ fontSize }) => fontSize || '1.28rem',
    },
  },
  editButton: {
    float: 'right',
    opacity: ({ hover }) => (hover ? '0' : '1'),
  },
  labelButton: {
    cursor: ({ clickable }) => (clickable ? 'pointer !important' : 'default !important'),
    border: 'none',
  },
  label: {
    border: '0 !important',
    padding: '0 8px 0 0 !important',
    textAlign: 'left !important',
  },
}

class EditableHeader extends React.Component {

  state = { editName: false }

  editName = e => {
    e.stopPropagation()
    this.setState({ editName: true })
  }

  submitNewName = e => {
    const newName = e.target.value
    this.setState({ editName: false })
    if (!this.validateName(newName)) return
    this.props.changeNameHandler(newName)
  }

  submitNewNameEnter = e => {
    if (e.keyCode === 13) {
      this.submitNewName(e)
    }
  }

  validateName(newName) {
    if (newName === '') {
      this.setState({ editName: false })
      return false
    }
    return true
  }

  render() {
    const { name, classes, iconSize = 'mini', isHoverable } = this.props
    const { editName } = this.state
    const opacity = (isHoverable === true ? ({ hover }) => (hover ? '0' : '1') : '1')
    return (
      <div className={classes.parent}>
        {editName
          ? (
            <Input
              size='mini'
              className={classes.nameInput}
              control='input'
              autoFocus
              defaultValue={name}
              onBlur={this.submitNewName}
              onClick={e => e.stopPropagation()}
              onKeyDown={this.submitNewNameEnter}
            />
          )
          : (
            <>
              <Button as='div' className={`${classes.labelButton}`} labelPosition='left'>
                <Label basic className={`${classes.label}`} onDoubleClick={this.editName}>
                  {name}
                </Label>
              </Button>
              <Button
                className={`${classes.editButton} ${EditableHeaderButtonClassName}`}
                size={iconSize}
                circular
                icon='edit'
                onClick={this.editName}
                style={{ backgroundColor: 'white', opacity }}
              />
            </>
          )}
      </div>
    )
  }
}

export default injectSheet(styles)(EditableHeader)
