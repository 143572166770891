import React, { useState, useEffect } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Button } from 'semantic-ui-react'
import { connect } from 'react-redux'

import nexus from 'nexus'


const mapStateToProps = state => ({ user: state.user })

const renderSshPayload = (payload, copied, setCopied, statusColor) => (
  <>
    <Button className='margin-rl-10' color={statusColor}>
      <CopyToClipboard text={payload.ssh_url} onCopy={() => setCopied(true)}>
        <div>
          { copied ? 'copied to clipboard!' : 'click to copy ssh url to clipboard' }
        </div>
      </CopyToClipboard>
    </Button>

    <Button color={statusColor}>
      <a href={payload.web_url} target='_blank' rel='noopener noreferrer' style={{ color: 'white' }}>
        Open terminal in new browser tab
      </a>
    </Button>
  </>
)

const commandOpenSsh = async (deviceId, setAwaitingSshPayload, setSshPayload, setStatusColor) => {
  setAwaitingSshPayload(true)

  try {
    await nexus.command.openSsh(deviceId)
  } catch (e) {
    console.warn(`open ssh command failed: ${e}`)
    setStatusColor('red')
    setSshPayload('the open ssh command failed. get in touch with web dev')
  }
}

const SSHAccess = ({ activeDevice }) => {
  const [ copied, setCopied ] = useState(false)
  const [ sshPayload, setSshPayload ] = useState(null)
  const [ awaitingSshPayload, setAwaitingSshPayload ] = useState(false)
  const [ statusColor, setStatusColor ] = useState('blue')

  useEffect(() => {
    function handleReceivedPayload(payload) {
      setSshPayload(payload)
      setAwaitingSshPayload(false)
    }

    nexus.device.subscribe(`admin/${activeDevice.id}/ssh`, message => {
      const payload = message.value
      message.error ? setStatusColor('red') : setStatusColor('green')
      setStatusColor('green')
      handleReceivedPayload(payload)
    })
  }, [ activeDevice.id ])


  return (
    <>
      <Button
        primary
        loading={awaitingSshPayload}
        onClick={() => commandOpenSsh(activeDevice.id, setAwaitingSshPayload, setSshPayload, setStatusColor)}
      >
        Request SSH Connection
      </Button>

      { sshPayload ? renderSshPayload(sshPayload, copied, setCopied, statusColor) : null }
    </>
  )
}

export default connect(mapStateToProps)(SSHAccess)
