import { findAndUpdate } from 'redux/helper/updateFunctions'
import { Process } from 'utils/process'
import FlashMessenger from 'utils/FlashMessenger'
import { ellipseString } from 'utils/string'

export default (state, action) => {
  const { processes, flashMessages } = state
  const { processId } = action.payload

  const process = Process.getById(processes, processId)

  if (!process) return state

  const updateFn = targetProcess => Object.assign(targetProcess, { state: 'running' })
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  const msg = `${ellipseString(process.name, 25)} has resumed!`
  const flashMsg = FlashMessenger.createMessage('success', msg)
  const updatedFlashMessages = [ ...flashMessages, flashMsg ]

  return { ...state, processes: updatedProcesses, flashMessages: updatedFlashMessages }
}
