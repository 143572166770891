import React, { useState } from 'react'
import { Accordion, Ref } from 'semantic-ui-react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import DataManager from 'utils/DataManager'
import DraggableComponent from 'components/utility/DraggableComponent'
import StreamGroups from 'utils/UIConfig/StreamGroups'
import Validator from 'utils/validation/Validator'
import Stream from './Stream'

const Group = ({
  group: { folded, name, streams },
  activeProcess,
  activeDevice,
  toggleFold,
  toggleStreamFold,
  index,
  handleOnDrag,
}) => {
  const [isDragDisabled, setDisableDrag ] = useState(true)

  const filteredStreams = streams.filter(streamUI => {
    const { streamId } = streamUI
    const stream = StreamGroups
      .getStreamByIdAndGroupNameOnActiveProcess(activeProcess, streamId, name)
    return !Validator.isUndefinedOrNull(stream)
  })

  return (
    <DraggableComponent draggableId={name} index={index} isDragDisabled={isDragDisabled}>
      <Accordion.Title
        active={!folded}
        content={name}
        onClick={toggleFold}
        style={{ fontSize: '18px', backgroundColor: '#f5f5f5' }}
        className='accordion-menu-title'
        onMouseOver={() => setDisableDrag(false)}
        onFocus={() => setDisableDrag(false)}
        onMouseLeave={() => setDisableDrag(true)}
      />
      <DragDropContext
        onDragEnd={handleOnDrag}
      >
        <Droppable droppableId={`graph-id-${index}`} type={{ type: 'Stream', groupName: name }}>
          { provided => (
            <Ref innerRef={provided.innerRef}>
              <Accordion.Content
                {...provided.droppableProps}
                active={!folded}
                style={{
                  paddingBottom: '40px',
                  backgroundColor: '#f5f5f5',
                }}
              >
                { !folded && (
                  <Accordion.Accordion>
                    {filteredStreams.map((streamUI, idx) => {
                      const { streamId } = streamUI
                      const streamData = DataManager.getStreamDataPoints(activeProcess, streamId)

                      const stream = StreamGroups
                        .getStreamByIdAndGroupNameOnActiveProcess(activeProcess, streamId, name)
                      const loaded = DataManager.isStreamDataLoaded(activeProcess, stream.id)

                      const processState = activeProcess.state

                      return (
                        <Stream
                          key={streamId}
                          stream={stream}
                          folded={streamUI.folded}
                          activeProcess={activeProcess}
                          processState={processState}
                          activeDevice={activeDevice}
                          streamData={streamData}
                          loaded={loaded}
                          groupName={name}
                          toggleFold={() => toggleStreamFold(streamId)}
                          index={idx}
                          totalStreamsCount={filteredStreams.length}
                        />
                      )
                    })}
                    {provided.placeholder}
                  </Accordion.Accordion>
                )}
              </Accordion.Content>
            </Ref>
          )}
        </Droppable>
      </DragDropContext>
    </DraggableComponent>
  )
}
export default Group
