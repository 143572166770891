import React from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Icon, Button, Message } from 'semantic-ui-react'

import DeviceConfig from 'utils/DeviceConfig'
import { convertFrom } from 'utils/units'
import EventTrigger from 'utils/EventTrigger/EventTrigger'
import ProcessEventTriggers from 'utils/process/ProcessEventTriggers'
import Streams from 'utils/Streams'
import callUpdateDevice from 'redux/thunks/device/callUpdateDevice'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import ParameterMenu from './ParameterMenu'

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateDevice: (deviceId, params) => dispatch(
    callUpdateDevice(deviceId, params),
  ),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

class StreamEventTriggerModal extends React.Component {

  static createEmptyParameterStore = () => (
    EventTrigger.TYPES.reduce((acc, type) => ({ ...acc, [type]: {} }), {})
  )

  initialState = {
    isError: false,
    errorMessage: '',
    isUpdating: false,
    isSuccess: false,
    successMsg: '',
    targetStreamId: this.props.showStreamSelection ? null : this.props.streamId,
    type: EventTrigger.TYPES[0],
    parameterStore: StreamEventTriggerModal.createEmptyParameterStore(),
    eventType: 'alarm',
  }

  state = { ...this.initialState }

  handleUpdate = (e, { name, value }) => this.setState({
    [name]: value,
    isError: false,
    errorMessage: '',
    isSuccess: false,
  })

  parseByType = (type, value) => {
    switch (type) {
      case 'number':
        return value !== '' ? Number(value) : NaN
      default:
        throw new Error(`Unknown parameter trigger type ${type} provided.`)
    }
  }

  validateByType = (type, value) => {
    switch (type) {
      case 'number':
        return !Number.isNaN(value)
      default:
        throw new Error(`Unknown parameter type ${type} provided.`)
    }
  }

  handleParameterUpdate = (e, { name, value }) => (
    this.setState(prevState => ({
      parameterStore: {
        ...prevState.parameterStore,
        [prevState.type]: {
          ...prevState.parameterStore[prevState.type],
          [name]: value,
        },
      },
      isError: false,
      errorMessage: '',
      isSuccess: false,
    }))
  )

  getStreamOptions = () => {
    const { type } = this.state
    const { activeDevice, activeProcess, mode } = this.props

    const streams = mode === 'device'
      ? activeDevice.streams
      : activeProcess.deviceDescription

    const getStreamName = mode === 'device'
      ? stream => DeviceConfig.getDisplayedStreamName(activeDevice, stream.id)
      : stream => stream.name

    return EventTrigger
      .getByType(type).suitableStreams(streams)
      .map(stream => ({
        value: stream.id,
        key: stream.id,
        text: getStreamName(stream),
      }))
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  getTypeOptions = () => (
    EventTrigger.TYPES.map(type => ({
      value: type,
      key: type,
      text: EventTrigger.getByType(type).description,
    }))
  )

  handleAPIResponse = (res, successMsg) => {
    if (res.success) {
      this.setState({
        isSuccess: true,
        isUpdating: false,
        successMsg,
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: res.message,
        isUpdating: false,
        successMsg: '',
      })
    }
  }

  submitAsDeviceDefaults = async eventTriggerParams => {
    const { activeDevice, dispatchCallUpdateDevice } = this.props
    const { targetStreamId, type, eventType } = this.state

    const trigger = EventTrigger.getByType(type).create(eventTriggerParams, eventType)

    const updatedEventTriggers = DeviceConfig
      .getUpdatedEventTriggers(activeDevice, targetStreamId, trigger)

    const updatedConfig = DeviceConfig
      .updateStreamConfig(activeDevice, targetStreamId, { eventTriggers: updatedEventTriggers })

    const updateParams = { config: updatedConfig }
    this.setState({ isUpdating: true })
    const res = await dispatchCallUpdateDevice(activeDevice.id, updateParams)
    this.handleAPIResponse(res, 'Alert set as device default')
  }

  submitToProcess = async eventTriggerParams => {
    const {
      activeProcess,
      dispatchCallUpdateProcess,
    } = this.props

    const { targetStreamId, type, eventType } = this.state

    const trigger = EventTrigger.getByType(type).create(eventTriggerParams, eventType)

    this.setState({ isUpdating: true })

    const updatedEventTriggers = ProcessEventTriggers
      .add(activeProcess, targetStreamId, trigger)

    const res = await dispatchCallUpdateProcess(
      activeProcess.id, { eventTriggers: updatedEventTriggers },
    )
    this.handleAPIResponse(res, 'Alert set.')
  }

  enrichAndValidateParameters = () => {

    const { type, parameterStore } = this.state
    const { parameters } = EventTrigger.getByType(type)

    return parameters.map(parameter => {

      const storedValue = parameterStore[type][parameter.name]
      const parsedValue = this.parseByType(parameter.type, storedValue)
      const valid = this.validateByType(parameter.type, parsedValue)

      return {
        ...parameter,
        value: parsedValue,
        valid,
      }
    })
  }

  unitConvertParameters = parameters => {
    const { targetStreamId } = this.state
    const { activeDevice } = this.props
    const stream = Streams.getById(activeDevice.streams, targetStreamId)

    const displayedUnit = DeviceConfig
      .getDisplayedStreamUnit(activeDevice, targetStreamId)

    return parameters.map(parameter => {
      const { value } = parameter
      const convertedValue = convertFrom(displayedUnit).to(stream.unit)(value)
      return { ...parameter, value: convertedValue }
    })
  }

  parseToParameterObject = parameters => (
    parameters.reduce((params, curr) => ({ ...params, [curr.name]: curr.value }), {})
  )

  submit = async () => {
    const { targetStreamId, type } = this.state

    if (!targetStreamId || !type) return

    const enrichedParameters = this.enrichAndValidateParameters()

    const hasInvalidParameter = enrichedParameters.some(parameter => !parameter.valid)
    if (hasInvalidParameter) return

    const unitConvertedParameters = this.unitConvertParameters(enrichedParameters)
    const eventTriggerParams = this.parseToParameterObject(unitConvertedParameters)

    const { mode } = this.props

    if (mode === 'device') {
      await this.submitAsDeviceDefaults(eventTriggerParams)
    } else if (mode === 'process') {
      await this.submitToProcess(eventTriggerParams)
    }
  }

  getExistingEventTrigger = () => {

    const { type, targetStreamId } = this.state
    const { mode, activeDevice, activeProcess } = this.props

    if (mode === 'device') {
      return targetStreamId !== null
        ? DeviceConfig.getStreamEventTriggerByType(activeDevice, targetStreamId, type)
        : null
    }
    return ProcessEventTriggers.getByStreamIdAndType(activeProcess, targetStreamId, type)
  }

  clearStateAndClose = () => {
    const { closeHandler } = this.props
    this.setState(this.initialState)
    closeHandler()
  }

  render() {

    const {
      isError,
      errorMessage,
      isUpdating,
      isSuccess,
      successMsg,
      targetStreamId,
      type,
      parameterStore,
    } = this.state

    const { activeDevice, showStreamSelection } = this.props

    const currentEventTrigger = this.getExistingEventTrigger()

    return (
      <Modal
        size='small'
        open={this.props.open}
        onClose={this.clearStateAndClose}
        onClick={event => event.stopPropagation()}
      >
        <Modal.Header>
          <Icon name='edit'/>
          Create Alert
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              error={isError}
              success={isSuccess}
              onSubmit={this.submit}
              style={{ marginBottom: '15px', paddingBottom: '15px' }}
            >
              <Form.Select
                fluid
                name='type'
                label='Type'
                options={this.getTypeOptions()}
                onChange={this.handleUpdate}
                value={type}
              />
              {showStreamSelection && <Form.Select
                fluid
                name='targetStreamId'
                label='Stream'
                options={this.getStreamOptions()}
                onChange={this.handleUpdate}
                placeholder='Choose Stream'
                value={targetStreamId}
              />}
              <ParameterMenu
                activeDevice={activeDevice}
                handleParameterUpdate={this.handleParameterUpdate}
                targetStreamId={targetStreamId}
                type={type}
                parameterStore={parameterStore}
                currentEventTrigger={currentEventTrigger}
              />
              <Message
                error
                header='Something went wrong.'
                content={errorMessage}
                icon='warning circle'
              />
              <Message
                success
                header='Success!'
                content={<p>{successMsg}</p>}
                icon='warning circle'
              />
              <Button
                floated='right'
                primary
                type='submit'
                loading={isUpdating}
              >
                Submit
              </Button>
              <Button
                floated='right'
                onClick={this.clearStateAndClose}
              >
                Close
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(null, mapDispatchToProps)(StreamEventTriggerModal)
