import React from 'react'
import { Menu, Dropdown } from 'semantic-ui-react'
import { connect } from 'react-redux'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'

const mapStateToProps = state => ({ processes: state.processes })

const getProcessOptions = (device, processes) => {

  const userProcessesOnDevice = processes
    .filter(process => process.deviceId === device.id)
    .map(process => ({ text: process.name, value: process.id, key: process.id }))
    .reverse()

  return [
    { text: 'All', value: 'All', key: 0 },
    ...userProcessesOnDevice,
  ]
}

const LogViewQueryBar = ({
  selectedProcess,
  startDate,
  endDate,
  activeDevice,
  updateSelectedProcess,
  setTimeLimit,
  processes,
  showProcessSelect,
}) => (
  <Menu>
    <Menu.Menu>
      {showProcessSelect && <Menu.Item>
        Process: &nbsp;
        <Dropdown
          defaultValue={selectedProcess}
          selection
          search
          options={getProcessOptions(activeDevice, processes)}
          onChange={updateSelectedProcess}
        />
      </Menu.Item>}
    </Menu.Menu>
    <Menu.Menu>
      <Menu.Item>
        Start Date: &nbsp;
        <SemanticDatepicker
          name='startDate'
          selected={startDate}
          maxDate={endDate}
          onChange={setTimeLimit}
        />
        &nbsp;
        End Date: &nbsp;
        <SemanticDatepicker
          name='endDate'
          selected={endDate}
          minDate={startDate}
          onChange={setTimeLimit}
        />
      </Menu.Item>
    </Menu.Menu>
  </Menu>
)

export default connect(mapStateToProps)(LogViewQueryBar)
