import { Process } from 'utils/process'
import DataManager from 'utils/DataManager'

export const createUserObject = userData => ({
  userName: userData.userName,
  fullName: userData.fullName,
  organization: userData.organization,
  email: userData.email,
  id: userData.id,
  role: userData.role,
  phoneNumber: userData.phoneNumber,
  notifications: userData.notifications,
  events: userData.events,
})

export const createProcessObject = processData => ({
  id: processData.id,
  deviceId: processData.deviceId,
  name: processData.name,
  comment: processData.comment,
  createdAt: processData.createdAt,
  createdBy: processData.createdBy,
  cloneHistory: processData.cloneHistory,
  description: processData.description,
  entryPhaseId: processData.entryPhaseId || 0,
  progression: processData.progression,
  deviceDescription: processData.deviceDescription,
  access: processData.access,
  pinned: !!processData.pinned,
  ownerId: processData.ownerId,
  historicDataLoaded: false,
  startedAt: processData.startedAt,
  startedBy: processData.startedBy,
  data: DataManager.initStreamData(processData),
  state: Process.deriveProcessState(processData),
  finishedAt: processData.finishedAt,
  outdated: false,
  eventTriggers: processData.eventTriggers || {},
})

export const createListProcessObject = processData => ({
  id: processData.id,
  deviceId: processData.deviceId,
  name: processData.name,
  comment: processData.comment,
  createdAt: processData.createdAt,
  cloneHistory: processData.cloneHistory,
  createdBy: processData.createdBy,
  access: processData.access,
  pinned: !!processData.pinned,
  ownerId: processData.ownerId,
  startedAt: processData.startedAt,
  startedBy: processData.startedBy,
  finishedAt: processData.finishedAt,
})

export const createDeviceObject = deviceData => ({
  isVirtual: deviceData.isVirtual,
  id: deviceData.id,
  access: deviceData.access,
  name: deviceData.name,
  type: deviceData.type,
  state: deviceData.state,
  runningProcessId: deviceData.runningProcessId,
  connectionState: 'offline',
  hardwareVersion: deviceData.hardwareVersion,
  streams: deviceData.streams,
  ownerId: deviceData.ownerId,
  calibration: deviceData.calibration,
  streamDefaults: deviceData.streamDefaults,
  streamNameAliases: deviceData.streamNameAliases,
  subscriptions: {},
  readyForCommand: true,
  uIConfig: deviceData.uIConfig || deviceData.uiconfig,
  config: deviceData.config || {
    streams: [],
    global: {},
  },
})

export const createDeviceLogObject = logData => ({
  id: logData._id,
  content: logData.content,
  user: logData.user,
  topic: logData.topic,
  createdAt: logData.createdAt,
  level: logData.level,
})
