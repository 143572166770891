import React from 'react'
import { connect } from 'react-redux'
import { Segment, Header, Button, Form, Icon } from 'semantic-ui-react'

import Time from 'utils/Time'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import { Process, PROCESS_STATES } from 'utils/process'
import DescriptionParser from 'utils/process/DescriptionParser'
import { setPhaseProps } from 'redux/actions/actions'

const mapDispatchToProps = dispatch => ({
  dispatchSetTransitionMethod: (processId, phaseId, transition) => dispatch(
    setPhaseProps({ processId, phaseId, update: { transition } }),
  ),
  dispatchSetPhaseDuration: (processId, phaseId, duration) => dispatch(
    setPhaseProps({ processId, phaseId, update: { duration } }),
  ),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

const TransitionFooter = ({
  activeProcess,
  phaseId,
  dispatchSetTransitionMethod,
  dispatchSetPhaseDuration,
  phaseCanBeModified,
  dispatchCallUpdateProcess,
}) => {

  const save = async () => {
    if (activeProcess.state === PROCESS_STATES.executable) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const handlePhaseTransitionMethod = async method => {
    dispatchSetTransitionMethod(activeProcess.id, phaseId, method)
    await save()
  }

  const handleDurationUpdate = async (unit, e) => {
    let { value } = e.target

    if (isNaN(value)) return

    if (value === '') value = 0

    const duration = Time
      .parseTimeElapsedFromSeconds(DescriptionParser.getPhaseDuration(activeProcess, phaseId))
    const newDuration = { ...duration }

    newDuration[unit] = parseInt(value, 10)
    const durationInSeconds = Time.durationToSeconds(newDuration)

    dispatchSetPhaseDuration(activeProcess.id, phaseId, durationInSeconds)
    await save()
  }

  const renderDurationInput = (duration, unit, disabled, key) => (
    <Form.Input
      key={key}
      value={duration[unit]}
      onChange={event => handleDurationUpdate(unit, event)}
      onBlur={save}
      fluid
      readOnly={disabled}
      label={unit}
    />
  )

  const transition = DescriptionParser.getPhaseTransitionMethod(activeProcess, phaseId)
  const duration = Time
    .parseTimeElapsedFromSeconds(DescriptionParser.getPhaseDuration(activeProcess, phaseId))
  const durationUnits = ['days', 'hours', 'minutes', 'seconds']
  const disabledInput = !phaseCanBeModified

  return (
    <Segment style={{ backgroundColor: '#f5f5f5' }}>
      <Header as='h4'>Select transition method</Header>
      <Button.Group>
        <Button
          primary={transition === 'manual'}
          icon
          labelPosition='left'
          disabled={disabledInput}
          onClick={() => handlePhaseTransitionMethod('manual')}
        >
          <Icon name='hand pointer' />
          Manual
        </Button>
        <Button.Or />
        <Button
          primary={transition === 'time-based'}
          icon
          labelPosition='right'
          disabled={disabledInput}
          onClick={() => handlePhaseTransitionMethod('time-based')}
        >
          Duration
          <Icon name='clock outline' />
        </Button>
      </Button.Group>
      {transition === 'time-based' ? (
        <Segment>
          <Form>
            <Form.Group widths='equal'>
              {durationUnits
                .map((unit, i) => renderDurationInput(duration, unit, disabledInput, i))}
            </Form.Group>
          </Form>
        </Segment>
      ) : null}
    </Segment>
  )
}

export default connect(null, mapDispatchToProps)(TransitionFooter)
