import React, { useState } from 'react'
import { Table, Checkbox } from 'semantic-ui-react'
import Time from 'utils/Time'
import DeviceConfig from 'utils/DeviceConfig'
import { convertFrom, hasUnit } from 'utils/units'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'

const renderFloatStream = (stream, activeDevice, phases) => {
  const streamUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)
  return (
    <Table.Row key={stream.id}>
      <Table.Cell>
        {hasUnit(stream.unit) ? `${stream.name} [${streamUnit}]` : stream.name}
      </Table.Cell>
      {phases.map(({ data: phase }) => {
        const target = phase.downstreams[stream.id].value
        const customConversion = CustomConversions
          .getCustomConversionsByTargetUnit(activeDevice, stream.id, streamUnit)
        const streamValue = customConversion
          ? CustomConversions.convertWithRatio(target, customConversion)
          : convertFrom(stream.unit).to(streamUnit)(target)

        return <Table.Cell key={phase.name} textAlign='right'>{streamValue}</Table.Cell>
      })}
    </Table.Row>
  )
}

const renderBooleanStream = (stream, activeDevice, phases) => (
  <Table.Row key={stream.id}>
    <Table.Cell>
      {stream.name}
    </Table.Cell>
    {phases.map(({ data: phase }) => {
      const streamValue = phase.downstreams[stream.id].value ? 'on' : 'off'
      return <Table.Cell key={phase.name} textAlign='right'>{streamValue}</Table.Cell>
    })}
  </Table.Row>
)

const renderStreamByExpectedValueType = (stream, activeDevice, phases) => {
  switch (stream.expectedValueType) {
    case 'boolean': {
      return renderBooleanStream(stream, activeDevice, phases)
    }
    default: {
      return renderFloatStream(stream, activeDevice, phases)
    }
  }
}

const renderGroupCells = ({ groupName, phaseCount, iterations }, idx) => {
  if (groupName === null) return <Table.Cell key={idx} />
  return (
    <Table.Cell key={groupName} colSpan={phaseCount} textAlign='center'>
      {`${groupName} (x${iterations})`}
    </Table.Cell>
  )
}

const getReducedGroups = phases => (
  phases.reduce((acc, { data: { groupName } }) => {
    if (!groupName) return [ ...acc, { groupName: null, phaseCount: 1 } ]
    const latestEntry = acc[acc.length - 1]

    if (latestEntry && latestEntry.groupName === groupName) {
      latestEntry.phaseCount += 1
      return acc
    }

    return [ ...acc, { groupName, phaseCount: 1 } ]
  }, [])
)

const renderGroups = (process, phases) => {
  const reducedGroups = getReducedGroups(phases)

  const noGroups = reducedGroups.every(({ groupName }) => groupName === null)
  if (noGroups) return null

  const groupsWithIteration = reducedGroups.map(aGroup => {
    if (aGroup.groupName === null) return { ...aGroup, iterations: null }
    return {
      ...aGroup,
      iterations: PhaseGroupParser.getGroupIterationsCount(process, aGroup.groupName),
    }
  })

  return (
    <Table.Row>
      <Table.Cell>Group</Table.Cell>
      {groupsWithIteration.map(renderGroupCells)}
    </Table.Row>
  )
}

const ProcessConfiguration = props => {

  const [ showControllerParameters, toggleShowControllerParameters ] = useState(false)
  const { processDownStreams, phases, activeDevice, activeProcess } = props

  return (
    <Table celled className='ospin-red' collapsing>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            <Checkbox
              toggle
              label='Show Controller Parameters'
              onChange={() => toggleShowControllerParameters(!showControllerParameters)}
              checked={showControllerParameters}
            />
          </Table.HeaderCell>
          {phases.map(({ data: phase, id }) => <Table.HeaderCell key={id}>{phase.name}</Table.HeaderCell>)}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {renderGroups(activeProcess, phases)}
        <Table.Row>
          <Table.Cell>Transition</Table.Cell>
          {phases.map(({ data: phase, id }) => <Table.Cell key={id} textAlign='right'>{phase.transition}</Table.Cell>)}
        </Table.Row>
        <Table.Row>
          <Table.Cell>Duration [s]</Table.Cell>
          {phases.map(({ data: phase, id }) => {
            const disabled = phase.transition !== 'time-based'
            return (
              <Table.Cell
                disabled={disabled}
                key={id}
                textAlign='right'
              >
                {Time.stringFromDuration(phase.duration)}
              </Table.Cell>
            )
          })}
        </Table.Row>
        {processDownStreams
          .filter(stream => !stream.controller || (stream.controller && showControllerParameters))
          .map(stream => renderStreamByExpectedValueType(stream, activeDevice, phases))}
      </Table.Body>
    </Table>
  )
}

export default ProcessConfiguration
