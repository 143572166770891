import React from 'react'
import { Container, Table } from 'semantic-ui-react'
import { connect } from 'react-redux'
import callLoadLogs from 'redux/thunks/log/callLoadLogs'
import Logs from 'utils/Logs'
import DeviceViewLoader from 'components/utility/DeviceViewLoader'
import LogViewHeader from './LogViewHeader'
import LogViewQueryBar from './LogViewQueryBar'
import LogList from './LogList'
import LogViewTableControls from './LogViewTableControls'

const mapDispatchToProps = dispatch => ({
  dispatchCallLoadLogs: (deviceId, queryParams) => dispatch(
    callLoadLogs(deviceId, queryParams),
  ),
})

class LogView extends React.Component {

  state = {
    queryString: '',
    activePage: 1,
    resultsPerPage: 10,
    filteredLogIds: [],
    filterProperty: 'level',
    selectedProcess: 'All',
    isLoading: false,
    loadingMore: false,
    updatingRecent: false,
    startDate: null,
    endDate: null,
  }

  async componentDidMount() {
    const { logs } = this.props
    if (!logs.length) {
      this.setState({ isLoading: true })
      await this.loadLogHandler()
      this.setState({ isLoading: false })
    }
  }

  setTimeLimit = (date, limit) => (
    this.setState({ [limit]: date })
  )

  updateActivePage = (_, data) => (
    this.setState({ activePage: data.activePage })
  )

  updateResultsPerPage = (_, data) => (
    this.setState({ resultsPerPage: data.value, activePage: 1 })
  )

  updateSelectedProcess = (_, data) => (
    this.setState({ selectedProcess: data.value })
  )

  updateQueryString = event => (
    this.setState({ queryString: event.target.value }, () => {
      this.filterLogActivities()
    })
  )

  updateFilteredLogIds = filteredLogIds => (
    this.setState({ filteredLogIds }, () => {
      this.updateActivePage(null, { activePage: 1 })
    })
  )

  setFilterProperty = (_, data) => (
    this.setState({ filterProperty: data.value }, () => {
      this.filterLogActivities()
    })
  )

  filterByProcess = log => {
    const { selectedProcess } = this.state
    if (selectedProcess === 'All') return true
    return log.processId === selectedProcess
  }

  filterByStartTime = log => {
    const { startDate } = this.state
    if (startDate === null) return true
    return log.createdAt > startDate.getTime()
  }

  filterByEndTime = log => {
    const { endDate } = this.state
    if (endDate === null) return true
    return log.createdAt <= endDate.getTime()
  }

  filterByAppliedQuery = log => {
    const { filteredLogIds } = this.state
    return !filteredLogIds.includes(log.id)
  }

  getFilteredLogs = () => {
    const { logs } = this.props
    return logs
      .filter(this.filterByProcess)
      .filter(this.filterByStartTime)
      .filter(this.filterByEndTime)
      .filter(this.filterByAppliedQuery)
  }

  getCurrentPageLogs = filteredLogs => {
    const { activePage, resultsPerPage } = this.state
    return filteredLogs
      .slice(activePage * resultsPerPage - resultsPerPage, activePage * resultsPerPage)
  }

  getTotalPages = filteredLogs => {
    const { resultsPerPage } = this.state
    return Math.ceil(filteredLogs.length / resultsPerPage)
  }

  filterLogActivities = () => {

    const { queryString, filterProperty } = this.state
    const { logs } = this.props

    if (queryString.trim() === '') {
      this.updateFilteredLogIds([])
      return
    }

    const re = new RegExp(queryString, 'i')

    const filteredLogIds = logs
      .filter(log => !re.test(log[filterProperty]))
      .map(log => log.id)

    this.updateFilteredLogIds(filteredLogIds)
  }

  createLogQuery = () => {
    const { selectedProcess, startDate, endDate } = this.state
    const { logs } = this.props

    const endTime = endDate !== null
      ? endDate.getTime()
      : Logs.getLastTimeStamp(logs)

    const query = { endTime }

    if (selectedProcess !== 'All') {
      query.processId = selectedProcess
    }

    if (startDate !== null) {
      query.startTime = startDate.getTime()
    }

    return query
  }

  async loadLogHandler(queryParams) {
    const { activeDevice, dispatchCallLoadLogs, defaultQuery } = this.props

    await dispatchCallLoadLogs(activeDevice.id, { ...queryParams, ...defaultQuery })
  }

  async loadMoreLogs() {
    this.setState({ loadingMore: true })
    const query = this.createLogQuery()

    await this.loadLogHandler(query)
    this.setState({ loadingMore: false })
  }

  async refreshLogs() {
    this.setState({ updatingRecent: true })
    const { logs } = this.props

    const startTime = Logs.getMostRecentTimeStamp(logs)
    await this.loadLogHandler({ startTime })
    this.setState({ updatingRecent: false })
  }

  renderHeader = () => {
    const { updatingRecent, filterProperty } = this.state

    return (
      <LogViewHeader
        updatingRecent={updatingRecent}
        filterProperty={filterProperty}
        refreshLogs={() => this.refreshLogs()}
        setFilterProperty={(_, data) => this.setFilterProperty(_, data)}
        updateQueryString={event => this.updateQueryString(event)}
      />
    )
  }

  renderQueryBar = () => {
    const { selectedProcess, startDate, endDate } = this.state
    const { activeDevice, showProcessSelect } = this.props

    return (
      <LogViewQueryBar
        showProcessSelect={showProcessSelect}
        selectedProcess={selectedProcess}
        startDate={startDate}
        endDate={endDate}
        activeDevice={activeDevice}
        updateSelectedProcess={(_, data) => this.updateSelectedProcess(_, data)}
        setTimeLimit={(_, { value, name }) => this.setTimeLimit(value, name)}
      />
    )
  }

  renderTableControls = totalPages => {
    const { activePage, resultsPerPage, loadingMore } = this.state

    return (
      <LogViewTableControls
        activePage={activePage}
        resultsPerPage={resultsPerPage}
        totalPages={totalPages}
        loadingMore={loadingMore}
        updateActivePage={(_, data) => this.updateActivePage(_, data)}
        updateResultsPerPage={(_, data) => this.updateResultsPerPage(_, data)}
        loadMoreLogs={() => this.loadMoreLogs()}
      />
    )
  }

  renderTable = displayedLogs => (
    <Table celled padded className='ospin-red'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Level</Table.HeaderCell>
          <Table.HeaderCell>Subject</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
          <Table.HeaderCell>Timestamp</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <LogList displayedLogs={displayedLogs} />
    </Table>
  )

  render() {
    const { isLoading } = this.state

    if (isLoading) return <DeviceViewLoader />

    const filteredLogs = this.getFilteredLogs()
    const totalPages = this.getTotalPages(filteredLogs)

    const displayedLogs = this.getCurrentPageLogs(filteredLogs)

    return (
      <Container fluid>
        {this.renderHeader()}
        {this.renderQueryBar()}
        {this.renderTableControls(totalPages)}
        {this.renderTable(displayedLogs)}
      </Container>
    )
  }
}

export default connect(null, mapDispatchToProps)(LogView)
