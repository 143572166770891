import React from 'react'
import { Button, Divider, Icon, Modal, Form, Segment, Header } from 'semantic-ui-react'
import { getNotificationHeadline } from 'utils/user'

class ConciergeModal extends React.Component {

  close() {
    this.props.deleteEphemeralNotificationsHandler(this.props.user.id, this.props.ephemeralNotifications)
    this.props.closeHandler()
  }

  render() {

    return (
      <Modal
        size='tiny'
         open={this.props.show}
         onClose={this.close.bind(this)}
         onClick={event => event.stopPropagation()}
      >
        <Modal.Header>
          <Icon name='envelope open'/>Your news</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            <Form>
              {this.props.ephemeralNotifications.map((notification, i) =>
                <Segment key={i}>
                  <Header as='h3' content={getNotificationHeadline(notification.topic)}/>
                  {notification.data.message}
                </Segment>
              )}
              <Divider/>
              <Button floated='right'
                      onClick={this.close.bind(this)}
              >Got it!</Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default ConciergeModal
