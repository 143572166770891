import { convertFrom, applyUnitConversionOnDataPoints } from 'utils/units'
import { linear } from 'utils/math'
import DeviceConfig from 'utils/DeviceConfig'
import CalibrationMode from 'utils/calibration/CalibrationMode'
import DataTools from 'utils/DataTools'


class LinearCalibrationSelectionMode extends CalibrationMode {

  static calculateParams(componentState) {
    const { pointSelectionAtoP } = componentState

    const {
      firstIntervalAverage,
      secondIntervalAverage,
      firstIntervalReference,
      secondIntervalReference,
    } = pointSelectionAtoP

    const parsedFirstIntervalRef = parseFloat(firstIntervalReference)
    const parsedSecondIntervalRef = parseFloat(secondIntervalReference)

    if (Number.isNaN(parsedFirstIntervalRef)) {
      return { error: 'First interval value is not a number.' }
    }

    if (Number.isNaN(parsedSecondIntervalRef)) {
      return { error: 'Second interval value is not a number.' }
    }

    if (parsedFirstIntervalRef === parsedSecondIntervalRef) {
      return { error: 'First and second interval values may not be equal.' }
    }

    if (secondIntervalAverage === firstIntervalAverage) {
      return { error: 'First and second interval averages may not be equal.' }
    }

    let p1 = [firstIntervalAverage, parsedFirstIntervalRef]
    let p2 = [secondIntervalAverage, parsedSecondIntervalRef]

    if (parsedFirstIntervalRef > parsedSecondIntervalRef) {
      [p1, p2] = [p2, p1]
    }

    return { offset: linear.offset(p1, p2), slope: linear.slope(p1, p2) }
  }

  static getNextField(currField) {
    const nextFields = {
      firstIntervalStart: 'firstIntervalEnd',
      firstIntervalEnd: 'secondIntervalStart',
      secondIntervalStart: 'secondIntervalEnd',
    }

    return nextFields[currField]
  }

  static getUpdatedPointSelection(componentState, time) {
    const { pointSelectionAtoP, selectedField } = componentState

    if (selectedField === 'firstIntervalEnd'
      && pointSelectionAtoP.firstIntervalStart !== '') {
      if (time <= pointSelectionAtoP.firstIntervalStart) {
        return
      }
    }

    if (selectedField === 'firstIntervalStart'
      && pointSelectionAtoP.firstIntervalEnd !== '') {
      if (time >= pointSelectionAtoP.firstIntervalEnd) {
        return
      }
    }

    if (selectedField === 'secondIntervalEnd'
      && pointSelectionAtoP.secondIntervalStart !== '') {
      if (time <= pointSelectionAtoP.secondIntervalStart) {
        return
      }
    }

    if (selectedField === 'secondIntervalStart'
      && pointSelectionAtoP.secondIntervalEnd !== '') {
      if (time >= pointSelectionAtoP.secondIntervalEnd) {
        return
      }
    }

    const nextField = this.getNextField(selectedField)
    return {
      params: {
        pointSelectionAtoP: {
          ...pointSelectionAtoP,
          [selectedField]: time,
        },
      },
      nextField,
    }
  }

  static getUpdatedAverage(componentState, componentProps, rawData) {

    const { pointSelectionAtoP } = componentState
    const { activeDevice, stream } = componentProps

    const {
      firstIntervalStart,
      firstIntervalEnd,
      secondIntervalStart,
      secondIntervalEnd,
      firstIntervalAverage,
      secondIntervalAverage,
    } = pointSelectionAtoP

    const newAvrgLower = this.getAverage(rawData, firstIntervalStart, firstIntervalEnd)
    const newAvrgUpper = this.getAverage(rawData, secondIntervalStart, secondIntervalEnd)

    const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)
    const unitConverter = convertFrom(stream.unit).to(displayedUnit)

    return {
      params: {
        pointSelectionAtoP: {
          ...pointSelectionAtoP,
          firstIntervalAverage: unitConverter(newAvrgLower) || firstIntervalAverage,
          secondIntervalAverage: unitConverter(newAvrgUpper) || secondIntervalAverage,
        },
      },
    }
  }

  static attachGraphSlice(streamData, rawData, componentState, componentProps) {

    const { pointSelectionAtoP } = componentState
    const { activeDevice, stream } = componentProps

    const {
      firstIntervalStart,
      firstIntervalEnd,
      secondIntervalStart,
      secondIntervalEnd,
    } = pointSelectionAtoP

    const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)
    const unitConverter = convertFrom(stream.unit).to(displayedUnit)

    if (firstIntervalStart && firstIntervalEnd) {
      const slice = DataTools.valuesWithinTimeRange(rawData, firstIntervalStart, firstIntervalEnd)
      const convertedDataPoints = applyUnitConversionOnDataPoints(slice, unitConverter)
      streamData.push(this.createGraphSlice(convertedDataPoints, { color: 'red', label: 'Interval 1' }))
    }

    if (secondIntervalStart && secondIntervalEnd) {
      const slice = DataTools.valuesWithinTimeRange(rawData, secondIntervalStart, secondIntervalEnd)
      const convertedDataPoints = applyUnitConversionOnDataPoints(slice, unitConverter)
      streamData.push(this.createGraphSlice(convertedDataPoints, { color: 'blue', label: 'Interval 2' }))
    }
  }
}

export default LinearCalibrationSelectionMode
