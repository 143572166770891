import React from 'react'
import { Segment } from 'semantic-ui-react'

import PhaseTabContent from './PhaseTabContent'

const Builder = ({ phases, processDownStreams, activeDevice, activeProcess }) => (
  <>
    <Segment
      className='no-border no-shadow'
    >
      {phases.map(({ data: phase, id }) => (
        <PhaseTabContent
          key={id}
          phaseId={id}
          phase={phase}
          processDownStreams={processDownStreams}
          activeProcess={activeProcess}
          activeDevice={activeDevice}
          phasesLength={phases.length}
          phases={phases}
        />
      ))}
      <div />
    </Segment>
  </>
)

export default Builder
