import React from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Icon, Divider, Button, Message } from 'semantic-ui-react'

import DeviceConfig from 'utils/DeviceConfig'
import Streams from 'utils/Streams'
import callUpdateDevice from 'redux/thunks/device/callUpdateDevice'
import SetByUnitTypeMenu from './SetByUnitTypeMenu'
import SetByStreamMenu from './SetByStreamMenu'

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateDevice: (deviceId, params) => dispatch(
    callUpdateDevice(deviceId, params),
  ),
})

class SetUnitModal extends React.Component {

  initialState = {
    isError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    streamUnit: null,
    targetStreamId: null,
    mode: 'unit',
    deviceUnit: null,
    altDeviceUnit: null,
  }

  state = { ...this.initialState }

  handleUpdate = (e, { name, value }) => this.setState({
    [name]: value,
    isError: false,
    errorMessage: '',
    isSuccess: false,
  })

  handleAPIResponse(res) {
    if (res.success) {
      this.setState({
        isSuccess: true,
        isError: false,
        errorMessage: '',
        isLoading: false,
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: res.message,
        isSuccess: false,
        isLoading: false,
      })
    }
  }

  async submitByStream() {
    const { targetStreamId, streamUnit } = this.state

    if (!targetStreamId || !streamUnit) return

    const { activeDevice, dispatchCallUpdateDevice } = this.props

    const updatedConfig = DeviceConfig
      .updateStreamConfig(activeDevice, targetStreamId, { defaultUnit: streamUnit })

    const updateParams = { config: updatedConfig }

    this.setState({ isLoading: true })
    const res = await dispatchCallUpdateDevice(activeDevice.id, updateParams)
    this.handleAPIResponse(res)
  }

  async submitByUnit() {

    const { deviceUnit, altDeviceUnit } = this.state

    if (!deviceUnit || !altDeviceUnit) return

    const { activeDevice, dispatchCallUpdateDevice } = this.props

    const targetStreamIds = Streams
      .getStreamsByUnit(activeDevice.streams, deviceUnit)
      .map(stream => stream.id)

    const newDisplayedUnitConfigs = targetStreamIds.map(id => (
      { streamId: id, updateParams: { defaultUnit: altDeviceUnit } }
    ))

    const updatedConfig = DeviceConfig
      .updateManyStreamConfigs(activeDevice, newDisplayedUnitConfigs)

    const updateParams = { config: updatedConfig }

    this.setState({ isLoading: true })
    const res = await dispatchCallUpdateDevice(activeDevice.id, updateParams)
    this.handleAPIResponse(res)
  }

  static renderInfoMessage(mode) {

    const msgUnitMode = 'This mode will set the default unit for all streams with a specific unit type'
    const msgStreamMode = 'This mode will overwrite a stream default unit with a compatible unit of your choice'

    return (
      <Message
        info
        content={mode === 'unit' ? msgUnitMode : msgStreamMode}
      />
    )
  }

  async submitNewDefaultUnits() {

    const { mode } = this.state

    if (mode === 'unit') {
      await this.submitByUnit()
    } else if (mode === 'stream') {
      await this.submitByStream()
    }
  }

  clearStateAndClose = () => {
    const { closeHandler } = this.props
    this.setState(this.initialState)
    closeHandler()
  }

  render() {

    const {
      isError,
      errorMessage,
      isLoading,
      isSuccess,
      mode,
      streamUnit,
      targetStreamId,
      deviceUnit,
      altDeviceUnit,
    } = this.state

    const { activeDevice } = this.props

    const modeOptions = [
      { value: 'unit', text: 'By type', key: 0 },
      { value: 'stream', text: 'By stream', key: 1 },
    ]

    return (
      <Modal
        size='small'
        open={this.props.open}
        onClose={this.clearStateAndClose}
        onClick={event => event.stopPropagation()}
      >
        <Modal.Header>
          <Icon name='edit'/>
          Set Stream Units
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              error={isError}
              success={isSuccess}
              onSubmit={() => this.submitNewDefaultUnits()}
              style={{ marginBottom: '15px', paddingBottom: '15px' }}
            >
              <Form.Group widths='equal'>
                <Form.Select
                  fluid
                  name='mode'
                  label='Mode'
                  options={modeOptions}
                  onChange={this.handleUpdate}
                  placeholder='Choose mode'
                  value={mode}
                />
              </Form.Group>
              {SetUnitModal.renderInfoMessage(mode)}
              {mode === 'stream'
                ? <SetByStreamMenu
                  activeDevice={activeDevice}
                  handleUpdate={this.handleUpdate}
                  streamUnit={streamUnit}
                  targetStreamId={targetStreamId}
                />
                : <SetByUnitTypeMenu
                  activeDevice={activeDevice}
                  handleUpdate={this.handleUpdate}
                  deviceUnit={deviceUnit}
                  altDeviceUnit={altDeviceUnit}
                />}
              <Message
                error
                header='Something went wrong.'
                content={errorMessage}
                icon='warning circle'
              />
              <Message
                success
                header='Success!'
                content={<p>Units were set.</p>}
                icon='warning circle'
              />
              <Divider/>
              <Button
                floated='right'
                primary
                type='submit'
                loading={isLoading}
              >
                Submit
              </Button>
              <Button
                floated='right'
                onClick={this.clearStateAndClose}
              >
                Close
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(null, mapDispatchToProps)(SetUnitModal)
