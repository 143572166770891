import nexus from 'nexus'
import { setDeviceUIConfig } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callSetDeviceUIConfig = async (dispatch, userId, deviceId, newDeviceUiConfig) => {
  nexus.uIConfig.putDeviceUIConfig(userId, deviceId, newDeviceUiConfig)
  dispatch(setDeviceUIConfig({ deviceId, newDeviceUiConfig }))
}

export default thunkWrapper(callSetDeviceUIConfig)
