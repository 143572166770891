import React from 'react'
import { Modal, Icon } from 'semantic-ui-react'

const ModalHeader = ({ header, icon }) => (
  <Modal.Header>
    <Icon name={icon}/>
    {header}
  </Modal.Header>
)

export default ModalHeader
