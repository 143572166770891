import React from 'react'
import { Button, Divider, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'
import {
  createPhaseGroup,
  appendPhaseToGroup,
  prependPhaseToGroup,
} from 'redux/actions/actions'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import { Process, PROCESS_STATES, phaseIsModifiable } from 'utils/process'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import PhaseTreeItemFactory from './PhaseTreeItemFactory'

const mapDispatchToProps = dispatch => ({
  dispatchCreatePhaseGroup: (processId, phaseIds) => dispatch(createPhaseGroup(
    { processId, phaseIds },
  )),
  dispatchAppendPhaseToGroup: (processId, groupName, phaseId) => dispatch(appendPhaseToGroup(
    { processId, groupName, phaseId },
  )),
  dispatchPrependPhaseToGroup: (processId, groupName, phaseId) => dispatch(prependPhaseToGroup(
    { processId, groupName, phaseId },
  )),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

function PhaseTreeBody(props) {
  const {
    dispatchCreatePhaseGroup,
    dispatchAppendPhaseToGroup,
    dispatchPrependPhaseToGroup,
    dispatchCallUpdateProcess,
    runningPhase,
    addPhaseToGroup,
    activeProcess,
    groupedPhaseData,
    isGroupingDisabled,
    isDraggingOver,
    activeDevice,
  } = props

  const save = async () => {
    if (activeProcess.state === PROCESS_STATES.executable) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const handleGroupPhases = async phasesToBeGrouped => {
    const { groupName: group1, id: id1 } = phasesToBeGrouped[0]
    const { groupName: group2, id: id2 } = phasesToBeGrouped[1]

    if (group1 && !group2) {
      dispatchAppendPhaseToGroup(activeProcess.id, group1, id2)
    }

    if (!group1 && group2) {
      dispatchPrependPhaseToGroup(activeProcess.id, group2, id1)
    }

    if (!group1 && !group2) {
      dispatchCreatePhaseGroup(activeProcess.id, [id1, id2])
    }

    await save()
  }

  const renderConnectorElement = phasesToBeGrouped => {
    const [ firstEntry, secondEntry ] = phasesToBeGrouped
    const didRun = firstEntry.isGroup
      ? PhaseGroupParser.groupHasFinished(activeProcess, firstEntry.groupName)
      : !phaseIsModifiable(activeProcess, firstEntry.id)

    const bothElementAreAGroup = firstEntry.isGroup && secondEntry.isGroup
    const processIsFinished = activeProcess.state === PROCESS_STATES.finished

    if (didRun || bothElementAreAGroup || processIsFinished) {
      return (
        <Icon
          name='chevron circle down'
          color='grey'
        />
      )
    }

    return (
      <Button disabled={isGroupingDisabled} onClick={() => handleGroupPhases(phasesToBeGrouped)} size='mini' basic animated='fade'>
        <Button.Content visible>
          <Icon
            style={{ marginLeft: '4px' }}
            name='chevron circle down'
            color='grey'
          />
        </Button.Content>
        <Button.Content hidden>
          <Icon
            style={{ marginLeft: '3.5px' }}
            name='chain'
            color='blue'
          />
        </Button.Content>
      </Button>
    )
  }

  const getDividerStyle = () => {
    const style = { textAlign: 'center' }
    if (isDraggingOver) {
      style.opacity = 0
    }
    return style
  }

  const renderChainPhase = phasesToBeGrouped => (
    <>
      <Divider style={getDividerStyle()} fitted horizontal>
        {renderConnectorElement(phasesToBeGrouped)}
      </Divider>
    </>
  )

  return (
    groupedPhaseData.map((phaseData, i) => (
      [
        <div key={phaseData.idx * 2}>
          {i > 0 ? (
            renderChainPhase([groupedPhaseData[i - 1], groupedPhaseData[i]])
          ) : (
            <div />
          )}
        </div>,
        <PhaseTreeItemFactory
          key={phaseData.idx * 2 + 1}
          runningPhase={runningPhase}
          addPhaseToGroup={addPhaseToGroup}
          activeProcess={activeProcess}
          treeElement={phaseData.isGroup ? 'group' : 'tab'}
          phaseData={phaseData}
          activeDevice={activeDevice}
        />,
      ]
    ))
  )
}

export default connect(
  null,
  mapDispatchToProps,
)((PhaseTreeBody))
