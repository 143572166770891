import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

import callDeleteProcesses from 'redux/thunks/process/callDeleteProcesses'
import { Process } from 'utils/process'
import FlashMessenger from 'utils/FlashMessenger'
import ProcessRow from './ProcessRow'
import ProcessTableControls from './ProcessTableControls'

const mapDispatchToProps = dispatch => (
  { dispatchCallDeleteProcesses: ids => dispatch(callDeleteProcesses(ids)) }
)

const mapStateToProps = ({ user, deviceUsers }) => ({ user, deviceUsers })

class ProcessesTable extends React.Component {

  constructor() {
    super()
    this.checkProcess.bind(this)
  }

  state = {
    selectedIds: [],
    allSelected: false,
    selectedAction: 'none',
    executingAction: false,
  }

  handleSelectAll = () => {
    const { allSelected } = this.state
    const { filteredProcesses, activePage, resultsPerPage } = this.props
    const selection = !allSelected
      ? [...filteredProcesses
        .slice(activePage * resultsPerPage - resultsPerPage, activePage * resultsPerPage)
        .map(p => p.id)]
      : []
    this.setState({ allSelected: !allSelected, selectedIds: selection })
  }

  deleteSelectedProcesses = async () => {

    const { selectedIds } = this.state

    if (!selectedIds.length) {
      return
    }

    const { deviceProcesses, dispatchCallDeleteProcesses, activeDevice } = this.props
    const { runningProcessId } = activeDevice

    if (runningProcessId && selectedIds.includes(runningProcessId)) {
      const process = Process.getById(deviceProcesses, runningProcessId)
      FlashMessenger.error(`Cannot delete a ${process.state} process!`)
      return
    }

    this.setState({ executingAction: true })
    const result = await dispatchCallDeleteProcesses(selectedIds)
    if (result.status === 403) {
      FlashMessenger.error('Operation failed. No sufficient access rights to one or more selected processes.')
    }
    this.setState({ executingAction: false })
  }

  executeSelectedAction = async () => {

    const { selectedAction } = this.state

    if (selectedAction === 'delete') {
      await this.deleteSelectedProcesses()
    }
  }

  updateSelectedAction = (event, data) => {
    this.setState({ selectedAction: data.value })
  }

  updateActivePage = (_, data) => {
    const { updateActivePageHandler } = this.props
    this.setState({ allSelected: false, selectedIds: [] }, () => updateActivePageHandler(_, data))
  }

  checkProcess(processId) {
    const { selectedIds } = this.state
    const newSelectedIds = [...selectedIds]

    if (newSelectedIds.includes(processId)) {
      const indexToRemove = newSelectedIds.findIndex(id => id === processId)
      newSelectedIds.splice(indexToRemove, 1)
    } else {
      newSelectedIds.push(processId)
    }
    this.setState({ selectedIds: newSelectedIds })
  }

  render() {
    const { selectedIds, allSelected, executingAction } = this.state

    const {
      activePage,
      totalPages,
      resultsPerPage,
      openModalHandler,
      deviceUsers,
      updateResultsPerPageHandler,
      filteredProcesses,
      processClickHandler,
      user,
    } = this.props

    return (
      <div>
        <ProcessTableControls
          activePage={activePage}
          totalPages={totalPages}
          updateActivePage={this.updateActivePage}
          updateResultsPerPage={updateResultsPerPageHandler}
          openModalHandler={openModalHandler}
          updateSelectedAction={this.updateSelectedAction}
          executeSelectedAction={this.executeSelectedAction}
          executingAction={executingAction}
          allSelected={allSelected}
          handleSelectAll={this.handleSelectAll}
        />
        <Table className='ospin-red' celled selectable padded sortable>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Comment</Table.HeaderCell>
              <Table.HeaderCell>Clone History</Table.HeaderCell>
              <Table.HeaderCell>State</Table.HeaderCell>
              <Table.HeaderCell>Owner</Table.HeaderCell>
              <Table.HeaderCell>Created</Table.HeaderCell>
              <Table.HeaderCell>Finished</Table.HeaderCell>
              <Table.HeaderCell>Duration</Table.HeaderCell>
              <Table.HeaderCell>Select</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {filteredProcesses
              .slice(activePage * resultsPerPage - resultsPerPage, activePage * resultsPerPage)
              .map((process, i) => {

                const processIndex = i + (activePage - 1) * resultsPerPage
                const selected = selectedIds.includes(process.id)

                return (
                  <ProcessRow
                    key={processIndex}
                    checked={selected}
                    process={process}
                    processIndex={processIndex}
                    selectHandler={() => this.checkProcess(process.id)}
                    processClickHandler={processClickHandler}
                    openModalHandler={openModalHandler}
                    user={user}
                    deviceUsers={deviceUsers}
                  />
                )
              })}
          </Table.Body>
        </Table>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessesTable)
