import Streams from 'utils/Streams'
import DescriptionParser from 'utils/process/DescriptionParser'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import PhaseProgression from 'utils/process/PhaseProgression'
import Validator from 'utils/validation/Validator'

class DataManager {

  static initStreamData(process) {
    const upStreams = Streams.getUpstreams(process.deviceDescription)

    return upStreams.map(upStream => ({
      id: upStream.id,
      dataPoints: [],
      loaded: false,
      error: false,
    }))
  }

  static getStreamDataObject(process, streamId) {
    return process.data.find(streamData => streamData.id === streamId)
  }

  static getStreamDataPoints(process, streamId) {
    return DataManager.getStreamDataObject(process, streamId)?.dataPoints || []
  }

  static isStreamDataLoaded(process, streamId) {
    return DataManager.getStreamDataObject(process, streamId)?.loaded
  }

  static isStreamDataError(process, streamId) {
    return DataManager.getStreamDataObject(process, streamId)?.error
  }

  static setStreamDataErrorFlag(process, streamId) {
    return process.data.map(stream => {
      if (stream.id === streamId) {
        stream.loaded = true
        stream.error = true
      }
      return stream
    })
  }

  static generatePhaseGraph = (slice, process, phaseId, clickHandler, cursor) => ({
    click: clickHandler ? e => clickHandler(slice, e) : null,
    markerSize: 0,
    type: 'line',
    showInLegend: true,
    legendText: DescriptionParser.getPhaseName(process, phaseId),
    dataPoints: slice,
    cursor,
    phaseId,
  })

  static getPreviousDataSlice = (process, phase, iterationOf, data) => {

    if (Validator.isUndefinedOrNull(iterationOf)) {
      return data[data.length - 1]
    }

    const prevPhaseId = DescriptionParser.getPreviousPhaseId(process, phase.phaseId)
    const { iterationOf: prevIterationOf } = DescriptionParser
      .getPhaseById(process, prevPhaseId)

    if (Validator.isUndefinedOrNull(prevIterationOf)) {
      return data[data.length - 1]
    }

    return data.find(aSlice => aSlice.phaseId === prevIterationOf)
  }

  static generatePhaseSeriesData(
    process,
    datapoints,
    clickHandler = null,
    { cursor = 'default' } = {},
  ) {

    const data = []
    const { progression } = process

    progression.forEach(phase => {
      const start = PhaseProgression.getSecondsSinceProcessStart(process, phase.phaseId)
      const end = PhaseProgression
        .getSecondsSinceProcessStart(process, DescriptionParser.getNextPhaseId(process, phase.phaseId))

      const slice = datapoints.filter(p => (p.x >= start && p.x < end))

      // add last datapoint of previous phase to prevent gaps in graphs

      if (!slice.length) return

      const { iterationOf } = DescriptionParser.getPhaseById(process, phase.phaseId)

      if (data.length) {
        const lastSlice = DataManager.getPreviousDataSlice(process, phase, iterationOf, data)

        if (lastSlice && lastSlice.dataPoints.length) {
          const lastDataPoint = lastSlice.dataPoints[lastSlice.dataPoints.length - 1]

          slice.reverse()
          slice.push(lastDataPoint)
          slice.reverse()
        }
      }

      if (Validator.isUndefinedOrNull(iterationOf)) {
        data.push(DataManager.generatePhaseGraph(slice, process, phase.phaseId, clickHandler, cursor))
        return
      }

      const dataSlice = data.find(aSlice => aSlice.phaseId === iterationOf)

      if (dataSlice) {
        const breakPoint = { x: slice[0].x, y: null }
        dataSlice.dataPoints = [ ...dataSlice.dataPoints, breakPoint, ...slice ]
        return
      }

      data.push(DataManager.generatePhaseGraph(slice, process, iterationOf, clickHandler, cursor))
    })
    return data
  }

  static timeFrameModeToSeconds = mode => {
    switch (mode) {
      case 'hour': return 3600
      case 'hour8': return 8 * 3600
      case 'day': return 24 * 3600
      default:
        throw Error(`Unknown mode ${mode} provided`)
    }
  }

  static getPhaseBoundaries = (process, selectedPhase) => {
    const phase = DescriptionParser.getPhaseById(process, selectedPhase)
    const { groupName } = phase

    let firstPhaseId = selectedPhase
    let lastPhaseId = selectedPhase

    if (groupName) {
      const hasAtLeastOneIteration = process.progression.some(({ phaseId }) => {
        const iteratedPhase = DescriptionParser.getPhaseById(process, phaseId)
        return iteratedPhase.groupName === groupName && iteratedPhase.iterationOf === selectedPhase
      })

      if (hasAtLeastOneIteration) {
        const iterationData = PhaseGroupParser.getIterationData(process, groupName)
        firstPhaseId = iterationData[0].phaseIds[0]
        const lastPhaseIds = PhaseGroupParser.getIterationEndPhaseIds(process, groupName)
        lastPhaseId = lastPhaseIds[lastPhaseIds.length - 1]
      }

    }

    const start = PhaseProgression.getSecondsSinceProcessStart(process, firstPhaseId)
    const end = PhaseProgression.getSecondsSinceProcessStart(
      process, DescriptionParser.getNextPhaseId(process, lastPhaseId),
    )

    return { start, end }
  }

  static sliceStreamData(process, data, mode, selectedPhase, CONSTANTS, zoomParams) {
    if (data.length === 0) return []

    switch (mode) {
      case 'live': {
        const { XviewportMinimum } = zoomParams
        if (XviewportMinimum !== null) return [ ...data ]
        return data.slice(-CONSTANTS.POINTS_IN_LIVE_VIEW)
      }
      case 'phase': {
        const phase = DescriptionParser.getPhaseById(process, selectedPhase)
        const { iterationOf } = phase

        const phaseId = Validator.isUndefinedOrNull(iterationOf) ? selectedPhase : iterationOf
        const { start, end } = DataManager.getPhaseBoundaries(process, phaseId)
        return data.filter(p => (p.x >= start && p.x <= end))
      }
      case 'full':
        return [ ...data ]
      default: {
        const now = data[data.length - 1].x
        const start = now - DataManager.timeFrameModeToSeconds(mode)
        return data.filter(p => p.x > start)
      }
    }
  }

  static convertMsToSeconds(msArray) {
    return msArray.map(ms => ms / 1000)
  }

  static serializeDataPoints(xValues, yValues) {
    return xValues.map((x, idx) => ({ x, y: yValues[idx] === -500 ? null : yValues[idx] }))
  }

  // appends the incoming live stream data to the current stream data
  static appendDataPointsToStreamData(existingStreamData, newStreamData) {
    const [ timesInMs, values ] = newStreamData
    const timesInSeconds = DataManager.convertMsToSeconds(timesInMs)

    const newDataPoints = DataManager.serializeDataPoints(timesInSeconds, values)
      .sort((dataPointA, dataPointB) => dataPointA.x - dataPointB.x)

    existingStreamData.dataPoints = [ ...existingStreamData.dataPoints, ...newDataPoints ]
  }

  static parseLiveStreamData(process, data) {
    const upStreamIds = Object.keys(data)

    upStreamIds.forEach(streamId => {
      const existingStreamData = DataManager.getStreamDataObject(process, streamId)
      if (!existingStreamData) return

      const newStreamData = data[streamId]

      DataManager.appendDataPointsToStreamData(existingStreamData, newStreamData)
    })
  }

  static parseHistoricData(process, streamId, data) {
    const prevDataPoints = DataManager.getStreamDataPoints(process, streamId)

    const { times, values } = data
    if (!times) return process.data

    const buffer = times.map((time, idx) => ({ x: time / 1000, y: values[idx] }))

    // sanitize data by removing duplicates and inserting in the right spot
    // while merging the old and new dataset

    let newData = []
    let i = 0
    let j = 0

    while (i < buffer.length && j < prevDataPoints.length) {
      if (buffer[i].x <= prevDataPoints[j].x) {
        newData.push(buffer[i])
        i++
      } else {
        newData.push(prevDataPoints[j])
        j++
      }
    }

    while (i < buffer.length) {
      newData.push(buffer[i])
      i++
    }

    while (j < prevDataPoints.length) {
      newData.push(prevDataPoints[j])
      j++
    }

    // remove duplicates
    newData = newData.filter((dp, i) => (i < newData.length - 1 ? dp.x !== newData[i + 1].x : true))

    return process.data.map(stream => {
      if (stream.id === streamId) {
        stream.dataPoints = newData
        stream.loaded = true
      }
      return stream
    })
  }

  static getLastStreamDataPoint(process, streamId) {
    const streamData = DataManager.getStreamDataPoints(process, streamId)
    if (!streamData.length) return null
    return streamData[streamData.length - 1].y
  }

  static getMaxStreamValue(process, streamId) {
    const streamData = DataManager.getStreamDataPoints(process, streamId)
    if (!streamData.length) return null
    return Math.max(...streamData.map(p => p.y))
  }

  static getMinStreamValue(process, streamId) {
    const streamData = DataManager.getStreamDataPoints(process, streamId)
    if (!streamData.length) return null
    return Math.min(...streamData.map(p => p.y))
  }
}

export default DataManager
