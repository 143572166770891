import React from 'react'
import { connect } from 'react-redux'
import EditableHeader, { EditableHeaderButtonClassName } from 'components/utility/EditableHeader'
import { Button, Icon } from 'semantic-ui-react'
import PDVC from 'utils/PDVC'
import { Process, PROCESS_STATES } from 'utils/process'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import PhaseProgression from 'utils/process/PhaseProgression'
import FlashMessenger from 'utils/FlashMessenger'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import { renamePhaseGroup } from 'redux/actions/actions'
import PhaseIterationCounter from './PhaseIterationCounter'

const editableHeaderClassName = `&:hover .${EditableHeaderButtonClassName}`
const styles = { parent: { marginLeft: '10px' } }
styles.parent[editableHeaderClassName] = { opacity: '0' }

const mapStateToProps = state => ({
  processDescriptionSnapshots: state.processDescriptionSnapshots,
  displayedPhaseId: state.displayedPhaseId,
  user: state.user,
})

const mapDispatchToProps = dispatch => ({
  dispatchRenamePhaseGroup: (processId, oldGroupName, newGroupName) => dispatch(
    renamePhaseGroup({ processId, oldGroupName, newGroupName }),
  ),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

function PhaseGroupHeader({
  groupName,
  activeProcess,
  dispatchCallUpdateProcess,
  dispatchRenamePhaseGroup,
  processDescriptionSnapshots,
  setDisableDrag,
  deleteGroup,
}) {
  const firstId = PhaseGroupParser.getFirstPhaseIdOfGroup(activeProcess, groupName)

  const save = async () => {
    await Process.save(activeProcess, dispatchCallUpdateProcess)
  }

  const changeNameHandler = async newGroupName => {

    if (newGroupName === groupName) return

    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)

    if (diffs.length) {
      FlashMessenger.warning('Please revert or apply your process changes before renaming a phase')
      return
    }

    try {
      dispatchRenamePhaseGroup(activeProcess.id, groupName, newGroupName)
      await save()
    } catch (e) {
      FlashMessenger.error(e.message)
    }
  }

  const isFinished = activeProcess.state === PROCESS_STATES.finished
  const isDeletable = !isFinished && !PhaseProgression.contains(activeProcess, firstId)

  return (
    <div
      onMouseOver={() => setDisableDrag(false)}
      onFocus={() => setDisableDrag(false)}
      onMouseLeave={() => setDisableDrag(true)}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <EditableHeader
          name={groupName}
          changeNameHandler={newName => changeNameHandler(newName)}
          fontSize='1rem'
          fluid
          iconSize='tiny'
          isHoverable={false}
        />
        {isDeletable
          ? (
            <>
              <Button
                icon
                circular
                size='tiny'
                color='red'
                style={{ backgroundColor: 'white' }}
                onClick={deleteGroup}
              >
                <Icon name='trash' color='red' />
              </Button>
            </>
          ) : null}
      </div>
      <PhaseIterationCounter
        groupName={groupName}
        activeProcess={activeProcess}
      />
      {' '}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseGroupHeader)
