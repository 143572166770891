import Streams from 'utils/Streams'

class DeviceConfig {

  static getStreamConfig = (device, streamId) => (
    DeviceConfig._injectTopLevelConfigProps(
      device,
      streamId,
      device.config.streams.find(configEntry => configEntry.streamId === streamId),
    )
  )

  static hasStreamConfig = (streamsConfig, streamId) => (
    streamsConfig.some(streamConfig => streamConfig.streamId === streamId)
  )

  static createStreamConfiguration = (device, stream, streamConfig) => {

    if (!streamConfig && !stream) {
      return
    }

    if (!streamConfig) {
      return DeviceConfig.createUnconfiguredStream(stream)
    }

    if (!stream) {
      return DeviceConfig.createDisconnectedConfiguredStream(streamConfig)
    }

    return DeviceConfig.createConnectedConfiguredStream(stream, streamConfig)
  }

  static getConfiguredStreamById = (device, streamId) => {
    const stream = Streams.getById(device.streams, streamId)
    const streamConfig = DeviceConfig.getStreamConfig(device, streamId)

    return DeviceConfig.createStreamConfiguration(device, stream, streamConfig)
  }

  static getConfiguredStream = (device, stream) => {
    const streamConfig = DeviceConfig.getStreamConfig(device, stream.id)

    return DeviceConfig.createStreamConfiguration(device, stream, streamConfig)
  }

  static pickConfigValue = (configValue, fallback) => (
    configValue !== undefined ? configValue : fallback
  )

  static createUnconfiguredStream = stream => (
    {
      ...stream,
      displayedName: stream.name,
      displayedUnit: stream.unit,
      targetDefault: stream.value,
      eventTriggers: [],
      customConversions: [],
      calibration: undefined,
      connected: true,
    }
  )

  static createDisconnectedConfiguredStream = streamConfig => {

    const {
      nameAlias,
      defaultUnit,
      customDefault,
      calibration,
      streamId,
      eventTriggers,
      customConversions,
    } = streamConfig

    const displayedNameFallbackMsg = 'Name can\'t be retrieved'
    const displayedUnitFallbackMsg = '-'
    const customDefaultFallbackMsg = 'Default target can\'t be retrieved'

    return {
      id: streamId,
      displayedName: DeviceConfig.pickConfigValue(nameAlias, displayedNameFallbackMsg),
      displayedUnit: DeviceConfig.pickConfigValue(defaultUnit, displayedUnitFallbackMsg),
      targetDefault: DeviceConfig.pickConfigValue(customDefault, customDefaultFallbackMsg),
      eventTriggers: eventTriggers || [],
      customConversions: customConversions || [],
      calibration,
      connected: false,
    }
  }

  static createConnectedConfiguredStream = (stream, streamConfig) => {

    const {
      nameAlias,
      defaultUnit,
      customDefault,
      calibration,
      eventTriggers,
      customConversions,
    } = streamConfig

    return {
      ...stream,
      displayedName: DeviceConfig.pickConfigValue(nameAlias, stream.name),
      displayedUnit: DeviceConfig.pickConfigValue(defaultUnit, stream.unit),
      targetDefault: DeviceConfig.pickConfigValue(customDefault, stream.default),
      eventTriggers: eventTriggers || [],
      customConversions: customConversions || [],
      calibration,
      connected: true,
    }
  }

  static getConfiguredStreams = device => (
    device.streams.map(stream => DeviceConfig.getConfiguredStream(device, stream))
  )

  static getConfiguredStreamsById = (device, streamIds) => (
    streamIds.map(streamId => DeviceConfig.getConfiguredStreamById(device, streamId))
  )

  static getDisplayedStreamName = (device, streamId) => (
    DeviceConfig.getConfiguredStreamById(device, streamId).displayedName
  )

  static getDisplayedStreamUnit = (device, streamId) => (
    DeviceConfig.getConfiguredStreamById(device, streamId).displayedUnit
  )

  static getCustomStreamDefault = (device, streamId) => (
    DeviceConfig.getConfiguredStreamById(device, streamId).targetDefault
  )

  static getStreamCalibration = (device, streamId) => (
    DeviceConfig.getConfiguredStreamById(device, streamId).calibration
  )

  static getStreamEventTriggers = (device, streamId) => (
    DeviceConfig.getConfiguredStreamById(device, streamId).eventTriggers
  )

  static getStreamEventTriggerByType = (device, streamId, type) => {
    const triggers = DeviceConfig.getStreamEventTriggers(device, streamId)
    if (triggers.length) return triggers.find(aTrigger => aTrigger.type === type)
  }

  static getConfiguredStreamsWithCustomDefault = device => {
    const streamIds = DeviceConfig._getStreamDefaultEntries(device)
      .map(defaultEntry => defaultEntry.streamId)

    return DeviceConfig.getConfiguredStreamsById(device, streamIds)
  }

  static getConfiguredStreamsWithCalibration = device => {
    const streamIds = DeviceConfig._getCalibrationEntries(device)
      .map(calibrationEntry => calibrationEntry.streamId)

    return DeviceConfig.getConfiguredStreamsById(device, streamIds)
  }

  static hasStreamCalibration = (device, streamId) => (
    DeviceConfig.getConfiguredStreamsWithCalibration(device)
      .some(streamConfig => streamConfig.id === streamId)
  )

  static getConfiguredStreamsWithNameAlias = device => {
    const streamIds = DeviceConfig._getStreamNameAliasEntries(device)
      .map(aliasEntry => aliasEntry.streamId)

    return DeviceConfig.getConfiguredStreamsById(device, streamIds)
  }

  static getConfiguresStreamsByQuery(device, query) {
    const streamIds = device.config.streams
      .filter(query)
      .map(config => config.streamId)

    return DeviceConfig.getConfiguredStreamsById(device, streamIds)
  }

  static getConfiguredStreamsWithDisplayedUnit = device => (
    DeviceConfig.getConfiguresStreamsByQuery(device, DeviceConfig.streamConfigHasDisplayedUnit)
  )

  static streamConfigHasDisplayedUnit = config => (
    config.defaultUnit !== undefined
  )

  static getConfiguredStreamsWithEventTriggers = device => (
    DeviceConfig.getConfiguresStreamsByQuery(device, DeviceConfig.streamConfigHasEventTriggers)
  )

  static streamConfigHasEventTriggers = config => (
    config.eventTriggers && config.eventTriggers.length
  )

  static isEmptyStreamConfig = streamConfig => {
    const noValuesSet = Object.values(streamConfig)
      .every(value => value === undefined)

    const configProps = Object.keys(streamConfig)
    const containsOnlyStreamId = configProps.length === 1 && configProps[0] === 'streamId'
    return noValuesSet || containsOnlyStreamId
  }

  /* adding and modifying stream config */

  static createEmptyStreamConfiguration = streamId => (
    { streamId }
  )

  static createUpdatedConfiguration = (config, params) => (
    { ...config, ...params }
  )

  static createUpdatedStreamsConfiguration = (streamsConfig, streamId, params) => {
    const configExists = DeviceConfig.hasStreamConfig(streamsConfig, streamId)


    if (!configExists) {
      const newStreamConfig = DeviceConfig.createEmptyStreamConfiguration(streamId)
      streamsConfig.push(newStreamConfig)
    }

    return DeviceConfig.applyStreamConfigChanges(
      streamsConfig, streamId, params
    )
  }

  static applyStreamConfigChanges = (streamsConfig, streamId, updateParams) => (
    streamsConfig.map(streamConfig => {
      if (streamConfig.streamId === streamId) {
        return { ...streamConfig, ...updateParams }
      }
      return streamConfig
    })
  )

  static updateStreamConfig = (device, streamId, updateParams) => {
    const streamsConfiguration = [ ...device.config.streams ]

    const updatedStreamConfiguration = DeviceConfig.createUpdatedStreamsConfiguration(
      streamsConfiguration, streamId, updateParams
    )

    return DeviceConfig.createUpdatedConfiguration(device.config, { streams: updatedStreamConfiguration })
  }

  static updateManyStreamConfigs = (device, updateParamsArray) => {
    let streamsConfiguration = [ ...device.config.streams ]

    updateParamsArray.forEach(({ streamId, updateParams }) => {
      streamsConfiguration = DeviceConfig.createUpdatedStreamsConfiguration(
        streamsConfiguration, streamId, updateParams
      )
    })

    return DeviceConfig.createUpdatedConfiguration(device.config, { streams: streamsConfiguration })
  }

  static getUpdatedEventTriggers = (device, streamId, newTrigger) => {
    const eventTriggers = DeviceConfig.getStreamEventTriggers(device, streamId) || []

    const existingEventTrigger = eventTriggers.find(trigger => trigger.type === newTrigger.type)

    if (existingEventTrigger) {
      return eventTriggers.map(trigger => {
        if (trigger.type === newTrigger.type) return newTrigger
        return trigger
      })
    }
    return [ ...eventTriggers, newTrigger ]
  }

  static removeEventTriggerByType = (device, streamId, type) => {
    const eventTriggers = DeviceConfig.getStreamEventTriggers(device, streamId) || []
    return eventTriggers.filter(trigger => trigger.type !== type)
  }

  static getStreamEventTriggersForProcess = device => {
    const connectedStreamsWithTriggers = DeviceConfig
      .getConfiguredStreamsWithEventTriggers(device)
      .filter(stream => stream.connected)

    return connectedStreamsWithTriggers.reduce((acc, stream) => {
      const streamTriggers = stream.eventTriggers.reduce((triggers, trigger) => (
        { ...triggers, [trigger.type]: trigger }
      ), {})

      return { ...acc, [stream.id]: streamTriggers }
    }, {})
  }

  /* removing stream config */

  static removeStreamConfigProp = (streamsConfig, streamId, prop) => (
    streamsConfig
      .map(streamConfig => {
        if (streamConfig.streamId === streamId) {
          delete streamConfig[prop]
        }
        return streamConfig
      })
      .filter(streamConfig => !DeviceConfig.isEmptyStreamConfig(streamConfig))
  )

  static removeStreamDefaultUnit = (device, streamId) => {
    const streamsConfiguration = [ ...device.config.streams ]
    const updatedStreamsConfiguration = DeviceConfig.removeStreamConfigProp(streamsConfiguration, streamId, 'defaultUnit')

    return DeviceConfig.createUpdatedConfiguration(device.config, { streams: updatedStreamsConfiguration })
  }

  /* all following functions are candidates for deprecation and only for internal
  /* usage until alias, defaults and calibration were moved to config;
  /* to access to desired config property use the above defined functions
   */

  static _getStreamDefaultEntry = (device, streamId) => (
    device.streamDefaults.find(defaultEntry => defaultEntry.streamId === streamId)
  )

  static _getStreamDefault = (device, streamId) => {
    const foundDefaultEntry = DeviceConfig._getStreamDefaultEntry(device, streamId)
    return foundDefaultEntry ? foundDefaultEntry.value : undefined
  }

  static _getStreamDefaultEntries = device => (
    device.streamDefaults
  )

  static _getStreamAliasEntry = (device, streamId) => (
    device.streamNameAliases.find(aliasEntry => aliasEntry.streamId === streamId)
  )

  static _getStreamNameAliasEntries = device => (
    device.streamNameAliases
  )

  static _getStreamNameAlias = (device, streamId) => {
    const foundAliasEntry = DeviceConfig._getStreamAliasEntry(device, streamId)
    return foundAliasEntry ? foundAliasEntry.alias : undefined
  }

  static _getCalibrationEntry = (device, streamId) => (
    device.calibration.find(calibrationEntry => calibrationEntry.streamId === streamId)
  )

  static _getCalibrationEntries = device => (
    device.calibration
  )

  /* DeviceConfig function injects alias, default value and calibration into
  /* the fetched stream config from device.config.streams so we can already fetch
  /* them via the new API
   */

  static _injectTopLevelConfigProps = (device, streamId, streamConfig = {}) => {
    const alias = DeviceConfig._getStreamNameAlias(device, streamId)
    const defaultValue = DeviceConfig._getStreamDefault(device, streamId)
    let calibrationEntry = DeviceConfig._getCalibrationEntry(device, streamId)

    // removed streamId from the calibration entr within the configured stream

    if (calibrationEntry) {
      calibrationEntry = { offset: calibrationEntry.offset, slope: calibrationEntry.slope }
    }

    const injectedStreamConfig = {
      ...streamConfig,
      nameAlias: alias,
      customDefault: defaultValue,
      calibration: calibrationEntry,
      streamId,
    }

    // if all values on streamConfig are undefined, we want to return undefined instead
    // of an empty object (outer function should consider DeviceConfig to be a unconfigured stream)

    if (DeviceConfig.isEmptyStreamConfig(injectedStreamConfig)) return undefined

    return injectedStreamConfig
  }
}

export default DeviceConfig
