import React, { useState } from 'react'
import { connect } from 'react-redux'
import callGrantDeviceAccess from 'redux/thunks/device/callGrantDeviceAccess'
import modalize from 'components/utility/modal/modalize'
import FlashMessenger from 'utils/FlashMessenger'
import AccessDeviceModalForm from './AccessDeviceModalForm'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({ dispatchGrantDeviceAccess: (deviceId, email, groupName, hostName) => dispatch(callGrantDeviceAccess(deviceId, email, groupName, hostName)) })

const AccessDeviceModal = props => {
  const [saveMsg, setSaveMsg] = useState({ isError: false, msg: '' })
  const [isLoading, setIsLoading] = useState(false)
  const [accessDetails, setAccessDetails] = useState({ email: '', assignedGroup: '', assignedGroupData: null })

  const onToggleClose = () => {
    const { closeHandler } = props
    closeHandler()
  }

  const handleUserUpdate = (event, data) => {
    setAccessDetails({
      ...accessDetails,
      email: data.value,
    })
  }

  const handleGroupChange = value => {
    const assignedGroupData = props.activeDevice.access.find(group => group.name === value)
    setAccessDetails({
      ...accessDetails,
      assignedGroupData,
      assignedGroup: value,
    })

  }

  const onSubmit = async () => {
    const { email, assignedGroup } = accessDetails
    const isEmailValid = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)

    if (!assignedGroup) {
      setSaveMsg({ isError: true, msg: 'Please provide a group for the user.' })
      return
    }

    if (!isEmailValid) {
      setSaveMsg({ isError: true, msg: 'Please enter a valid email address.' })
      return
    }

    setIsLoading(true)

    const res = await props.dispatchGrantDeviceAccess(
      props.activeDevice.id,
      email, assignedGroup,
      props.user.userName,
    )

    if (res.success) {
      const { activeDevice: { name } } = props
      FlashMessenger.success(`User ${email} was invited to ${name}.`)
      onToggleClose()
    } else {
      setSaveMsg({ isError: true, msg: res.message })
    }

    setIsLoading(false)
  }

  const { activeDevice: { access } } = props
  const groupOptions = access.map(group => ({
    value: group.name,
    text: group.name,
    key: group.name,
  }))
  return (
    <AccessDeviceModalForm
      isError={saveMsg.isError}
      selected={accessDetails.assignedGroup}
      errorMessage={saveMsg.msg}
      successMessage={saveMsg.msg}
      closeHandler={onToggleClose}
      submitHandler={onSubmit}
      submitText='Add User'
      loading={isLoading}
      groupOptions={groupOptions}
      handleGroupChange={handleGroupChange}
      handleUserUpdate={handleUserUpdate}
    />
  )
}

export default modalize(connect(mapStateToProps, mapDispatchToProps)(AccessDeviceModal))
