import React from 'react'
import { connect } from 'react-redux'
import modalize from 'components/utility/modal/modalize'
import PumpCalibrationModalForm from 'components/device/modals/PumpCalibrationModal/PumpCalibrationModalForm'
import { List } from 'semantic-ui-react'
import callUpdateDevice from 'redux/thunks/device/callUpdateDevice'
import Streams from 'utils/Streams'
import DeviceConfig from 'utils/DeviceConfig'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'


const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateDevice: (processId, processName) => dispatch(
    callUpdateDevice(processId, processName),
  ),
})

const steps = [
  'Measure the pumped volume over a period of one minute'
    + ' at a fixed rpm value',
  'Calculate the ratio with this formula:\n\n total pumped volume [ml] / rpm',
  'Enter the result below',
]

class PumpCalibrationModal extends React.Component {

  state = {
    isError: false,
    isSuccess: false,
    errorMessage: '',
    ratio: '',
    selectedStreamId: undefined,
    loading: false,
  }

  getPumpOptions = () => {
    const { activeDevice } = this.props
    const streams = Streams.getPumpStreams(activeDevice.streams)
    return streams.map(stream => ({
      text: DeviceConfig.getDisplayedStreamName(activeDevice, stream.id),
      value: stream.id,
      key: stream.id,
    }))
  }

  updateValue = (_, { value, name }) => (
    this.setState({ [name]: value, isError: false, isSuccess: false, errorMessage: '' })
  )

  setError = text => (this.setState({
    isError: true,
    errorMessage: text,
    isSuccess: false,
    loading: false,
  }))

  submitRatio = async () => {
    const { ratio, selectedStreamId } = this.state

    if (selectedStreamId === undefined) {
      this.setError('No pump selected')
      return
    }

    const parsedRatio = parseFloat(ratio)
    if (isNaN(parsedRatio)) {
      this.setError('Ratio value has to be a number')
      return
    }

    const { dispatchCallUpdateDevice, activeDevice } = this.props

    const updatedConversions = CustomConversions
      .getUpdatedConversions(activeDevice, selectedStreamId, { to: 'ml/min', ratio: parsedRatio })
    const updatedConfig = DeviceConfig
      .updateStreamConfig(activeDevice, selectedStreamId, { customConversions: updatedConversions })

    this.setState({ loading: true })
    const res = await dispatchCallUpdateDevice(activeDevice.id, { config: updatedConfig })
    if (res.success) {
      this.setState({
        isSuccess: true,
        isError: false,
        errorMessage: '',
        loading: false,
      })
    } else {
      this.setError(res.message)
    }
  }

  render() {

    const { isError, errorMessage, ratio, selectedStreamId, isSuccess, loading } = this.state
    const { closeHandler } = this.props

    const pumpOptions = this.getPumpOptions()

    return (
      <>
        <p>
          Calibrating the pump flow rate will allow you to enter flow rates instead of rpm values
          for the pump. The goal of the calibration is to calculate the ratio of pumped liquid
          per rotation.
        </p>
        <List ordered items={steps} />
        <PumpCalibrationModalForm
          isError={isError}
          errorMessage={errorMessage}
          isSuccess={isSuccess}
          successMessage='Pump calibration set'
          loading={loading}
          closeHandler={closeHandler}
          submitHandler={this.submitRatio}
          submitText='Confirm'
          handleUpdate={this.updateValue}
          ratio={ratio}
          pumpOptions={pumpOptions}
          selectedStreamId={selectedStreamId}
          showSubmitButton={pumpOptions.length > 0}
        />
      </>
    )
  }
}

export default connect(null, mapDispatchToProps)(modalize(PumpCalibrationModal))
