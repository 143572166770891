import UIConfig from './UIConfig'
import StreamGroups from './StreamGroups'

export default class GraphsUIConfig extends UIConfig {

  static toggleStreamFolded(groupName, streamId, { activeDevice, userId }) {
    const { uIConfig, uIConfig: { graphs } } = activeDevice

    uIConfig.graphs.groups = StreamGroups.toggleGroupStreamFolded(graphs.groups, groupName, streamId)

    super.persistUIConfig(userId, activeDevice.id, uIConfig)
  }
}
