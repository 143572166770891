import { findAndUpdate } from 'redux/helper/updateFunctions'
import { setPhasePropsWithGroupConsideration } from 'utils/process'

export default (state, action) => {
  const { processes } = state
  const { processId, phaseId, update } = action.payload

  if ('transition' in update && update.transition === 'manual') {
    update.duration = 0
  }

  const updateFn = process => setPhasePropsWithGroupConsideration(process, phaseId, update)

  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }
}
