import Stream from 'utils/Stream'
import EventTrigger from 'utils/EventTrigger/EventTrigger'
import DescriptionParser from 'utils/process/DescriptionParser'
import PhaseProgression from 'utils/process/PhaseProgression'
import ProcessEventTriggers from 'utils/process/ProcessEventTriggers'


class LineGenerator {

  static createTargetLine(dataPoints, opt) {
    return {
      markerSize: 0,
      type: 'line',
      showInLegend: true,
      legendText: 'target',
      lineColor: '#5eafed',
      color: '#5eafed',
      lineDashType: 'dash',
      dataPoints,
      ...opt,
    }
  }

  static secondsSinceProcessStart(activeProcess, reference = Date.now()) {
    return (reference - activeProcess.startedAt) / 1000
  }

  static getFirstTimeStamp(activeProcess, startTime) {
    return Math.max(this.secondsSinceProcessStart(activeProcess, startTime), 0)
  }

  static getLastTimeStamp(activeProcess) {
    return activeProcess.state === 'finished'
      ? this.secondsSinceProcessStart(activeProcess, activeProcess.finishedAt)
      : this.secondsSinceProcessStart(activeProcess)
  }

  static getNextTimeStamp(activeProcess, phaseId) {
    const nextPhaseId = DescriptionParser.getNextPhaseId(activeProcess, phaseId)
    const { startTime: nextStartTime } = PhaseProgression
      .getPhaseById(activeProcess, nextPhaseId)
    return this.secondsSinceProcessStart(activeProcess, nextStartTime)
  }

  static fillGraph(segments, streamData) {
    const filledSegments = segments.map(({ start, end, target }) => {

      const segmentDataPoints = streamData
        .filter(dp => dp.x >= start && dp.x <= end)
        .map(dp => ({ ...dp, y: target }))

      // adding segment corners (rev - push -rev) is more efficient than unshift for adding first

      segmentDataPoints.reverse()
      segmentDataPoints.push({ x: start, y: target })
      segmentDataPoints.reverse()

      segmentDataPoints.push({ x: end, y: target })
      return segmentDataPoints
    })

    return filledSegments.reduce((lineData, segment) => ([ ...lineData, ...segment ]), [])
  }

  static trimSegments(segments, { start, end }) {
    const trimmedSegments = segments.filter(segment => segment.end >= start && segment.start < end)

    trimmedSegments[0].start = start
    trimmedSegments[trimmedSegments.length - 1].end = end

    return trimmedSegments
  }

  static createDataPoints(activeProcess, targetStreamId, range, streamData) {
    const segments = this.createSegments(activeProcess, targetStreamId)
    const trimmedSegments = this.trimSegments(segments, range)
    return this.fillGraph(trimmedSegments, streamData)
  }


  static createSegments(activeProcess, targetStreamId) {
    const { progression } = activeProcess

    return progression.map(({ startTime, phaseId }, i) => {
      const target = DescriptionParser.getStreamTarget(activeProcess, phaseId, targetStreamId)
      const endTime = i === progression.length - 1
        ? this.getLastTimeStamp(activeProcess)
        : this.getNextTimeStamp(activeProcess, phaseId)

      return {
        start: this.getFirstTimeStamp(activeProcess, startTime),
        end: endTime,
        target,
      }
    })
  }

  static getTargetLines(stream, activeProcess, range, streamData) {
    const targetStreamRef = Stream.getRelatedStreamEntry(stream, 'target')
    if (!targetStreamRef) return

    // backwards compatibility
    const targetStreamId = targetStreamRef.streamID || targetStreamRef.streamId

    const dataPoints = this.createDataPoints(activeProcess, targetStreamId, range, streamData)

    return this.createTargetLine(dataPoints)
  }

  static getEventTriggerLines(stream, activeProcess, range, streamData) {
    const eventTriggers = ProcessEventTriggers.getByStreamId(activeProcess, stream.id)

    if (!eventTriggers) return []

    const diplayedTriggerTypes = Object
      .keys(eventTriggers)
      .filter(type => EventTrigger.getByType(type).isDisplayable)

    return diplayedTriggerTypes.map(type => {
      const { threshold } = eventTriggers[type]
      const { start, end } = range
      const segment = { start, end, target: threshold }

      const dataPoints = this.fillGraph([ segment ], streamData)
      return this.createTargetLine(dataPoints, {
        lineColor: 'red',
        color: 'red',
        lineDashType: 'shortDash',
        showInLegend: false,
        lineThickness: 1,
      })
    })
  }
}

export default LineGenerator
