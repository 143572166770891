import React from 'react'
import formalize from 'components/utility/modal/formalize'
import { Table, List } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'

const renderMissingInProcess = (missingInProcess, activeDevice) => (
  <Table.Cell positive>
    <List>
      {missingInProcess.map(stream => (
        <List.Item
          icon='plus'
          key={stream.id}
          content={`${DeviceConfig.getDisplayedStreamName(activeDevice, stream.id)} (${stream.id})`}
        />
      ))}
    </List>
  </Table.Cell>
)

const renderMissingInDevice = missingInDevice => (
  <Table.Cell negative>
    <List>
      {missingInDevice.map(stream => (
        <List.Item
          icon='minus'
          key={stream.id}
          content={`${stream.name} (${stream.id})`}
        />
      ))}
    </List>
  </Table.Cell>
)

const DescriptionMismatchModalForm = ({
  missMatches: { missingInProcess, missingInDevice },
  activeDevice,
}) => (
  <>
    <Table celled className='max-width-498'>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell negative>New Device Streams</Table.HeaderCell>
          <Table.HeaderCell positive>Outdated Device Streams</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row widths='equal'>
          {renderMissingInProcess(missingInProcess, activeDevice)}
          {renderMissingInDevice(missingInDevice)}
        </Table.Row>
      </Table.Body>
    </Table>
  </>
)

export default formalize(DescriptionMismatchModalForm)
