import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getData } from '@jsonforms/core'
import { JsonFormsDispatch, JsonFormsReduxContext } from '@jsonforms/react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import createStyles from '@material-ui/core/styles/createStyles'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const styles = createStyles({
  container: { padding: '1em' },
  title: {
    textAlign: 'center',
    padding: '0.25em',
  },
  dataContent: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '0.25em',
    backgroundColor: '#cecece',
  },
  demoform: {
    margin: 'auto',
    padding: '1rem',
  },
})


const Builder = ({ classes, readableJSON, singleLineEscapedJSON }) => {

  const [ copied, setCopied ] = useState(false)
  const [ oldJSON, setOldJSON ] = useState(singleLineEscapedJSON)

  if (oldJSON !== singleLineEscapedJSON) {
    setCopied(false)
    setOldJSON(singleLineEscapedJSON)
  }

  return (
    <>
      <Grid container justify='center' spacing={1} className={classes.container}>
        <Grid item sm={6}>
          <Typography variant='h3' className={classes.title}>
            JSON Output
          </Typography>
          <div className={classes.dataContent}>
            <pre id='boundData'>{readableJSON}</pre>
          </div>
          <br />
          <br />
          <CopyToClipboard text={singleLineEscapedJSON} onCopy={() => setCopied(true)}>
            <div style={{
              cursor: 'pointer', backgroundColor: '#ddf', padding: '0 10px', borderRadius: '3px',
            }}
            >
              <Typography variant='h3' className={classes.title}>
                MiniEscaped Output
              </Typography>
              <div style={{ textAlign: 'center', border: '10px #fff' }}>
                <div style={{ overflow: 'auto', display: 'block', padding: '0 10px' }} className={classes.dataContent}>
                  <pre id='boundData'>{singleLineEscapedJSON}</pre>
                </div>
                <div style={{ fontSize: '25px', padding: '30px' }}>
                  { copied
                    ? <span style={{ color: 'red' }}>Copied</span>
                    : <span style={{}}>Click to copy to clickboard</span>}
                </div>
              </div>
            </div>
          </CopyToClipboard>
        </Grid>
        <Grid item sm={6}>
          <Typography variant='h3' className={classes.title}>
            Input
          </Typography>
          <div className={classes.demoform} id='form'>
            <JsonFormsReduxContext>
              <JsonFormsDispatch />
            </JsonFormsReduxContext>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

function escapeDoubleQuotes(str) { return str.replace(/\\([\s\S])|(")/g, '\\$1$2') }

const mapStateToProps = state => ({
  readableJSON: JSON.stringify(getData(state), null, 2),
  singleLineEscapedJSON: escapeDoubleQuotes(JSON.stringify(getData(state), null)),
})

export default connect(mapStateToProps)(withStyles(styles)(Builder))
