import nexus from 'nexus'
import { logout } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callLogout = async dispatch => {
  await nexus.auth.signOut()
  dispatch(logout())
}

export default thunkWrapper(callLogout)
