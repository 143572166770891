import UpperLimitTrigger from 'utils/EventTrigger/UpperLimitTrigger'
import LowerLimitTrigger from 'utils/EventTrigger/LowerLimitTrigger'

// EventTrigger forks to the correct trigger interface using the type

export default class EventTrigger {

  static TYPES = [
    UpperLimitTrigger.type,
    LowerLimitTrigger.type,
  ]

  static getByType = type => {
    switch (type) {
      case UpperLimitTrigger.type:
        return UpperLimitTrigger
      case LowerLimitTrigger.type:
        return LowerLimitTrigger
      default:
        throw new Error(`Unknown event trigger type ${type} provided.`)
    }
  }
}
