import { findAndUpdate } from 'redux/helper/updateFunctions'
import DataManager from 'utils/DataManager'

export default (state, action) => {
  const { streamId, processId } = action.payload

  const { processes } = state
  const updateFn = process => DataManager.setStreamDataErrorFlag(process, streamId)
  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])

  return { ...state, processes: updatedProcesses }
}
