import { Process, setPhaseProps } from 'utils/process'
import DescriptionParser from 'utils/process/DescriptionParser'
import PhaseProgression from 'utils/process/PhaseProgression'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import Validator from 'utils/validation/Validator'
import diff from 'deep-diff'

class PDVC {

  static createSnapshot(processId, processDescription) {
    return {
      id: processId,
      description: JSON.parse(JSON.stringify(processDescription)),
    }
  }

  static getSnapshot(processId, snapshots) {
    return snapshots.find(snapshot => snapshot.id === processId)
  }

  static revertToSnapshot(process, snapshots) {
    const snapshot = this.getSnapshot(process.id, snapshots)
    return JSON.parse(JSON.stringify(snapshot.description))
  }

  static _compareProcessDescription(process, snapshot) {
    const diffs = diff(snapshot.description, process.description)
    const normalizedDiffs = this._normalizeDiffs(process, snapshot, diffs)
    const filteredDiffs = normalizedDiffs.filter(aDiff => this.isAdhocChange(aDiff))

    return this.sortByPath(filteredDiffs)
  }

  static collapseIterationData(process, diffs) {
    return diffs.map(aDiff => {
      const { phaseId } = aDiff
      const phase = DescriptionParser.getPhaseById(process, parseInt(phaseId, 10))

      if (!phase) return aDiff

      const { groupName, iterationOf, name } = phase

      if (!groupName) return aDiff

      if (Validator.isUndefinedOrNull(iterationOf)) {
        return { ...aDiff, phaseName: `${groupName}: ${name} (1. cycle)` }
      }

      const iterationIds = PhaseGroupParser.getPhaseIterationIds(process, phase.iterationOf)
      const iterationIndex = iterationIds.findIndex(aPhaseId => aPhaseId === parseInt(phaseId, 10))
      const phaseName = `${groupName}: ${name} (${iterationIndex + 2}. cycle)`
      return { ...aDiff, phaseName }
    })
  }

  static getDiff(process, snapshots) {
    const snapshot = this.getSnapshot(process.id, snapshots)
    const diffs = snapshot ? this._compareProcessDescription(process, snapshot) : []
    return PDVC.collapseIterationData(process, diffs)
  }

  static _getChangeType(diff) {
    switch (true) {
      case (['N', 'D'].includes(diff.kind) && diff.path.length === 1):
        return 'phaseCountChange'
      case (diff.kind === 'E' && diff.path.length === 2):
        return 'phasePropChange'
      case (diff.kind === 'N' && diff.path.length === 2):
        return 'phasePropAdd'
      case (diff.kind === 'D' && diff.path.length === 2):
        return 'phasePropRemoved'
      case (diff.kind === 'E' && diff.path.length === 4):
        return 'streamTargetChange'
      default:
        return 'unknownChange'
    }
  }

  static _normalizeDiffs(process, snapshot, diffs) {
    if (!diffs) return []

    const ACTIONS = {
      N: 'added',
      E: 'edited',
      D: 'deleted',
    }

    return diffs.map(diff => {

      const action = ACTIONS[diff.kind]
      const phaseId = diff.path[0]
      const phaseName = action === 'added'
        ? DescriptionParser.getPhaseName(process, phaseId)
        : DescriptionParser.getPhaseName(snapshot, phaseId)

      return {
        snapshot: diff.lhs,
        edited: diff.rhs,
        phaseId,
        phaseName,
        action,
        depth: diff.path.length,
        type: this._getChangeType(diff),
        path: diff.path,
        property: diff.path[1],
      }
    })
  }

  static sortByPath(diffs) {
    return diffs
      .sort((a, b) => a.path.join``.localeCompare(b.path.join``)) // ¯\_(ツ)_/¯
  }

  static isAdhocChange(aDiff) {
    return !this.isPhaseNameChange(aDiff) && !this.isIterationChange(aDiff)
  }

  static runningPhaseModified(runningPhaseId, diffs) {
    return diffs.some(diff => parseInt(diff.phaseId, 10) === runningPhaseId)
  }

  static hasStreamTargetChange(diffs, phaseId) {
    return diffs.some(diff => diff.type === 'streamTargetChange' && parseInt(diff.phaseId) === phaseId)
  }

  static isPhaseNameChange(diff) {
    return diff.type === 'phasePropChange' && diff.path[diff.depth - 1] === 'name'
  }

  static get PHASE_CHANGE_TYPES() {
    return ['phasePropChange', 'phasePropAdd', 'phasePropRemoved']
  }

  static isIterationChange(diff) {
    return PDVC.PHASE_CHANGE_TYPES.includes(diff.type) && diff.path[diff.depth - 1] === 'iterationOf'
  }

  static createInsertedPhaseName(phaseName){
    return `${phaseName}'`
  }

  static applyRunningPhaseModifications(process, snapshot, insertPhase) {
    let updatedProcess = process
    let elapsedTime = 0
    let insertedPhaseId = null

    const runningPhaseId = Process.getRunningPhase(process).phaseId
    const runningPhaseData = DescriptionParser.getPhaseById(process, runningPhaseId)

    if (runningPhaseData.transition === 'time-based') {
      elapsedTime = PhaseProgression.getSecondsSincePhaseStart(updatedProcess, runningPhaseId)
    }

    if (insertPhase) {
      updatedProcess = Process.appendNewPhase(process, undefined, snapshot)
      insertedPhaseId = DescriptionParser.getLastPhaseId(updatedProcess)

      const insertedPhaseIndex = DescriptionParser.getPhaseIds(updatedProcess).length - 1
      const runningPhaseIndex = DescriptionParser
        .getPhaseExecutionIndex(updatedProcess, runningPhaseData.next)

      Process.movePhase(updatedProcess, insertedPhaseIndex, runningPhaseIndex)

      setPhaseProps(updatedProcess, insertedPhaseId, {
        name: this.createInsertedPhaseName(runningPhaseData.name),
        transition: runningPhaseData.transition,
        duration: runningPhaseData.transition === 'time-based' ? runningPhaseData.duration - elapsedTime : 0,
        downstreams: { ...runningPhaseData.downstreams },
      })

      if (runningPhaseData.groupName) {
        setPhaseProps(updatedProcess, insertedPhaseId, { groupName: runningPhaseData.groupName })
      }

      const runningPhaseSnapshotData = DescriptionParser.getPhaseById(snapshot, runningPhaseId)

      setPhaseProps(updatedProcess, runningPhaseId, {
        downstreams: { ...runningPhaseSnapshotData.downstreams },
        name: runningPhaseSnapshotData.name,
        duration: elapsedTime,
        transition: runningPhaseSnapshotData.transition,
      })
    }

    return { process: updatedProcess, elapsedTime, entryPhaseId: insertedPhaseId || runningPhaseId }
  }

  static applyDescriptionModification(process, snapshots, diffs) {

    let updatedProcess = { ...process }
    let elapsedTime = 0
    let entryPhaseId = Process.getRunningPhase(updatedProcess).phaseId

    const runningPhaseModified = this.runningPhaseModified(entryPhaseId, diffs)

    if (runningPhaseModified) {
      const insertPhase = this.hasStreamTargetChange(diffs, entryPhaseId)
      const snapshot = this.getSnapshot(updatedProcess.id, snapshots)
      const res = this.applyRunningPhaseModifications(updatedProcess, snapshot, insertPhase)

      updatedProcess = res.process
      elapsedTime = res.elapsedTime
      entryPhaseId = res.entryPhaseId
    } else if (DescriptionParser.getPhaseTransitionMethod(updatedProcess, entryPhaseId) === 'time-based') {
      elapsedTime = PhaseProgression.getSecondsSincePhaseStart(updatedProcess, entryPhaseId)
    }

    return { updatedProcess, elapsedTime, entryPhaseId: parseInt(entryPhaseId, 10) }
  }
}

export default PDVC
