import PhaseGroupParser from './PhaseGroupParser'
import PhaseProgression from './PhaseProgression'
import Validator from '../validation/Validator'
import { setPhaseProps, Process, PROCESS_STATES } from '../process'

class PhaseGroupHandler {

  static generateUniqueGroupname(process) {
    const existingGroupNames = PhaseGroupParser.getUniqueGroupNames(process)
    let counter = existingGroupNames.length + 1
    let newGroupName = `Group ${counter}`
    while (existingGroupNames.includes(newGroupName)) {
      counter += 1
      newGroupName = `Group ${counter}`
    }
    return newGroupName
  }

  static setGroup(process, phaseId, groupName) {
    setPhaseProps(process, phaseId, { groupName })
  }

  static removePhaseFromGroup(process, phaseId) {
    delete process.description[phaseId].groupName
    delete process.description[phaseId].iterationOf
  }

  static deleteGroup (process, targetGroup, keepOriginalPhases) {
    const deletedPhases = PhaseGroupParser.getGroupPhases(process, targetGroup)
    const originalPhases = PhaseGroupParser.getOriginalGroupPhases(process, targetGroup)

    if (!keepOriginalPhases) {
      deletedPhases.forEach(delPhase => Process.deletePhase(process, delPhase.id))
      return process
    }

    PhaseGroupHandler.deleteGroupIterations(process, targetGroup, 1)
    originalPhases.map(og => PhaseGroupHandler.removePhaseFromGroup(process, og.id))
    return process
  }

  static validatePhaseHasNoGroup(process, phaseId) {
    const groupName = PhaseGroupParser.getGroupName(process, phaseId)
    if (!Validator.isUndefinedOrNull(groupName)) {
      throw new Error(`Phase with id ${phaseId} already belongs to group ${groupName}`)
    }
  }

  static renameGroup(process, oldGroupName, newGroupName) {
    const existingGroupNames = PhaseGroupParser.getUniqueGroupNames(process)

    if (newGroupName.length === 0) {
      throw new Error('Group name has to be at least one character')
    }

    if (existingGroupNames.includes(newGroupName)) {
      throw new Error(`A group with the name ${newGroupName} already exsists`)
    }

    const phaseIdsToUpdate = PhaseGroupParser
      .getIterationData(process, oldGroupName)
      .reduce((acc, { phaseIds }) => [ ...acc, ...phaseIds ], [])

    phaseIdsToUpdate.forEach(aPhaseId => {
      setPhaseProps(process, aPhaseId, { groupName: newGroupName })
    })
  }

  static createGroup(process, phaseIds) {
    phaseIds.forEach(aPhaseId => PhaseGroupHandler.validatePhaseHasNoGroup(process, aPhaseId))
    const uniqueGroupname = PhaseGroupHandler.generateUniqueGroupname(process)
    phaseIds.forEach(aPhaseId => PhaseGroupHandler.setGroup(process, aPhaseId, uniqueGroupname))
  }

  static updateGroupConfiguration(process, groupName, includeRunningIteration = false) {
    const iterationData = PhaseGroupParser.getIterationData(process, groupName)
    const runningPhase = PhaseProgression.getLatest(process)
    const { iteration: runningIteration } = iterationData
      .find(({ phaseIds }) => phaseIds.includes(runningPhase.phaseId))

    const offset = includeRunningIteration ? 1 : 0

    PhaseGroupHandler.createRemainderGroup(process, iterationData, runningIteration - offset)
  }

  static createPhaseWithinGroup(process, groupName, snapshot) {
    const endPhaseIds = PhaseGroupParser.getIterationEndPhaseIds(process, groupName)
    const finishedIterations = PhaseGroupParser.getFinishedIterationsCount(process, groupName)
    const insertAtPhaseIds = endPhaseIds.slice(finishedIterations)

    let newOriginalPhaseId
    let newOriginalPhaseName

    insertAtPhaseIds.forEach((aPhaseId, idx) => {
      const newPhase = Process.createPhase(process, undefined, aPhaseId, snapshot)
      if (idx === 0) {
        newOriginalPhaseId = newPhase.phaseId
        newOriginalPhaseName = newPhase.phaseData.name
      } else {
        newPhase.phaseData.iterationOf = newOriginalPhaseId
        newPhase.phaseData.name = newOriginalPhaseName
      }
      Process.attachPhase(process, newPhase, aPhaseId)
      PhaseGroupHandler.setGroup(process, newPhase.phaseId, groupName)
    })

    // update group configuration in case of running group modification

    const runningGroup = Process.getRunningGroup(process)

    if (runningGroup && runningGroup === groupName && finishedIterations) {
      PhaseGroupHandler.updateGroupConfiguration(process, groupName, true)
    }

    return process
  }

  static deletePhaseWithinGroup(process, groupName, phaseId) {
    const iterationData = PhaseGroupParser.getIterationData(process, groupName)
    const deletedPhaseIteration = iterationData.find(({ phaseIds }) => phaseIds.includes(phaseId))
    const phasePosition = deletedPhaseIteration.phaseIds.findIndex(aPhaseId => aPhaseId === phaseId)
    const deletedPhaseIterationCount = deletedPhaseIteration.iteration - 1

    let deletedPhaseNextId

    if (phasePosition < iterationData[0].phaseIds.length - 1) {
      deletedPhaseNextId = iterationData[0].phaseIds[phasePosition + 1]
    } else {
      const lastPhaseId = PhaseGroupParser.getLastPhaseIdOfGroup(process, groupName)
      const phase = PhaseGroupParser.getPhaseById(process, lastPhaseId)
      deletedPhaseNextId = phase.next
    }

    const phaseIdsToDelete = iterationData
      .slice(deletedPhaseIterationCount)
      .map(({ phaseIds }) => phaseIds[phasePosition])

    let updatedProcess

    // there seems to be a reference issue - therefore I'm passing in the same process
    // over and over again into deletePhase and I don't know why it works;
    // otherwise activeProcess in the UI is is not getting the proper entryPhaseId
    // even though all dev tools show the correct entryPhaseId in the application state..

    phaseIdsToDelete.forEach(aPhaseId => {
      updatedProcess = Process.deletePhase(process, aPhaseId).updatedProcess
    })

    // update group configuration in case of running group modification

    const runningGroup = Process.getRunningGroup(updatedProcess)

    if (runningGroup && runningGroup === groupName && deletedPhaseIterationCount) {
      const runningPhase = PhaseProgression.getLatest(updatedProcess)
      const { iteration: runningIteration } = iterationData
        .find(({ phaseIds }) => phaseIds.includes(runningPhase.phaseId))

      const includeCurrentIterationOffset = runningIteration === deletedPhaseIteration.iteration ? 1 : 0

      PhaseGroupHandler.createRemainderGroup(
        updatedProcess,
        iterationData,
        runningIteration - includeCurrentIterationOffset,
      )
    }

    return { updatedProcess, deletedPhaseNextId }
  }

  static setGroupIterationsCount(process, groupName, count) {

    if (count === 0) {
      throw new Error('iteration count must be bigger than 0')
    }

    const currentCount = PhaseGroupParser.getGroupIterationsCount(process, groupName)

    if (currentCount === count) return process

    if (count < currentCount) {
      return PhaseGroupHandler.deleteGroupIterations(process, groupName, count)
    }
    return PhaseGroupHandler.addGroupIterations(process, groupName, count - currentCount)
  }

  static deleteGroupIterations(process, groupName, count) {
    let updatedProcess = { ...process }
    const iterationsToDelete = PhaseGroupParser
      .getIterationData(updatedProcess, groupName)
      .filter((_, idx) => idx >= count)

    iterationsToDelete.forEach(({ phaseIds }) => {
      phaseIds.forEach(aPhaseId => {
        updatedProcess = Process.deletePhase(updatedProcess, aPhaseId).updatedProcess
      })
    })
    return updatedProcess
  }

  static addGroupIterations(process, groupName, addCount) {
    const originalPhases = PhaseGroupParser.getOriginalGroupPhases(process, groupName)
    const endPhaseIds = PhaseGroupParser.getIterationEndPhaseIds(process, groupName)
    let pointOfInsertion = endPhaseIds[endPhaseIds.length - 1]

    for (let i = 1; i <= addCount; i += 1) {
      for (const { id, data: { name } } of originalPhases) {
        const newPhase = Process.createPhase(process, undefined, id)
        newPhase.phaseData.iterationOf = id
        newPhase.phaseData.name = name

        Process.attachPhase(process, newPhase, pointOfInsertion)
        PhaseGroupHandler.setGroup(process, newPhase.phaseId, groupName)

        pointOfInsertion = newPhase.phaseId
      }
    }
  }

  static propagatePhaseIterations(process, groupName, phase, insertionPoints) {
    insertionPoints.forEach(aPhaseId => {
      const newPhase = Process.createPhase(process, undefined, phase.id)
      newPhase.phaseData.iterationOf = phase.id
      newPhase.phaseData.name = phase.data.name
      Process.attachPhase(process, newPhase, aPhaseId)
      PhaseGroupHandler.setGroup(process, newPhase.phaseId, groupName)
    })
  }

  static appendPhaseToGroup(process, groupName, phaseId) {

    if (process.state === PROCESS_STATES.finished) {
      throw new Error('Unvalid operation for a finished process')
    }

    if (PhaseGroupParser.groupHasFinished(process, groupName)) {
      throw new Error('Can\'t append a phase to a finished group')
    }

    const phaseSequence = PhaseGroupParser.getPhasesSequence(process)
    const srcIdx = PhaseGroupParser.getPhaseExecutionIndex(process, phaseId)

    const finishedCount = PhaseGroupParser.getFinishedIterationsCount(process, groupName)
    const destPhaseId = PhaseGroupParser.getIterationEndPhaseIds(process, groupName)[finishedCount]

    const destIdx = PhaseGroupParser.getPhaseExecutionIndex(process, destPhaseId) + 1

    const sourcePhase = phaseSequence[srcIdx]

    PhaseGroupHandler.movePhaseToTheEnd(process, srcIdx, destIdx, groupName, sourcePhase)
  }

  static prependPhaseToGroup(process, groupName, phaseId) {

    if (process.state === PROCESS_STATES.finished) {
      throw new Error('Unvalid operation for a finished process')
    }

    if (process.state !== PROCESS_STATES.executable) {
      const finishedCount = PhaseGroupParser.getFinishedIterationsCount(process, groupName)

      if (finishedCount !== 0) {
        throw new Error('Can\'t prepend a phase to a progressed group')
      }
    }

    const destPhaseId = PhaseGroupParser.getFirstPhaseIdOfGroup(process, groupName)
    const phaseSequence = PhaseGroupParser.getPhasesSequence(process)
    const srcIdx = PhaseGroupParser.getPhaseExecutionIndex(process, phaseId)
    const destIdx = PhaseGroupParser.getPhaseExecutionIndex(process, destPhaseId)

    const sourcePhase = phaseSequence[srcIdx]

    PhaseGroupHandler.movePhaseToTheStart(process, srcIdx, destIdx, groupName, sourcePhase)
  }

  static movePhaseToTheStart(process, src, dest, groupName, sourcePhase) {
    const runningGroup = Process.getRunningGroup(process)
    const targetGroupIsRunning = runningGroup && runningGroup === groupName

    const skippedIterations = targetGroupIsRunning
      ? PhaseGroupParser.getFinishedIterationsCount(process, groupName) + 1
      : 0

    const insertionPoints = PhaseGroupParser
      .getIterationEndPhaseIds(process, groupName).slice(skippedIterations, -1)

    const phaseImmediatelyBeforeGroup = dest - src === 1

    if (!phaseImmediatelyBeforeGroup) {
      Process.movePhase(process, src, dest)
    }

    PhaseGroupHandler.setGroup(process, sourcePhase.id, groupName)
    PhaseGroupHandler.propagatePhaseIterations(process, groupName, sourcePhase, insertionPoints)

    // update group configuration in case of running group modification

    if (targetGroupIsRunning) {
      const iterationData = PhaseGroupParser.getIterationData(process, groupName)
      const runningPhase = PhaseProgression.getLatest(process)
      const { iteration: runningIteration } = iterationData
        .find(({ phaseIds }) => phaseIds.includes(runningPhase.phaseId))

      const normalPhaseCount = iterationData[0].phaseIds.length
      const iterationWithTooManyPhases = iterationData
        .find(({ phaseIds }) => phaseIds.length > normalPhaseCount)

      // the new phase ends up in the end of a wrong iteration, so we have to move it
      // to the start of the next one...

      if (iterationWithTooManyPhases) {
        const lastPhaseId = iterationWithTooManyPhases.phaseIds.pop()
        const nextIteration = iterationData
          .find(({ iteration }) => iteration === iterationWithTooManyPhases.iteration + 1)
        if (nextIteration) {
          nextIteration.phaseIds.unshift(lastPhaseId)
        }
      }

      PhaseGroupHandler.createRemainderGroup(process, iterationData, runningIteration)
    }
  }

  static movePhaseToTheEnd(process, src, dest, groupName, sourcePhase) {
    const runningGroup = Process.getRunningGroup(process)
    const targetGroupIsRunning = runningGroup && runningGroup === groupName

    const skippedIterations = targetGroupIsRunning
      ? PhaseGroupParser.getFinishedIterationsCount(process, groupName) + 1
      : 1

    const insertionPoints = PhaseGroupParser
      .getIterationEndPhaseIds(process, groupName).slice(skippedIterations)

    const phaseImmediatelyAfterGroup = src - dest === 1

    if (!phaseImmediatelyAfterGroup) {
      const offset = src < dest ? -1 : 0
      Process.movePhase(process, src, dest + offset)
    }

    PhaseGroupHandler.setGroup(process, sourcePhase.id, groupName)
    PhaseGroupHandler.propagatePhaseIterations(process, groupName, sourcePhase, insertionPoints)

    if (targetGroupIsRunning && skippedIterations > 1) {
      PhaseGroupHandler.updateGroupConfiguration(process, groupName, true)
    }
  }

  static movePhaseToInnerPosition(process, src, dest, groupName, sourcePhase, destPhase, iterationData) {
    const runningGroup = Process.getRunningGroup(process)
    const targetGroupIsRunning = runningGroup && runningGroup === groupName

    const skippedIterations = targetGroupIsRunning
      ? PhaseGroupParser.getFinishedIterationsCount(process, groupName) + 1
      : 1

    const iteration = iterationData.find(({ phaseIds }) => phaseIds.includes(destPhase.id))
    const postionInLoop = iteration.phaseIds.findIndex(aPhaseId => aPhaseId === destPhase.id)
    const insertionPoints = iterationData
      .map(({ phaseIds }) => phaseIds[postionInLoop - 1]).slice(skippedIterations)

    const offset = src < dest ? -1 : 0
    Process.movePhase(process, src, dest + offset)

    PhaseGroupHandler.setGroup(process, sourcePhase.id, groupName)
    PhaseGroupHandler.propagatePhaseIterations(process, groupName, sourcePhase, insertionPoints)

    if (targetGroupIsRunning) {
      PhaseGroupHandler.updateGroupConfiguration(process, groupName, true)
    }
  }

  static movePhaseIntoGroup(process, src, dest, groupName, dropAtStart = false) {
    const phaseSequence = PhaseGroupParser.getPhasesSequence(process)
    const iterationData = PhaseGroupParser.getIterationData(process, groupName)

    const destPhase = phaseSequence[dest]
    const sourcePhase = phaseSequence[src]

    if (dropAtStart) {
      return PhaseGroupHandler.movePhaseToTheStart(process, src, dest, groupName, sourcePhase)
    }

    let iteration

    if (groupName === Process.getRunningGroup(process)) {
      const runningPhase = Process.getRunningPhase(process)
      iteration = iterationData.find(({ phaseIds }) => phaseIds.includes(runningPhase.phaseId))
    } else {
      iteration = iterationData[0]
    }

    const positionOfInsertion = iteration.phaseIds.findIndex(aPhaseId => aPhaseId === destPhase?.id)

    if (positionOfInsertion === -1) {
      return PhaseGroupHandler.movePhaseToTheEnd(process, src, dest, groupName, sourcePhase)
    }

    return PhaseGroupHandler.movePhaseToInnerPosition(
      process, src, dest, groupName, sourcePhase, destPhase, iterationData,
    )
  }

  static movePhaseOutOfGroup(process, src, dest) {
    let updatedProcess = { ...process }
    const phaseSequence = PhaseGroupParser.getPhasesSequence(updatedProcess)
    const sourcePhase = phaseSequence[src]
    const iterationData = PhaseGroupParser.getIterationData(process, sourcePhase.data.groupName)
    const runningGroup = Process.getRunningGroup(process)
    const targetGroupIsRunning = runningGroup && runningGroup === sourcePhase.data.groupName

    if (src !== dest) {
      Process.movePhase(updatedProcess, src, dest)
    }

    PhaseGroupHandler.removePhaseFromGroup(updatedProcess, sourcePhase.id)

    const iteration = iterationData.find(({ phaseIds }) => phaseIds.includes(sourcePhase.id))
    const positionInLoop = iteration.phaseIds.findIndex(aPhaseId => aPhaseId === sourcePhase.id)

    const phaseIterationIds = iterationData
      .map(({ phaseIds }) => phaseIds[positionInLoop])
      .slice(iteration.iteration)

    phaseIterationIds.forEach(aPhaseId => {
      updatedProcess = Process.deletePhase(updatedProcess, aPhaseId).updatedProcess
    })

    if (targetGroupIsRunning && iteration.iteration > 1) {
      const runningPhase = PhaseProgression.getLatest(updatedProcess)
      const { iteration: runningIteration } = iterationData
        .find(({ phaseIds }) => phaseIds.includes(runningPhase.phaseId))

      const getOffset = () => {
        const sameIteration = runningIteration === iteration.iteration
        if (!sameIteration) return 0

        const runningPhasePositionInLoop = iteration.phaseIds
          .findIndex(aPhaseId => aPhaseId === runningPhase.phaseId)
        const runningPhaseBeforeMovedPhase = runningPhasePositionInLoop < positionInLoop
        return runningPhaseBeforeMovedPhase && iteration.iteration === 1 ? 0 : 1
      }

      const includeCurrentIterationOffset = getOffset()
      iteration.phaseIds = iteration.phaseIds.filter(aPhaseId => aPhaseId !== sourcePhase.id)

      PhaseGroupHandler.createRemainderGroup(
        updatedProcess,
        iterationData,
        runningIteration - includeCurrentIterationOffset,
      )
    }

    return updatedProcess
  }

  static movePhaseWithinGroup(process, src, dest) {
    const phaseSequence = PhaseGroupParser.getPhasesSequence(process)
    const destPhase = phaseSequence[dest]
    const sourcePhase = phaseSequence[src]

    const iterationData = PhaseGroupParser.getIterationData(process, sourcePhase.data.groupName)
    const iteration = iterationData.find(({ phaseIds }) => phaseIds.includes(sourcePhase.id))

    const srcPhasePositionInLoop = iteration.phaseIds
      .findIndex(aPhaseId => aPhaseId === sourcePhase.id)
    const destPhasePositionInLoop = iteration.phaseIds
      .findIndex(aPhaseId => aPhaseId === destPhase.id)

    const sourceIterationIdx = iterationData
      .map(({ phaseIds }) => phaseIds[srcPhasePositionInLoop])
      .map(aPhaseId => PhaseGroupParser.getPhaseExecutionIndex(process, aPhaseId))
      .slice(iteration.iteration)

    const destIterationIdx = iterationData
      .map(({ phaseIds }) => phaseIds[destPhasePositionInLoop])
      .map(aPhaseId => PhaseGroupParser.getPhaseExecutionIndex(process, aPhaseId))
      .slice(iteration.iteration)

    Process.movePhase(process, src, dest)

    sourceIterationIdx.forEach((aSourceIdx, idx) => {
      Process.movePhase(process, aSourceIdx, destIterationIdx[idx])
    })

    const runningGroup = Process.getRunningGroup(process)
    const targetGroupIsRunning = runningGroup && runningGroup === sourcePhase.data.groupName

    if (targetGroupIsRunning) {
      PhaseGroupHandler.updateGroupConfiguration(process, sourcePhase.data.groupName)
    }
  }

  static movePhaseFromGroupToGroup(process, src, dest, newGroupName, dropAtStart = false) {
    const phaseSequence = PhaseGroupParser.getPhasesSequence(process)
    const destPhase = phaseSequence[dest]
    const totalPhases = phaseSequence.length
    const updatedProcess = PhaseGroupHandler.movePhaseOutOfGroup(process, src, totalPhases - 1)

    const totalPhasesAfterRemoval = PhaseGroupParser.getPhaseCount(updatedProcess)
    const newDest = destPhase
      ? PhaseGroupParser.getPhaseExecutionIndex(process, destPhase.id)
      : totalPhasesAfterRemoval

    PhaseGroupHandler.movePhaseIntoGroup(
      updatedProcess, totalPhasesAfterRemoval - 1, newDest, newGroupName, dropAtStart,
    )

    return updatedProcess
  }

  static moveGroup(process, src, dest) {
    const phaseSequence = PhaseGroupParser.getPhasesSequence(process)
    const sourcePhase = phaseSequence[src]
    const destPhase = phaseSequence[dest]
    const { data: { groupName } } = sourcePhase
    const preDestPhase = phaseSequence[dest - 1]
    const lastGroupPhaseId = PhaseGroupParser.getLastPhaseIdOfGroup(process, groupName)
    const preGroupPhaseId = PhaseGroupParser.getPreviousPhaseId(process, sourcePhase.id)
    const lastGroupPhase = PhaseGroupParser.getPhaseById(process, lastGroupPhaseId)
    const prevLastGroupPhaseNext = lastGroupPhase.next

    if (preDestPhase) {
      setPhaseProps(process, preDestPhase.id, { next: sourcePhase.id })
    }

    if (!Validator.isUndefinedOrNull(preGroupPhaseId)) {
      setPhaseProps(process, preGroupPhaseId, { next: prevLastGroupPhaseNext })
    }

    const next = destPhase ? destPhase.id : -1
    setPhaseProps(process, lastGroupPhaseId, { next })

    if (dest === 0) {
      Process.setEntryPhaseId(process, sourcePhase.id)
    }

    if (src === 0) {
      Process.setEntryPhaseId(process, prevLastGroupPhaseNext)
    }
  }

  static createBridgeGroup(process, iterationData, currIterationCount) {

    /* create bridge group with a single iteration when running phase has been modified;
     * the logic in applyDescriptionModification will then break the currently running phase
     * into two phases in the same manner it does for non-group running phases
     */

    const { phaseIds } = iterationData.find(({ iteration }) => iteration === currIterationCount)
    const bridgeGroupName = PhaseGroupHandler.generateUniqueGroupname(process)

    phaseIds.forEach(aPhaseId => {
      PhaseGroupHandler.setGroup(process, aPhaseId, bridgeGroupName)
      delete process.description[aPhaseId].iterationOf
    })
  }

  static createRemainderGroup(process, iterationData, currIterationCount) {

    const futureIterations = iterationData
      .filter(({ iteration }) => iteration > currIterationCount)

    if (futureIterations.length === 0) return

    const newGroupName = PhaseGroupHandler.generateUniqueGroupname(process)
    const newOriginalPhaseIds = futureIterations[0].phaseIds

    futureIterations.forEach(({ phaseIds }, iterIdx) => {
      phaseIds.forEach((aPhaseId, phaseIdx) => {
        if (aPhaseId in process.description) {
          PhaseGroupHandler.setGroup(process, aPhaseId, newGroupName)

          if (iterIdx === 0) {
            delete process.description[aPhaseId].iterationOf
            return
          }

          process.description[aPhaseId].iterationOf = newOriginalPhaseIds[phaseIdx]
        }
      })
    })
  }
}

export default PhaseGroupHandler
