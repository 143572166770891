import React from 'react'
import { Divider } from 'semantic-ui-react'

const getColor = state => {
  switch (state) {
    case 'paused':
      return '#ff8c00'
    case 'running':
      return '#1578c2'
    default:
      return '#585858'
  }
}


const ProcessLabel = ({ activeProcess: { name, state } }) => (
  <div style={{
    display: 'inline-block',
    marginLeft: '16px',
  }}>
  <span
    style={{
      fontSize: '1.35em',
      fontWeight: 'bold',
    }}
  >
      {name}
    </span>
    <Divider
      style={{
        margin: '0.5rem 0',
      }} />
    <span
      style={{
        color: '#585858',
      }}
    >
      state:&nbsp;
      <span
        style={{
          color : getColor(state),
          fontWeight: 'bold',
        }}
      >
        {state}
      </span>
    </span>
  </div>
)

export default ProcessLabel
