/* Unit-Converter:
 *  syntax: convertFrom(sourceUnit).to(targetUnit) returns a function
 *  that takes a value as an input and maps it from sourceUnit to
 *  targetUnit. We are using default units for intermediate conversion.
 *
 *  conversions use rounding per default to keep float number short
 *  (in the process we store however as accurate as possible)
 *
 */

import { roundToTheNthDecimal } from 'utils/number'
import UI_CONSTANTS from 'config/ui'


const INVALID_VALUES = [null, undefined, '', false]

const UNIT_TYPES = {
  TEMPERATURE: 'TEMPERATURE',
  ROTATIONAL_SPEED: 'ROTATIONAL_SPEED',
  UNITLESS: 'UNITLESS',
  PERCENTAGE: 'PERCENTAGE',
}

const unitTypeUnitOptions = {
  [UNIT_TYPES.TEMPERATURE]: ['K', '°C', '°F'],
  [UNIT_TYPES.ROTATIONAL_SPEED]: ['rpm'],
  [UNIT_TYPES.UNITLESS]: [UI_CONSTANTS.NO_UNIT_CHAR],
  [UNIT_TYPES.PERCENTAGE]: ['%'],
}

export const unitTypeDisplayText = {
  [UNIT_TYPES.TEMPERATURE]: 'temperature',
  [UNIT_TYPES.ROTATIONAL_SPEED]: 'rotational speed',
  [UNIT_TYPES.UNITLESS]: 'unitless',
  [UNIT_TYPES.PERCENTAGE]: 'percentage',
}

export function unitToUnitType(unit) {
  switch (true) {
    case (unitTypeUnitOptions[UNIT_TYPES.ROTATIONAL_SPEED].includes(unit)):
      return UNIT_TYPES.ROTATIONAL_SPEED
    case (unitTypeUnitOptions[UNIT_TYPES.TEMPERATURE].includes(unit)):
      return UNIT_TYPES.TEMPERATURE
    case (unitTypeUnitOptions[UNIT_TYPES.UNITLESS].includes(unit)):
      return UNIT_TYPES.UNITLESS
    case (unitTypeUnitOptions[UNIT_TYPES.PERCENTAGE].includes(unit)):
      return UNIT_TYPES.PERCENTAGE
    default:
      return undefined
  }
}

export const getUnitOptions = sourceUnit => {
  const unitType = unitToUnitType(sourceUnit)

  if (!unitType) {
    return [ sourceUnit ]
  }

  const possibleUnits = unitTypeUnitOptions[unitType]

  if (!possibleUnits) {
    return [ sourceUnit ]
  }

  return possibleUnits
}

const DEFAULT_UNITS = {
  [UNIT_TYPES.TEMPERATURE]: 'K',
  [UNIT_TYPES.ROTATIONAL_SPEED]: 'RPM',
  [UNIT_TYPES.UNITLESS]: UI_CONSTANTS.NO_UNIT_CHAR,
  [UNIT_TYPES.PERCENTAGE]: '%',
}

const unitToDefault = {
  '°C': value => 273.15 + value,
  K: value => value,
  '°F': value => (value - 32) / 1.8 + 273.15,
  [UI_CONSTANTS.NO_UNIT_CHAR]: value => value,
  '%': value => value,
  rpm: value => value,
}

const defaultToUnit = {
  '°C': value => value - 273.15,
  K: value => value,
  '°F': value => (value - 273.15) * 1.8 + 32,
  [UI_CONSTANTS.NO_UNIT_CHAR]: value => value,
  '%': value => value,
  rpm: value => value,
}

const createNoConversionFunction = round => (
  value => {
    if (Number.isNaN(Number(value)) || !round) return value
    return roundToTheNthDecimal(value, UI_CONSTANTS.NUMBER_INPUT_DECIMALS)
  }
)

const createFromNonDefaultUnitConversionFunction = (sourceUnit, targetUnit, round) => (
  value => {
    if (INVALID_VALUES.includes(value)) return value
    if (round) {
      return roundToTheNthDecimal(
        defaultToUnit[targetUnit](unitToDefault[sourceUnit](value)),
        UI_CONSTANTS.NUMBER_INPUT_DECIMALS,
      )
    }
    return defaultToUnit[targetUnit](unitToDefault[sourceUnit](value))
  }
)

const createFromDefaultUnitConversionFunction = (targetUnit, round) => (
  value => {
    if (INVALID_VALUES.includes(value)) return value
    if (round) {
      return roundToTheNthDecimal(
        defaultToUnit[targetUnit](value),
        UI_CONSTANTS.NUMBER_INPUT_DECIMALS,
      )
    }
    return defaultToUnit[targetUnit](value)
  }
)

export const convertFrom = (sourceUnit, { round = true } = {}) => (
  {
    to: targetUnit => {

      const unitType = unitToUnitType(sourceUnit)
      const targetUnitType = unitToUnitType(targetUnit)
      // if we can't find the unit type from the unit, dont convert

      if (!targetUnitType) {
        return createNoConversionFunction(round)
      }

      if (!unitType) {
        return createNoConversionFunction(round)
      }

      if (sourceUnit === targetUnit) {
        return createNoConversionFunction(round)
      }

      if (sourceUnit !== DEFAULT_UNITS[unitType]) {
        return createFromNonDefaultUnitConversionFunction(sourceUnit, targetUnit, round)
      }

      return createFromDefaultUnitConversionFunction(targetUnit, round)
    },
  }
)

export const convertMany = (sourceUnit, targetUnit, values) => {
  const converter = convertFrom(sourceUnit).to(targetUnit)
  return values.map(converter)
}

export const convertManyFrom = (sourceUnit, { round = true } = {}) => (
  {
    to: targetUnit => {
      const converter = convertFrom(sourceUnit, { round }).to(targetUnit)
      return values => (values.map(converter))
    },
  }
)

export const replaceNoUnitCharacter = (unit, replacement) => (
  unit === UI_CONSTANTS.NO_UNIT_CHAR ? replacement : unit
)

export const applyUnitConversionOnDataPoints = (dataPoints, converter) => (
  dataPoints.map(p => ({
    ...p,
    y: converter(p.y),
  }))
)

export const applyUnitConversionOnData = (data, converter) => {
  data.forEach(dataSlice => {
    dataSlice.dataPoints = applyUnitConversionOnDataPoints(dataSlice.dataPoints, converter)
  })
}

export const hasUnit = unit => unitToUnitType(unit) !== undefined
&& unitToUnitType(unit) !== UNIT_TYPES.UNITLESS
