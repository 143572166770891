import React from 'react'
import { connect } from 'react-redux'
import { Button, Popup, Grid, Icon } from 'semantic-ui-react'
import StopProcessModal from 'components/processviewer/modals/StopProcessModal'
import PauseProcessModal from 'components/processviewer/modals/PauseProcessModal'
import NextPhaseModal from 'components/processviewer/modals/NextPhaseModal'
import callResumeProcess from 'redux/thunks/command/callResumeProcess'
import { updateDevice } from 'redux/actions/actions'
import { Device } from 'utils/device'
import ProcessLabel from './ProcessLabel'

const mapDispatchToProps = dispatch => ({
  callResumeProcess: (processId, deviceId) => dispatch(callResumeProcess(processId, deviceId)),
  updateDevice: (deviceId, params) => dispatch(updateDevice({ deviceId, params: { ...params } })),
})

class ProcessViewerHeader extends React.Component {

  state = {
    showNextPhaseModal: false,
    showStopProcessModal: false,
    showPauseProcessModal: false,
  }

  toggleModal(modalTrigger) {
    this.setState(prevState => ({ [modalTrigger]: !prevState[modalTrigger] }))
  }

  async resumeProcessHandler() {
    if (this.props.activeDevice.readyForCommand) {
      this.props.updateDevice(this.props.activeDevice.id, { readyForCommand: false })
      await this.props.callResumeProcess(this.props.activeProcess.id, this.props.activeProcess.deviceId)
    }
  }

  render() {

    const { showNextPhaseModal, showStopProcessModal, showPauseProcessModal } = this.state
    const { activeProcess, activeDevice } = this.props

    return (
      <Grid>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column width={9}>
            <ProcessLabel activeProcess={activeProcess} />
          </Grid.Column>
          <Grid.Column width={4}>
            <div className='float-right'>
              {activeProcess.state !== 'finished' && (
                <Popup
                  trigger={(
                    <Button
                      color='red'
                      size='small'
                      icon
                      labelPosition='left'
                      onClick={() => this.toggleModal('showStopProcessModal')}
                      style={{ marginRight: '16px' }}
                      disabled={Device.hasInconsistentState(activeDevice)}
                    >
                      <Icon name='stop' />
                      Stop
                    </Button>
                  )}
                  content='stop process'
                  position='bottom center'
                />
              )}
              {activeProcess.state === 'paused'
                ? (
                  <Button.Group size='small'>
                    <Button
                      icon='play'
                      onClick={() => this.resumeProcessHandler()}
                      disabled={Device.hasInconsistentState(activeDevice)}
                    />
                  </Button.Group>
                )
                : <></>}
              {activeProcess.state === 'running'
                ? (
                  <Button.Group size='small'>
                    <Popup
                      trigger={(
                        <Button
                          icon='pause'
                          onClick={() => this.toggleModal('showPauseProcessModal')}
                          disabled={Device.hasInconsistentState(activeDevice)}
                        />
                      )}
                      content='pause process'
                      position='bottom center'
                    />
                    <Popup
                      trigger={(
                        <Button
                          icon='step forward'
                          onClick={() => this.toggleModal('showNextPhaseModal')}
                          disabled={Device.hasInconsistentState(activeDevice)}
                        />
                      )}
                      content='go to next phase'
                      position='bottom center'
                    />
                  </Button.Group>
                )
                : <></>}
            </div>
          </Grid.Column>
        </Grid.Row>
        <StopProcessModal
          open={showStopProcessModal}
          activeProcess={this.props.activeProcess}
          closeHandler={() => this.toggleModal('showStopProcessModal')}
          headerIcon='stop'
          headerText='Stop process'
        />
        <PauseProcessModal
          open={showPauseProcessModal}
          activeProcess={this.props.activeProcess}
          closeHandler={() => this.toggleModal('showPauseProcessModal')}
          activeDevice={this.props.activeDevice}
          headerIcon='pause'
          headerText='Pause process'
        />
        <NextPhaseModal
          open={showNextPhaseModal}
          activeProcess={this.props.activeProcess}
          closeHandler={() => this.toggleModal('showNextPhaseModal')}
          headerIcon='angle double right'
          headerText='Go to next phase'
        />
      </Grid>
    )
  }
}

export default connect(null, mapDispatchToProps)(ProcessViewerHeader)
