import React from 'react'
import formalize from 'components/utility/modal/formalize'
import { Form, Checkbox } from 'semantic-ui-react'

const DeleteGroupModalForm = props => {
  const {
    toggleKeepOriginalPhases,
    toggleOriginalPhases,
    isContainingAllProcessPhases,
  } = props

  return (
    <>
      <Form.Field>
        <Checkbox
          radio
          label='Delete only the cycles, disbanding the group'
          name='checkboxRadioGroup'
          checked={toggleOriginalPhases === true}
          onChange={() => toggleKeepOriginalPhases(true)}
        />
      </Form.Field>
      <Form.Field style={{ margin: '0px auto 15px', width: '100%' }}>
        <Checkbox
          radio
          label='Delete the group and its phases'
          name='checkboxRadioGroup'
          disabled={isContainingAllProcessPhases === true}
          checked={toggleOriginalPhases === false}
          onChange={() => toggleKeepOriginalPhases(false)}
        />
      </Form.Field>
    </>
  )
}

export default formalize(DeleteGroupModalForm)
