import nexus from 'nexus'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callStartProcess = async (_, process, elapsedTime, update, entryPhaseId) => {
  const startParams = {
    deviceId: process.deviceId,
    entryPhaseId,
    elapsedTime,
    update,
  }
  await nexus.command.startProcess(process.id, startParams)
}

export default thunkWrapper(callStartProcess)
