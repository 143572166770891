import React from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Message } from 'semantic-ui-react'
import UIConfig from 'utils/UIConfig/UIConfig'
import Streams from 'utils/Streams'
import PhaseGroupRenderer from 'utils/render/PhaseGroupRenderer'
import ProcessRectifier from 'utils/process/ProcessRectifier'
import DeviceConfig from 'utils/DeviceConfig'

import ProcessViewerBody from 'components/processviewer/body/ProcessViewerBody'
import { Process, EXECUTED_PROCESS_STATES } from 'utils/process'
import ProcessValidator from 'utils/validation/ProcessValidator'
import callLoadProcessDataSummaryStreamwise from 'redux/thunks/process/callLoadProcessDataSummaryStreamwise'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import { updateProcess, loadProcessViewer } from 'redux/actions/actions'
import PhasesNavbarWrapper from './phasesdisplaybar/PhasesNavbarWrapper'
import ProcessBuilderHeader from './headers/ProcessBuilderHeader'
import DescriptionMismatchModal from './modals/DescriptionMismatchModal'
import ProcessViewerHeader from './headers/ProcessViewerHeader'
import ChangesFooter from './body/builder/ChangesFooter'

const mapStateToProps = state => ({
  processBuilderValidationErrors: state.processBuilderValidationErrors,
  logs: state.logs,
  userId: state.user.id,
})

const mapDispatchToProps = dispatch => ({
  callUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
  updateProcess: (processId, params) => dispatch(updateProcess({ processId, params })),
  callLoadProcessDataSummaryStreamwise: (processId, streamIds) => dispatch(callLoadProcessDataSummaryStreamwise(processId, streamIds)),
  loadProcessViewer: process => dispatch(loadProcessViewer(process)),
})

class ProcessViewer extends React.Component {

  state = {
    showDescriptionMismatchModal: false,
    missMatches: {},
    amendingProcess: false,
    amendingError: false,
    amendingErrorMessage: '',
  }

  async componentDidMount() {

    const { activeProcess, activeDevice, userId } = this.props

    const processUpStreams = Streams.getUpstreams(activeProcess.deviceDescription)
    const processDownStreams = Streams.getDownstreams(activeProcess.deviceDescription)
    const { uIConfig } = activeDevice

    UIConfig.updateProcessBuilderAndGraphsConfig(
      uIConfig, processUpStreams, processDownStreams, userId, activeDevice.id,
    )

    this.props.loadProcessViewer(activeProcess)

    if (activeProcess.state === 'executable') {
      const missMatchStreams = ProcessValidator.getMismatchingStreams(activeProcess, activeDevice)

      if (missMatchStreams.missingInDevice.length || missMatchStreams.missingInProcess.length) {
        this.setState({ showDescriptionMismatchModal: true, missMatches: missMatchStreams })
      }
    } else if (activeProcess.state in EXECUTED_PROCESS_STATES) {
      const upStreamIds = processUpStreams.map(stream => stream.id)
      await this.props.callLoadProcessDataSummaryStreamwise(activeProcess.id, upStreamIds)
    }
  }

  renderChangesFooter = () => {
    const { activeProcess, activeDevice } = this.props

    const processState = Process.deriveProcessState(activeProcess, activeDevice)
    if (processState !== 'running' && processState !== 'paused') return null

    const phases = PhaseGroupRenderer.getGroupAdjustedPhaseSequence(activeProcess)
    return (
      <Grid.Column style={{ marginTop: '5px', marginRight: '1rem' }} width={16}>
        <ChangesFooter
          activeProcess={activeProcess}
          activeDevice={activeDevice}
          phases={phases}
        />
      </Grid.Column>
    )
  }

  renderProcessViewerHeader = () => {
    if (this.props.activeProcess.state !== 'executable') {
      return (
        <ProcessViewerHeader
          activeProcess={this.props.activeProcess}
          activeDevice={this.props.activeDevice}
        />
      )
    }
    return (
      <ProcessBuilderHeader
        activeProcess={this.props.activeProcess}
        activeDevice={this.props.activeDevice}
      />
    )
  }

  renderPhasesSideBar = () => {

    const { activeProcess, activeDevice, moveProcessPhase } = this.props

    return (
      <PhasesNavbarWrapper
        activeProcess={activeProcess}
        activeDevice={activeDevice}
        phases={PhaseGroupRenderer.getGroupAdjustedPhaseSequence(activeProcess)}
        movePhaseHandler={moveProcessPhase}
        runningPhase={Process.getRunningPhase(activeProcess)}
      />
    )
  }

  toggleModal = modalTrigger => {
    this.setState(prevState => ({ [modalTrigger]: !prevState[modalTrigger] }))
  }

  mapErrorsToString = error => `${error.type} ${error.phaseName ? `in phase '${error.phaseName}'` : ''}: ${error.message}`

  amendDescriptionMisMatch = async () => {
    const { activeProcess, activeDevice, callUpdateProcess, updateProcess } = this.props
    this.setState({ amendingProcess: true })

    const missMatchStreams = ProcessValidator.getMismatchingStreams(activeProcess, activeDevice)

    const amendedProcess = ProcessRectifier
      .amendDescriptionMismatch(activeProcess, activeDevice, missMatchStreams)

    const params = {
      description: amendedProcess.description,
      deviceDescription: amendedProcess.deviceDescription,
      eventTriggers: DeviceConfig.getStreamEventTriggersForProcess(activeDevice),
    }

    updateProcess(activeProcess.id, { data: amendedProcess.data })

    const res = await callUpdateProcess(activeProcess.id, params)
    if (res.success) {
      this.toggleModal('showDescriptionMismatchModal')
      this.setState({ amendingProcess: false })
    } else {
      this.setState({
        amendingError: true,
        amendingErrorMessage: res.message,
        amendingProcess: false,
      })
    }
  }

  render() {

    const {
      processBuilderValidationErrors,
      logs,
      activeProcess,
      activeDevice,
    } = this.props

    const {
      showDescriptionMismatchModal,
      missMatches,
      amendingError,
      amendingErrorMessage,
      amendingProcess,
    } = this.state

    const { deviceDescription } = activeProcess
    const errorDisplay = processBuilderValidationErrors.map(this.mapErrorsToString)

    return (
      <Grid style={{ minWidth: '750px' }}>
        <Grid.Row>
          <Container fluid>
            {processBuilderValidationErrors.length > 0 && (
              <Message error>
                <Message.Header>
                  Process could not be dispatched.
                </Message.Header>
                <Message.List items={errorDisplay} />
              </Message>
            )}
          </Container>
        </Grid.Row>
        <Grid.Row>
          <Container fluid>
            {this.renderProcessViewerHeader()}
          </Container>
          {this.renderChangesFooter()}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={13}>
            <ProcessViewerBody
              processDownStreams={Streams.getDownstreamsWrapper(activeProcess, 'process', 'ProcessViewer.jsx')}
              processUpStreams={Streams.getUpstreams(deviceDescription)}
              phases={PhaseGroupRenderer.getGroupAdjustedPhaseSequence(activeProcess)}
              activeProcess={activeProcess}
              activeDevice={activeDevice}
              logs={logs}
            />
          </Grid.Column>
          <Grid.Column width={3}>
            {this.renderPhasesSideBar()}
          </Grid.Column>
          <Grid.Column width={1} />
        </Grid.Row>
        {showDescriptionMismatchModal && (
          <DescriptionMismatchModal
            open
            closeHandler={() => this.toggleModal('showDescriptionMismatchModal')}
            headerIcon='warning'
            headerText='Process description does not match the device'
            missMatches={missMatches}
            activeDevice={activeDevice}
            amendDescriptionMisMatchHandler={this.amendDescriptionMisMatch}
            amendingError={amendingError}
            amendingErrorMessage={amendingErrorMessage}
            amendingProcess={amendingProcess}
          />
        )}
      </Grid>
    )
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ProcessViewer)
