import React from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Icon, Input, Divider, Button, Message, Checkbox, Select } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'
import { convertFrom, hasUnit } from 'utils/units'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'
import callSetStreamDefault from 'redux/thunks/device/callSetStreamDefault'

const mapDispatchToProps = dispatch => ({
  dispatchCallSetStreamDefault: (streamId, deviceId, value) => dispatch(
    callSetStreamDefault(streamId, deviceId, value),
  ),
})

class SetStreamDefaultModal extends React.Component {

  initialState = {
    isError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    streamDefault: '',
    targetStreamId: null,
  }

  state = { ...this.initialState }

  handleUpdate = (e, { name, value }) => this.setState({
    [name]: value,
    isError: false,
    errorMessage: '',
    isSuccess: false,
  })

  handleAPIResponse = res => {
    if (res.success) {
      this.setState({
        isSuccess: true,
        isError: false,
        errorMessage: '',
        isLoading: false,
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: res.message,
        isSuccess: false,
        isLoading: false,
      })
    }
  }

  getStreamOptions = () => {
    const { activeDevice } = this.props

    const displayedStreams = DeviceConfig.getConfiguredStreams(activeDevice)

    return displayedStreams
      .filter(stream => stream.type === 'downstream')
      .map(stream => ({
        value: stream.id,
        text: stream.displayedName,
        key: stream.id,
      }))
  }

  getFloatValue = (streamDefault, activeDevice, targetStreamId, targetStream) => {
    const parsedTarget = parseFloat(streamDefault)

    if (isNaN(parsedTarget)) {
      this.setState({ isError: true, errorMessage: 'Default value has to be a number.' })
      return
    }

    const { displayedUnit, unit } = DeviceConfig
      .getConfiguredStreamById(activeDevice, targetStreamId)

    const customConversion = CustomConversions
      .getCustomConversionsByTargetUnit(activeDevice, targetStreamId, displayedUnit)

    const convertedDefaultTarget = customConversion
      ? CustomConversions.revertWithRatio(parsedTarget, customConversion)
      : convertFrom(displayedUnit, { round: false }).to(unit)(parsedTarget)

    if (convertedDefaultTarget > targetStream.max || convertedDefaultTarget < targetStream.min) {
      this.setState({ isError: true, errorMessage: 'Default target not within valid stream limits.' })
      return
    }
    return convertedDefaultTarget
  }

  getOneOfValue = (streamDefault, targetStream) => {
    if (!targetStream.expectedValueOptions.includes(streamDefault)) {
      this.setState({ isError: true, errorMessage: 'Default target not within acceped values.' })
      return
    }
    return streamDefault
  }

  submitNewDefault = async () => {

    const { targetStreamId, streamDefault } = this.state
    const { activeDevice, dispatchCallSetStreamDefault } = this.props
    const targetStream = activeDevice.streams.find(stream => stream.id === targetStreamId)

    const convertedDefaultTarget = targetStream.expectedValueType === 'oneOf'
      ? this.getOneOfValue(streamDefault, targetStream)
      : this.getFloatValue(streamDefault, activeDevice, targetStreamId, targetStream)

    if (convertedDefaultTarget === undefined) return

    this.setState({ isLoading: true })

    const res = await dispatchCallSetStreamDefault(
      targetStreamId, activeDevice.id, convertedDefaultTarget,
    )

    this.handleAPIResponse(res)
  }

  submitBooleanDefault = async newState => {
    const { targetStreamId } = this.state
    const { activeDevice, dispatchCallSetStreamDefault } = this.props

    const res = await dispatchCallSetStreamDefault(targetStreamId, activeDevice.id, newState)
    this.handleAPIResponse(res)
  }

  clearStateAndClose = () => {
    const { closeHandler } = this.props
    this.setState(this.initialState)
    closeHandler()
  }

  renderBooleanStream = configuredStream => {

    const { targetDefault } = configuredStream

    return (
      <Form.Field>
        <label>
          Current Default
        </label>
        <Checkbox
          toggle
          checked={targetDefault}
          onChange={() => this.submitBooleanDefault(!targetDefault)}
          style={{ marginTop: '8px' }}
        />
      </Form.Field>
    )
  }

  getStreamValueOptions = configuredStream => {
    const { activeDevice } = this.props
    const { unit, expectedValueOptions, displayedUnit, id } = configuredStream

    return expectedValueOptions.map(value => {
      const customConversion = CustomConversions
        .getCustomConversionsByTargetUnit(activeDevice, id, displayedUnit)

      return {
        value,
        text: customConversion
          ? CustomConversions.convertWithRatio(value, customConversion)
          : convertFrom(unit).to(displayedUnit)(value),
      }
    })
  }

  renderOneOfStream = configuredStream => {
    const { streamDefault } = this.state
    const { unit, displayedUnit, targetDefault } = configuredStream
    const streamValueIsConvertible = hasUnit(unit)
    const options = this.getStreamValueOptions(configuredStream)

    return (
      <>
        <Form.Field>
          <label>
            Current Default
            {streamValueIsConvertible ? `[${displayedUnit}]` : ''}
          </label>
          <Select
            value={configuredStream ? targetDefault : ''}
            readOnly
            open={false}
            options={options}
            required
          />
        </Form.Field>
        <Form.Field>
          <label>
            New Default
            {streamValueIsConvertible ? ` [${displayedUnit}]` : ''}
          </label>
          <Select
            value={streamDefault}
            options={options}
            closeOnChange
            required
            onChange={this.handleUpdate}
            name='streamDefault'
          />
        </Form.Field>
      </>
    )
  }

  renderFloatStream = configuredStream => {

    const { streamDefault } = this.state
    const { activeDevice } = this.props

    const { unit, displayedUnit, targetDefault, id } = configuredStream

    const customConversion = CustomConversions
      .getCustomConversionsByTargetUnit(activeDevice, id, displayedUnit)

    const displayedCustomDefault = customConversion
      ? CustomConversions.convertWithRatio(targetDefault, customConversion)
      : convertFrom(unit).to(displayedUnit)(targetDefault)

    return (
      <>
        <Form.Field>
          <label>
            Current Default
            {` [${displayedUnit}]`}
          </label>
          <Input
            fluid
            type='text'
            value={configuredStream ? displayedCustomDefault : 'None'}
            readOnly
          />
        </Form.Field>
        <Form.Field>
          <label>
            New Default
            {` [${displayedUnit}]`}
          </label>
          <Input
            fluid
            name='streamDefault'
            type='number'
            required
            value={streamDefault}
            onChange={this.handleUpdate}
          />
        </Form.Field>
      </>
    )
  }

  renderStreamByExpectedValueType = configuredStream => {
    if (!configuredStream) return null

    switch (configuredStream.expectedValueType) {
      case 'boolean': {
        return this.renderBooleanStream(configuredStream)
      }
      case 'oneOf': {
        return this.renderOneOfStream(configuredStream)
      }
      default: {
        return this.renderFloatStream(configuredStream)
      }
    }
  }

  showSubmitButton = configuredStream => (
    configuredStream && configuredStream.expectedValueType !== 'boolean'
  )

  render() {

    const {
      isError,
      errorMessage,
      isLoading,
      isSuccess,
      targetStreamId,
    } = this.state

    const { activeDevice, open } = this.props

    const configuredStream = targetStreamId !== null
      ? DeviceConfig.getConfiguredStreamById(activeDevice, targetStreamId)
      : null

    const streamOptions = this.getStreamOptions()

    return (
      <Modal
        size='tiny'
        open={open}
        onClose={this.clearStateAndClose}
        onClick={event => event.stopPropagation()}
      >
        <Modal.Header>
          <Icon name='edit' />
          Set Stream Default
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              error={isError}
              success={isSuccess}
              onSubmit={this.submitNewDefault}
              style={{ marginBottom: '15px', paddingBottom: '15px' }}
            >
              <Form.Group widths='equal'>
                <Form.Select
                  fluid
                  name='targetStreamId'
                  label='Stream'
                  value={targetStreamId}
                  options={streamOptions}
                  onChange={this.handleUpdate}
                  placeholder='Choose Stream'
                />
              </Form.Group>
              <Form.Group widths='equal'>
                {this.renderStreamByExpectedValueType(configuredStream)}
              </Form.Group>
              <Message
                error
                header='Something went wrong.'
                content={errorMessage}
                icon='warning circle'
              />
              <Message
                success
                header='Success!'
                content={<p>Default was set.</p>}
                icon='warning circle'
              />
              <Divider />
              {this.showSubmitButton(configuredStream) && (
                <Button
                  floated='right'
                  primary
                  type='submit'
                  loading={isLoading}
                >
                  Submit
                </Button>
              )}
              <Button
                floated='right'
                onClick={this.clearStateAndClose}
              >
                Close
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(null, mapDispatchToProps)(SetStreamDefaultModal)
