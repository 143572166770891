import React from 'react'
import { connect } from 'react-redux'
import { loadProcessViewer } from 'redux/actions/actions'
import callCreateProcess from 'redux/thunks/process/callCreateProcess'
import modalize from 'components/utility/modal/modalize'
import DemoEndModalForm from 'components/device/modals/DemoEndModalForm'

const mapDispatchToProps = dispatch => ({
  dispatchCallCreateProcess: (deviceId, processParams) => dispatch(callCreateProcess(deviceId, processParams)),
  dispatchLoadProcessViewer: process => dispatch(loadProcessViewer(process)),
})

class DemoEndModal extends React.Component {

  goToSignUp = async () => {
    window.open('https://ospin-app.com/signup')
  }

  render() {
    const { closeHandler } = this.props
    return (
      <>
        <DemoEndModalForm goToSignUp={this.goToSignUp} closeHandler={closeHandler} />
      </>
    )
  }
}

export default connect(null, mapDispatchToProps)(modalize(DemoEndModal))
