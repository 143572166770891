import nexus from 'nexus'
import { modifyProcessAccess } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callModifyProcessAccess = async (dispatch, processId, userId, oldGroup, newGroup) => {
  await nexus.process.modifyAccess(processId, { userId, oldGroup, newGroup })
  dispatch(modifyProcessAccess({
    processId,
    userId,
    oldGroup,
    newGroup,
  }))
}

export default thunkWrapper(callModifyProcessAccess)
