import React from 'react'
import { Form, Checkbox } from 'semantic-ui-react'


const BooleanStream = ({ stream, streamTarget, disabledInput, updateStreamTarget }) => (
  <div>
    <span style={{ fontWeight: 'bold' }}>
      {stream.name}
    </span>
    <Form.Group>
      <Form.Field>
        <Checkbox
          toggle
          checked={streamTarget}
          onChange={() => updateStreamTarget(stream.id, !streamTarget)}
          readOnly={disabledInput}
          disabled={disabledInput}
          style={{ marginTop: '8px' }}
        />
      </Form.Field>
    </Form.Group>
  </div>
)

export default BooleanStream
