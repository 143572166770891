import { logAndExtractError, extractStatusCode } from 'utils/error'

const responseWrapper = (fn, dispatch) => async (...args) => {
  try {
    await fn(dispatch, ...args)
    return { success: true, message: 'OK.', status: 200 }
  } catch (e) {
    return { success: false, message: logAndExtractError(e), status: extractStatusCode(e) }
  }
}

export default fn => (...args) => async dispatch => (
  responseWrapper(fn, dispatch)(...args)
)
