import nexus from 'nexus'
import { setActiveDevice } from 'redux/actions/actions'
import UIConfig from 'utils/UIConfig/UIConfig'
import { createDeviceObject } from 'redux/helper/objectMapper'
import thunkWrapper from 'redux/thunks/thunkWrapper'
import Streams from 'utils/Streams'

const callLoadDevice = async (dispatch, loadedDevice, userId, deviceId) => {
  let device
  if (loadedDevice) {
    device = loadedDevice
  } else {
    const deviceData = await nexus.user.getUserDevice(userId, deviceId)
    device = createDeviceObject(deviceData)
  }

  const deviceUsers = await nexus.device.getUsers(device.id)
  const processesData = await nexus.device.getProcesses({ deviceId })
  dispatch(setActiveDevice({ device, deviceUsers, processesData }))

  const deviceUpStreams = Streams.getUpstreams(device.streams)
  const deviceDownStreams = Streams.getDownstreams(device.streams)
  if (!UIConfig.hasProcessBuilder(device.uIConfig)) {
    UIConfig.createProcessBuilder(device.uIConfig)
  }
  UIConfig.updateProcessBuilderAndGraphsConfig(
    device.uIConfig, deviceUpStreams, deviceDownStreams, userId, deviceId,
  )
}

export default thunkWrapper(callLoadDevice)
