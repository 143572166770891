import signIn from './signIn';
import signInDemo from './signInDemo';
import signUpDemo from './signUpDemo';
import signOut from './signOut';
import signUp from './signUp';
import changePassword from './changePassword';
import currentSession from './currentSession';
import confirmUser from './confirmUser';
import forgotPasswordConfirm from './forgotPasswordConfirm';
import forgotPasswordRequest from './forgotPasswordRequest';

export default {
  signIn,
  signOut,
  signUp,
  changePassword,
  confirmUser,
  currentSession,
  forgotPasswordConfirm,
  forgotPasswordRequest,
  signUpDemo,
  signInDemo
};
