import create from './create'
import remove from './remove'
import list from './list'
import get from './get'
import update from './update'
import { subscribe, resetIotProvider, setIotConfig } from './subscribe'
import getUsers from './getUsers'
import grantAccess from './grantAccess'
import revokeAccess from './revokeAccess'
import modifyAccess from './modifyAccess'
import transferOwnership from './transferOwnership'
import setCalibration from './setCalibration'
import deleteCalibration from './deleteCalibration'
import setStreamDefault from './setStreamDefault'
import deleteStreamDefault from './deleteStreamDefault'
import setStreamNameAlias from './setStreamNameAlias'
import deleteStreamNameAlias from './deleteStreamNameAlias'
import getProcesses from './getProcesses'

export default {
  create,
  remove,
  list,
  get,
  getProcesses,
  update,
  subscribe,
  resetIotProvider,
  setIotConfig,
  getUsers,
  grantAccess,
  revokeAccess,
  modifyAccess,
  transferOwnership,
  setCalibration,
  deleteCalibration,
  setStreamDefault,
  deleteStreamDefault,
  setStreamNameAlias,
  deleteStreamNameAlias,
}
