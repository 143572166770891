import StreamsUIConfig from 'utils/UIConfig/StreamsUIConfig'
import ArrayTools from 'utils/ArrayTools'
import Streams from 'utils/Streams'

export default class StreamGroups {

  static findGroupByName(groups, groupName) {
    return groups.find(group => group.name === groupName)
  }

  static toggleGroupFolded(groups, groupName) {
    const targetGroup = this.findGroupByName(groups, groupName)
    targetGroup.folded = !targetGroup.folded

    return groups
  }

  static toggleGroupStreamFolded(groups, groupName, streamId) {
    const group = this.findGroupByName(groups, groupName)
    const { streams } = group

    group.streams = StreamsUIConfig.toggleFolded(streams, streamId)
    return groups
  }

  static foldAll(groups) {
    groups.forEach(group => {
      group.folded = true
      group.streams.forEach(stream => {
        stream.folded = true
      })
    })
    return groups
  }

  static moveGroup(groups, sourceIndex, destinationIndex) {
    const updatedGroups = ArrayTools.moveFromTo(groups, sourceIndex, destinationIndex)
    return updatedGroups
  }

  static moveStream(groups, groupName, sourceIndex, destinationIndex) {
    const group = groups.find(group => group.name === groupName)
    group.streams = StreamsUIConfig.moveStream(group, sourceIndex, destinationIndex)
    return groups
  }

  static appendStreamToGroup(group, uIStream) {
    const hasStream = group.streams.some(stream => stream.streamId === uIStream.streamId)
    if (!hasStream) {
      group.streams.push(uIStream)
    }
  }

  static createNewStreamGroup(groupName, streamUI) {
    return {
      name: groupName,
      folded: false,
      streams: [streamUI],
    }
  }

  static addNewStreams(groups, streams) {
    streams.forEach(({ groupName, id }) => {
      const uIStream = { streamId: id, folded: false }
      const group = groups.find(grp => grp.name === groupName)

      if (group) {
        this.appendStreamToGroup(group, uIStream)
      } else {
        const newGroup = this.createNewStreamGroup(groupName, uIStream)
        groups.push(newGroup)
      }
    })
    return groups
  }

  static checkForNewStreams(groups, streams) {
    return streams.some(stream => {
      const uiStreamGroup = groups.find(group => stream.groupName === group.name)
      if (!uiStreamGroup) return true
      return !uiStreamGroup.streams.find(uiStream => uiStream.streamId === stream.id)
    })
  }

  static isGroupEmptyOnActiveProcess(group, activeProcess) {
    return group.streams
      .map(stream => Streams.getById(activeProcess.deviceDescription, stream.streamId))
      .every(stream => stream === undefined || stream.groupName !== group.name)
  }

  static getStreamByIdAndGroupNameOnActiveProcess(activeProcess, streamId, groupName) {
    const stream = Streams.getById(activeProcess.deviceDescription, streamId)
    if (!stream || stream.groupName !== groupName) return null
    return stream
  }

}
