import startProcess from './startProcess'
import resumeProcess from './resumeProcess'
import endProcess from './endProcess'
import pauseProcess from './pauseProcess'
import nextPhase from './nextPhase'
import openSsh from './openSsh'

export default {
  startProcess,
  nextPhase,
  endProcess,
  resumeProcess,
  pauseProcess,
  openSsh,
}
