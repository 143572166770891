export default class ArrayTools {

  static moveFromTo(array, fromIndex, toIndex) {

    if (fromIndex === toIndex || fromIndex === undefined || toIndex === undefined) return array

    const returnedArray = [...array]

    const returnedValues = returnedArray.splice(fromIndex, 1)
    returnedArray.splice(toIndex, 0, returnedValues[0])

    return returnedArray
  }
}
