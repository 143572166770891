import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Segment, Menu } from 'semantic-ui-react'

import Logs from 'utils/Logs'
import LogView from 'components/log/LogView'
import StreamEventTriggerTable from 'components/streamEventTrigger/StreamEventTriggerTable'
import Validator from 'utils/validation/Validator'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import PhaseProgression from 'utils/process/PhaseProgression'
import DescriptionParser from 'utils/process/DescriptionParser'
import { getFirstPhaseId } from 'utils/process'
import { setDisplayedProcessPhase } from 'redux/actions/actions'
import DataGraphs from './dataviewer/DataGraphs'
import ProcessConfiguration from './processconfiguration/ProcessConfiguration'
import Builder from './builder/Builder'
import OutdatedProcessInfoBanner from './OutdatedProcessInfoBanner'


const mapStateToProps = ({ displayedPhaseId }) => ({ displayedPhaseId })

const mapDispatchToProps = dispatch => ({
  dispatchSetDisplayedProcessPhase: phaseId => dispatch(
    setDisplayedProcessPhase(phaseId),
  ),
})

const TABS = [ 'Builder', 'Data Viewer', 'Configuration', 'Process Logs', 'Alerts' ]

const renderMenuItem = (tab, key, activeTab, setActiveTab) => (
  <Menu.Item
    key={key}
    name={tab}
    active={activeTab === tab}
    onClick={() => setActiveTab(tab)}
  />
)

const ProcessViewerBody = ({
  activeProcess,
  phases,
  activeDevice,
  processDownStreams,
  processUpStreams,
  logs,
  dispatchSetDisplayedProcessPhase,
  displayedPhaseId,
}) => {

  const [ activeTab, setActiveTab ] = useState(activeProcess.state === 'executable' ? 'Builder' : 'Data Viewer')

  const renderFallbackPhase = () => {
    const latestPhase = PhaseProgression.getLatest(activeProcess)
    const newDisplayedPhaseId = latestPhase ? latestPhase.phaseId : getFirstPhaseId(activeProcess)
    dispatchSetDisplayedProcessPhase(newDisplayedPhaseId)
  }

  useEffect(() => {
    const phaseIsRendered = phases.some(({ id }) => id === displayedPhaseId)

    if (phaseIsRendered) return

    const phase = DescriptionParser.getPhaseById(activeProcess, displayedPhaseId)

    if (!phase) {
      renderFallbackPhase()
      return
    }

    const { iterationOf, groupName } = phase

    if (!groupName) {
      renderFallbackPhase()
      return
    }

    const phaseIterationIds = Validator.isUndefinedOrNull(iterationOf)
      ? PhaseGroupParser.getPhaseIterationIds(activeProcess, displayedPhaseId)
      : PhaseGroupParser.getPhaseIterationIds(activeProcess, iterationOf)

    const nextIteration = phases.find(({ id }) => phaseIterationIds.includes(id))

    if (nextIteration) {
      dispatchSetDisplayedProcessPhase(nextIteration.id)
    } else {
      dispatchSetDisplayedProcessPhase(iterationOf)
    }
  })

  return (
    <div>
      <OutdatedProcessInfoBanner activeProcess={activeProcess} />
      <Segment>
        <div>
          <Menu attached tabular>
            {TABS.map((tab, i) => renderMenuItem(tab, i, activeTab, setActiveTab))}
          </Menu>
          {activeTab === 'Builder' && (
            <Segment
              attached='bottom'
              className='remove-l-b-r-border no-padding'
              style={{ padding: '0 !important' }}
            >
              <Builder
                phases={phases}
                processDownStreams={processDownStreams}
                activeProcess={activeProcess}
                activeDevice={activeDevice}
              />
            </Segment>
          )}
          {activeTab === 'Data Viewer' && (
            <Segment attached='bottom' className='remove-l-b-r-border'>
              <DataGraphs
                processUpStreams={processUpStreams}
                activeProcess={activeProcess}
                activeDevice={activeDevice}
              />
            </Segment>
          )}
          {activeTab === 'Configuration' && (
            <Segment
              attached='bottom'
              style={{ overflow: 'auto' }}
              className='remove-l-b-r-border'
            >
              <ProcessConfiguration
                processDownStreams={processDownStreams}
                phases={phases}
                activeDevice={activeDevice}
                activeProcess={activeProcess}
              />
            </Segment>
          )}
          {activeTab === 'Process Logs' && (
            <Segment attached='bottom' className='remove-l-b-r-border'>
              <LogView
                activeDevice={activeDevice}
                defaultQuery={{ processId: activeProcess.id }}
                logs={Logs.getProcessLogs(logs, activeProcess.id)}
              />
            </Segment>
          )}
          {activeTab === 'Alerts' && (
            <Segment attached='bottom' className='remove-l-b-r-border'>
              <StreamEventTriggerTable
                activeDevice={activeDevice}
                activeProcess={activeProcess}
                mode='process'
              />
            </Segment>
          )}
        </div>
      </Segment>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessViewerBody)
