function slope(p1, p2){
  return (p2[1] - p1[1]) / (p2[0] - p1[0])
}

function offset(p1, p2){
  return p2[1] - slope(p1, p2) * p2[0]
}

export const linear = {
  slope,
  offset
}
