import nexus from 'nexus'
import { setSession } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callLogin = async dispatch => {
  const res = await nexus.user.createDemoUser()
  const { userName, password } = res
  const result = await nexus.auth.signIn(userName, password)
  const session = result.signInUserSession
  if (session) {
    const userSub = session.accessToken.payload.sub
    const userData = await nexus.user.get(userSub)
    dispatch(setSession({ authenticated: true, userData }))
  }
}

export default thunkWrapper(callLogin)
