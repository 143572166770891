import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'
import { convertFrom } from 'utils/units'
import { roundToTheNthDecimal } from 'utils/number'
import UI_CONSTANTS from 'config/ui'

import callDeleteCalibration from 'redux/thunks/device/callDeleteCalibration'
import Authorizer from 'utils/Authorizer'
import NoDataMessage from 'components/utility/NoDataMessage'
import DeletePopup from './DeletePopup'
import TableHeader from 'components/utility/table/TableHeader'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  callDeleteCalibration: (streamId, deviceId) => dispatch(
    callDeleteCalibration(streamId, deviceId)
  ),
})

const CalibrationTable = ({ activeDevice, user, callDeleteCalibration }) => {

  async function deleteCalibrationEntry(streamId) {
    await callDeleteCalibration(streamId, activeDevice.id)
  }

  const configuredStreams = DeviceConfig.getConfiguredStreamsWithCalibration(activeDevice)

  if (!configuredStreams.length) return <NoDataMessage />

  return (
    <Table className='ospin-red' celled collapsing>
      <TableHeader items={['Stream', 'Offset', 'Slope', 'Info', '']} />
      <Table.Body>
        {configuredStreams.map(stream => {

          const converter = convertFrom(stream.unit).to(stream.displayedUnit)
          const displayedOffset = roundToTheNthDecimal(
            converter(stream.calibration.offset) - converter(0),
            UI_CONSTANTS.NUMBER_INPUT_DECIMALS,
          )

          return (
            <Table.Row key={stream.id} warning={!stream.connected}>
              <Table.Cell>
                {stream.displayedName} [{stream.displayedUnit}]
              </Table.Cell>
              <Table.Cell>
                {displayedOffset}
              </Table.Cell>
              <Table.Cell>
                {stream.calibration.slope.toFixed(3)}
              </Table.Cell>
              <Table.Cell>
                {!stream.connected ? 'stream not connected' : 'Ok'}
              </Table.Cell>
              <Table.Cell>
                <DeletePopup
                  confirmHandler={() => deleteCalibrationEntry(stream.id)}
                  disabled={!Authorizer.user(user).hasEditRights(activeDevice)}
                  iconName='close'
                  position='right center'
                />
              </Table.Cell>
            </Table.Row>
          )
        })}
      </Table.Body>
    </Table>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CalibrationTable)
