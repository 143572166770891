class DescriptionParser {

  static processHasValidPhases(process) {
    const { description } = process
    return Object.keys(description).length > 0
  }

  static getPhasesSequence(process) {
    if (!DescriptionParser.processHasValidPhases(process)) return []

    const { description, entryPhaseId } = process
    const sequencedPhases = []
    let currentPhaseId = entryPhaseId

    while (currentPhaseId !== -1 && currentPhaseId in description) {
      const nextPhase = description[currentPhaseId]
      sequencedPhases.push({ id: currentPhaseId, data: nextPhase })
      currentPhaseId = nextPhase.next
    }

    return sequencedPhases
  }

  static getPhasesArray(process) {
    return DescriptionParser.getPhasesSequence(process).map(phase => phase.data)
  }

  static getPhaseIds(process) {
    return DescriptionParser.getPhasesSequence(process).map(phase => phase.id)
  }

  static getStreamTarget(process, phaseId, streamId) {
    return process.description[phaseId]?.downstreams[streamId]?.value
  }

  static getPhaseTransitionMethod(process, phaseId) {
    return process.description[phaseId]?.transition
  }

  static getPhaseDuration(process, phaseId) {
    return process.description[phaseId]?.duration
  }

  static getPhaseName(process, phaseId) {
    return process.description[phaseId]?.name
  }

  static getNextPhaseId(process, phaseId) {
    return process.description[phaseId]?.next
  }

  static getPreviousPhaseId(process, phaseId) {
    const phases = DescriptionParser.getPhasesSequence(process)
    return phases.find(phase => phase.data.next === phaseId)?.id
  }

  static getPhaseById(process, phaseId) {
    return process.description[phaseId]
  }

  static getLastPhaseId(process) {
    const ids = DescriptionParser.getPhaseIds(process)
    return ids[ids.length - 1]
  }

  static hasPhaseWithId(description, phaseId) {
    return phaseId in description
  }

  static getPhaseExecutionIndex(process, phaseId) {
    return DescriptionParser.getPhaseIds(process).findIndex(aPhaseId => aPhaseId === phaseId)
  }

  static getPhaseCount(process) {
    return DescriptionParser.getPhasesSequence(process).length
  }
}

export default DescriptionParser
