import nexus from 'nexus'
import { checkDeviceHeartbeats, addDevices } from 'redux/actions/actions'
import NETWORK_CONSTANTS from 'config/network'
import { createDeviceObject } from 'redux/helper/objectMapper'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callGetDevices = async (dispatch, cachedDevices, userId) => {
  const devicesData = await nexus.user.listUserDevices(userId)
  const devices = []

  for (const deviceData of devicesData) {
    const device = createDeviceObject(deviceData)

    devices.push(device)
  }

  setInterval(() => dispatch(checkDeviceHeartbeats()),
    NETWORK_CONSTANTS.MAX_MS_TIME_BETWEEN_HEARTBEATS)

  dispatch(addDevices(devices))
}

export default thunkWrapper(callGetDevices)
