import create from './create'
import createDemoUser from './createDemoUser'
import get from './get'
import update from './update'
import getPublic from './public/get'
import remove from './remove'
import acceptDeviceInvitation from './acceptDeviceInvitation'
import deleteNotification from './deleteNotification'
import revokeIotPolicy from './revokeIotPolicy'
import getChangelog from './getChangelog'
import deleteNotificationsByTopic from './deleteNotificationsByTopic'
import getUserDevice from './devices/getUserDevice'
import listUserDevices from './devices/listUserDevices'

export default {
  acceptDeviceInvitation,
  deleteNotification,
  create,
  remove,
  revokeIotPolicy,
  getChangelog,
  deleteNotificationsByTopic,
  get,
  getPublic,
  getUserDevice,
  listUserDevices,
  update,
  createDemoUser,
}
