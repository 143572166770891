import React from 'react'
import { connect } from 'react-redux'
import { Table, Dropdown, Label } from 'semantic-ui-react'

import callRevokeDeviceAccess from 'redux/thunks/device/callRevokeDeviceAccess'
import DeletePopup from 'components/device/setup/DeletePopup'
import Authorizer from 'utils/Authorizer'

const mapDispatchToProps = dispatch => ({
  dispatchCallRevokeDeviceAccess: (deviceId, userId, groupName, hostName) => dispatch(
    callRevokeDeviceAccess(deviceId, userId, groupName, hostName)
  ),
})

const User = ({
  user,
  activeDevice,
  me,
  modifyAccessHandler,
  dispatchCallRevokeDeviceAccess,
}) => {

  const deleteAndClose = async (userId, groupName) => {
    await dispatchCallRevokeDeviceAccess(activeDevice.id, userId, groupName)
  }

  const modifyAccess = async (userId, newGroup, event, data) => {
    await modifyAccessHandler(userId, data.value)
  }

  const { userName, id } = user

  const groupOptions = activeDevice.access.map(group => ({
    value: group.name,
    text: group.name,
    key: group.name,
  }))

  const groupOptionsExcludingSupport = groupOptions.filter(group => group.text !== 'support')
  const accessGroup = Authorizer.getUserGroup(activeDevice, id)
  const I = Authorizer.user(me)
  return (
    <Table.Row>
      <Table.Cell>
        {userName}
        {Authorizer.user(user).isOwnerOf(activeDevice) && (
          <span style={{ marginLeft: '16px' }}>
            <Label>
              device owner
            </Label>
          </span>
        )}
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          onChange={(event, data) => modifyAccess(id, accessGroup.name, event, data)}
          value={accessGroup.name}
          options={groupOptionsExcludingSupport}
          disabled={!I.canModifyAccessOf(user).to(activeDevice)}
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <DeletePopup
          confirmHandler={() => deleteAndClose(user.id, accessGroup.name)}
          disabled={!I.canModifyAccessOf(user).to(activeDevice)}
          iconName='user times'
          position='left center'
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default connect(null, mapDispatchToProps)(User)
