import PDVC from 'utils/PDVC'
import { getFirstPhaseId } from 'utils/process'

export default (state, action) => {
  const process = action.payload
  const { processDescriptionSnapshots } = state

  if (processDescriptionSnapshots.every(snapshot => snapshot.id !== process.id)) {
    processDescriptionSnapshots.push(
      PDVC.createSnapshot(process.id, process.description),
    )
  }

  return {
    ...state,
    displayedPhaseId: getFirstPhaseId(process),
    processBuilderValidationErrors: [],
    processDescriptionSnapshots: [...processDescriptionSnapshots],
  }
}
