import React, { useState } from 'react'
import { Input, Icon, Popup } from 'semantic-ui-react'
import { setPhaseGroupIterationsCount } from 'redux/actions/actions'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import { connect } from 'react-redux'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import DescriptionParser from 'utils/process/DescriptionParser'
import { Process, PROCESS_STATES } from 'utils/process'
import PDVC from 'utils/PDVC'

const mapStateToProps = ({ processDescriptionSnapshots }) => ({ processDescriptionSnapshots })

const mapDispatchToProps = dispatch => ({
  dispatchSetPhaseGroupIterationsCount: (processId, groupName, count) => dispatch(
    setPhaseGroupIterationsCount({ processId, groupName, count }),
  ),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

const ITERATIONS_CONSTANTS = { MAX: 100 }

const disabledInputText = `
  This input is disabled because you have modified the running phase of this group.
  You can either apply your changes to the running phase first and then apply your
  changes to the iterations in a second step or increase the iterations before modifying the running phase
`
function PhaseIterationCounter({
  activeProcess,
  groupName,
  dispatchSetPhaseGroupIterationsCount,
  dispatchCallUpdateProcess,
  processDescriptionSnapshots,
}) {
  const count = PhaseGroupParser.getGroupIterationsCount(activeProcess, groupName)
  const groupIsRunning = groupName === Process.getRunningGroup(activeProcess, groupName)
  const [isIterationsError, setIterationsError] = useState(false)
  const [isTakingInput, setIsTakingInput] = useState(false)
  const [iterationsInput, setIterationsInput] = useState('')


  const getMinimumAllowedIterationsCount = () => (
    PhaseGroupParser.getFinishedIterationsCount(activeProcess, groupName) + 1
  )

  const isValidIterationsCount = newIterationCount => {
    const minIreations = getMinimumAllowedIterationsCount()

    return (newIterationCount < ITERATIONS_CONSTANTS.MAX
    && newIterationCount >= minIreations && !isNaN(newIterationCount))
  }

  const save = async () => {
    if (activeProcess.state === PROCESS_STATES.executable && !isIterationsError) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const submit = async () => {
    if (!isIterationsError && iterationsInput !== '') {
      dispatchSetPhaseGroupIterationsCount(activeProcess.id, groupName, iterationsInput)
      await save()
    }

    setIterationsError(false)
    setIsTakingInput(false)
    setIterationsInput('')
  }

  const onChangeIterationsInput = async e => {
    const { target: { value } } = e
    setIsTakingInput(true)
    setIterationsInput(value)
    setIterationsError(false)

    const newIterationCount = Number(value)

    if (!isValidIterationsCount(newIterationCount)) {
      setIterationsError(true)
    }
  }

  const getDisplayedTargetValue = () => {

    if (isTakingInput) return iterationsInput

    return count
  }

  const renderProgression = () => {
    if (activeProcess.state === PROCESS_STATES.executable) return null
    const { finished, total } = PhaseGroupParser
      .getGroupIterationsProgression(activeProcess, groupName)
    const offset = groupIsRunning ? 1 : 0

    return <b style={{ marginTop: '5px', marginRight: '11px' }}>{`${finished + offset}/${total}`}</b>
  }

  const showInputField = () => {
    if (activeProcess.state === PROCESS_STATES.finished) return false
    if (activeProcess.state === PROCESS_STATES.executable) return true
    if (!PhaseGroupParser.groupHasFinished(activeProcess, groupName)) return true
    return false
  }

  const disableInputField = () => {
    if (activeProcess.state === PROCESS_STATES.finished) return true
    if (activeProcess.state === PROCESS_STATES.executable) return false

    const diffs = PDVC.getDiff(activeProcess, processDescriptionSnapshots)
    const runningPhase = Process.getRunningPhase(activeProcess)
    const phase = DescriptionParser.getPhaseById(activeProcess, runningPhase.phaseId)

    if (!phase) return true

    const { groupName: runningPhaseGroupName } = phase

    if (!runningPhaseGroupName || runningPhaseGroupName !== groupName) return false

    const runningPhaseChangedWithRequiredPhaseInsertion = diffs
      .some(({ phaseId, property }) => parseInt(phaseId, 10) === runningPhase.phaseId && property === 'downstreams')

    return runningPhaseChangedWithRequiredPhaseInsertion
  }

  const getCyclesText = () => {
    if (PhaseGroupParser.groupHasFinished(activeProcess, groupName)) return 'cycles completed'
    return groupIsRunning ? 'running cycles' : 'cycles'
  }

  const renderProgressionRowText = showInput => {
    if (showInput) {
      return (
        <>
          <div style={{ flexGrow: 1 }} />
          <b style={{ marginTop: '5px', marginRight: '12px' }}>{renderProgression()}</b>
        </>
      )
    }

    return (
      <>
        <b style={{ marginTop: '5px', marginRight: '12px' }}>{renderProgression()}</b>
        <div style={{ flexGrow: 1 }} />
      </>
    )
  }

  const renderInputField = showInput => {
    const disableInput = disableInputField()


    if (!showInput) return null

    if (disableInput) {
      return (
        <Popup
          basic
          position='bottom right'
          trigger={(
            <div>
              <Input
                aria-label='iterations-input'
                onClick={e => e.stopPropagation()}
                value={count}
                type='number'
                size='mini'
                style={{ width: '100%', maxWidth: '100px', cursor: 'default' }}
                disabled
              />
            </div>
          )}
          content={disabledInputText}
        />
      )
    }

    return (
      <Input
        aria-label='iterations-input'
        onClick={e => e.stopPropagation()}
        value={getDisplayedTargetValue()}
        error={isIterationsError}
        onChange={onChangeIterationsInput}
        onBlur={submit}
        type='number'
        size='mini'
        style={{ width: '100%', maxWidth: '100px' }}
        max={ITERATIONS_CONSTANTS.MAX}
        min={getMinimumAllowedIterationsCount()}
      />
    )
  }

  const iconColor = groupIsRunning ? 'blue' : 'grey'
  const showInput = showInputField()

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
        {renderInputField(showInput)}
        {renderProgressionRowText(showInput)}
        <Popup
          position='bottom right'
          trigger={(
            <Icon
              aria-label='iterations-icon'
              style={{ cursor: 'help', marginRight: '10px', marginTop: '4px' }}
              name='retweet'
              color={iconColor}
            />
          )}
          content={(
            <p>{getCyclesText()}</p>
          )}
        />
      </div>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseIterationCounter)
