import React from 'react'
import { connect } from 'react-redux'
import { Segment, List, Label } from 'semantic-ui-react'

import { PROCESS_STATES } from 'utils/process'
import DescriptionParser from 'utils/process/DescriptionParser'
import { setDisplayedProcessPhase } from 'redux/actions/actions'
import DraggablePortal from 'components/utility/DraggablePortal'

const mapStateToProps = state => ({ displayedPhaseId: state.displayedPhaseId })

const mapDispatchToProps = dispatch => ({
  setDisplayedProcessPhase: phaseId => dispatch(
    setDisplayedProcessPhase(phaseId),
  ),
})

const phaseWidgetClassMap = {
  blue: {
    iconClass: 'phaseWidget-color-ospin-blue',
    borderClass: 'border-color-ospin-blue',
  },
  red: {
    iconClass: 'phaseWidget-color-ospin-red',
    borderClass: 'border-color-ospin-red',
  },
  grey: {
    iconClass: 'phaseWidget-color-ospin-grey',
    borderClass: 'border-color-ospin-grey',
  },
}

const isPhaseIteration = (activeProcess, runningPhase, phaseId) => {
  const phase = DescriptionParser
    .getPhaseById(activeProcess, runningPhase.phaseId)

  if (!phase) return false

  const { iterationOf } = phase

  return phaseId === iterationOf
}

const getPhaseWidgetClasses = (phaseId, displayedPhaseId, runningPhase, activeProcess) => {

  if (phaseId === runningPhase.phaseId || isPhaseIteration(activeProcess, runningPhase, phaseId)) {
    return phaseWidgetClassMap.blue
  }

  if (phaseId === displayedPhaseId) {
    return phaseWidgetClassMap.red
  }

  return phaseWidgetClassMap.grey
}

const PhaseTabDraggable = props => {

  const {
    displayedPhaseId,
    phaseId,
    index,
    runningPhase,
    activeProcess,
    phase,
    isDragDisabled = false,
  } = props
  const { transition, name } = phase
  const isManualTransition = transition === 'manual'

  const { iconClass, borderClass } = getPhaseWidgetClasses(
    phaseId,
    displayedPhaseId,
    runningPhase,
    activeProcess,
  )

  return (
    <DraggablePortal
      index={index}
      draggableId={phaseId.toString()}
      As={List.Item}
      onClick={() => props.setDisplayedProcessPhase(phaseId)}
      active={phaseId === displayedPhaseId}
      isDragDisabled={activeProcess.state === PROCESS_STATES.finished || isDragDisabled}
    >
      <Segment
        className={borderClass}
        style={{ cursor: 'pointer' }}
      >
        {name}
        <Label
          icon={
            isManualTransition
              ? 'hand pointer'
              : 'clock outline'
          }
          corner='right'
          className={iconClass}
        />
      </Segment>
    </DraggablePortal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseTabDraggable)
