export function addUserToGroup(resource, groupName, userId){
  resource.access = resource.access.map(group => {
    if (group.name === groupName){
      group.users.push(userId)
    }
    return group
  })
}

export function removeUserFromGroup(resource, groupName, userId){
  resource.access = resource.access.map(group => {
    if (group.name === groupName){
      group.users = group.users.filter(id => id !== userId)
    }
    return group
  })
}
