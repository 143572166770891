import nexus from 'nexus'
import { addHistoricStreamData, setStreamDataErrorFlag } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callLoadProcessDataSummaryStreamwise = async (dispatch, processId, streamIds) => {
  const dataRequests = streamIds.map(streamId => ({
    streamId,
    dataPromise: nexus.datapoints
      .get({
        processId,
        streamId,
        numberOfPoints: 1500,
        start: -1,
        end: -1,
      }),
  }))

  dataRequests.forEach(async request => {
    try {
      const streamData = await request.dataPromise
      dispatch(addHistoricStreamData({
        streamId: request.streamId,
        data: streamData,
        processId,
      }))
    } catch (e) {
      dispatch(setStreamDataErrorFlag({ streamId: request.streamId, processId }))
    }
  })
}

export default thunkWrapper(callLoadProcessDataSummaryStreamwise)
