import { createListProcessObject } from 'redux/helper/objectMapper'
import { Process } from 'utils/process'


export default (state, action) => {
  const { devices } = state

  const { deviceUsers, device, processesData } = action.payload

  const updatedDevices = devices.length
    ? devices.map(loadedDevice => {
      if (loadedDevice.id === device.id) return device
      return loadedDevice
    })
    : [ device ]

  const deviceMap = updatedDevices.reduce((acc, device) => {
    acc[device.id] = device
    return acc
  }, {})

  const processes = processesData.map(processData => {
    const listProcess = createListProcessObject(processData)
    const processDevice = deviceMap[device.id]

    const existingProcess = state.processes.find(process => process.id === processData.id)

    return {
      ...existingProcess,
      ...listProcess,
      state: Process.deriveProcessState(listProcess, processDevice),
    }
  })

  return {
    ...state,
    deviceUsers,
    activeDeviceInitialized: true,
    devices: updatedDevices,
    processes,
  }
}
