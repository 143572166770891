import { findAndUpdate } from 'redux/helper/updateFunctions'
import { ellipseString } from 'utils/string'
import PDVC from 'utils/PDVC'
import FlashMessenger from 'utils/FlashMessenger'
import { Process } from 'utils/process'

const processOnlyInListRepresentation = process => !process.description

export default (state, action) => {
  const { processes, processDescriptionSnapshots, flashMessages } = state
  const { processId, phase } = action.payload
  const process = Process.getById(processes, processId)

  if (!process || processOnlyInListRepresentation(process)) return state

  const updateFn = targetProcess => Process.manageProcessProgression(targetProcess, phase)
  const updateFns = [ updateFn ]

  // if PDVC detects uncommited changes to the process, discard them by restoring the
  // snapshot description before adding the phase
  const diffs = PDVC.getDiff(process, processDescriptionSnapshots)

  if (diffs.length) {
    const revertedDescription = PDVC.revertToSnapshot(process, processDescriptionSnapshots)
    const updateDescriptionFn = targetProcess => Object
      .assign(targetProcess, { description: revertedDescription })
    updateFns.unshift(updateDescriptionFn)
  }

  const updatedProcesses = findAndUpdate(processId, processes, updateFns)
  const updatedFlashMessages = [ ...flashMessages ]

  // show a message for the transition for every phase but the first

  if (process.progression.length > 1) {
    const msg = `${ellipseString(process.name, 25)} has progressed into the next phase.`
    const flashMsg = FlashMessenger.createMessage('info', msg)
    updatedFlashMessages.push(flashMsg)
  }

  return {
    ...state,
    processes: updatedProcesses,
    processBuilderValidationErrors: [],
    flashMessages: updatedFlashMessages,
  }
}
