import React from 'react'
import { Form, Label, Input, TextArea } from 'semantic-ui-react'
import formalize from 'components/utility/modal/formalize'
import ProcessValidator from 'utils/validation/ProcessValidator'

const { VALID_PROCESS_DATA: { nameLength, commentLength } } = ProcessValidator

const renderNameInput = (newName, updateHandler) => (
  <Form.Field error={!ProcessValidator.isValidProcessName(newName)}>
    <label>
      Process Name
    </label>
    <Input
      fluid
      placeholder='new process name...'
      value={newName}
      name='newName'
      onChange={updateHandler}
    />
    <Label>
      {newName.length}/{nameLength}
    </Label>
  </Form.Field>
)

const renderCommentInput = (newComment, updateHandler) => (
  <Form.Field error={!ProcessValidator.isValidProcessComment(newComment)}>
    <label>
      Process Comment
    </label>
    <TextArea
      onChange={updateHandler}
      name='newComment'
      value={newComment}
    />
    <Label>
      {newComment.length}/{commentLength}
    </Label>
  </Form.Field>
)

const EditProcessModalForm = ({ newName, newComment, handleUpdate }) => (
  <>
    {renderNameInput(newName, handleUpdate)}
    {renderCommentInput(newComment, handleUpdate)}
  </>
)

export default formalize(EditProcessModalForm)
