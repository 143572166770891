import defaultIcon from 'images/streamIcons/stream-default-icon.png'

import pressure from 'images/streamIcons/pressure.png'
import frequency from 'images/streamIcons/frequency.png'
import glucose from 'images/streamIcons/glucose.png'
import lactate from 'images/streamIcons/lactate.png'
import temperature from 'images/streamIcons/temperature.png'
import pH from 'images/streamIcons/pH.png'
import DO from 'images/streamIcons/DO.png'
import CO2 from 'images/streamIcons/CO2.png'
import air from 'images/streamIcons/Air.png'
import pwm from 'images/streamIcons/PWM.png'
import analog from 'images/streamIcons/analog.png'
import duration from 'images/streamIcons/duration.png'
import O2 from 'images/streamIcons/O2.png'
import direction from 'images/streamIcons/direction.png'
import switchImg from 'images/streamIcons/switch.png' // reserved keyword switch :)
import bitset from 'images/streamIcons/bit.png'
import min from 'images/streamIcons/min.png'
import max from 'images/streamIcons/max.png'
import controllerStatus from 'images/streamIcons/controller-status.png'
import securityFlag from 'images/streamIcons/security-flag.png'
import motor from 'images/streamIcons/motor.png'
import valve from 'images/streamIcons/valve.png'
import pump from 'images/streamIcons/pump.png'
import blood from 'images/streamIcons/blood.png'
import P from 'images/streamIcons/P.png'
import I from 'images/streamIcons/I.png'
import D from 'images/streamIcons/D.png'

const DATA_TYPE_TO_IMAGE_MAP = {
  pressure,
  frequency,
  glucose,
  lactate,
  temperature,
  pH,
  DO,
  CO2,
  air,
  pwm,
  analog,
  duration,
  O2,
  direction,
  switch: switchImg,
  bitset,
  min,
  max,
  'pH controller status': controllerStatus,
  'controller status': controllerStatus,
  'security flag': securityFlag,
  'motor speed': motor,
  'valve position': valve,
  'pump speed': pump,
  'blood speed': blood,
  'P parameter': P,
  'I parameter': I,
  'D parameter': D,
}

export default class StreamIcons {

  static getDefaultImage() { return defaultIcon }

  static getImage({ dataType }) {
    return DATA_TYPE_TO_IMAGE_MAP[dataType] || StreamIcons.getDefaultImage()
  }

}
