import React from 'react'
import { Table } from "semantic-ui-react"
import Time from 'utils/Time'

const DeviceLogEntry = ({ deviceLog: { message, subject, createdAt, level } }) => (
  <Table.Row warning={level === 'warning'} error={level === 'error'}>
    <Table.Cell collapsing>{level}</Table.Cell>
    <Table.Cell>{subject}</Table.Cell>
    <Table.Cell>{message}</Table.Cell>
    <Table.Cell>{Time.getTrimmedDateString(createdAt)}</Table.Cell>
  </Table.Row>
)

export default DeviceLogEntry
