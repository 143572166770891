import React from 'react'
import { Popup, List } from 'semantic-ui-react'

const RecalibrationInformationPopup = () => (
  <Popup
    position='left center'
    trigger={
      <div className='toggle-text margin-b-8' >
        How do I recalibrate?
      </div>
    }
    content={
      <List ordered>
        <List.Item>
          Delete the calibration using the &lsquo;Delete Calibration&rsquo; Button
        </List.Item>
        <List.Item>
          For your new calibration with data selection use data that was either generated
          when no calibration was active or generated after the calibration reset&nbsp;
        </List.Item>
      </List>
    }
  />
)

export default RecalibrationInformationPopup
