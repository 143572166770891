import React from 'react'
import { connect } from 'react-redux'
import { Grid, Button, Accordion, Container, Message, Icon } from 'semantic-ui-react'
import Graph from 'components/processviewer/graphs/Graph'
import ProcessEventTriggers from 'utils/process/ProcessEventTriggers'
import GraphTools from 'utils/GraphTools'
import DeviceConfig from 'utils/DeviceConfig'
import StreamUtils from 'utils/Stream'
import DataManager from 'utils/DataManager'
import PhaseProgression from 'utils/process/PhaseProgression'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import DescriptionParser from 'utils/process/DescriptionParser'
import DraggableComponent from 'components/utility/DraggableComponent'
import callLoadHistoricStreamData from 'redux/thunks/process/callLoadHistoricStreamData'

import TimeFrameBar from './TimeFrameBar'
import GraphDataOptions from './GraphDataOptions'
import { ConfigForm, NoDataConfigForm } from './ConfigForm'

const mapStateToProps = ({ displayedPhaseId }) => ({ displayedPhaseId })

const mapDispatchToProps = dispatch => ({
  dispatchCallLoadHistoricStreamData: (process, streamId, count, start, end) => dispatch(
    callLoadHistoricStreamData(process, streamId, count, start, end),
  ),
})

class Stream extends React.Component {

  state = {
    mode: (this.props.activeProcess.state === 'running' || this.props.activeProcess.state === 'paused') ? 'live' : 'full',
    zoomMode: 'auto',
    zoomParams: GraphTools.getZoomParams('auto'),
    Ymin: null,
    Ymax: null,
    includeZero: true,
    lastPan: null,
    showTargetLine: false,
    showEventTriggerLines: false,
    openUnitDropDown: false,
    openZoomDropDown: false,
    selectedUnit: DeviceConfig
      .getConfiguredStreamById(this.props.activeDevice, this.props.stream.id).displayedUnit,
    isDragDisabled: true,
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.props.streamData !== nextProps.streamData
      || this.state !== nextState
      || nextProps.folded !== this.props.folded
      || nextProps.loaded !== this.props.loaded
      || nextProps.processState !== this.props.processState
      || nextProps.displayedPhaseId !== this.props.displayedPhaseId
    )
  }

  openZoomDropDownHandler = () => {
    const { openZoomDropDown } = this.state
    this.setState({ openZoomDropDown: !openZoomDropDown })
  }

  openUnitDropDownHandler = () => {
    const { openUnitDropDown } = this.state
    this.setState({ openUnitDropDown: !openUnitDropDown })
  }

  closeDropDown = () => {
    this.setState({ openUnitDropDown: false, openZoomDropDown: false })
  }

  changeDisplayedUnit = (event, { value }) => {
    const { zoomMode } = this.state
    const zoomParams = GraphTools.getZoomParams(zoomMode)
    this.setState({
      selectedUnit: value,
      zoomParams,
    })
    this.closeDropDown()
  }

  setShowTargetLine = () => {
    this.setState(prevState => ({ showTargetLine: !prevState.showTargetLine }))
  }

  setShowEventTriggerLines = () => {
    this.setState(prevState => ({ showEventTriggerLines: !prevState.showEventTriggerLines }))
  }

  setMode = mode => {
    const { zoomMode } = this.state
    const zoomParams = GraphTools.getZoomParams(zoomMode)

    if (mode === 'phase') {
      const {
        displayedPhaseId,
        activeProcess,
        streamData,
        dispatchCallLoadHistoricStreamData,
        stream,
      } = this.props

      const phase = DescriptionParser.getPhaseById(activeProcess, displayedPhaseId)

      let intervalStartPhaseId = displayedPhaseId
      let intervalEndPhaseId = displayedPhaseId

      const { groupName } = phase

      if (groupName) {
        const lastIterationIds = PhaseGroupParser.getIterationEndPhaseIds(activeProcess, groupName)
        intervalEndPhaseId = lastIterationIds[lastIterationIds.length - 1]

        const iterationData = PhaseGroupParser.getIterationData(activeProcess, groupName)
        intervalStartPhaseId = iterationData[0].phaseIds[0]
      }

      const start = PhaseProgression
        .getSecondsSinceProcessStart(activeProcess, intervalStartPhaseId)

      const end = PhaseProgression
        .getSecondsSinceProcessStart(
          activeProcess, DescriptionParser.getNextPhaseId(activeProcess, intervalEndPhaseId),
        )

      const datapointsInViewPort = streamData
        .filter(dp => dp.x >= start && dp.x <= end).length

      if (datapointsInViewPort < GraphTools.CONSTANTS.MINIMUM_POINTS_IN_VIEWPORT) {
        dispatchCallLoadHistoricStreamData(
          activeProcess.id, stream.id, GraphTools.CONSTANTS.RELOAD_COUNT, start, end,
        )
      }
    }

    this.setState({ mode, zoomParams })
  }

  setYAxisLimits = (limitBound, newLimit) => {
    this.setState({ [limitBound]: newLimit })
  }

  changeZoomMode = (event, data) => {
    const zoomSettings = GraphTools.changeZoomMode({ data })
    this.setState({ ...zoomSettings, openZoomDropDown: false })
  }

  toggleIncludeZero = () => {
    this.setState(prevState => ({ includeZero: !prevState.includeZero }))
  }

  getZoomParamsForLiveData = (zoomMode, event) => {
    const { viewportMinimum: minTime, viewportMaximum: maxTime } = event.axisX[0]
    const { viewportMinimum: minValue, viewportMaximum: maxValue } = event.axisY[0]
    return GraphTools.getZoomParams(zoomMode, maxTime, minTime, maxValue, minValue)
  }

  reloadDataOnRangeChangeHandler = async event => {
    const { lastPan, zoomMode, mode } = this.state
    if (event.trigger === 'reset') {
      this.setState({ zoomParams: GraphTools.getZoomParams(zoomMode) })
    }

    const { activeProcess, stream, streamData } = this.props
    const { triggersFetch, lastPan: updatedPan } = GraphTools.eventTriggersDataFetch({ event, lastPan })

    if (event.trigger === 'pan') {
      this.setState({ lastPan: updatedPan })
    }

    if (mode === 'live') {
      this.setState({ zoomParams: this.getZoomParamsForLiveData(zoomMode, event) })
      return
    }

    if (triggersFetch) {
      const zoomParams = await GraphTools.fetchDataInViewport({
        event, zoomMode, activeProcess, stream, completeStreamData: streamData,
      })
      this.setState(zoomParams)
    }
  }

  setDisableDrag = boolean => this.setState({ isDragDisabled: boolean })

  render() {
    const {
      mode,
      Ymin,
      Ymax,
      includeZero,
      zoomParams,
      selectedUnit,
      zoomMode,
      showTargetLine,
      showEventTriggerLines,
      openUnitDropDown,
      openZoomDropDown,
      isDragDisabled,
    } = this.state

    const {
      stream,
      folded,
      streamData,
      activeProcess,
      activeDevice,
      toggleFold,
      index,
      loaded,
      displayedPhaseId,
      totalStreamsCount,
    } = this.props

    const { lastDataPoint, displayedGraphData } = GraphTools.getDisplayedStreamData({
      stream,
      streamData,
      selectedUnit,
      mode,
      selectedPhaseId: displayedPhaseId,
      activeProcess,
      showTargetLine,
      zoomParams,
      showEventTriggerLines,
    })

    const hasError = DataManager.isStreamDataError(activeProcess, stream.id)
    const hasTarget = StreamUtils.hasRelatedTarget(stream)
    const hasDisplayableEventTrigger = ProcessEventTriggers
      .hasDisplayableEventTrigger(activeProcess, stream.id)

    return (
      <DraggableComponent
        draggableId={stream.id}
        index={index}
        style={{ padding: '0', borderTop: '1px solid rgba(34,36,38,.15)' }}
        isDragDisabled={isDragDisabled || totalStreamsCount === 1}
      >
        <Accordion.Title
          active={!folded}
          content={stream.name}
          onClick={toggleFold}
          onMouseOver={() => this.setDisableDrag(false)}
          onFocus={() => this.setDisableDrag(false)}
          onMouseLeave={() => this.setDisableDrag(true)}
        />
        { !folded && (
          <Accordion.Content style={{ paddingBottom: '0' }} active={!folded}>
            {hasError && (
              <Message
                error
                className='padding-8'
              >
                <Icon name='warning circle' />
                Parts of the data for this stream could not be loaded. Contact the support if this problem persists.
              </Message>
            )}
            <Container fluid>
              <Grid>
                <Grid.Row className='graph-boundary' id={stream.id}>
                  <Grid.Column width='12'>
                    <GraphDataOptions stream={stream} activeProcess={activeProcess} activeDevice={activeDevice} />
                    {' '}
                    <Button.Group compact>
                      <TimeFrameBar
                        activeProcess={activeProcess}
                        mode={mode}
                        setMode={this.setMode}
                      />
                    </Button.Group>
                    <Graph
                      displayedUnit={selectedUnit}
                      stream={stream}
                      data={displayedGraphData}
                      zoomParams={zoomParams}
                      Ymin={Ymin}
                      Ymax={Ymax}
                      includeZero={includeZero}
                      reloadDataOnRangeChangeHandler={this.reloadDataOnRangeChangeHandler}
                      loading={!loaded}
                    />
                  </Grid.Column>
                  <Grid.Column width='4'>
                    { displayedGraphData.length === 0 ? <NoDataConfigForm loading={!loaded} />
                      : (
                        <ConfigForm
                          stream={stream}
                          lastDataPoint={lastDataPoint}
                          selectedUnit={selectedUnit}
                          zoomMode={zoomMode}
                          Ymin={Ymin}
                          Ymax={Ymax}
                          changeDisplayedUnit={this.changeDisplayedUnit}
                          changeZoomMode={this.changeZoomMode}
                          includeZero={includeZero}
                          includeZeroHandler={this.toggleIncludeZero}
                          setYAxisLimits={this.setYAxisLimits}
                          hasTarget={hasTarget}
                          hasDisplayableEventTrigger={hasDisplayableEventTrigger}
                          showTargetLine={showTargetLine}
                          showEventTriggerLines={showEventTriggerLines}
                          setShowTargetLine={this.setShowTargetLine}
                          setShowEventTriggerLines={this.setShowEventTriggerLines}
                          openZoomDropDownHandler={this.openZoomDropDownHandler}
                          openZoomDropDown={openZoomDropDown}
                          openUnitDropDownHandler={this.openUnitDropDownHandler}
                          openUnitDropDown={openUnitDropDown}
                          closeDropDown={this.closeDropDown}
                        />
                      )}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Accordion.Content>
        )}
      </DraggableComponent>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stream)
