import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Divider, Button, Message } from 'semantic-ui-react'
import callTransferDeviceOwnership from 'redux/thunks/device/callTransferDeviceOwnership'

const mapStateToProps = state => ({
  user: state.user,
  deviceUsers: state.deviceUsers,
})

const mapDispatchToProps = dispatch => ({
  dispatchCallTransferDeviceOwnership: (deviceId, newOwnerId) => dispatch(
    callTransferDeviceOwnership(deviceId, newOwnerId)
  ),
})

const TransferOwnershipModal = ({
  user,
  activeDevice,
  open,
  closeHandler,
  deviceUsers,
  dispatchCallTransferDeviceOwnership,
}) => {

  const defaultState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    errorMessage: '',
  }

  const [ newOwnerId, setNewOwner ] = useState(null)

  const [ requestState, setRequestState ] = useState(defaultState)

  const close = () => {
    closeHandler()
  }

  const handleUserChange = (event, data) => {
    setNewOwner(data.value)
  }

  const submitNewOwner = async () => {
    setRequestState({ ...defaultState, isLoading: true })

    const res = await dispatchCallTransferDeviceOwnership(activeDevice.id, newOwnerId)
    if (res.success) {
      setRequestState({ ...defaultState, isSuccess: true })
    } else {
      setRequestState({ ...defaultState, isError: true, errorMessage: res.message })
    }
  }

  const { isError, errorMessage, isLoading, isSuccess } = requestState

  const userOptions = deviceUsers
    .filter(deviceUser => deviceUser.id !== user.id)
    .map(deviceUser => ({ value: deviceUser.id, key: deviceUser.id, text: deviceUser.userName }))

  return (
    <Modal
      size='tiny'
      open={open}
      onClose={close}
      onClick={event => event.stopPropagation()}
    >
      <Modal.Header>
        Transfer ownership
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Message
            warning
            header='Are you sure?'
            content={
              <p>
                Transfering the ownership will remove your ownership
                privileges and grant them to the new device owner. The
                new device owner will be able to control all user
                access to the device, including yours!
              </p>
            }
            icon='warning'
          />
          <Form
            error={isError}
            success={isSuccess}
            onSubmit={submitNewOwner}
            style={{ marginBottom: '15px', paddingBottom: '15px' }}
          >
            <Form.Select
              fluid
              label='New Owner'
              options={userOptions}
              onChange={handleUserChange}
              placeholder='Choose new owner'
            />
            <Message
              error
              header='Something went wrong.'
              content={errorMessage}
              icon='warning circle'
            />
            <Message
              success
              header='Success!'
              content={<p>Device ownership transfered.</p>}
              icon='check circle'
            />
            <Divider/>
            <Button floated='right'
              primary
              type='submit'
              loading={isLoading}
            > Submit</Button>
            <Button floated='right'
              onClick={close}
            >Close</Button>
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TransferOwnershipModal)
