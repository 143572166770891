import { findAndUpdate } from 'redux/helper/updateFunctions'
import { Process } from 'utils/process'
import PhaseGroupHandler from 'utils/process/PhaseGroupHandler'

const COMMANDS = {
  MOVE_GROUP: 'MOVE_GROUP',
  MOVE_PHASE_WITHIN_PROCESS_TREE: 'MOVE_PHASE_WITHIN_PROCESS_TREE',
  MOVE_PHASE_OUT_OF_GROUP: 'MOVE_PHASE_OUT_OF_GROUP',
  MOVE_PHASE_INTO_GROUP: 'MOVE_PHASE_INTO_GROUP',
  MOVE_PHASE_WITHIN_GROUP: 'MOVE_PHASE_WITHIN_GROUP',
  MOVE_PHASE_FROM_GROUP_TO_GROUP: 'MOVE_PHASE_FROM_GROUP_TO_GROUP',
  MOVE_GROUP_INTO_GROUP: 'MOVE_GROUP_INTO_GROUP',
}

const getCommand = (srcArea, destArea, srcPhaseInGroup) => {
  switch (true) {
    case (srcPhaseInGroup && srcArea === 'process-tree' && destArea !== 'process-tree'): {
      return COMMANDS.MOVE_GROUP_INTO_GROUP
    }
    case (destArea === 'process-tree' && srcArea === 'process-tree' && srcPhaseInGroup): {
      return COMMANDS.MOVE_GROUP
    }
    case (destArea === 'process-tree' && srcArea === 'process-tree' && !srcPhaseInGroup): {
      return COMMANDS.MOVE_PHASE_WITHIN_PROCESS_TREE
    }
    case (destArea === 'process-tree' && srcArea !== 'process-tree'): {
      return COMMANDS.MOVE_PHASE_OUT_OF_GROUP
    }
    case (destArea !== 'process-tree' && srcArea === 'process-tree'): {
      return COMMANDS.MOVE_PHASE_INTO_GROUP
    }
    case (destArea === srcArea): {
      return COMMANDS.MOVE_PHASE_WITHIN_GROUP
    }
    default:
      return COMMANDS.MOVE_PHASE_FROM_GROUP_TO_GROUP
  }
}

export default (state, action) => {
  const { processes } = state
  const { processId, src, dest, srcArea, destArea, srcPhaseInGroup } = action.payload

  const command = getCommand(srcArea, destArea, srcPhaseInGroup)
  if (command === COMMANDS.MOVE_GROUP_INTO_GROUP) {
    // this prevents the attempt of dragging a group into another
    return state
  }

  const getMoveFunction = () => {
    switch (command) {
      case COMMANDS.MOVE_GROUP:
        return {
          fn: process => PhaseGroupHandler.moveGroup(process, src, dest),
          reliesOnReturn: false,
        }
      case COMMANDS.MOVE_PHASE_WITHIN_PROCESS_TREE:
        return {
          fn: process => Process.movePhase(process, src, dest),
          reliesOnReturn: false,
        }
      case COMMANDS.MOVE_PHASE_OUT_OF_GROUP:
        return {
          fn: process => PhaseGroupHandler.movePhaseOutOfGroup(process, src, dest),
          reliesOnReturn: true,
        }
      case COMMANDS.MOVE_PHASE_INTO_GROUP:
        return {
          fn: process => PhaseGroupHandler.movePhaseIntoGroup(process, src, dest, destArea),
          reliesOnReturn: false,
        }
      case COMMANDS.MOVE_PHASE_WITHIN_GROUP:
        return {
          fn: process => PhaseGroupHandler.movePhaseWithinGroup(process, src, dest),
          reliesOnReturn: false,
        }
      case COMMANDS.MOVE_PHASE_FROM_GROUP_TO_GROUP:
        return {
          fn: process => PhaseGroupHandler.movePhaseFromGroupToGroup(process, src, dest, destArea),
          reliesOnReturn: true,
        }
      default:
        throw new Error('Unknow move command')
    }
  }

  const { fn: updateFn, reliesOnReturn } = getMoveFunction()

  const updatedProcesses = !reliesOnReturn
    ? findAndUpdate(processId, processes, [ updateFn ])
    : processes.map(aProcess => {
      if (aProcess.id === processId) {
        return updateFn(aProcess)
      }
      return aProcess
    })

  return { ...state, processes: updatedProcesses }
}
