import React from 'react'
import { Divider, Form, Message } from 'semantic-ui-react'
import { connect } from 'react-redux'
import DataManager from 'utils/DataManager'
import callLoadHistoricStreamData from 'redux/thunks/process/callLoadHistoricStreamData'
import callDeleteCalibration from 'redux/thunks/device/callDeleteCalibration'
import callSetStreamCalibration from 'redux/thunks/device/callSetStreamCalibration'
import DeviceConfig from 'utils/DeviceConfig'
import {
  convertFrom,
  applyUnitConversionOnData,
  replaceNoUnitCharacter,
} from 'utils/units'
import { roundToTheNthDecimal } from 'utils/number'
import Calibration from 'utils/calibration/Calibration'
import UI_CONSTANTS from 'config/ui'
import Graph from 'components/processviewer/graphs/Graph'
import modalize from 'components/utility/modal/modalize'
import RecalibrationInformationPopup from './RecalibrationInformationPopup'
import OffsetMenu from './OffsetMenu'
import OffsetAndSlopeMenu from './OffsetAndSlopeMenu'
import AverageSelectionMenu from './AverageSelectionMenu'
import ExistingCalibrationMessage from './ExistingCalibrationMessage'
import Footer from './Footer'


const mapDispatchToProps = dispatch => ({
  dispatchCallLoadHistoricStreamData: (processId, streamId, numberOfPoints, start, end) => dispatch(
    callLoadHistoricStreamData(processId, streamId, numberOfPoints, start, end),
  ),
  dispatchCallSetStreamCalibration: (deviceId, streamId, slope, offset) => dispatch(
    callSetStreamCalibration(deviceId, streamId, slope, offset),
  ),
  dispatchCallDeleteCalibration: (streamId, deviceId) => dispatch(
    callDeleteCalibration(streamId, deviceId),
  ),
})

class CalibrationModal extends React.Component {

  CONSTANTS = {
    PAN_DEBOUNCE_INTERVAL: 2000,
    MINIMUM_POINTS_IN_VIEWPORT: 600,
    RELOAD_COUNT: 3000,
    DEFAULT_NUMBER_POINTS: 3000,
    POINTS_IN_LIVE_VIEW: 120,
    MANUAL_ZOOM_Y_MIN: 0,
    MANUAL_ZOOM_Y_MAX: 100,
  }

  getClearMessageState = () => ({
    isSuccess: false,
    successMessage: '',
    successMessageHeader: '',
    isError: false,
    errorMessage: '',
  })

  getClearSelectionOffsetRange = () => ({
    startTime: '',
    endTime: '',
    offsetRangeAvrg: '',
    offsetRangeReference: '',
  })

  getClearSelectionAtoPState = () => ({
    firstIntervalStart: '',
    firstIntervalEnd: '',
    secondIntervalStart: '',
    secondIntervalEnd: '',
    firstIntervalAverage: '',
    firstIntervalReference: '',
    secondIntervalAverage: '',
    secondIntervalReference: '',
  })

  getClearInputState = () => ({
    pointSelectionAtoP: { ...this.getClearSelectionAtoPState() },
    pointSelectionOffset: { ...this.getClearSelectionOffsetRange() },
    offset: '',
    slope: '',
  })

  constructor(props) {
    super(props)
    this.state = {
      zoomParams: CalibrationModal.getZoomParams(),
      lastPan: null,
      mode: 'phase',
      calibrating: false,
      resetting: false,
      type: 'offset-man',
      selectedField: 'startTime',
      ...this.getClearInputState(),
      ...this.getClearMessageState(),
    }
    this.calibration = new Calibration(this)
  }


  displayError = errorMessage => (
    this.setState({ ...this.getClearMessageState(), isError: true, errorMessage })
  )

  displaySuccess = (successMessage, successMessageHeader) => (
    this.setState({
      ...this.getClearMessageState(),
      isSuccess: true,
      successMessage,
      successMessageHeader,
    })
  )

  resetInputFields = e => {
    e.preventDefault()
    this.setState({ ...this.getClearInputState(), ...this.getClearMessageState() })
  }

  setSelectedField = (e, selectedField) => {
    e.preventDefault()
    this.setState({ selectedField })
  }

  deleteCalibrationEntry = async e => {
    e.preventDefault()
    this.setState({ resetting: true })
    const { dispatchCallDeleteCalibration, stream, activeDevice } = this.props
    const res = await dispatchCallDeleteCalibration(stream.id, activeDevice.id)

    if (res.success) {
      this.displaySuccess('', 'Calibration deleted successfully')
    } else {
      this.displayError(res.message)
    }
    this.setState({ resetting: false })
  }

  getDataPointFromEvent = ({ dataPoint }) => ({ time: dataPoint.x, value: dataPoint.y })

  selectPoints(data, e) {

    const { time } = this.getDataPointFromEvent(e)

    const { params: updatedPoints, nextField } = this.calibration.getUpdatedPointSelection(time)

    if (!updatedPoints) return
    this.setState({ ...updatedPoints, selectedField: nextField }, () => {
      const { params } = this.calibration.getUpdatedAverage(data)
      this.setState({ ...params, ...this.getClearMessageState })
    })
  }

  static getZoomParams = (
    XviewportMaximum = null,
    XviewportMinimum = null,
    YviewportMaximum = null,
    YviewportMinimum = null,
  ) => ({
    XviewportMaximum,
    XviewportMinimum,
    YviewportMaximum,
    YviewportMinimum,
    type: 'x',
  })

  reloadDataOnRangeChangeHandler = async event => {

    /*
     * Basic idea:
     *  determine how many datapoints are visible - if not enough (defined in
     *  CONSTANTS.MINIMUM_POINTS_IN_VIEWPORT), load x datapoints in the given range,
     *  plus additional datapoints to the left and right for panning;
     *  Because panning triggers rangeChange-event multiple times, its limited on
     *  once every 2 seconds. Override 'reset'-event to move back to current selected
     *  view (otherwise no render is triggered)
    */

    let reloadOnPan = false
    let newPan = null

    if (event.trigger === 'pan') {

      const { lastPan } = this.state

      newPan = Date.now()
      if (!lastPan || newPan - lastPan > this.CONSTANTS.PAN_DEBOUNCE_INTERVAL) {
        reloadOnPan = true
        this.setState({ lastPan: newPan })
      }
    }

    if (event.trigger === 'zoom' || reloadOnPan) {

      const { activeProcess, stream, dispatchCallLoadHistoricStreamData } = this.props

      const minTime = event.axisX[0].viewportMinimum
      const maxTime = event.axisX[0].viewportMaximum

      const completeStreamData = activeProcess.data
        .find(streamData => streamData.id === stream.id).dataPoints

      const datapointsInViewPort = completeStreamData
        .filter(dp => dp.x >= minTime && dp.x <= maxTime).length

      const minValue = event.axisY[0].viewportMinimum
      const maxValue = event.axisY[0].viewportMaximum

      const zoomParams = CalibrationModal
        .getZoomParams(maxTime, minTime, maxValue, minValue)


      if (datapointsInViewPort < this.CONSTANTS.MINIMUM_POINTS_IN_VIEWPORT) {

        /*
         * augment the selected viewport on the x-Axis by width = maxTime - minTime
         * to the left and right to have one 'width' of current viewport with the
         * right density loaded to the right and left for panning without reloading
         * immediately
         */

        const width = maxTime - minTime
        const start = minTime - width < 0 ? 0 : minTime - width
        const end = maxTime + width

        await dispatchCallLoadHistoricStreamData(
          activeProcess.id,
          stream.id,
          this.CONSTANTS.RELOAD_COUNT,
          start,
          end,
        )
      }
      this.setState({ zoomParams })
    } else if (event.trigger === 'reset') {
      this.resetZoomParams()
    }
  }

  resetZoomParams = () => {
    const zoomParams = CalibrationModal.getZoomParams()
    this.setState({ zoomParams })
  }

  handleTypeChange = (event, data) => {
    this.setState({ type: data.value, ...this.getClearMessageState() })
  }

  setOffset = event => {
    this.setState({ offset: event.target.value, ...this.getClearMessageState() })
  }

  setSlope = event => (
    this.setState({ slope: event.target.value, ...this.getClearMessageState() })
  )

  handleRefUpdate = event => (
    this.setState({ [event.target.name]: event.target.value, ...this.getClearMessageState() })
  )

  handleRefUpdateOffset = event => {

    const { pointSelectionOffset } = this.state

    const updatedPointSelectionOffsetRange = {
      ...pointSelectionOffset,
      offsetRangeReference: event.target.value,
    }

    this.setState({
      pointSelectionOffset: updatedPointSelectionOffsetRange,
      ...this.getClearMessageState(),
    })
  }

  handleRefUpdateAtoP = event => {

    const { pointSelectionAtoP } = this.state

    const updatedPointSelectionAtoP = {
      ...pointSelectionAtoP,
      [event.target.name]: event.target.value,
    }

    this.setState({
      pointSelectionAtoP: updatedPointSelectionAtoP,
      ...this.getClearMessageState(),
    })
  }

  getCalibrationInput = () => {
    const { type } = this.state
    const { activeDevice, stream } = this.props

    const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)

    switch (type) {
      case 'offset-man': {
        const { selectedField, pointSelectionOffset } = this.state
        return { displayedUnit, selectedField, pointSelectionOffset }
      }
      case 'offset-slope-man': {
        const { offset, slope } = this.state
        return { offset, slope, displayedUnit }
      }
      case 'offset-slope-avrg2p': {
        const { pointSelectionAtoP, selectedField } = this.state
        return { pointSelectionAtoP, displayedUnit, selectedField }
      }
      default:
        throw new Error(`Unknown calibration type: ${type}`)
    }
  }

  handleCalibrationSubmit = async () => {

    const { activeDevice, stream, dispatchCallSetStreamCalibration } = this.props
    const params = this.calibration.getParams()

    if (params.error) {
      this.displayError(params.error)
      return
    }

    this.setState({ calibrating: true })

    const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)
    const convertToOriginal = convertFrom(displayedUnit).to(stream.unit)

    // offset needs to be converted in relation to 0 of the chosen unit
    // slope is a relation and independent of the unit, so no conversion required - or?

    const convertedOffset = convertToOriginal(params.offset) - convertToOriginal(0)

    const res = await dispatchCallSetStreamCalibration(
      activeDevice.id,
      stream.id,
      params.slope,
      convertedOffset,
    )

    if (res.success) {
      const successMessage = `Calibrated with slope
        ${roundToTheNthDecimal(params.slope, UI_CONSTANTS.NUMBER_INPUT_DECIMALS)}
        and offset ${roundToTheNthDecimal(params.offset, UI_CONSTANTS.NUMBER_INPUT_DECIMALS)}
        ${replaceNoUnitCharacter(displayedUnit, '')}.`

      this.displaySuccess(successMessage, 'Calibration set successfully.')
    } else {
      this.displayError(res.message)
    }
    this.setState({ calibrating: false })
  }

  computeSlice(data) {

    const { mode, zoomParams } = this.state
    const { activeProcess, displayedPhaseId } = this.props

    const slicedStreamData = DataManager.sliceStreamData(
      activeProcess, data, mode, displayedPhaseId, this.CONSTANTS, zoomParams,
    )

    return DataManager.generatePhaseSeriesData(
      activeProcess, slicedStreamData, this.selectPoints.bind(this), { cursor: 'pointer' },
    )
  }

  renderOffsetMenu = menuParams => (
    <OffsetMenu
      menuParams={menuParams}
      setSelectedField={this.setSelectedField}
      handleRefUpdateOffset={this.handleRefUpdateOffset}
    />
  )

  renderOffsetAndSlopeMenu = menuParams => (
    <OffsetAndSlopeMenu
      menuParams={menuParams}
      setOffset={this.setOffset}
      setSlope={this.setSlope}
    />
  )

  renderAverageSelectionMenu = menuParams => (
    <AverageSelectionMenu
      handleRefUpdateAtoP={this.handleRefUpdateAtoP}
      menuParams={menuParams}
      setSelectedField={this.setSelectedField}
    />
  )

  static getCalibrationOptions = () => ([
    { key: 1, value: 'offset-man', text: 'Only Offset' },
    { key: 2, value: 'offset-slope-man', text: 'Linear Calibration (direct input)' },
    { key: 3, value: 'offset-slope-avrg2p', text: 'Linear Calibration (from selection)' },
  ])

  renderMenu(params) {

    const { type } = this.state

    switch (type) {
      case 'offset-man': {
        return this.renderOffsetMenu(params)
      }
      case 'offset-slope-man': {
        return this.renderOffsetAndSlopeMenu(params)
      }
      case 'offset-slope-avrg2p': {
        return this.renderAverageSelectionMenu(params)
      }
      default:
        return null
    }
  }

  renderFinishedProcessMessage = () => {
    const { activeProcess: { state } } = this.props

    if (state !== 'finished') return null

    return (
      <Message
        warning
        header='You are about to calibrate in a finished process.'
        content='Please make sure that the data you are about to use had no calibration applied to it.'
      />)
  }

  render() {

    const {
      calibrating,
      errorMessage,
      isError,
      isSuccess,
      resetting,
      successMessage,
      successMessageHeader,
      type,
      zoomParams,
    } = this.state

    const { activeProcess, activeDevice, stream, closeHandler } = this.props

    const completeStreamData = activeProcess.data
      .find(deviceStream => deviceStream.id === stream.id).dataPoints

    const streamData = this.computeSlice(completeStreamData)

    const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)
    const unitConverter = convertFrom(stream.unit).to(displayedUnit)

    applyUnitConversionOnData(streamData, unitConverter)

    this.calibration.attachGraphSlice(streamData, completeStreamData)

    const loaded = DataManager.isStreamDataLoaded(activeProcess, stream.id)

    return (
      <>
        {this.renderFinishedProcessMessage()}
        <ExistingCalibrationMessage
          activeDevice={activeDevice}
          stream={stream}
        />
        <RecalibrationInformationPopup />
        <Form
          error={isError}
          success={isSuccess}
          onSubmit={this.handleCalibrationSubmit}
          style={{ marginBottom: '15px', paddingBottom: '15px' }}
        >
          <Form.Select
            fluid
            label='Type'
            options={CalibrationModal.getCalibrationOptions()}
            value={type}
            onChange={this.handleTypeChange}
          />
          <div>
            <Graph
              displayedUnit={displayedUnit}
              stream={stream}
              data={streamData}
              zoomParams={zoomParams}
              Ymin={null}
              Ymax={null}
              includeZero
              reloadDataOnRangeChangeHandler={this.reloadDataOnRangeChangeHandler}
              loading={!loaded}
            />
            <Divider/>
            {this.renderMenu(this.getCalibrationInput())}
          </div>
          <Divider/>
          <Message
            error
            header='Error.'
            content={errorMessage}
          />
          <Message
            success
            header={successMessageHeader}
            content={successMessage}
          />
          <Footer
            activeDevice={activeDevice}
            stream={stream}
            calibrating={calibrating}
            resetting={resetting}
            resetInputFields={this.resetInputFields}
            deleteCalibrationEntry={this.deleteCalibrationEntry}
            closeHandler={closeHandler}
          />
        </Form>
      </>
    )
  }
}

export default modalize(connect(null, mapDispatchToProps)(CalibrationModal))
