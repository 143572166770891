import React, { useEffect } from 'react'
import { Container, Segment, Item, Grid, Header, Divider, Loader } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import callDeleteUserAlert from 'redux/thunks/event/callDeleteUserAlert'
import callGetDevices from 'redux/thunks/device/callGetDevices'
import { Device } from '../../utils/device'
import Alert from './Alert'

const mapStateToProps = state => ({
  user: state.user,
  devices: state.devices,
  devicesInitialized: state.devicesInitialized,
})

const mapDispatchToProps = dispatch => ({
  dispatchGetDevices: (cachedDevices, userId) => dispatch(callGetDevices(cachedDevices, userId)),
  dispatchDeleteUserAlert: alertId => dispatch(callDeleteUserAlert(alertId)),
})

const Alerts = ({
  user,
  devices,
  dispatchGetDevices,
  dispatchDeleteUserAlert,
  devicesInitialized,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [populatedAlerts, setAlerts] = React.useState([])

  useEffect(() => {
    const loadDevices = async () => {
      setLoading(true)
      await dispatchGetDevices(devices, user.id)
      setLoading(false)
    }
    const displayAlerts = async () => {
      const alerts = user.events

      alerts.sort((a, b) => (
        (a.createdAt < b.createdAt) ? 1 : -1
      ))

      const augmentedAlerts = alerts.map(alert => {
        const deviceName = Device.getById(devices, alert.deviceId)?.name
        return { deviceName, ...alert }
      })
      setAlerts(augmentedAlerts)
    }

    if (!devicesInitialized) {
      loadDevices()
    } else {
      displayAlerts()
    }

  }, [user, devices, dispatchGetDevices, devicesInitialized])

  if (loading) return <Loader active size='big' />

  return (
    <Grid>
      <Grid.Row>
        <Container fluid text>
          <Segment>
            <Header as='h2'>Alerts</Header>
            <Divider />
            <Item.Group divided>
              {populatedAlerts.map(alert => (
                <Alert key={alert.id} alert={alert} deleteAlert={dispatchDeleteUserAlert} />
              ))}
            </Item.Group>
          </Segment>
        </Container>
      </Grid.Row>
    </Grid>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Alerts))
