import { createListProcessObject } from 'redux/helper/objectMapper'
import { Process } from 'utils/process'

export default (state, action) => {
  const deviceMap = state.devices.reduce((acc, device) => {
    acc[device.id] = device
    return acc
  }, {})

  const newProcesses = action.payload.map(processData => {
    const process = createListProcessObject(processData)
    const processDevice = deviceMap[process.deviceId]
    process.state = Process.deriveProcessState(process, processDevice)
    return process
  })

  return { ...state, processes: [...newProcesses] }
}
