import { ACTIONS } from 'redux/actions/actions'
import setStreamTarget from 'redux/reducers/process/setStreamTarget'
import setPhaseProps from 'redux/reducers/process/setPhaseProps'
import deletePhase from 'redux/reducers/process/deletePhase'
import movePhase from 'redux/reducers/process/movePhase'
import switchPhase from 'redux/reducers/process/switchPhase'
import addPhase from 'redux/reducers/process/addPhase'
import setProcessBuilderValidationErrors from 'redux/reducers/process/setProcessBuilderValidationErrors'
import updateProcessDescriptionSnapshot from 'redux/reducers/process/updateProcessDescriptionSnapshot'
import addHistoricStreamData from 'redux/reducers/process/addHistoricStreamData'
import addLiveStreamData from 'redux/reducers/process/addLiveStreamData'
import setStreamDataErrorFlag from 'redux/reducers/process/setStreamDataErrorFlag'
import addProcessUser from 'redux/reducers/process/addProcessUser'
import modifyProcessAccess from 'redux/reducers/process/modifyProcessAccess'
import removeProcessUser from 'redux/reducers/process/removeProcessUser'
import updateProcess from 'redux/reducers/process/updateProcess'
import addProcessToProcessesList from 'redux/reducers/process/addProcessToProcessesList'
import addProcesses from 'redux/reducers/process/addProcesses'
import deleteProcesses from 'redux/reducers/process/deleteProcesses'
import createPhaseGroup from 'redux/reducers/process/createPhaseGroup'
import addPhaseWithinGroup from 'redux/reducers/process/addPhaseWithinGroup'
import deletePhaseWithinGroup from 'redux/reducers/process/deletePhaseWithinGroup'
import setPhaseGroupIterationsCount from 'redux/reducers/process/setPhaseGroupIterationsCount'
import appendPhaseToGroup from 'redux/reducers/process/appendPhaseToGroup'
import prependPhaseToGroup from 'redux/reducers/process/prependPhaseToGroup'
import renamePhaseGroup from 'redux/reducers/process/renamePhaseGroup'

export default {
  [ACTIONS.SET_STREAM_TARGET]: setStreamTarget,
  [ACTIONS.SET_PHASE_PROPS]: setPhaseProps,
  [ACTIONS.DELETE_PHASE]: deletePhase,
  [ACTIONS.ADD_PROCESS_PHASE]: addPhase,
  [ACTIONS.MOVE_PHASE]: movePhase,
  [ACTIONS.SWITCH_PROCESS_PHASE]: switchPhase,
  [ACTIONS.SET_PROCESSBUILDER_VALIDATION_ERRORS]: setProcessBuilderValidationErrors,
  [ACTIONS.UPDATE_PROCESS_DESCRIPTION_SNAPSHOT]: updateProcessDescriptionSnapshot,
  [ACTIONS.ADD_HISTORIC_STREAM_DATA]: addHistoricStreamData,
  [ACTIONS.ADD_LIVE_STREAM_DATA]: addLiveStreamData,
  [ACTIONS.SET_STREAM_DATA_ERROR_FLAG]: setStreamDataErrorFlag,
  [ACTIONS.ADD_PROCESS_USER]: addProcessUser,
  [ACTIONS.MODIFY_PROCESS_ACCESS]: modifyProcessAccess,
  [ACTIONS.REMOVE_PROCESS_USER]: removeProcessUser,
  [ACTIONS.UPDATE_PROCESS]: updateProcess,
  [ACTIONS.ADD_PROCESS_TO_LIST]: addProcessToProcessesList,
  [ACTIONS.ADD_PROCESSES]: addProcesses,
  [ACTIONS.DELETE_PROCESSES]: deleteProcesses,
  [ACTIONS.CREATE_PHASE_GROUP]: createPhaseGroup,
  [ACTIONS.ADD_PHASE_WITHIN_GROUP]: addPhaseWithinGroup,
  [ACTIONS.DELETE_PHASE_WITHIN_GROUP]: deletePhaseWithinGroup,
  [ACTIONS.SET_PHASE_GROUP_ITERATIONS_COUNT]: setPhaseGroupIterationsCount,
  [ACTIONS.APPEND_PHASE_TO_GROUP]: appendPhaseToGroup,
  [ACTIONS.PREPEND_PHASE_TO_GROUP]: prependPhaseToGroup,
  [ACTIONS.RENAME_PHASE_GROUP]: renamePhaseGroup,
}
