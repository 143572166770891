import nexus from 'nexus'
import { deleteStreamConfigurationProperty } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callDeleteCalibration = async (dispatch, streamId, deviceId) => {
  await nexus.device.deleteCalibration(deviceId, { streamId })
  dispatch(deleteStreamConfigurationProperty({ deviceId, streamId, prop: 'calibration' }))
}

export default thunkWrapper(callDeleteCalibration)
