import nexus from 'nexus'
import { modifyDeviceAccess } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callModifyDeviceAccess = async (dispatch, deviceId, userId, newGroup, oldGroup) => {
  await nexus.device.modifyAccess(deviceId, { userId, newGroup, oldGroup })
  dispatch(modifyDeviceAccess({
    userId,
    newGroup,
    oldGroup,
    deviceId,
  }))
}

export default thunkWrapper(callModifyDeviceAccess)
