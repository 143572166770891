import React from 'react'
import { Button, Dropdown, Pagination, Menu } from 'semantic-ui-react'


const perPageOptions = [
  { text: '5', value: 5 },
  { text: '10', value: 10 },
  { text: '20', value: 20 },
  { text: '50', value: 50 },
]

const selectActions = [
  { text: 'None', value: 'none' },
  { text: 'Delete', value: 'delete' },
]

const ProcessesTableControls = ({
  activePage,
  totalPages,
  updateActivePage,
  updateResultsPerPage,
  updateSelectedAction,
  executeSelectedAction,
  executingAction,
  allSelected,
  handleSelectAll,
}) => (
  <Menu fluid>
    <Menu.Menu>
      <Menu.Item>
        <Pagination
          defaultActivePage={activePage}
          totalPages={totalPages}
          onPageChange={updateActivePage}
        />
      </Menu.Item>
      <Menu.Item>
        <span>
          Results per page: {' '}
          <Dropdown
            defaultValue={perPageOptions[1].value}
            selection
            options={perPageOptions}
            compact
            onChange={updateResultsPerPage}
          />
        </span>
      </Menu.Item>
    </Menu.Menu>
    <Menu.Menu position='right'>
      <Menu.Item>
        <span>
          Select action:
          {' '}
          <Dropdown
            defaultValue={selectActions[0].value}
            selection
            options={selectActions}
            onChange={updateSelectedAction}
          />
          <span>&nbsp;</span>
          <Button
            onClick={executeSelectedAction}
            loading={executingAction}
          >
            Apply
          </Button>
          <Button
            onClick={handleSelectAll}
            floated='right'
          >
            {!allSelected ? 'Select' : 'Deselect'} all
          </Button>
        </span>
      </Menu.Item>
    </Menu.Menu>
  </Menu>
)

export default ProcessesTableControls
