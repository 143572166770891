import React from 'react'
import { Form } from 'semantic-ui-react'

const OffsetAndSlopeMenu = ({
  menuParams,
  setOffset,
  setSlope,
}) => {

  const {
    displayedUnit,
    offset,
    slope,
  } = menuParams

  return (
    <Form.Group widths='equal'>
      <Form.Input
        fluid
        label={`Offset [${displayedUnit}] (default 0)`}
        placeholder='offset..'
        value={offset}
        onChange={setOffset}
      />
      <Form.Input
        fluid
        label='Slope (default 1)'
        placeholder='slope..'
        value={slope}
        onChange={setSlope}
      />
    </Form.Group>
  )
}

export default OffsetAndSlopeMenu
