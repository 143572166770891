import nexus from 'nexus'
import { updateDevice } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callUpdateDevice = async (dispatch, deviceId, params) => {
  await nexus.device.update(deviceId, params)
  dispatch(updateDevice({ deviceId, params }))
}

export default thunkWrapper(callUpdateDevice)
