import React, { useState } from 'react'
import { Menu, Grid, Divider, Button, Icon } from 'semantic-ui-react'
import { connect } from 'react-redux'

import Authorizer from 'utils/Authorizer'
import UserTable from './UserTable'
import SupportTable from './SupportTable'
import AdminToolbar from './AdminToolbar'
import AccessDeviceModal from '../modals/AccessDeviceModal'
import TransferOwnershipModal from '../modals/TransferOwnershipModal'


const mapStateToProps = state => ({
  user: state.user,
  deviceUsers: state.deviceUsers,
})

const renderAdminToolbar = activeDevice => (
  <>
    <Grid.Row>
      <AdminToolbar activeDevice={activeDevice} />
    </Grid.Row>
    <Divider />
  </>
)

const ManageAccess = ({
  activeDevice,
  user,
  deviceUsers,
}) => {

  const [ showNewUserModal, toggleNewUserModal ] = useState(false)
  const [ showTransferOwnershipModal, toggleTransferOwnershopModal ] = useState(false)

  const supportUsers = deviceUsers.filter(deviceUser => deviceUser.accessGroup === 'support')
  return (
    <div>
      {Authorizer.user(user).hasDeveloperAccess() ? renderAdminToolbar(activeDevice) : null}
      <Grid.Row key={0}>
        <Menu>
          <Menu.Item>
            <Button
              primary
              icon
              labelPosition='left'
              onClick={() => toggleNewUserModal(true)}
              disabled={!Authorizer.user(user).hasManageAccessRights(activeDevice)}
            >
              <Icon name='plus' />
              Add User
            </Button>
          </Menu.Item>
          <Menu.Item>
            <Button
              primary
              onClick={() => toggleTransferOwnershopModal(true)}
              disabled={!Authorizer.user(user).isOwnerOf(activeDevice)}
            >
              Transfer Ownership
            </Button>
          </Menu.Item>
        </Menu>
      </Grid.Row>
      <Divider />
      <Grid.Row>
        <UserTable
          activeDevice={activeDevice}
        />
        <AccessDeviceModal
          headerIcon='user plus'
          headerText=' Add User'
          open={showNewUserModal}
          activeDevice={activeDevice}
          closeHandler={() => toggleNewUserModal(false)}
        />
        <TransferOwnershipModal
          open={showTransferOwnershipModal}
          activeDevice={activeDevice}
          closeHandler={() => toggleTransferOwnershopModal(false)}
        />
        <br />
        <SupportTable activeDevice={activeDevice} supportUsers={supportUsers} />
      </Grid.Row>
    </div>
  )
}

export default connect(mapStateToProps, null)(ManageAccess)
