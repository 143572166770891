import React from 'react'
import { Button } from 'semantic-ui-react'

const ModalActions = ({
  submitHandler,
  submitText = 'Confirm',
  loading,
  closeHandler,
  closeText = 'Close',
  showSubmitButton = true,
}) => (
  <div>
    {showSubmitButton && (
      <Button
        floated='right'
        primary
        type='submit'
        loading={loading}
        onClick={submitHandler}
      >
        {submitText}
      </Button>
    )}
    <Button floated='right' onClick={closeHandler}>
      {closeText}
    </Button>
  </div>
)

export default ModalActions
