import React from 'react'
import { connect } from 'react-redux'
import { Modal, Form, Icon, Input, Divider, Button, Message } from 'semantic-ui-react'

import callSetStreamNameAlias from 'redux/thunks/device/callSetStreamNameAlias'

const mapDispatchToProps = dispatch => ({
  callSetStreamNameAlias: (streamId, deviceId, alias) => dispatch(callSetStreamNameAlias(streamId,
    deviceId, alias)),
})

class SetStreamNameAliasModal extends React.Component {

  initialState = {
    isError: false,
    errorMessage: '',
    isLoading: false,
    isSuccess: false,
    streamAlias: '',
    targetStreamId: '',
  }

  state = { ...this.initialState }

  submitNewAliasName = async () => {

    const { targetStreamId, streamAlias } = this.state

    this.setState({ isLoading: true })

    const res = await this.props.callSetStreamNameAlias(targetStreamId,
      this.props.activeDevice.id, streamAlias)

    if (res.success) {
      this.setState({
        isSuccess: true,
        isError: false,
        errorMessage: '',
        isLoading: false,
      })
    } else {
      this.setState({
        isError: true,
        errorMessage: res.message,
        isSuccess: false,
        isLoading: false,
      })
    }
  }

  handleUpdate = (e, { name, value }) => this.setState({ [name]: value })

  clearStateAndClose = () => {
    const { closeHandler } = this.props
    this.setState(this.initialState)
    closeHandler()
  }

  render() {

    const { isError, errorMessage, isLoading, isSuccess, streamAlias, targetStreamId } = this.state
    const { streams, streamNameAliases } = this.props.activeDevice

    const streamOptions = streams
    //.filter(stream => !stream.controller || stream.controller.type === 'target')
      .map((stream, i) => ({
        value: stream.id,
        text: stream.name,
        key: i,
      }))

    const currentAlias = streamNameAliases
      .find(aliasEntry => aliasEntry.streamId === targetStreamId)

    return (
      <Modal
        size='tiny'
        open={this.props.open}
        onClose={this.clearStateAndClose}
        onClick={event => event.stopPropagation()}
      >
        <Modal.Header>
          <Icon name='edit' />
          Set Stream Name Alias
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form
              error={isError}
              success={isSuccess}
              onSubmit={this.submitNewAliasName}
              style={{ marginBottom: '15px', paddingBottom: '15px' }}
            >
              <Form.Group widths='equal'>
                <Form.Select
                  fluid
                  name='targetStreamId'
                  label='Default Name'
                  options={streamOptions}
                  onChange={this.handleUpdate}
                  placeholder='Choose Stream'
                  value={targetStreamId}
                />
                <Form.Field>
                  <label>Current Alias</label>
                  <Input
                    fluid
                    type='text'
                    readOnly
                    value={currentAlias ? currentAlias.alias : 'None'}
                  />
                </Form.Field>
                <Form.Field>
                  <label>New Alias</label>
                  <Input
                    fluid
                    name='streamAlias'
                    placeholder='new alias...'
                    type='text'
                    required
                    value={streamAlias}
                    onChange={this.handleUpdate}
                  />
                </Form.Field>
              </Form.Group>
              <Message
                error
                header='Something went wrong.'
                content={errorMessage}
                icon='warning circle'
              />
              <Message
                success
                header='Success!'
                content={<p>Alias was set.</p>}
                icon='warning circle'
              />
              <Divider/>
              <Button
                floated='right'
                primary
                type='submit'
                loading={isLoading}
              >
                Submit
              </Button>
              <Button
                floated='right'
                onClick={this.clearStateAndClose}
              >
                Close
              </Button>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    )
  }
}

export default connect(null, mapDispatchToProps)(SetStreamNameAliasModal)
