import React from 'react'
import { Icon, Item, Button } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import callAcceptDeviceInvitation from 'redux/thunks/device/callAcceptDeviceInvitation'
import callDeleteUserNotification from 'redux/thunks/user/callDeleteUserNotification'

const mapStateToProps = state => ({
    user: state.user,
})

const mapDispatchToProps = dispatch => ({
    callAcceptDeviceInvitation: (userId, invitationId) => dispatch(callAcceptDeviceInvitation(userId, invitationId)),
    callDeclineDeviceInvitation: (userId, invitationId) => dispatch(callDeleteUserNotification(userId, invitationId))
})

const Invitation = ({ inv, user, callDeclineDeviceInvitation, callAcceptDeviceInvitation })  => {

  return (
    <Item>
      <Icon name='envelope' size='large'/>
      <Item.Content>
        <Item.Header as='a'>Invitation</Item.Header>
        <Item.Meta>
          <span className='cinema'>by {inv.from.name}</span>
        </Item.Meta>
        <Item.Description>{inv.data.message}</Item.Description>
        <Item.Extra>
          <Button
            floated='right'
            onClick={() => callDeclineDeviceInvitation(user.id, inv.id)}
          >
            Decline
          </Button>
          <Button
            primary
            floated='right'
            onClick={() => callAcceptDeviceInvitation(user.id, inv.id)}
          >
            Accept
          </Button>
        </Item.Extra>
      </Item.Content>
    </Item>)
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invitation))
