import React from 'react'
import { Table, Checkbox, Dropdown, Button, Icon, Popup, List } from 'semantic-ui-react'
import Time from 'utils/Time'
import { PROCESS_STATES } from 'utils/process'

const getDuration = (state, startedAt, finishedAt) => {
  if (finishedAt && startedAt) return Time.stringFromDuration((finishedAt - startedAt) / 1000)
  if (state === PROCESS_STATES.finished) return 'Not exposed'
  return 'N/A'
}

const getFinishedString = (state, finishedAt) => {
  if (finishedAt) return Time.getTrimmedDateString(finishedAt)
  if (state === PROCESS_STATES.finished) return 'Not exposed'
  return 'N/A'
}

const ProcessRow = ({
  process,
  deviceUsers,
  checked,
  openModalHandler,
  selectHandler,
  processClickHandler,
}) => {

  const {
    name,
    comment,
    state,
    createdAt,
    startedAt,
    finishedAt,
    cloneHistory,
    ownerId,
  } = process

  const getDisplayableProcessOwner = () => {
    const owner = deviceUsers.find(user => user.id === ownerId)
    return owner ? owner.userName : 'user not found'
  }

  const renderProcessHistory = () => (
    <List>
      {cloneHistory.length > 0
        ? <>
          {cloneHistory.map(entry => (
            <List.Item key={entry.id} >
              <Icon
                name='level up alternate'
                flipped='horizontally'
                size='small'
              />
              {entry.name}
            </List.Item>))}
        </>
        : <div>- No history yet -</div> }
    </List>
  )

  const duration = getDuration(state, startedAt, finishedAt)
  const finishedString = getFinishedString(state, finishedAt)

  return (
    <Table.Row key={process.id} positive={process.status === 'running'}>
      <Table.Cell collapsing>
        <Dropdown item closeOnChange>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => openModalHandler('showCloneModal', process)} icon='clone' text='Clone' />
            <Dropdown.Item onClick={() => openModalHandler('showAccessProcessModal', process)} icon='users' text='Access' />
            <Dropdown.Item icon='edit' text='Edit' onClick={() => openModalHandler('showEditModal', process)} />
          </Dropdown.Menu>
        </Dropdown>
      </Table.Cell>
      <Table.Cell>
        <span className='process-link' onClick={() => processClickHandler(process)}>{name}</span>
      </Table.Cell>
      <Table.Cell>{comment}</Table.Cell>
      <Table.Cell collapsing>
        <Popup
          trigger={
            <Button icon compact basic labelPosition='left'>
              <Icon name='code branch'/>
              Show
            </Button>
          }
          content={renderProcessHistory()}
          position='bottom center'
        />
      </Table.Cell>
      <Table.Cell>{state}</Table.Cell>
      <Table.Cell>{getDisplayableProcessOwner()}</Table.Cell>
      <Table.Cell>{Time.getTrimmedDateString(createdAt)}</Table.Cell>
      <Table.Cell>{finishedString}</Table.Cell>
      <Table.Cell>{duration}</Table.Cell>
      <Table.Cell collapsing>
        <Checkbox
          checked={checked}
          onChange={() => selectHandler()}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default ProcessRow
