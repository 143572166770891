import Streams from 'utils/Streams'
import DeviceConfig from 'utils/DeviceConfig'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'
import { replaceNoUnitCharacter, convertManyFrom } from 'utils/units'
import DescriptionParser from 'utils/process/DescriptionParser'
import Validator from 'utils/validation/Validator'

export default class ProcessValidator extends Validator {

  static VALIDATION_MESSAGES = {
    TooLarge: 'The process is too large. Please reduce the number of phases and/or cycle iterations'
  }

  static VALIDATION_ERRORS = new Set([
    'MissingDeviceStreamError',
    'MissingProcessStreamError',
    'TransitionError',
    'StreamTargetError',
    'TooLarge',
  ])

  static VALID_PROCESS_DATA = {
    nameLength: 45,
    commentLength: 250,
  }

  static isValidProcessName = string => (
    ProcessValidator
      .lessThanOrEqual(string.length, ProcessValidator.VALID_PROCESS_DATA.nameLength)
  )

  static isValidProcessComment = string => (
    ProcessValidator
      .lessThanOrEqual(string.length, ProcessValidator.VALID_PROCESS_DATA.commentLength)
  )

  static createError(type, message, phaseName = undefined) {
    if (!ProcessValidator.VALIDATION_ERRORS.has(type)) {
      throw new Error(`Unknown validation error type: ${type}`)
    }

    return {
      type,
      message: message || ProcessValidator.VALIDATION_MESSAGES[type],
      phaseName,
    }
  }

  static createStreamErrors(streams, type, msgFn) {
    return streams.map(stream => (
      ProcessValidator.createError(type, msgFn(stream))
    ))
  }

  static createTooLargeError() {
    return ProcessValidator.createError('TooLarge')
  }

  static validateDescriptionMismatch(process, device) {
    const { missingInDevice, missingInProcess } = ProcessValidator
      .getMismatchingStreams(process, device)

    const missingDeviceStreamErrors = ProcessValidator.createStreamErrors(
      missingInDevice,
      'MissingDeviceStreamError',
      stream => `Your process description includes a not connected device stream: ${stream.name}`,
    )

    const missingProcessStreamErrors = ProcessValidator.createStreamErrors(
      missingInProcess,
      'MissingProcessStreamError',
      stream => `Your process description is missing a connected device stream:
        ${DeviceConfig.getDisplayedStreamName(device, stream.id)}`,
    )

    return [ ...missingDeviceStreamErrors, ...missingProcessStreamErrors ]
  }

  static getMissingStreams(streamsA, streamsB) {
    // returns all streams that are in A but not in B
    return streamsA.filter(streamOfA => !Streams.contains(streamsB, streamOfA.id))
  }

  static getMismatchingStreams(process, device) {
    const { deviceDescription } = process
    const { streams } = device

    const missingInDevice = ProcessValidator.getMissingStreams(deviceDescription, streams)
    const missingInProcess = ProcessValidator.getMissingStreams(streams, deviceDescription)

    return { missingInDevice, missingInProcess }
  }

  static validateDescription(process, device) {
    const finishingMethodErrors = ProcessValidator.validateFinishingMethod(process)
    const streamTargetErrors = ProcessValidator.validateStreamTargets(process, device)
    const descriptionMismatchErrors = ProcessValidator.validateDescriptionMismatch(process, device)

    return [...finishingMethodErrors, ...streamTargetErrors, ...descriptionMismatchErrors]
  }

  static validateFinishingMethod(process) {
    return DescriptionParser
      .getPhasesArray(process)
      .filter(({ transition, duration }) => transition === 'time-based' && duration <= 0)
      .map(({ name }) => (
        ProcessValidator.createError(
          'TransitionError',
          'No duration time for time-based transition provided.',
          name,
        )
      ))
  }

  static validateFloatStream({ device, phase, target, stream }) {
    const { displayedUnit } = DeviceConfig.getConfiguredStream(device, stream)

    const streamValues = [ target, stream.min, stream.max ]

    const customConversion = CustomConversions
      .getCustomConversionsByTargetUnit(device, stream.id, displayedUnit)

    const [ displayedTarget, displayedMin, displayedMax ] = customConversion
      ? streamValues.map(value => CustomConversions.convertWithRatio(value, customConversion))
      : convertManyFrom(stream.unit).to(displayedUnit)(streamValues)

    if (!ProcessValidator.isNumber(target)) {
      return ProcessValidator.createError(
        'StreamTargetError',
        `Invalid target value ${target} in group ${stream.groupName} at stream ${stream.name}`,
        phase.name,
      )
    }

    if (!ProcessValidator.withinRange(target, [stream.min, stream.max])) {
      return ProcessValidator.createError(
        'StreamTargetError',
        `Value in group ${stream.groupName} at stream ${stream.name} is out of limits.
          Provided ${displayedTarget} ${replaceNoUnitCharacter(displayedUnit, '')},
          limits are [${displayedMin} ${replaceNoUnitCharacter(displayedUnit, '')},
          ${displayedMax} ${replaceNoUnitCharacter(displayedUnit, '')}]`,
        phase.name,
      )
    }
  }

  static validateBoolean({ target, phase, stream }) {
    if (!ProcessValidator.isBoolean(target)) {
      return ProcessValidator.createError(
        'StreamTargetError',
        `Invalid target value ${target} in group ${stream.groupName} at stream ${stream.name}. Expected true or false.`,
        phase.name,
      )
    }
  }

  static validateOneOf() {
    // TODO
  }

  static validateByStreamType(params) {
    const { stream: { expectedValueType } } = params

    if (!(Streams.EXPECTED_VALUE_TYPE.has(expectedValueType))) {
      throw new Error(`Unknown expected value type ${expectedValueType} provided`)
    }

    switch (expectedValueType) {
      case 'boolean':
        return ProcessValidator.validateBoolean(params)
      case 'oneOf':
        return ProcessValidator.validateOneOf()
      default:
        return ProcessValidator.validateFloatStream(params)
    }
  }

  static validatePhaseStreamTargets(device, process, { id, data: phase }) {
    const streamIds = Object.keys(phase.downstreams)

    return streamIds
      .map(streamId => {
        const stream = Streams.getById(process.deviceDescription, streamId)
        const target = DescriptionParser.getStreamTarget(process, id, stream.id)
        return ProcessValidator.validateByStreamType({ device, phase, target, stream })
      })
      .filter(error => error)
  }

  static validateStreamTargets(process, device) {
    const phases = DescriptionParser.getPhasesSequence(process)

    return phases.reduce((acc, phase) => (
      [ ...acc, ...ProcessValidator.validatePhaseStreamTargets(device, process, phase) ]
    ), [])
  }
}
