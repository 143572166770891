import React from 'react'
import CanvasJSReact from 'lib/canvasjs.react'
import { Segment, Header } from 'semantic-ui-react'

import { replaceNoUnitCharacter } from 'utils/units'
import { roundToTheNthDecimal } from 'utils/number'
import Time from 'utils/Time'
import UI_CONSTANTS from 'config/ui'

const { CanvasJSChart } = CanvasJSReact

const objectsOnly = arr => arr.filter(item => (
  typeof item === 'object' && !Array.isArray(item)
))

function getTotalDuration(data) {
  const lastDataSetPoints = data[data.length - 1].dataPoints
  if (lastDataSetPoints.length === 0) return
  const lastXValue = lastDataSetPoints[lastDataSetPoints.length - 1].x

  return lastXValue
}

function renderNoDataPlaceHolder(streamName, loading) {
  return (
    <Segment
      placeholder
      className='graph-placeholder'
      loading={loading}
    >
      {!loading
      && (
        <Header icon color='grey'>
          {streamName}
        : No data found.
        </Header>
      )}
    </Segment>
  )
}

const Graph = ({
  displayedUnit,
  stream,
  zoomParams,
  includeZero,
  data,
  Ymin,
  Ymax,
  reloadDataOnRangeChangeHandler,
  loading,
}) => {

  // this following is in place to solve: https://github.com/ospin-web-dev/REACTor/issues/897
  // to eleminate this tech debt, please take a look at: https://github.com/ospin-web-dev/REACTor/issues/904
  const safeData = objectsOnly(data)

  if (safeData.length === 0) {
    return renderNoDataPlaceHolder(stream.name, loading)
  }

  const totalDuration = getTotalDuration(safeData)
  const derivedTimeDisplayFormat = Time.deriveTimeDisplayFormat(totalDuration)
  const options = {
    rangeChanged: e => reloadDataOnRangeChangeHandler(e),
    zoomEnabled: true,
    zoomType: zoomParams.type,
    exportEnabled: true,
    theme: 'light2', // "light1", "dark1", "dark2"
    title: {
      text: stream.name,
      fontSize: 18,
    },
    axisY: {
      title: displayedUnit,
      includeZero,
      titleFontSize: 16,
      maximum: Ymax,
      minimum: Ymin,
      viewportMinimum: zoomParams.YviewportMinimum,
      viewportMaximum: zoomParams.YviewportMaximum,
      gridThickness: 1,
    },
    axisX: {
      title: 'Time',
      labelFormatter: xAxis => Time.timeAxisLabelFormat(xAxis.value, derivedTimeDisplayFormat),
      titleFontSize: 16,
      viewportMinimum: zoomParams.XviewportMinimum,
      viewportMaximum: zoomParams.XviewportMaximum,
      gridThickness: 1,
    },
    toolTip: {
      contentFormatter: ({ entries }) => {
        const seconds = entries[0].dataPoint.x
        const x = Time.timeAxisLabelFormat(seconds, derivedTimeDisplayFormat)

        return `${x} : ${roundToTheNthDecimal(entries[0].dataPoint.y, UI_CONSTANTS.NUMBER_INPUT_DECIMALS)} ${replaceNoUnitCharacter(displayedUnit, '')}`
      },
    },
    data: safeData,
  }

  return (
    <div
      onMouseDown={e => e.stopPropagation()}
      onClick={e => e.stopPropagation()}
      data-testid={`stream-canvas-${stream.name}`}
    >
      <CanvasJSChart options={options} />
    </div>
  )
}

export default Graph
