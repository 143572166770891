import OutOfBoundaryTrigger from 'utils/EventTrigger/OutOfBoundaryTrigger'


class LowerLimitTrigger extends OutOfBoundaryTrigger {

  static get type() {
    return 'LOWER_LIMIT'
  }

  static get description() {
    return 'lower boundary'
  }

  static get parameters() {
    return [
      { name: 'threshold', label: 'lower boundary', type: 'number' },
    ]
  }

  static invalidParameters = (trigger, target) => {
    const { threshold } = trigger
    return target <= threshold
  }

  static create = ({ threshold }, eventType) => ({
    type: LowerLimitTrigger.type,
    threshold,
    eventType,
  })
}

export default LowerLimitTrigger
