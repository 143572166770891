import React from 'react'
import { Message, Divider } from 'semantic-ui-react'
import modalize from 'components/utility/modal/modalize'
import DescriptionMismatchModalForm from 'components/processviewer/modals/DescriptionMismatchModalForm'


const explanationMessage = [
  'Update: Choose if you want to adapt the process description '
    + 'to the device changes. This will delete redundant streams from the process and add '
    + 'missing streams with default parameters',
  'Close: Choose if you want to keep the process as it is. You will not be able to start '
    + 'this process with the current device configuration',
]

const DescriptionMismatchModal = ({
  missMatches,
  activeDevice,
  closeHandler,
  amendDescriptionMisMatchHandler,
}) => (
  <>
    Your process seems to be not up to date with the current device configuration.
    <Divider/>
    <Message
      info
      header='Action required.'
      list={explanationMessage}
      className='max-width-498'
    />
    <DescriptionMismatchModalForm
      closeHandler={closeHandler}
      submitHandler={amendDescriptionMisMatchHandler}
      missMatches={missMatches}
      activeDevice={activeDevice}
    />
  </>
)

export default modalize(DescriptionMismatchModal)
