import React from 'react'
import { connect } from 'react-redux'
import { Form, Input, Popup } from "semantic-ui-react"

import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import { phaseIsModifiable, Process, PROCESS_STATES } from "utils/process"
import DescriptionParser from "utils/process/DescriptionParser"
import { setStreamTarget } from 'redux/actions/actions'
import DeviceConfig from 'utils/DeviceConfig'
import { replaceNoUnitCharacter } from 'utils/units'

const mapDispatchToProps = dispatch => ({
  dispatchSetStreamTarget: (processId, phaseId, streamId, value) => dispatch(setStreamTarget({
    processId,
    phaseId,
    streamId,
    value,
  })),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

const ControllerStream = ({
  activeProcess,
  activeDevice,
  phaseId,
  stream,
  dispatchSetStreamTarget,
  dispatchCallUpdateProcess,
  resetSuccessMessage,
}) => {

  const handleSubmit = event => {
    dispatchSetStreamTarget(activeProcess.id, phaseId, stream.id, event.target.value)
    resetSuccessMessage()
  }

  const save = async() => {
    const target = DescriptionParser.getStreamTarget(activeProcess, phaseId, stream.id)
    dispatchSetStreamTarget(activeProcess.id, phaseId, stream.id, parseFloat(target))
    if (activeProcess.state === PROCESS_STATES.executable) {
      await Process.save(activeProcess, dispatchCallUpdateProcess)
    }
  }

  const target = DescriptionParser.getStreamTarget(activeProcess, phaseId, stream.id)
  const defaultValue = DeviceConfig.getCustomStreamDefault(activeDevice, stream.id)
  const unit = replaceNoUnitCharacter(stream.unit, '')
  const limits = `min: ${stream.min} | max: ${stream.max}`

  const disabledInput = !phaseIsModifiable(activeProcess, phaseId)

  return (
    <Form.Field error={target > stream.max || target < stream.min}>
      <Popup
        trigger={
          <label>
            {stream.name}
            <span style={{ color: 'grey', marginLeft: '8px' }}>
              {`[ default: ${defaultValue}${unit} ]`}
            </span>
          </label>
        }
        content={limits}
      />
      <Input
        fluid
        name={stream.name}
        type="text"
        min={stream.min}
        max={stream.max}
        required
        value={target}
        onChange={handleSubmit}
        onBlur={save}
        readOnly={disabledInput}
      />
    </Form.Field>
  )
}

export default connect(null, mapDispatchToProps)(ControllerStream)
