class PhaseProgression {

  static getLatest(process) {
    return process.progression[process.progression.length - 1]
  }

  static contains(process, phaseId) {
    return process.progression.some(entry => entry.phaseId === phaseId)
  }

  static getPhaseById(process, phaseId) {
    return process.progression.find(aPhase => aPhase.phaseId === phaseId)
  }

  static getSecondsSinceProcessStart(process, phaseId) {
    const processStart = process.progression[0].startTime
    const phase = PhaseProgression.getPhaseById(process, phaseId)

    if (phase) {
      const phaseStart = phase.startTime
      return (phaseStart - processStart) / 1000
    }
    return Infinity
  }

  static getSecondsSincePhaseStart(process, phaseId) {
    const phase = PhaseProgression.getPhaseById(process, phaseId)
    if (phase) return Math.round(((Date.now() - phase.startTime) / 1000))

    return 0
  }

  static noPhaseObject() {
    return { phaseId: -1, startTime: -1 }
  }
}

export default PhaseProgression
