import React from 'react'
export default {
  connectedStreams: <p>
    A stream is a communication channel between the device and the web application.
    There are two types of streams in the OSPIN application:<br/><br/>
    a. <strong>upstreams</strong> as in a stream from the device <strong>up</strong>
    &nbsp;to the web application.<br/>
    b. <strong>downstreams</strong> as in a stream from the web application <strong>down</strong>
    &nbsp;to the device.<br/><br/>
    Everything that is sent by the device, such as measured temperature, pH, or pO2,
    is associated with an upstream. Values of upstreams are shown in a processes
    data graphs and the device dashboard. Everyhing that is communicated to the device,
    such as pump speed or controller values, is associated with a downstream.
    Values for downstreams are set in the process builder.</p>,
  streamDefaults: <p>
    Every input that you can provide to the device (downstream)
    has a value set by default. You can overwrite these default values here.</p>,
  streamNameAliases: <p>
    Every stream on the device has a name set by default.
    You can overwrite these default values here.</p>,
  calibrations: <p>
    Every sensor on the device can be calibrated using the calibration
    menu present on the sensor's associated graph.
    This section provides an overview on current calibrations.</p>,
  pumpCalibration: <p>
    Calibrate pumps in order to use flow rate instead of rpm values.</p>,
  units: <p>
    Every stream on the device
    comes with a default unit.
    You can overwrite these default values here.</p>,
  alerts: <p>
    Here you can define default alerts which are automatically added to every process
    that is created on the device. You still have full control to modify or delete the
    alerts on specific processes (see the 'Alerts' tab in the process)
    without affecting the default alerts defined in this section.</p>,
}
