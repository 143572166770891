import PhaseGroupHandler from 'utils/process/PhaseGroupHandler'
import PhaseGroupParser from 'utils/process/PhaseGroupParser'
import PDVC from 'utils/PDVC'

export default (state, action) => {
  const { groupName, processId } = action.payload
  const { processes, processDescriptionSnapshots } = state

  let displayedPhaseId = ''

  const updatedProcesses = processes.map(process => {
    if (process.id === processId) {
      const snapshot = PDVC.getSnapshot(processId, processDescriptionSnapshots)
      const updatedProcess = PhaseGroupHandler.createPhaseWithinGroup(process, groupName, snapshot)
      displayedPhaseId = PhaseGroupParser.getIterationEndPhaseIds(updatedProcess, groupName)[0]
      return { ...updatedProcess }
    }
    return process
  })

  return { ...state, processes: updatedProcesses, displayedPhaseId }
}
