import React from 'react'
import { Button, Card, Container, Popup } from 'semantic-ui-react'
import './ProcessTileIconBar.css'
import NoDataMessage from 'components/utility/NoDataMessage'

const getIconStyle = iconName => ({
  backgroundColor: 'white',
  padding: '0.5em',
  color: (iconName === 'star' && '#FFB500'),
})

const renderIcon = ({ icon, popupText, disabled, handler, showIcon }) => (
  <Popup
    content={popupText}
    basic
    position='bottom left'
    key={icon}
    style={{ pointerEvents: 'none' }} // prevents MouseLeaveEvent bug after hovering icon
    trigger={
      <Button
        className={`float-right margin-l-4 ${(showIcon ? 'reveal-icon' : 'hide-icon')}`}
        disabled={disabled}
        size='tiny'
        circular
        style={getIconStyle(icon)}
        icon={icon}
        onClick={handler}
      />
    }
  />
)

const renderIcons = (
  isActive,
  onToggleOverlayToDeleteProcess,
  toggleModal,
  handleToggleFavorite,
  isPinned,
  showIcons,
  comment,
) => {
  const iconData = [
    {
      icon: isPinned ? 'star' : 'star outline',
      popupText: 'favorite',
      disabled: false,
      handler: (e) => handleToggleFavorite(e),
      showIcon: isPinned ? true : showIcons,
    },
    {
      icon: 'trash',
      popupText: 'delete',
      disabled: isActive,
      handler: (e) => onToggleOverlayToDeleteProcess(e),
      showIcon: showIcons,
    },
    {
      icon: 'clone',
      popupText: 'clone',
      disabled: false,
      handler: (e) => toggleModal(e, 'showCloneModal'),
      showIcon: showIcons,
    },
    {
      icon: 'edit',
      popupText: 'edit',
      disabled: false,
      handler: (e) => toggleModal(e, 'showEditModal'),
      showIcon: showIcons,
    },
    {
      icon: 'user',
      popupText: 'access',
      disabled: false,
      handler: (e) => toggleModal(e, 'showAccessProcessModal'),
      showIcon: showIcons,
    },
    {
      icon: 'info',
      popupText: comment || <NoDataMessage text='No comment provided' />,
      disabled: false,
      showIcon: showIcons,
    },
  ]

  return iconData.map(renderIcon)
}

const ProcessTileIconBar = props => {

  const {
    displayedStatus,
    showIcons,
    isActive,
    toggleModal,
    onToggleOverlayToDeleteProcess,
    handleToggleFavorite,
    isPinned,
    comment,
  } = props

  return (
    <Card.Content extra>
      <Container className='float-left'>
        <div className='space-between-items'>
          <div>
            {displayedStatus}
          </div>
          <div className='pointer transition-opacity'>
            {renderIcons(
              isActive,
              onToggleOverlayToDeleteProcess,
              toggleModal,
              handleToggleFavorite,
              isPinned,
              showIcons,
              comment,
            )}
          </div>
        </div>
      </Container>
    </Card.Content>
  )
}


export default ProcessTileIconBar
