import React, { useState, useEffect } from 'react'
import { Message, Icon, Grid } from 'semantic-ui-react'
import { Device } from 'utils/device'

const InconsistentDeviceStateBanner = ({ activeDevice }) => {

  const [ evaluateState, setEvaluateState ] = useState(false)

  useEffect(() => {
    setTimeout(() => setEvaluateState(true), 3000)
  })

  if (!evaluateState) return null

  const isInconsistentState = Device.hasInconsistentState(activeDevice)

  if (!isInconsistentState) return null

  return (
    <Grid.Row>
      <Message warning icon>
        <Icon name='warning'/>
        This device seems to have lost its connection to the internet while running a process.
        Commands to the device and changes to its running process will not be possible until the
        device re-establishes its internet connection.
      </Message>
    </Grid.Row>
  )
}

export default InconsistentDeviceStateBanner
