import React from 'react'
import { Menu, Button, Dropdown, Icon, Input } from 'semantic-ui-react'

const getSearchOptions = () => ([
  { text: 'in Level', value: 'level' },
  { text: 'in Subject', value: 'subject' },
  { text: 'in Message', value: 'message' },
])

const LogViewHeader = ({
  updatingRecent,
  filterProperty,
  refreshLogs,
  setFilterProperty,
  updateQueryString,
}) => (
  <Menu>
    <Menu.Menu>
      <Menu.Item>
        <Button
          primary
          icon
          labelPosition='left'
          onClick={refreshLogs}
          loading={updatingRecent}
        >
          <Icon name='sync' />
          Refresh
        </Button>
      </Menu.Item>
    </Menu.Menu>
    <Menu.Menu position='right'>
      <Menu.Item>
        <Input
          action={
            <Dropdown
              button
              basic
              options={getSearchOptions()}
              onChange={setFilterProperty}
              defaultValue={filterProperty}
            />
          }
          onChange={updateQueryString}
          icon='search'
          iconPosition='left'
          placeholder='Search...'
        />
      </Menu.Item>
    </Menu.Menu>
  </Menu>
)

export default LogViewHeader
