import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import formalize from 'components/utility/modal/formalize'
import NoDataMessage from 'components/utility/NoDataMessage'


const renderSelectPump = (selectedStreamId, pumpOptions, updateHandler) => {

  if (pumpOptions.length === 0) return <NoDataMessage text='No pumps detected' />

  return (
    <Form.Select
      fluid
      name='selectedStreamId'
      label='Pump'
      options={pumpOptions}
      onChange={updateHandler}
      value={selectedStreamId}
      placeholder='Select pump...'
    />
  )
}

const renderRatioInput = (ratio, updateHandler) => (
  <Form.Field>
    <label>
      ml/rotation
    </label>
    <Input
      fluid
      placeholder='Enter ratio...'
      name='ratio'
      type='number'
      required
      value={ratio}
      onChange={updateHandler}
    />
  </Form.Field>
)

const PumpCalibrationModalForm = ({ ratio, selectedStreamId, pumpOptions, handleUpdate }) => (
  <>
    {renderSelectPump(selectedStreamId, pumpOptions, handleUpdate)}
    {selectedStreamId && renderRatioInput(ratio, handleUpdate)}
  </>
)

export default formalize(PumpCalibrationModalForm)
