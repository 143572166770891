import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'
import ProcessEventTriggers from 'utils/process/ProcessEventTriggers'
import EventTrigger from 'utils/EventTrigger/EventTrigger'
import callUpdateDevice from 'redux/thunks/device/callUpdateDevice'
import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import Authorizer from 'utils/Authorizer'
import DeletePopup from 'components/device/setup/DeletePopup'
import StreamEventTriggerModal from './StreamEventTriggerModal/StreamEventTriggerModal'
import IconButton from 'components/utility/button/IconButton'
import TableHeader from 'components/utility/table/TableHeader'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateDevice: (deviceId, params) => dispatch(
    callUpdateDevice(deviceId, params),
  ),
  dispatchCallUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

class StreamEventTriggerTable extends React.Component {

  state = { showStreamTriggerModal: false }

  toggleStreamEventTriggerModal = () => (
    this.setState(prevState => ({ showStreamTriggerModal: !prevState.showStreamTriggerModal }))
  )

  getDisplayedStreams = () => {
    const { mode, activeDevice, activeProcess } = this.props

    if (mode === 'device') {
      return DeviceConfig.getConfiguredStreamsWithEventTriggers(activeDevice)
    }

    // this should go someday into a process config utility

    const streams = ProcessEventTriggers.getStreamsWithEvenTriggersDenormalized(activeProcess)

    return streams.map(stream => {
      const displayedDeviceUnit = DeviceConfig
        .getDisplayedStreamUnit(activeDevice, stream.id)

      const displayedUnit = displayedDeviceUnit === '-'
        ? stream.unit
        : displayedDeviceUnit

      return {
        ...stream,
        displayedName: stream.name,
        displayedUnit,
        connected: true,
      }
    })
  }

  async deleteStreamEvenTriggerFromDevice(streamId, type) {
    const { activeDevice, dispatchCallUpdateDevice } = this.props

    const updatedEventTriggers = DeviceConfig
      .removeEventTriggerByType(activeDevice, streamId, type)

    const updatedConfig = DeviceConfig
      .updateStreamConfig(activeDevice, streamId, { eventTriggers: updatedEventTriggers })

    const updateParams = { config: updatedConfig }
    await dispatchCallUpdateDevice(activeDevice.id, updateParams)
  }

  async deleteStreamEvenTriggerFromProcess(streamId, type) {
    const {
      activeProcess,
      dispatchCallUpdateProcess,
    } = this.props

    const updatedEventTriggers = ProcessEventTriggers
      .removeByType(activeProcess, streamId, type)

    await dispatchCallUpdateProcess(
      activeProcess.id, { eventTriggers: updatedEventTriggers },
    )
  }


  async deleteStreamEventTrigger(streamId, type) {
    const { mode } = this.props
    if (mode === 'device') {
      await this.deleteStreamEvenTriggerFromDevice(streamId, type)
      return
    }
    await this.deleteStreamEvenTriggerFromProcess(streamId, type)
  }

  userHasNoDeleteRights = () => {
    const { activeDevice, user, activeProcess, mode } = this.props
    const noResourceAccess = !Authorizer.user(user).hasEditRights(mode === 'device' ? activeDevice : activeProcess)
    const notDeviceAdmin = !Authorizer.user(user).isDeviceAdminOf(activeDevice)
    return notDeviceAdmin && noResourceAccess
  }

  render() {

    const { activeDevice, activeProcess, mode } = this.props
    const { showStreamTriggerModal } = this.state

    const configuredStreams = this.getDisplayedStreams()

    return (
      <>
        <IconButton
          text='Set Alert'
          clickHandler={this.toggleStreamEventTriggerModal}
        />
        {configuredStreams.length !== 0 && <Table className='ospin-red' celled collapsing>
          <TableHeader items={['Stream', 'Type', 'Parameters', 'Info', '']} />
          <Table.Body>
            {configuredStreams.map(stream => (
              stream.eventTriggers.map(eventTrigger => (
                <Table.Row key={stream.id+eventTrigger.type} warning={!stream.connected}>
                  <Table.Cell>
                    {stream.displayedName}
                  </Table.Cell>
                  <Table.Cell>
                    {EventTrigger.getByType(eventTrigger.type).description}
                  </Table.Cell>
                  <Table.Cell>
                    {EventTrigger.getByType(eventTrigger.type)
                      .displayParameters(eventTrigger, stream.unit, stream.displayedUnit)}
                  </Table.Cell>
                  <Table.Cell>
                    {!stream.connected ? 'stream not connected' : 'Ok'}
                  </Table.Cell>
                  <Table.Cell>
                    <DeletePopup
                      confirmHandler={() => this.deleteStreamEventTrigger(stream.id, eventTrigger.type)}
                      disabled={this.userHasNoDeleteRights()}
                      iconName='close'
                      position='right center'
                    />
                  </Table.Cell>
                </Table.Row>
              ))
            ))}
          </Table.Body>
        </Table>}
        <StreamEventTriggerModal
          closeHandler={this.toggleStreamEventTriggerModal}
          activeDevice={activeDevice}
          activeProcess={activeProcess}
          open={showStreamTriggerModal}
          showStreamSelection
          mode={mode}
        />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamEventTriggerTable)
