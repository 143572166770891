import React from 'react'
import { Form, Dropdown, Popup } from 'semantic-ui-react'

const AccessDropdown = props => {
  const { selected, groupOptions, onUpdate, label, placeholder } = props

  const descriptions = {
    admins: 'Admins can view, delete, execute, edit and manage which users can access the process.',
    operators: 'Operators can view, edit and run the process.',
    viewers: 'Viewers can look at the process but cannot apply any changes.',
  }

  const groupOptionsExcludingSupport = groupOptions.filter(option => option.value !== 'support')
  return (
    <Form.Dropdown
      fluid
      label={label}
      placeholder={placeholder}
      selection
      trigger={selected !== '' ? selected : null}
    >
      <Dropdown.Menu>
        {groupOptionsExcludingSupport.map(group => (
          <Popup
            key={group.value}
            content={descriptions[group.text]}
            on='hover'
            position='top right'
            style={{ marginBottom: '100px' }}
            basic
            trigger={(
              <Dropdown.Item
                active={selected === group.value}
                onClick={onUpdate}
                text={group.text}
                value={group.value}
              />
            )}
          />
        ))}
      </Dropdown.Menu>
    </Form.Dropdown>
  )
}

export default AccessDropdown
