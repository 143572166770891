export function getInvitationNotifications(user) {
  return user.notifications.filter(note => note.topic.split('/')[1] === 'invitation')
}

export function getEphemeralNotifications(user) {
  return user.notifications.filter(note => note.type === "ephemeral")
}

export function getChangeLogNotifications(user) {
  return user.notifications.filter(note => note.topic === "changelog")
}

export function getNotificationHeadline(notificationTopic) {
  if (notificationTopic === "device/revocation") {
    return "Device Access Revocation"
  }
}