import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'

import callDeleteStreamNameAlias from 'redux/thunks/device/callDeleteStreamNameAlias'
import Authorizer from 'utils/Authorizer'
import DeletePopup from './DeletePopup'
import SetStreamNameAliasModal from '../modals/SetStreamNameAliasModal'
import IconButton from 'components/utility/button/IconButton'
import TableHeader from 'components/utility/table/TableHeader'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  callDeleteStreamNameAlias: (streamId, deviceId) => dispatch(callDeleteStreamNameAlias(streamId, deviceId))
})

class StreamNameAliasTable extends React.Component {

  state = {
    showSetStreamAliasModal: false
  }

  toggleSetStreamAliasModal = () => {
    this.setState(prevState => ({showSetStreamAliasModal: !prevState.showSetStreamAliasModal}))
  }

  async deleteStreamNameAlias(streamId) {
    await this.props.callDeleteStreamNameAlias(streamId, this.props.activeDevice.id)
  }

  render() {
    const { activeDevice, user } = this.props
    const { streamNameAliases, streams } = activeDevice
    const { showSetStreamAliasModal } = this.state

    const streamNames = streamNameAliases.map(streamNameAlias => {
      const streamData = streams.find(stream => stream.id === streamNameAlias.streamId)

      if (!streamData) return -1
      return streamData.name
    })

    const streamsData = streamNameAliases.map((streamNameAlias, i) => ({...streamNameAlias, name: streamNames[i]}))

    return (
      <>
        <IconButton
          text='Set Alias'
          clickHandler={this.toggleSetStreamAliasModal}
        />
        {streamsData.length !== 0 && <Table key={1} className='ospin-red' celled collapsing>
          <TableHeader items={['Stream', 'Alias', 'Info', '']} />
          <Table.Body>
            {streamsData.map((stream, i) =>
              <Table.Row key={i} warning={stream.name === -1}>
                <Table.Cell>
                  {stream.name === -1 ? stream.streamId : stream.name}
                </Table.Cell>
                <Table.Cell>
                  {stream.alias}
                </Table.Cell>
                <Table.Cell>{stream.name === -1 ? 'Could not fetch stream name (stream not connected)' : 'Ok'}</Table.Cell>
                <Table.Cell>
                  <DeletePopup
                    confirmHandler={this.deleteStreamNameAlias.bind(this, stream.streamId)}
                    disabled={!Authorizer.user(user).hasEditRights(activeDevice)}
                    iconName='close'
                    position='right center'
                  />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>}
        <SetStreamNameAliasModal
          closeHandler={this.toggleSetStreamAliasModal}
          activeDevice={this.props.activeDevice}
          open={showSetStreamAliasModal}
        />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StreamNameAliasTable)
