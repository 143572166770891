import { findAndUpdate } from 'redux/helper/updateFunctions'

export default (state, action) => {
  const { devices } = state
  const { deviceId, params } = action.payload

  const updateFn = device => Object.assign(device, params)

  const updatedDevices = findAndUpdate(deviceId, devices, [ updateFn ])

  return { ...state, devices: updatedDevices }
}
