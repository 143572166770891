import React from 'react'
import SSHAccess from './SSHAccess'
import { Menu, Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'

import Authorizer from 'utils/Authorizer'

const mapStateToProps = state => ({ user: state.user })

const renderSSHAccess = (activeDevice) => (
  <Menu.Item>
    <SSHAccess activeDevice={activeDevice} />
  </Menu.Item>
)

const AdminToolbar = ({ activeDevice, user }) => (
  <div>
    <Grid.Row key={0}>
      <Menu>
        <Menu.Item>
          Admin Tools
        </Menu.Item>
        {Authorizer.user(user).hasDeveloperAccess() ? renderSSHAccess(activeDevice) : null }
      </Menu>
    </Grid.Row>
  </div>
)

export default connect(mapStateToProps)(AdminToolbar)
