import React from 'react'

import { Button } from 'semantic-ui-react'

// :)
const overlayStyle = {
  cursor: 'default',
  position: 'absolute',
  width: '100%',
  height: '100%',
}

const containerStyle = {
  position: 'flex',
  width: '100%',
  height: '100%',
  alignItems: 'center',
  backgroundColor: 'rgba(51, 51, 51, 0.75)',
  justifyContent: 'center'
}

const confirmButtonStyle = {
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

const cancelButtonStyle = {
  padding: '7px 10px',
  position: 'absolute',
  top: '65%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

const ProcessTileOverlay = ({ confirmDeleteProcess }) => (
  <div style={overlayStyle}>
    <div style={containerStyle}>
      <Button className='ospin-red-hoverable' style={confirmButtonStyle} onClick={(e) => confirmDeleteProcess(e, true)}>
        Delete
      </Button>
      <Button size='tiny' style={cancelButtonStyle} onClick={(e) => confirmDeleteProcess(e, false)}>
        Cancel
      </Button>
    </div>
  </div>
)

export default ProcessTileOverlay
