import { findAndUpdate } from 'redux/helper/updateFunctions'
import { Device } from 'utils/device'

export default (state, action) => {
  const { devices } = state
  const heartbeats = { ...state.heartbeats }
  heartbeats.devices[action.payload] = Date.now()

  const activeDevice = Device.getById(devices, action.payload)
  if (!activeDevice || activeDevice.connectionState === 'online') {
    return { ...state, heartbeats }
  }

  const updateFn = device => Object.assign(device, { connectionState: 'online' })

  const updatedDevices = findAndUpdate(activeDevice.id, devices, [ updateFn ])
  return { ...state, devices: updatedDevices, heartbeats }
}
