import { Process } from 'utils/process'
import PDVC from 'utils/PDVC'
import DescriptionParser from 'utils/process/DescriptionParser'

export default (state, action) => {
  const { payload: { deviceId, processId } } = action
  const activeDevice = state.devices.find(device => device.id === deviceId)
  const { processes, processDescriptionSnapshots } = state

  let displayedPhaseId = ''

  const updatedProcesses = processes.map(process => {
    if (process.id === processId) {
      const snapshot = PDVC.getSnapshot(processId, processDescriptionSnapshots)

      const updatedProcess = Process.appendNewPhase(process, activeDevice, snapshot)
      displayedPhaseId = DescriptionParser.getLastPhaseId(updatedProcess)
      return { ...updatedProcess }
    }
    return process
  })

  return { ...state, processes: updatedProcesses, displayedPhaseId }
}
