import { findAndUpdate } from 'redux/helper/updateFunctions'
import { addUserToGroup } from 'utils/access'

export default (state, action) => {
  const { processes, deviceUsers } = state
  const { processId, groupName, email } = action.payload

  const addedUser = deviceUsers.find(user => user.email === email)
  const updateFn = process => addUserToGroup(process, groupName, addedUser.id)

  const updatedProcesses = findAndUpdate(processId, processes, [ updateFn ])
  return { ...state, processes: updatedProcesses }
}
