import React from 'react'
import formalize from 'components/utility/modal/formalize'

const StopProcessModalForm = () => (
  <>
    Are you sure you want to stop the process?
  </>
)

export default formalize(StopProcessModalForm)
