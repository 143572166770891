import config from 'react-global-configuration'

const envs = {
  development: {
    reportClientErrors: false,
    target: 'development',
  },
  production: {
    reportClientErrors: true,
    target: 'production',
  },
  test: {
    reportClientErrors: false,
    target: 'test',
  },
}

const defaultConfigArgs = { freeze: true }


export const createConfig = (opt = defaultConfigArgs) => config.set(envs[process.env.NODE_ENV], { freeze: opt.freeze })
