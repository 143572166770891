import nexus from 'nexus'
import { removeNotificationsByTopic } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callRemoveNotificationsByTopic = async (dispatch, userId, topic) => {
  nexus.user.deleteNotificationsByTopic(userId, topic)
  dispatch(removeNotificationsByTopic({ topic }))
}

export default thunkWrapper(callRemoveNotificationsByTopic)
