import React, { useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'

import { combineReducers, createStore } from 'redux'
import { Provider } from 'react-redux'
import { Actions, jsonformsReducer } from '@jsonforms/core'
import { materialCells, materialRenderers } from '@jsonforms/material-renderers'
import * as PCB from './assets/PCB.png'
import * as MEAT from './assets/MEAT.png'
import Builder from './Builder'

import datastreamsInput from './schemas/datastreams-input.json'
import datastreamsUI from './schemas/datastreams-ui.json'
import datastreamsSchema from './schemas/datastreams-schema.json'

import functionalitiesInput from './schemas/functionalities-input.json'
import functionalitiesUI from './schemas/functionalities-ui.json'
import functionalitiesSchema from './schemas/functionalities-schema.json'

import './assets/App.css'


function initStore(schema, ui, data) {
  const initState = {
    jsonforms: {
      cells: materialCells,
      renderers: materialRenderers,
    },
  }

  const rootReducer = combineReducers({ jsonforms: jsonformsReducer() })
  const store = createStore(rootReducer, initState)

  store.dispatch(Actions.init(data, schema, ui))
  return store
}

const datastreamsStore = initStore(
  datastreamsSchema,
  datastreamsUI,
  datastreamsInput,
)

const functionalitiesStore = initStore(
  functionalitiesSchema,
  functionalitiesUI,
  functionalitiesInput,
)

const render = store => (
  <Provider store={store}>
    <Builder />
  </Provider>
)


const Main = () => {
  const [tabIdx, setTabIdx] = useState(0)
  function handleTabChange(event, newValue) { setTabIdx(newValue) }

  return (
    <>
      <div className='App'>
        <header className='App-header' style={{ height: '200px', textAlign: 'center', padding: '1.2em' }}>
          <div className='App-logo'>
            <img src={MEAT} className='App-logo' alt='logo' />
            <img src={PCB} className='App-logo' alt='logo' />
          </div>
          <h1 className="App-title">OSPIN Board JSON Generator</h1>
          <p className="App-intro">More Forms. Less Bugs.</p>
        </header>
      </div>
      <Tabs value={tabIdx} onChange={handleTabChange}>
        <Tab label='datastream' />
        <Tab label='functionalities' />
      </Tabs>
      {tabIdx === 0 ? render(datastreamsStore) : render(functionalitiesStore)}
    </>
  )
}

export default Main
