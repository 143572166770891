import Streams from 'utils/Streams'
import store from 'redux/store'
import callSetDeviceUIConfig from 'redux/thunks/uIConfig/callSetDeviceUIConfig'
import StreamGroups from './StreamGroups'

export default class UIConfig {

  static persistUIConfig(userId, deviceId, uIConfig) {
    store.dispatch(callSetDeviceUIConfig(userId, deviceId, uIConfig))
  }

  static toggleGroupFolded(groupName, propName, { activeDevice, userId }) {
    const { uIConfig } = activeDevice
    const { groups } = uIConfig[propName]
    uIConfig[propName].groups = StreamGroups.toggleGroupFolded(groups, groupName)
    this.persistUIConfig(userId, activeDevice.id, uIConfig)
  }

  static foldall(propName, { activeDevice, userId }) {
    const { uIConfig } = activeDevice
    const { groups } = uIConfig[propName]

    uIConfig[propName].groups = StreamGroups.foldAll(groups)
    this.persistUIConfig(userId, activeDevice.id, uIConfig)
  }

  static mapGroupPosition(domPosition, groups, activeProcess) {
    let position = 0
    for (let i = 0; i < groups.length; i++) {
      const isGroupEmpty = StreamGroups.isGroupEmptyOnActiveProcess(groups[i], activeProcess)
      if (!isGroupEmpty) {
        if (position === domPosition) {
          return i
        }
        position++
      }
    }
    return -1
  }

  static mapStreamPosition(domPosition, group, activeProcess) {
    let position = 0
    for (let i = 0; i < group.streams.length; i++) {
      const stream = Streams.getById(activeProcess.deviceDescription, group.streams[i].streamId)
      if (stream) {
        if (position === domPosition) {
          return i
        }
        position++
      }
    }
    return -1
  }

  static moveGroup(index, draggableId, propName, { activeDevice, activeProcess, userId }) {
    const { uIConfig } = activeDevice
    const { groups } = uIConfig[propName]
    const destinationIndex = this.mapGroupPosition(index, groups, activeProcess)
    const sourceIndex = groups.findIndex(grp => grp.name === draggableId)

    if (destinationIndex === -1 || sourceIndex === -1 || destinationIndex === sourceIndex) return

    uIConfig[propName].groups = StreamGroups.moveGroup(groups, sourceIndex, destinationIndex)
    this.persistUIConfig(userId, activeDevice.id, uIConfig)
  }

  static moveStream (index, draggableId, groupName, propName, { activeDevice, activeProcess, userId }) {
    const { uIConfig } = activeDevice
    const { groups } = uIConfig[propName]

    const group = groups.find(grp => grp.name === groupName)
    const destinationIndex = this.mapStreamPosition(index, group, activeProcess)
    const sourceIndex = group.streams.findIndex(grp => grp.streamId === draggableId)

    if (destinationIndex === -1 || sourceIndex === -1 || destinationIndex === sourceIndex) return
    uIConfig[propName].groups = StreamGroups.moveStream(groups, groupName, sourceIndex, destinationIndex)
    this.persistUIConfig(userId, activeDevice.id, uIConfig)
  }

  static onDragEnd (result, propName, { activeDevice, activeProcess, userId }) {
    const { draggableId, type, destination, source } = result
    if (!destination || destination.droppableId !== source.droppableId) {
      return
    }
    if (type && type.type === 'Group') {
      this.moveGroup(destination.index, draggableId, propName, { activeDevice, activeProcess, userId })
    }
    if (type && type.type === 'Stream') {
      this.moveStream(destination.index, draggableId, type.groupName, propName, { activeDevice, activeProcess, userId })
    }
  }

  static addNewStreams(uIConfig, propName, streams) {
    StreamGroups.addNewStreams(uIConfig[propName].groups, streams)
    return uIConfig[propName]
  }

  static checkForNewStreams(uIConfig, propName, streams) {
    return StreamGroups.checkForNewStreams(uIConfig[propName].groups, streams)
  }

  static createProcessBuilder(uIConfig) {
    uIConfig.processBuilder = { groups: [] }
  }

  static hasProcessBuilder(uIConfig) {
    return uIConfig.processBuilder
  }

  static updateProcessBuilderAndGraphsConfig(uIConfig, processUpStreams, processDownStreams, userId, deviceId) {
    if (this.checkForNewStreams(uIConfig, 'graphs', processUpStreams)
      || this.checkForNewStreams(uIConfig, 'processBuilder', processDownStreams)) {
      uIConfig.graphs = this.addNewStreams(uIConfig, 'graphs', processUpStreams)
      uIConfig.processBuilder = this.addNewStreams(uIConfig, 'processBuilder', processDownStreams)
      this.persistUIConfig(userId, deviceId, uIConfig)
    }
  }

}
