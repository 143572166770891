export default class Validator {
  static isBoolean = value => typeof value === 'boolean'

  static isNumber = value => !Number.isNaN(value) && typeof value === 'number'

  static withinRange = (value, [ min, max ]) => value <= max && value >= min

  static lessThanOrEqual = (value, limit) => value <= limit

  static isUndefinedOrNull = value => (value === undefined || value === null)
}
