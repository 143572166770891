import Logs from 'utils/Logs'

export default (state, action) => {
  const { fetchedLogs } = action.payload
  const { logs } = state

  const updatedLogs = Logs.mergeLogs(logs, fetchedLogs)

  return {
    ...state,
    logs: updatedLogs,
  }
}
