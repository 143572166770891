import React from 'react'
import { connect } from 'react-redux'
import modalize from 'components/utility/modal/modalize'
import CloneProcessModalForm from 'components/device/modals/CloneProcessModalForm'
import callCloneProcess from 'redux/thunks/process/callCloneProcess'
import ProcessValidator from 'utils/validation/ProcessValidator'

const { VALID_PROCESS_DATA: { nameLength } } = ProcessValidator

const mapDispatchToProps = dispatch => ({
  dispatchCallCloneProcess: (processId, processName) => dispatch(
    callCloneProcess(processId, processName),
  ),
})

class CloneProcessModal extends React.Component {

  state = {
    newName: `Clone_${this.props.process.name}`.substr(0, nameLength),
    isError: false,
    errorMessage: '',
    loading: false,
  }

  handleUpdateName = ({ target: { value: name } }) => (
    this.setState({ newName: name, isError: false, errorMessage: '' })
  )

  createProcessClone = async () => {
    const { newName } = this.state
    const { process: { id }, closeHandler, dispatchCallCloneProcess } = this.props

    if (!ProcessValidator.isValidProcessName(newName)) {
      this.setState({
        isError: true,
        errorMessage: `Process name must be short than ${nameLength + 1}`,
      })
      return
    }

    this.setState({ loading: true })
    const res = await dispatchCallCloneProcess(id, newName)
    if (res.success) {
      this.setState({ loading: false })
      closeHandler()
    } else {
      this.setState({ isError: true, errorMessage: res.message, loading: false })
    }
  }

  render() {
    const { isError, errorMessage, loading, newName } = this.state
    const { closeHandler } = this.props
    return (
      <CloneProcessModalForm
        isError={isError}
        errorMessage={errorMessage}
        closeHandler={closeHandler}
        submitHandler={this.createProcessClone}
        submitText='Clone'
        loading={loading}
        newName={newName}
        handleUpdateName={this.handleUpdateName}
      />
    )
  }
}

export default modalize(connect(null, mapDispatchToProps)(CloneProcessModal))
