import React from 'react'
import { Form, Select } from 'semantic-ui-react'
import { hasUnit, convertFrom } from 'utils/units'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'
import Unit from '../Unit'

export default class OneOfStream extends React.Component {

  constructor(props) {
    super(props)
    const { stream, getStreamDisplayedUnit } = this.props
    this.state = { openValueDropDown: false, selectedUnit: getStreamDisplayedUnit(stream.id) }
  }

  getStreamValueOptions = () => {
    const { stream, activeDevice } = this.props
    const { selectedUnit } = this.state
    const { expectedValueOptions, unit } = stream

    return expectedValueOptions.map(value => {
      const customConversion = CustomConversions
        .getCustomConversionsByTargetUnit(activeDevice, stream.id, selectedUnit)

      return {
        value,
        text: customConversion
          ? CustomConversions.convertWithRatio(value, customConversion)
          : convertFrom(unit).to(selectedUnit)(value),
      }
    })
  }

  updateStreamUnit = newUnit => {
    const { changeDisplayedUnit, stream } = this.props
    this.setState({ selectedUnit: newUnit })
    changeDisplayedUnit(newUnit, stream.id)
  }

  save = async (event, { value }) => {
    const { stream, updateStreamTarget } = this.props
    if (!stream.expectedValueOptions.includes(value)) return
    updateStreamTarget(stream.id, value)
  }

  renderStream = style => {
    const { openValueDropDown } = this.state
    const { streamTarget, disabledInput, stream } = this.props
    const { expectedValueOptions } = stream

    const uniqueValue = expectedValueOptions.length === 1

    return (
      <Select
        value={streamTarget}
        open={uniqueValue || disabledInput ? false : openValueDropDown}
        options={this.getStreamValueOptions()}
        closeOnChange
        onClick={() => this.setState({ openValueDropDown: !openValueDropDown })}
        onChange={this.save}
        onClose={() => this.setState({ openValueDropDown: false })}
        style={style}
      />
    )
  }

  renderStreamWithUnit = () => {
    const { stream, activeDevice } = this.props
    const { selectedUnit } = this.state

    return (
      <Form.Field>
        {this.renderStream({
          paddingRight: '0',
          marginRight: '-2px',
          borderRadius: '.28571429rem 0 0 .28571429rem',
        })}
        <Unit
          stream={stream}
          activeDevice={activeDevice}
          displayedUnit={selectedUnit}
          onChange={this.updateStreamUnit}
        />
      </Form.Field>
    )
  }

  renderUnitlessStream = () => (
    <Form.Field>
      {this.renderStream()}
    </Form.Field>
  )

  render() {
    const { stream } = this.props

    return (
      <div>
        <span style={{ fontWeight: 'bold' }}>
          {stream.name}
        </span>
        <Form.Group inline>
          {hasUnit(stream.unit)
            ? this.renderStreamWithUnit()
            : this.renderUnitlessStream()}
        </Form.Group>
      </div>
    )
  }
}
