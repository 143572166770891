import { convertFrom, applyUnitConversionOnDataPoints } from 'utils/units'
import DeviceConfig from 'utils/DeviceConfig'
import CalibrationMode from 'utils/calibration/CalibrationMode'
import DataTools from 'utils/DataTools'

class OffsetOnlyMode extends CalibrationMode {

  static calculateParams(componentState) {

    const { pointSelectionOffset } = componentState

    const { offsetRangeAvrg, offsetRangeReference } = pointSelectionOffset
    const parsedOffset = parseFloat(offsetRangeReference) - offsetRangeAvrg

    if (Number.isNaN(parsedOffset)) {
      return { error: ('Offset is not a number.') }
    }

    return { offset: parsedOffset, slope: 1 }
  }

  static getNextField(currField) {
    const nextFields = { startTime: 'endTime' }

    return nextFields[currField]
  }

  static getUpdatedPointSelection(componentState, time) {
    const { pointSelectionOffset, selectedField } = componentState

    if (selectedField === 'endTime'
      && pointSelectionOffset.startTime !== '') {
      if (time <= pointSelectionOffset.startTime) {
        return
      }
    }

    if (selectedField === 'startTime'
      && pointSelectionOffset.endTime !== '') {
      if (time >= pointSelectionOffset.endTime) {
        return
      }
    }

    const nextField = this.getNextField(selectedField)
    return {
      params: {
        pointSelectionOffset: {
          ...pointSelectionOffset,
          [selectedField]: time,
        },
      },
      nextField,
    }
  }

  static getUpdatedAverage(componentState, componentProps, rawData) {
    const { activeDevice, stream } = componentProps
    const { pointSelectionOffset } = componentState

    const {
      startTime,
      endTime,
      offsetRangeAvrg,
    } = pointSelectionOffset

    const newAvrg = this.getAverage(rawData, startTime, endTime)

    const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)
    const unitConverter = convertFrom(stream.unit).to(displayedUnit)

    return {
      params: {
        pointSelectionOffset: {
          ...pointSelectionOffset,
          offsetRangeAvrg: unitConverter(newAvrg) || offsetRangeAvrg,
        },
      },
    }
  }

  static attachGraphSlice(streamData, rawData, componentState, componentProps) {

    const { pointSelectionOffset } = componentState
    const { activeDevice, stream } = componentProps

    const { startTime, endTime } = pointSelectionOffset

    const displayedUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id)
    const unitConverter = convertFrom(stream.unit).to(displayedUnit)

    if (startTime && endTime) {
      const slice = DataTools.valuesWithinTimeRange(rawData, startTime, endTime)
      const convertedDataPoints = applyUnitConversionOnDataPoints(slice, unitConverter)
      streamData.push(this.createGraphSlice(convertedDataPoints, { color: 'red', label: 'Selected' }))
    }
  }
}

export default OffsetOnlyMode
