import nexus from 'nexus'
import { setSession } from 'redux/actions/actions'

export default () => (
  async dispatch => {
    try {
      const session = await nexus.auth.currentSession()
      if (session) {
        const userSub = session.accessToken.payload.sub
        const userData = await nexus.user.get(userSub)
        dispatch(setSession({ authenticated: true, userData }))
        return
      }
    } catch (_) {}
    dispatch(setSession({ authenticated: false, userData: null }))
  }
)
