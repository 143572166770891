import React, { useEffect, useState } from 'react'
import { Segment, Container, Card } from 'semantic-ui-react'
import { connect } from 'react-redux'
import callLoadProcess from 'redux/thunks/process/callLoadProcess'
import { Device } from 'utils/device'
import { Process } from 'utils/process'
import DeviceDashboardUIConfig from 'utils/UIConfig/DeviceDashboardUIConfig'
import UIConfig from 'utils/UIConfig/UIConfig'
import StreamWidget from 'components/device/dashboard/StreamWidget'
import DashboardHeader from 'components/device/dashboard/DashboardHeader'
import MissingStreams from 'components/device/dashboard/MissingStreams'
import { updateProcess } from 'redux/actions/actions'


const removeStreamFromDashboard = async (userId, activeDevice, streamId) => {
  const { uIConfig, uIConfig: { deviceDashboard } } = activeDevice

  if (!DeviceDashboardUIConfig.hasDashboardStream(deviceDashboard, streamId)) return
  uIConfig.deviceDashboard = DeviceDashboardUIConfig.removeStreamFromDashboard(deviceDashboard, streamId)
  await UIConfig.persistUIConfig(userId, activeDevice.id, uIConfig)
}

const mapStateToProps = state => ({ processes: state.processes, userId: state.user.id })
const mapDispatchToProps = dispatch => ({
  dispatchUpdateProcess: (processId, process) => (
    dispatch(updateProcess({ processId, params: process }))
  ),
})

const Dashboard = ({ activeDevice, userId, processes, dispatchUpdateProcess }) => {
  const [processIsEnriched, enrichProcess] = useState(false)
  const runningProcess = processIsEnriched ? Process.getById(processes, activeDevice.runningProcessId) : null
  const dashboardStreams = Device.getStreams(activeDevice, activeDevice.uIConfig.deviceDashboard)
  const missingStreams = Device.findDisconnectedStreams(activeDevice, activeDevice.uIConfig.deviceDashboard)
  const removeStreamFromDashboardByStreamId = streamId => removeStreamFromDashboard(userId, activeDevice, streamId)

  useEffect(() => {
    const fetchAndEnrichProcess = async () => {
      if (activeDevice.runningProcessId) {
        const process = await callLoadProcess(activeDevice.runningProcessId)
        if (process) process.state = Process.deriveProcessState(process, activeDevice)
        dispatchUpdateProcess(activeDevice.runningProcessId, process)
      }
      enrichProcess(true)
    }
    if (!processIsEnriched) fetchAndEnrichProcess()
  }, [activeDevice, processIsEnriched, dispatchUpdateProcess])

  return (
    <Container fluid>
      <DashboardHeader
        activeDevice={activeDevice}
      />
      {missingStreams.length
        ? (
          <MissingStreams
            missingStreams={missingStreams}
            activeDevice={activeDevice}
            removeStreamFromDashboardByStreamId={removeStreamFromDashboardByStreamId}
          />
        )
        : null}
      {dashboardStreams.length ? (
        <Segment>
          <Card.Group>
            {dashboardStreams.map((stream, i) => (
              <StreamWidget
                key={i}
                runningProcess={runningProcess}
                activeDevice={activeDevice}
                stream={stream}
                userId={userId}
                removeStreamFromDashboardByStreamId={removeStreamFromDashboardByStreamId}
              />
            ))}
          </Card.Group>
        </Segment>
      ) : null }
    </Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
