import nexus from 'nexus'
import { removeNotifications } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callDeleteUserNotification= async (dispatch, userId, notificationId) => {
  await nexus.user.deleteNotification(userId, notificationId)
  dispatch(removeNotifications({ ids: [notificationId] }))
}

export default thunkWrapper(callDeleteUserNotification)
