import { ACTIONS } from 'redux/actions/actions'
import setDisplayedPhase from 'redux/reducers/ui/setDisplayedPhase'
import loadProcessViewer from 'redux/reducers/ui/loadProcessViewer'
import addFlashMessage from 'redux/reducers/ui/addFlashMessage'
import deleteFlashMessage from 'redux/reducers/ui/deleteFlashMessage'
import setDeviceUIConfig from 'redux/reducers/ui/setDeviceUIConfig'
import collapseSidebar from 'redux/reducers/ui/collapseSidebar'

export default {
  [ACTIONS.SET_DISPLAYED_PROCESS_PHASE]: setDisplayedPhase,
  [ACTIONS.LOAD_PROCESSVIEWER]: loadProcessViewer,
  [ACTIONS.ADD_FLASHMESSAGE]: addFlashMessage,
  [ACTIONS.DELETE_FLASHMESSAGE]: deleteFlashMessage,
  [ACTIONS.SET_DEVICE_UI_CONFIG]: setDeviceUIConfig,
  [ACTIONS.COLLAPSE_SIDEBAR]: collapseSidebar,
}
