import { PROCESS_STATES } from 'utils/process'
import DescriptionParser from './DescriptionParser'
import Validator from '../validation/Validator'

class PhaseGroupParser extends DescriptionParser {

  static getUniqueGroupNames(process) {
    return [ ...new Set(PhaseGroupParser.getPhasesArray(process)
      .map(({ groupName }) => groupName)
      .filter(aGroupName => !Validator.isUndefinedOrNull(aGroupName))),
    ]
  }

  static getGroupName(process, phaseId) {
    return process.description[phaseId]?.groupName
  }

  static getOriginalGroupPhases(process, targetGroupName) {
    return PhaseGroupParser
      .getPhasesSequence(process)
      .filter(({ data: { groupName, iterationOf } }) => (
        groupName === targetGroupName && Validator.isUndefinedOrNull(iterationOf)))
  }

  static getGroupPhases(process, targetGroupName) {
    return PhaseGroupParser
      .getPhasesSequence(process)
      .filter(({ data: { groupName } }) => groupName === targetGroupName)
  }

  static getGroupSize(process, targetGroupName) {
    return PhaseGroupParser
      .getPhasesSequence(process)
      .filter(({ data: { groupName } }) => groupName === targetGroupName).length
  }

  static getPhaseIterations(process, phaseId) {
    return PhaseGroupParser
      .getPhasesSequence(process)
      .filter(({ data: { iterationOf } }) => iterationOf === phaseId)
  }

  static getPhaseIterationIds(process, phaseId) {
    return PhaseGroupParser
      .getPhaseIterations(process, phaseId)
      .map(({ id }) => id)
  }

  static getIterationData(process, targetGroupName) {

    const allGroupPhases = PhaseGroupParser.getGroupPhases(process, targetGroupName)

    const startPhaseId = allGroupPhases[0].id

    return allGroupPhases.reduce((acc, { data: { iterationOf }, id }) => {
      if (iterationOf === startPhaseId || id === startPhaseId) {
        return [ ...acc, { iteration: acc.length + 1, phaseIds: [ id ] } ]
      }

      acc[acc.length - 1].phaseIds.push(id)
      return acc
    }, [])
  }

  static getIterationEndPhaseIds(process, targetGroupName) {
    const iterationData = PhaseGroupParser.getIterationData(process, targetGroupName)
    return iterationData.map(({ phaseIds }) => phaseIds[phaseIds.length - 1])
  }

  static getLastPhaseIdOfGroup(process, targetGroupName) {
    const lastPhaseIds = PhaseGroupParser.getIterationEndPhaseIds(process, targetGroupName)
    return lastPhaseIds[lastPhaseIds.length - 1]
  }

  static getFirstPhaseIdOfGroup(process, targetGroupName) {
    const iterData = PhaseGroupParser.getIterationData(process, targetGroupName)
    return iterData[0].phaseIds[0]
  }

  static getPhaseIterationCount(process, phaseId) {
    const { groupName } = PhaseGroupParser.getPhaseById(process, phaseId)
    const iterationData = PhaseGroupParser.getIterationData(process, groupName)
    const groupIteration = iterationData.find(({ phaseIds }) => phaseIds.includes(phaseId))
    return groupIteration?.iteration
  }

  static getGroupIterationsCount(process, groupName) {
    const iterationData = PhaseGroupParser.getIterationData(process, groupName)
    return iterationData.length
  }

  static getFinishedIterationsCount(process, groupName) {
    const endPhaseIds = PhaseGroupParser.getIterationEndPhaseIds(process, groupName)
    return endPhaseIds.filter(aPhaseId => {
      const executionIndex = process.progression.findIndex(({ phaseId }) => phaseId === aPhaseId)

      if (executionIndex === -1) return false

      if (executionIndex === process.progression.length - 1
        && process.state === PROCESS_STATES.finished) return true

      return executionIndex !== process.progression.length - 1
    }).length
  }

  static groupHasFinished(process, groupName) {
    const count = PhaseGroupParser.getGroupIterationsCount(process, groupName)
    const finishedCount = PhaseGroupParser.getFinishedIterationsCount(process, groupName)
    return count === finishedCount
  }

  static getGroupIterationsProgression(process, groupName) {
    const total = PhaseGroupParser.getGroupIterationsCount(process, groupName)
    const finished = PhaseGroupParser.getFinishedIterationsCount(process, groupName)
    return { finished, total }
  }
}

export default PhaseGroupParser
