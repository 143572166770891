import React from 'react'
import { connect } from 'react-redux'
import { Grid, Button, Icon, Popup } from 'semantic-ui-react'
import DemoEndModal from 'components/device/modals/DemoEndModal'

import callUpdateProcess from 'redux/thunks/process/callUpdateProcess'
import { setProcessBuilderValidationErrors } from 'redux/actions/actions'
import ProcessLabel from './ProcessLabel'

const mapDispatchToProps = dispatch => ({
  setProcessBuilderValidationErrors: errors => dispatch(setProcessBuilderValidationErrors({ errors })),
  callUpdateProcess: (processId, params) => dispatch(callUpdateProcess(processId, params)),
})

class ProcessBuilderHeader extends React.Component {

  state = {
    processStarting: false,
    showDemoEndModal: false,
  }

  constructor(props) {
    super(props)
    this.toggleDemoEndModal = this.toggleDemoEndModal.bind(this)
  }

  toggleDemoEndModal() {
    this.setState(prevState => ({
      ...prevState,
      showDemoEndModal: !prevState.showDemoEndModal,
    }))
  }

  render() {

    const { activeProcess } = this.props
    const { processStarting, showDemoEndModal } = this.state

    return (
      <Grid>
        <Grid.Row verticalAlign='middle'>
          <Grid.Column width={9}>
            <ProcessLabel activeProcess={activeProcess} />
          </Grid.Column>
          <Grid.Column width={4}>
            <DemoEndModal
              open={showDemoEndModal}
              closeHandler={this.toggleDemoEndModal}
              headerIcon='unlock alternate'
              headerText='Unlock The Full Experience!'
              size='large'
            />
            <Popup
              trigger={(
                <Button
                  primary
                  icon
                  floated='right'
                  labelPosition='left'
                  loading={processStarting}
                  onClick={this.toggleDemoEndModal}
                >
                  <Icon name='play' />
                  Start
                </Button>
              )}
              content='start process'
              position='bottom center'
            />
          </Grid.Column>
          <Grid.Column width={1} />
        </Grid.Row>
      </Grid>
    )
  }
}

export default connect(null, mapDispatchToProps)(ProcessBuilderHeader)
