import { initialState } from "../initialState"
import createReducer from 'redux/reducers/createReducer'

import userHandlers from 'redux/reducers/user/handlers'
import processHandlers from 'redux/reducers/process/handlers'
import uIHandlers from 'redux/reducers/ui/handlers'
import deviceHandlers from 'redux/reducers/device/handlers'
import commandHandlers from 'redux/reducers/command/handlers'
import logHandlers from 'redux/reducers/log/handlers'

const userReducer = createReducer(userHandlers)
const processReducer = createReducer(processHandlers)
const uIReducer = createReducer(uIHandlers)
const deviceReducer = createReducer(deviceHandlers)
const commandReducer = createReducer(commandHandlers)
const logReducer = createReducer(logHandlers)

function rootReducer(state = initialState, action) {
  switch (true) {
    case (action.type in userHandlers):
      return userReducer(state, action)
    case (action.type in processHandlers):
      return processReducer(state, action)
    case (action.type in uIHandlers):
      return uIReducer(state, action)
    case (action.type in deviceHandlers):
      return deviceReducer(state, action)
    case (action.type in commandHandlers):
      return commandReducer(state, action)
    case (action.type in logHandlers):
      return logReducer(state, action)
    default:
      return state
  }
}

export default rootReducer
