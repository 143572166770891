class Logs {

  static getLastTimeStamp = deviceLogs => {
    const deviceLogsCount = deviceLogs.length
    return deviceLogsCount
      ? deviceLogs[deviceLogsCount - 1].createdAt
      : undefined
  }

  static getMostRecentTimeStamp = deviceLogs => (
    deviceLogs.length
      ? deviceLogs[0].createdAt
      : undefined
  )

  static getDeviceLogs = (logs, deviceId) => (
    logs.filter(log => log.deviceId === deviceId)
  )

  static getProcessLogs = (logs, processId) => (
    logs.filter(log => log.processId === processId)
  )

  static mergeLogs = (oldLogs, newLogs) => {
    const oldLogsIds = oldLogs.map(log => log.id)

    const logsWithoutDuplicates = newLogs.filter(log => !oldLogsIds.includes(log.id))
    const mergedLogs = [ ...oldLogs, ...logsWithoutDuplicates ]
    mergedLogs.sort((logA, logB) => logB.createdAt - logA.createdAt)

    return mergedLogs
  }
}

export default Logs
