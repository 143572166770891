import { ACTIONS } from 'redux/actions/actions'
import addDevices from 'redux/reducers/device/addDevices'
import removeDeviceUser from 'redux/reducers/device/removeDeviceUser'
import modifyDeviceAccess from 'redux/reducers/device/modifyDeviceAccess'
import transferDeviceOwnership from 'redux/reducers/device/transferDeviceOwnership'
import deleteStreamConfigProperty from 'redux/reducers/device/deleteStreamConfigProperty'
import setStreamConfigProperty from 'redux/reducers/device/setStreamConfigProperty'
import setActiveDevice from 'redux/reducers/device/setActiveDevice'
import resetActiveDevice from 'redux/reducers/device/resetActiveDevice'
import checkDeviceHeartbeats from 'redux/reducers/device/checkDeviceHeartbeats'
import updateDeviceHeartbeat from 'redux/reducers/device/updateDeviceHeartbeat'
import updateDevice from 'redux/reducers/device/updateDevice'
import resetDeviceInitialization from 'redux/reducers/device/resetDeviceInitialization'

export default {
  [ACTIONS.ADD_DEVICES]: addDevices,
  [ACTIONS.REMOVE_DEVICE_USER]: removeDeviceUser,
  [ACTIONS.MODIFY_DEVICE_ACCESS]: modifyDeviceAccess,
  [ACTIONS.TRANSFER_DEVICE_OWNERSHIP]: transferDeviceOwnership,
  [ACTIONS.DELETE_STREAM_CONFIG_PROPERTY]: deleteStreamConfigProperty,
  [ACTIONS.SET_STREAM_CONFIG_PROPERTY]: setStreamConfigProperty,
  [ACTIONS.SET_ACTIVE_DEVICE]: setActiveDevice,
  [ACTIONS.RESET_ACTIVE_DEVICE]: resetActiveDevice,
  [ACTIONS.CHECK_DEVICE_HEARTBEATS]: checkDeviceHeartbeats,
  [ACTIONS.UPDATE_DEVICE_HEARTBEAT]: updateDeviceHeartbeat,
  [ACTIONS.UPDATE_DEVICE]: updateDevice,
  [ACTIONS.RESET_DEVICE_INITIALIZATION]: resetDeviceInitialization,
}
