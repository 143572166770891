import React from 'react'
import { Select, Form, Checkbox, Segment, Header, Responsive } from 'semantic-ui-react'
import { replaceNoUnitCharacter, getUnitOptions } from 'utils/units'

const renderLastDataPoint = (val, unit) => (
  <Segment style={{ fontSize: '13px', boxShadow: '0 4px 6px hsla(0, 0%, 0%, 0.2)' }}>
    <div style={{ display: 'flex' }}>
      <span>
        <b>Last value:</b>
      </span>
      <div style={{ flexGrow: 1 }} />
      <span className='float-right'>
        {val !== 'No Data' && val !== null ? val.toFixed(2) : val}
        {' '}
        {val !== 'No Data' && val !== null ? replaceNoUnitCharacter(unit) : ''}
      </span>
    </div>
  </Segment>

)

const getStreamUnitOptions = stream => (
  getUnitOptions(stream.unit).map(unit => ({ text: unit, value: unit }))
)

const zoomOptions = [
  { value: 'auto', text: 'auto' },
  { value: 'fixed-y-axis', text: 'fixed Y-axis' },
]

export const NoDataConfigForm = ({ loading }) => (
  <Segment placeholder loading={loading} className='graph-placeholder'>
    {!loading && <Header icon color='grey'> No data found. </Header>}
  </Segment>
)

const ResponsiveFormDropdowns = ({
  stream,
  selectedUnit,
  changeDisplayedUnit,
  changeZoomMode,
  openZoomDropDown,
  openUnitDropDown,
  openZoomDropDownHandler,
  openUnitDropDownHandler,
  closeDropDown,
}) => (
  <>
    <Responsive as={Form.Group} widths='equal' minWidth={1400}>
      <Form.Field>
        <label>Unit</label>
        <Select
          compact
          open={openUnitDropDown}
          options={getStreamUnitOptions(stream)}
          defaultValue={selectedUnit}
          onClick={openUnitDropDownHandler}
          onClose={closeDropDown}
          closeOnBlur
          onChange={(e, data) => changeDisplayedUnit(e, data)}
        />
      </Form.Field>
      <Form.Field>
        <label>Zoom</label>
        <Select
          open={openZoomDropDown}
          options={zoomOptions}
          onClick={openZoomDropDownHandler}
          defaultValue='auto'
          compact
          onClose={closeDropDown}
          closeOnBlur
          onChange={changeZoomMode}
        />
      </Form.Field>
    </Responsive>
    <Responsive maxWidth={1400}>
      <Form.Field>
        <label>Unit</label>
        <Select
          compact
          open={openUnitDropDown}
          options={getStreamUnitOptions(stream)}
          defaultValue={selectedUnit}
          onClick={openUnitDropDownHandler}
          onClose={closeDropDown}
          closeOnBlur
          onChange={(e, data) => changeDisplayedUnit(e, data)}
        />
      </Form.Field>
      <Form.Field>
        <label>Zoom</label>
        <Select
          open={openZoomDropDown}
          onClick={openZoomDropDownHandler}
          options={zoomOptions}
          onClose={closeDropDown}
          defaultValue='auto'
          compact
          closeOnBlur
          onChange={changeZoomMode}
        />
      </Form.Field>
    </Responsive>
    <br />
  </>
)

const ResponsiveFixedInput = ({ Ymin, Ymax, setYAxisLimits }) => (
  <>
    <Responsive as={Form.Group} style={{ margin: '0px 2px 0px' }} minWidth={1400}>
      <Form.Field
        error={Ymin >= Ymax}
        control='input'
        label='Ymin'
        onChange={({ target: { value } }) => setYAxisLimits('Ymin', value)}
        onClick={e => e.stopPropagation()}
        value={Ymin}
      />
      <Form.Field
        error={Ymin >= Ymax}
        control='input'
        label='Ymax'
        onChange={({ target: { value } }) => setYAxisLimits('Ymax', value)}
        onClick={e => e.stopPropagation()}
        value={Ymax}
      />
    </Responsive>
    <Responsive as={Form.Group} maxWidth={1400}>
      <div style={{ margin: '0px 15px 0px' }}>
        <Form.Field
          error={Ymin >= Ymax}
          control='input'
          label='Ymin'
          onChange={({ target: { value } }) => setYAxisLimits('Ymin', value)}
          onClick={e => e.stopPropagation()}
          value={Ymin}
        />
        <br />
        <Form.Field
          error={Ymin >= Ymax}
          control='input'
          label='Ymax'
          onChange={({ target: { value } }) => setYAxisLimits('Ymax', value)}
          onClick={e => e.stopPropagation()}
          value={Ymax}
        />
      </div>
    </Responsive>
  </>
)

export const ConfigForm = ({
  stream,
  lastDataPoint,
  selectedUnit,
  zoomMode,
  Ymin,
  Ymax,
  changeDisplayedUnit,
  changeZoomMode,
  includeZero,
  includeZeroHandler,
  setYAxisLimits,
  showTargetLine,
  setShowTargetLine,
  hasTarget,
  showEventTriggerLines,
  setShowEventTriggerLines,
  hasDisplayableEventTrigger,
  openZoomDropDown,
  openUnitDropDown,
  openZoomDropDownHandler,
  openUnitDropDownHandler,
  closeDropDown,
}) => (
  <>
    {renderLastDataPoint(lastDataPoint, selectedUnit)}
    <Segment style={{ boxShadow: '0 4px 6px hsla(0, 0%, 0%, 0.2)' }}>
      <Form>
        <ResponsiveFormDropdowns
          stream={stream}
          changeDisplayedUnit={changeDisplayedUnit}
          changeZoomMode={changeZoomMode}
          selectedUnit={selectedUnit}
          openZoomDropDown={openZoomDropDown}
          openUnitDropDown={openUnitDropDown}
          openZoomDropDownHandler={openZoomDropDownHandler}
          openUnitDropDownHandler={openUnitDropDownHandler}
          closeDropDown={closeDropDown}
        />
        <Form.Group>
          {zoomMode === 'auto' ? (
            <Form.Field>
              <Checkbox
                label='Include Zero'
                checked={includeZero}
                onChange={includeZeroHandler}
              />
            </Form.Field>
          )
            : (
              <ResponsiveFixedInput
                Ymin={Ymin}
                Ymax={Ymax}
                setYAxisLimits={setYAxisLimits}
              />
            )}
        </Form.Group>
        <Form.Group>
          {hasTarget && (
            <Form.Field>
              <Checkbox
                label='Show Target'
                checked={showTargetLine}
                onChange={setShowTargetLine}
              />
            </Form.Field>
          )}
        </Form.Group>
        {hasDisplayableEventTrigger && (
          <Form.Group>
            <Form.Field>
              <Checkbox
                label='Show Alert Thresholds'
                checked={showEventTriggerLines}
                onChange={setShowEventTriggerLines}
              />
            </Form.Field>
          </Form.Group>
        )}
      </Form>
    </Segment>
  </>
)
