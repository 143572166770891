import React from 'react'
import { connect } from 'react-redux'
import modalize from 'components/utility/modal/modalize'
import callStopProcess from 'redux/thunks/command/callStopProcess'
import StopProcessModalForm from 'components/processviewer/modals/StopProcessModalForm'

const mapDispatchToProps = dispatch => ({
  dispatchCallStopProcess: (processId, deviceId) => dispatch(callStopProcess(processId, deviceId)),
})

class StopProcessModal extends React.Component {

  state = {
    isError: false,
    errorMessage: '',
  }

  stopProcess = async () => {
    const { activeProcess, closeHandler, dispatchCallStopProcess } = this.props
    const { id, deviceId } = activeProcess

    const res = await dispatchCallStopProcess(id, deviceId)
    if (res.success) {
      this.setState({ isError: false, errorMessage: '' }, () => closeHandler())
    } else {
      this.setState({ isError: true, errorMessage: res.message })
    }
  }

  render() {
    const { isError, errorMessage } = this.state
    const { closeHandler } = this.props

    return (
      <StopProcessModalForm
        isError={isError}
        errorMessage={errorMessage}
        closeHandler={closeHandler}
        submitHandler={this.stopProcess}
        submitText='Confirm'
      />
    )
  }
}

export default modalize(connect(null, mapDispatchToProps)(StopProcessModal))
