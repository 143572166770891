import PDVC from 'utils/PDVC'

export default (state, action) => {
  const { processId, params } = action.payload
  const { processDescriptionSnapshots } = state

  const updatedSnapshots = processDescriptionSnapshots.map(snapshot => {
    if (snapshot.id === processId) return PDVC.createSnapshot(processId, params.description)
    return snapshot
  })

  return { ...state, processDescriptionSnapshots: updatedSnapshots }
}
