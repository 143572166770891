import React from 'react'
import { Table, Button, Icon, Dropdown } from 'semantic-ui-react'


const ProcessUserTable = ({
  processUsers,
  itsMe,
  itsTheOwner,
  modifyAccess,
  withDrawAccess,
  groupOptions,
}) => (
  <Table celled>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>username</Table.HeaderCell>
        <Table.HeaderCell>group</Table.HeaderCell>
        <Table.HeaderCell />
      </Table.Row>
    </Table.Header>
    <Table.Body>
      {processUsers.map(user => (
        <Table.Row key={user.id}>
          <Table.Cell>{user.name}</Table.Cell>
          <Table.Cell>
            <Dropdown
              onChange={(_, { value }) => modifyAccess(user.id, value)}
              defaultValue={user.groupName}
              options={groupOptions}
              disabled={itsMe(user.id) || itsTheOwner(user.id)}
            />
          </Table.Cell>
          <Table.Cell>
            <Button
              onClick={e => withDrawAccess(e, user.id, user.groupName)}
              icon
              color='red'
              size='small'
              inverted
              disabled={itsMe(user.id) || itsTheOwner(user.id)}
            >
              <Icon name='user times' />
            </Button>
          </Table.Cell>
        </Table.Row>))}
    </Table.Body>
  </Table>
)

export default ProcessUserTable
