import React from 'react'
import { Grid, Segment, Button, Divider, Image, Message, Container, Form } from 'semantic-ui-react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import FlashMessenger from 'utils/FlashMessenger'
import callLogin from 'redux/thunks/auth/callLogin'
import logo3 from '../../images/logo3.png'

const mapStateToProps = state => ({ authenticated: state.authenticated })

const mapDispatchToProps = dispatch => ({ callLogin: () => dispatch(callLogin()) })

class Login extends React.Component {

  state = {
    isError: false,
    errorMessage: '',
    pending: false,
  }

  componentDidMount() {
    const { location } = this.props
    if (!location.state) return

    const { signUpResponse: { isSuccess, message } } = location.state
    if (isSuccess) {
      FlashMessenger.success(message)
    } else {
      FlashMessenger.error('Something went wrong!')
    }
  }

  resetPassword = () => {
    const { history } = this.props
    history.push('/reset-password')
  }

  goToSignup = () => {
    window.open('https://ospin-app.com/signup')
  }

  submitLogin = async () => {
    this.setState({ pending: true })
    const res = await this.props.callLogin()

    if (!res.success) {
      const { message: { message } } = res
      this.setState({ isError: true, errorMessage: message, pending: false })
    }
  }

  render() {
    const { authenticated } = this.props
    if (authenticated) return <Redirect to='/devices' />

    const { pending, isError, errorMessage } = this.state

    return (
      <Container>
        <Grid centered columns={3}>
          <Grid.Column width={6} className='margin-top'>
            <Image
              src={logo3}
              centered
              size='medium'
            />
            <Divider horizontal hidden />
            <Segment>
              <p>
                This is a demo version of the OSPIN web application.
                It provides a streamlined overview of our automated
                <b> Modular Bioprocessing Technology.</b>
              </p>
              <Divider horizontal hidden />
              <div>
                <Form error={isError} size='large' onSubmit={e => e.preventDefault}>
                  <Message
                    error
                    header='Demo login failed.'
                    content={errorMessage}
                  />
                  <Button
                    loading={pending}
                    size='large'
                    positive
                    fluid
                    onClick={this.submitLogin}
                    id='login'
                    type='Login'
                  >
                        Try Demo
                  </Button>
                  <Divider />
                  <Button
                    size='large'
                    positive
                    fluid
                    className='ospin-red-hoverable'
                    onClick={() => window.open('https://ospin.de/contact/', '_blank')}
                  >
                      Contact Us!
                  </Button>
                </Form>
              </div>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    )
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))
