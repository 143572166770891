import { findAndUpdate } from 'redux/helper/updateFunctions'
import { addUserToGroup, removeUserFromGroup } from 'utils/access'

export default (state, action) => {
  const { processes } = state
  const { processId, userId, oldGroup, newGroup } = action.payload

  const updateFnAddToGroup = process => addUserToGroup(process, newGroup, userId)
  const updateFnRemoveFromGroup = process => removeUserFromGroup(process, oldGroup, userId)
  const updatedProcesses = findAndUpdate(
    processId, processes, [ updateFnAddToGroup, updateFnRemoveFromGroup ],
  )

  return { ...state, processes: updatedProcesses }
}
