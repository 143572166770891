import React from 'react'
import { Form, Input } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'
import Streams from 'utils/Streams'
import { getUnitOptions } from 'utils/units'

const excludeCurrentDefaultUnit = (unit, currentUnitEntry, stream) => (
  currentUnitEntry
    ? unit !== currentUnitEntry.unit
    : unit !== stream.unit
)

const getStreamUnitOptions = (stream, currentUnitEntry) => (
  // returns all possible units for stream but the device default unit
  stream
    ? getUnitOptions(stream.unit)
      .filter(unit => (excludeCurrentDefaultUnit(unit, currentUnitEntry, stream)))
      .map(unit => ({ key: unit, value: unit, text: unit }))
    : []
)

const getStreamOptions = device => {
  // returns the all streams that do offer at least another possible unit

  const displayedStreams = DeviceConfig.getConfiguredStreams(device)

  return displayedStreams
    .filter(stream => getUnitOptions(stream.unit).length > 1)
    .map(stream => ({
      value: stream.id,
      text: stream.displayedName,
      key: stream.id,
    }))
}

const SetByStreamMenu = ({
  activeDevice,
  handleUpdate,
  streamUnit,
  targetStreamId,
}) => {

  const currentUnitEntry = targetStreamId !== null
    ? DeviceConfig.getDisplayedStreamUnit(activeDevice, targetStreamId)
    : null

  const stream = Streams.getById(activeDevice.streams, targetStreamId)
  const unitOptions = getStreamUnitOptions(stream, currentUnitEntry)
  const streamOptions = getStreamOptions(activeDevice)

  return (
    <Form.Group widths='equal'>
      <Form.Select
        fluid
        name='targetStreamId'
        label='Stream'
        options={streamOptions}
        onChange={handleUpdate}
        placeholder='Choose Stream'
      />
      <Form.Field>
        <label>Device Default</label>
        <Input
          fluid
          type='text'
          readOnly
          value={stream ? stream.unit : 'None'}
        />
      </Form.Field>
      <Form.Field>
        <label>Current Unit</label>
        <Input
          fluid
          type='text'
          readOnly
          value={stream ? DeviceConfig.getDisplayedStreamUnit(activeDevice, stream.id) : 'None'}
        />
      </Form.Field>
      <Form.Select
        fluid
        name='streamUnit'
        label='New Unit'
        options={unitOptions}
        onChange={handleUpdate}
        placeholder='Choose new unit'
        value={streamUnit}
      />
    </Form.Group>
  )
}

export default SetByStreamMenu
