import React from 'react'
import { connect } from 'react-redux'
import { Table } from 'semantic-ui-react'
import CustomConversions from 'utils/DeviceConfig/CustomConversions'
import Authorizer from 'utils/Authorizer'
import DeviceConfig from 'utils/DeviceConfig'
import IconButton from 'components/utility/button/IconButton'
import TableHeader from 'components/utility/table/TableHeader'
import PumpCalibrationModal from 'components/device/modals/PumpCalibrationModal/PumpCalibrationModal'
import callUpdateDevice from 'redux/thunks/device/callUpdateDevice'
import DeletePopup from './DeletePopup'

const mapStateToProps = state => ({ user: state.user })

const mapDispatchToProps = dispatch => ({
  dispatchCallUpdateDevice: (processId, processName) => dispatch(
    callUpdateDevice(processId, processName),
  ),
})

class PumpCalibrationTable extends React.Component {

  state = { showModal: false }

  toggleModal = () => {
    this.setState(({ showModal }) => ({ showModal: !showModal }))
  }

  deletePumpCalibration = async streamId => {
    const { activeDevice, dispatchCallUpdateDevice } = this.props

    const updatedCustomConversions = CustomConversions
      .removeConversionByUnit(activeDevice, streamId, 'ml/min')

    const updatedConfig = DeviceConfig
      .updateStreamConfig(activeDevice, streamId, { customConversions: updatedCustomConversions })

    const displayedStreamUnit = DeviceConfig.getDisplayedStreamUnit(activeDevice, streamId)

    if (displayedStreamUnit === 'ml/min') {
      const { streams } = updatedConfig
      updatedConfig.streams = DeviceConfig.removeStreamConfigProp(streams, streamId, 'defaultUnit')
    }

    const updateParams = { config: updatedConfig }
    await dispatchCallUpdateDevice(activeDevice.id, updateParams)
  }

  renderStream = configuredStream => {

    const { id, connected, displayedName } = configuredStream
    const { activeDevice, user } = this.props

    const conversion = CustomConversions.getCustomConversionsByTargetUnit(activeDevice, id, 'ml/min')

    if (!conversion) return null

    return (
      <Table.Row key={id} warning={!connected}>
        <Table.Cell>
          {displayedName}
        </Table.Cell>
        <Table.Cell>
          {conversion.ratio}
        </Table.Cell>
        <Table.Cell>
          {!connected ? 'stream not connected' : 'Ok'}
        </Table.Cell>
        <Table.Cell>
          <DeletePopup
            confirmHandler={() => this.deletePumpCalibration(id)}
            disabled={!Authorizer.user(user).hasEditRights(activeDevice)}
            iconName='close'
            position='right center'
          />
        </Table.Cell>
      </Table.Row>
    )
  }

  render() {
    const { activeDevice } = this.props
    const { showModal } = this.state

    const configuredStreams = CustomConversions
      .getConfiguredStreamsWithCustomConversions(activeDevice)

    return (
      <>
        <IconButton
          text='Calibrate Pump'
          clickHandler={this.toggleModal}
        />
        {configuredStreams.length !== 0 && (
          <Table key={1} className='ospin-red' celled collapsing>
            <TableHeader items={['Stream', 'Ratio ml/rotation', 'Info', '']} />
            <Table.Body>
              {configuredStreams.map(this.renderStream)}
            </Table.Body>
          </Table>
        )}
        <PumpCalibrationModal
          open={showModal}
          closeHandler={this.toggleModal}
          headerIcon='settings'
          headerText='Calibrate pump flow rate'
          activeDevice={activeDevice}
        />
      </>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PumpCalibrationTable)
