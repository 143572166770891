import React from 'react'
import { Form, List, Segment, Header } from 'semantic-ui-react'
import DeviceConfig from 'utils/DeviceConfig'
import { Device } from 'utils/device'
import Streams from 'utils/Streams'
import { getUnitOptions, unitToUnitType, unitTypeDisplayText } from 'utils/units'

const getUnitTypeOptionsWithAlternatives = activeDevice => {
  const deviceUnits = Device.getUniqueNonEmptyDeviceUnits(activeDevice)

  return deviceUnits
    .filter(unit => getUnitOptions(unit).length > 1)
    .map(unit => {
      const unitTypeText = unitTypeDisplayText[unitToUnitType(unit)]
      return { key: unit, text: unitTypeText, value: unit }
    })
}

const getAlternativeUnitTypeOptions = (activeDevice, deviceUnit) => (
  deviceUnit
    ? getUnitOptions(deviceUnit)
      .map(unit => ({ key: unit, value: unit, text: unit }))
    : []
)

const renderAffectedStreams = (activeDevice, deviceUnit) => {
  const affectedStreams = Streams.getStreamsByUnit(activeDevice.streams, deviceUnit)

  return affectedStreams.length
    ? (
      <Segment>
        <Header as='h4'>
          Affected Streams
        </Header>
        <List bulleted>
          {affectedStreams.map(stream => (
            <List.Item key={stream.id}>
              {DeviceConfig.getDisplayedStreamName(activeDevice, stream.id)}
            </List.Item>
          ))}
        </List>
      </Segment>
    )
    : null
}

const SetByUnitTypeMenu = ({ activeDevice, handleUpdate, deviceUnit, altDeviceUnit }) => {

  const deviceUnitOptions = getUnitTypeOptionsWithAlternatives(activeDevice)
  const altDeviceUnitOptions = getAlternativeUnitTypeOptions(activeDevice, deviceUnit)

  return (
    <div>
      <Form.Group widths='equal'>
        <Form.Select
          fluid
          name='deviceUnit'
          label='Pick Type'
          options={deviceUnitOptions}
          onChange={handleUpdate}
          placeholder='Choose type'
          value={deviceUnit}
        />
        <Form.Select
          fluid
          name='altDeviceUnit'
          label='Pick Default Unit'
          options={altDeviceUnitOptions}
          onChange={handleUpdate}
          placeholder='Choose unit'
          value={altDeviceUnit}
        />
      </Form.Group>
      {renderAffectedStreams(activeDevice, deviceUnit)}
    </div>
  )
}

export default SetByUnitTypeMenu
