import React from 'react'
import dateFormat from 'dateformat'
import { Container, Segment, Header, List, Image, Ref, Sticky, Table } from "semantic-ui-react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import ReactMarkdown from 'react-markdown'
import injectSheet from 'react-jss'
import { connect } from 'react-redux'
import DynamicLoader from 'components/utility/DynamicLoader'
import callRemoveNotificationsByTopic from 'redux/thunks/user/callRemoveNotificationsByTopic'

import  nexus from "nexus"


export type GitHubUser = {
  login: string
  avatarUrl: string
}

export type GitHubPR = {
  title: string,
  closedAt: Date,
  user: GitHubUser
  body: string,
  labels: string[]
}

export interface ChangelogState {
  gitPrs: GitHubPR[],
  loading: boolean
}

const mapStateToProps = (state: any): StateProps => ({
  user: state.user,
})

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  callRemoveNotificationsByTopic: (userId: any, topic: string) => dispatch(callRemoveNotificationsByTopic(userId, topic))
})

const styles = {
  root: {
    '& img': {
      maxWidth: '100%'
    },
  }
}

type ChangelogTypeStyles = {
  root: string;
}

type StateProps = {
  user: any
}
type DispatchProps = {
  callRemoveNotificationsByTopic: (userId: any, topic: string) => void
}
type StyleProps = {
  classes: ChangelogTypeStyles
}
type Props = RouteComponentProps & StateProps & StyleProps & DispatchProps

class Changelog extends React.Component<Props, ChangelogState> {

  state = {
    gitPrs: [],
    loading: true
  }
  contextRef = React.createRef()

  async componentDidMount() {
    const gitPrs: GitHubPR[] = await nexus.user.getChangelog()

    const sortedPrs: GitHubPR[] = gitPrs.sort((a, b) => (
      (new Date(b.closedAt)).valueOf() - (new Date(a.closedAt)).valueOf()
    ))

    this.setState({ gitPrs: sortedPrs, loading: false }, () => {
      this.props.callRemoveNotificationsByTopic(this.props.user.id, 'changelog')
    })
  }

  renderEntry = (prs: GitHubPR[]) => {
    return (
      <Table celled striped>
        <Table.Body>
          {
            prs.map((entry, i) => (
              <Table.Row key={i}>
                <Table.Cell style={{ paddingBottom: "25px" }}>
                  <List.Item>
                    <List.Content>
                      <List.Description style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <div className="ui content header" style={{ marginBottom: "2px" }}>{entry.title}</div>
                        <div style={{ color: '#00000066', fontSize: '1em' }}>{dateFormat(entry.closedAt, 'shortDate')}</div>
                        <div data-testid='pr-owner' style={{ paddingTop: "10px" }}>
                          <Image avatar src={entry.user.avatarUrl} />
                          {entry.user.login}
                        </div>
                      </List.Description>
                      <ReactMarkdown source={entry.body} />
                    </List.Content>
                  </List.Item>
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    )
  }

  render() {

    const { loading, gitPrs } = this.state

    if (loading) return <DynamicLoader/>

    /*  ui and compact are semantic ui classes */
    return (
      <Container textAlign='center'>
        <Ref innerRef={this.contextRef}>
          <div className='ui compact' style={{ width: "95%", display: 'inline-flex', flexDirection: 'column' }} >
            <Sticky context={this.contextRef} pushing>
              <Segment>
                <Header as='h3'>OSPIN Web Application Changelog</Header>
              </Segment>
            </Sticky>
            <Segment textAlign='left' className={this.props.classes.root}>
              {this.renderEntry(gitPrs)}
            </Segment>
          </div>
        </Ref>
      </Container>
    );
  };
}
export default injectSheet(styles)(withRouter(connect<StateProps, DispatchProps, Props>(mapStateToProps, mapDispatchToProps)(Changelog)))
