import React, { useState } from 'react'
import { Container, Checkbox } from 'semantic-ui-react'
import StreamEventTriggerTable from 'components/streamEventTrigger/StreamEventTriggerTable'
import CalibrationTable from './CalibrationTable'
import StreamDefaultTable from './StreamDefaultsTable'
import ConnectedStreamsTable from './ConnectedStreamsTable'
import StreamNameAliasTable from './StreamNameAliasTable'
import PumpCalibrationTable from './PumpCalibrationTable'
import UnitsTable from './UnitsTable'
import SectionWrapper from './SectionWrapper/SectionWrapper'
import descriptions from './SectionWrapper/sectionDescriptions'


const renderConnectedStreams = (activeDevice, showController, toggleHandler) => (
  <>
    <Checkbox
      toggle
      label='Show Controller Parameters'
      onChange={() => toggleHandler(!showController)}
      checked={showController}
    />
    <ConnectedStreamsTable
      activeDevice={activeDevice}
      showControllerParameters={showController}
    />
  </>
)

const getSectionContents = (
  activeDevice,
  showControllerParameters,
  toggleShowControllerParameters,
) => ([
  {
    title: 'Connected Streams',
    content: renderConnectedStreams(
      activeDevice, showControllerParameters, toggleShowControllerParameters,
    ),
    desc: descriptions.connectedStreams,
  },
  {
    title: 'Stream Defaults',
    desc: descriptions.streamDefaults,
    content: <StreamDefaultTable activeDevice={activeDevice} />,
  },
  {
    title: 'Stream Name Aliases',
    desc: descriptions.streamNameAliases,
    content: <StreamNameAliasTable activeDevice={activeDevice} />,
  },
  {
    title: 'Sensor Calibration',
    desc: descriptions.calibrations,
    content: <CalibrationTable activeDevice={activeDevice} />,
  },
  {
    title: 'Pump Calibration',
    desc: descriptions.pumpCalibration,
    content: <PumpCalibrationTable activeDevice={activeDevice} />,
  },
  {
    title: 'Units',
    desc: descriptions.units,
    content: <UnitsTable activeDevice={activeDevice} />,
  },
  {
    title: 'Device Alerts',
    desc: descriptions.alerts,
    content: <StreamEventTriggerTable activeDevice={activeDevice} mode='device' />,
  },
])


const DeviceSetup = ({ activeDevice }) => {
  const [ showControllerParameters, toggleShowControllerParameters ] = useState(false)

  const sectionContents = getSectionContents(
    activeDevice, showControllerParameters, toggleShowControllerParameters,
  )

  return (
    <Container fluid>
      {sectionContents.map(({ title, content, desc }) => (
        <SectionWrapper title={title} description={desc} key={title}>
          {content}
        </SectionWrapper>
      ))}
    </Container>
  )
}

export default DeviceSetup
