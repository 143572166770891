import Amplify, { PubSub, Auth } from 'aws-amplify'
import { AWSIoTProvider } from '@aws-amplify/pubsub/lib/Providers'

let iotProvider = null
let iotRegion = null
let iotEndPoint = null
let stage = null

export const setIotConfig = config => {
  iotRegion = config.PubSub.region
  iotEndPoint = config.PubSub.endPoint
  stage = config.stage
}

async function init(){

  if (stage === null) {
    throw new Error('Missing target environment for Iot Broker')
  }

  if (iotRegion === null) {
    throw new Error('Missing region for Iot Broker')
  }

  if (iotEndPoint === null) {
    throw new Error('Missing iot endpoint for Iot Broker')
  }

  if (iotProvider === null){
    const credentials = await Auth.currentCredentials()

    const awsIoTProvider = new AWSIoTProvider({
      aws_pubsub_region: iotRegion,
      aws_pubsub_endpoint: iotEndPoint,
      credentials,
    })

    Amplify.addPluggable(awsIoTProvider)

    iotProvider = awsIoTProvider
  }
}

export const subscribe = async (topic, onReceive = null, onError = console.error, onClose = null) => {
  await init()
  return PubSub
    .subscribe(`${stage}/${topic}`).subscribe({
      next: data => onReceive && onReceive(data),
      error: error => onError(error),
      close: () => onClose && onClose(),
    })
}

export const resetIotProvider = () => {
  iotProvider = null
}
