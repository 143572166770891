import React from 'react'
import { Menu, Dropdown } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'


const timeQueryOptions = [
  { text: 'created', value: 'createdAt' },
  { text: 'started', value: 'startedAt' },
  { text: 'finished', value: 'finishedAt' },
]

const getDisplayedStyle = showDateQueryMenu => (
  showDateQueryMenu
    ? { marginBottom: '16px' }
    : { display: 'none' }
)

const DateQueryMenu = ({
  timeQueryProperty,
  startDate,
  endDate,
  updateTimeQueryProperty,
  setTimeLimit,
  showDateQueryMenu,
}) => (
  <Menu
    style={getDisplayedStyle(showDateQueryMenu)}
  >
    <Menu.Menu>
      <Menu.Item>
        Start: &nbsp;
        <SemanticDatepicker
          name='startDate'
          selected={startDate}
          onChange={setTimeLimit}
          maxDate={endDate}
        />
        &nbsp;
        End: &nbsp;
        <SemanticDatepicker
          name='endDate'
          selected={endDate}
          minDate={startDate}
          onChange={setTimeLimit}
        />
        <Dropdown
          selection
          options={timeQueryOptions}
          value={timeQueryProperty}
          disabled={!startDate && !endDate}
          name='timeQueryProperty'
          onChange={updateTimeQueryProperty}
          className='padding-13-16'
          style={{ marginLeft: '16px' }}
        />
      </Menu.Item>
    </Menu.Menu>
  </Menu>
)

export default DateQueryMenu
