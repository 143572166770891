import React from 'react'
import PhaseGroupDraggable from './PhaseGroupDraggable'
import PhaseTabDraggable from './PhaseTabDraggable'

function PhaseTreeItemFactory(props) {
  const {
    runningPhase,
    addPhaseToGroup,
    activeProcess,
    treeElement,
    phaseData,
    activeDevice,
  } = props

  const renderTreeItem = () => {
    switch (treeElement) {
      case 'group':
        return (
          <PhaseGroupDraggable
            phases={phaseData.group}
            index={phaseData.idx}
            key={phaseData.groupName}
            activeProcess={activeProcess}
            runningPhase={runningPhase}
            groupName={phaseData.groupName}
            addPhaseWithinGroup={addPhaseToGroup}
            activeDevice={activeDevice}
          />
        )
      case 'tab':
        return (
          <PhaseTabDraggable
            phase={phaseData.data}
            key={phaseData.idx}
            index={phaseData.idx}
            phaseId={phaseData.id}
            activeProcess={activeProcess}
            runningPhase={runningPhase}
          />
        )
      default:
        return (
          <PhaseTabDraggable
            phase={phaseData.data}
            key={phaseData.idx}
            index={phaseData.idx}
            phaseId={phaseData.id}
            activeProcess={activeProcess}
            runningPhase={runningPhase}
          />
        )
    }
  }

  return renderTreeItem()
}

export default PhaseTreeItemFactory
