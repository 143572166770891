import StreamIcons from './StreamIcons'

export default class Stream extends StreamIcons {

  static hasRelatedTarget(stream) {
    if (!this.hasRelatedStreams(stream)) return false
    return stream.relatedDownstreams.some(({ relationType }) => relationType === 'target')
  }

  static getRelatedStreamEntry(stream, type) {
    if (!this.hasRelatedTarget(stream)) return
    return stream.relatedDownstreams.find(({ relationType }) => relationType === type)
  }

  static hasRelatedStreams(stream) {
    return stream.relatedDownstreams && stream.relatedDownstreams.length
  }

  static streamTargetOutOfLimits = (stream, target) => parseFloat(target) > parseFloat(stream.max)
      || parseFloat(target) < parseFloat(stream.min)
}
