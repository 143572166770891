import React from 'react'
import { connect } from 'react-redux'
import { Container } from 'semantic-ui-react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Devices from 'components/devices/Devices'
import Device from 'components/device/Device'
import Profile from 'components/profile/Profile'
import Notifications from 'components/notifications/Notifications'
import Changelog from 'components/changelog/Changelog.tsx'
import Alerts from 'components/alerts/Alerts'
import ErrorPage from 'components/utility/ErrorPage'
import OspinSidebar from './OspinSidebar'
import 'components/main/Main.css'

const mapStateToProps = ({ collapsedSidebar }) => ({ collapsedSidebar })

const getMainStyleClasses = collapsed => (collapsed ? 'main-content-collapsed-sidebar' : 'main-content')

const Main = ({ collapsedSidebar }) => (
  <div>
    <OspinSidebar />
    <Container fluid className={getMainStyleClasses(collapsedSidebar)}>
      <Switch>
        <Route exact path='/' component={Devices} />
        <Route exact path='/devices' component={Devices} />
        <Route exact path='/changelog' component={Changelog} />
        <Route exact path='/profile' component={Profile} />
        <Route exact path='/messages' component={Notifications} />
        <Route exact path='/alerts' component={Alerts} />
        <Route exact path='/device/:deviceId/process/:processId' component={Device} />
        <Route path='/device/:deviceId' component={Device} />
        <Route to={{ errorCode: '404', pathname: '/404' }} component={ErrorPage} />
        <Route component={ErrorPage} />
      </Switch>
    </Container>
  </div>
)

export default connect(mapStateToProps)(withRouter(Main))
