import nexus from 'nexus'
import { addLogs } from 'redux/actions/actions'
import thunkWrapper from 'redux/thunks/thunkWrapper'

const callLoadLogs = async (dispatch, deviceId, queryParams) => {
  const fetchedLogs = await nexus.log.list(deviceId, queryParams)
  dispatch(addLogs({ fetchedLogs }))
}

export default thunkWrapper(callLoadLogs)
