import DeviceConfig from 'utils/DeviceConfig'
import { roundToTheNthDecimal } from 'utils/number'
import UI_CONSTANTS from 'config/ui'

class CustomConversions extends DeviceConfig {

  static getConfiguredStreamsWithCustomConversions = device => (
    CustomConversions
      .getConfiguresStreamsByQuery(device, CustomConversions.streamConfigHasCustomConversions)
  )

  static streamConfigHasCustomConversions = config => (
    config.customConversions && config.customConversions.length
  )

  static getCustomConversions = (device, streamId) => (
    CustomConversions.getConfiguredStreamById(device, streamId).customConversions
  )

  static getCustomConversionsByTargetUnit = (device, streamId, unit) => {
    const { customConversions } = CustomConversions.getConfiguredStreamById(device, streamId)
    return customConversions.find(aConversion => aConversion.to === unit)
  }

  static getUpdatedConversions = (device, streamId, newConversion) => {
    const customConversions = CustomConversions.getCustomConversions(device, streamId) || []

    const existingConversion = customConversions
      .find(aConversion => aConversion.to === newConversion.to)

    if (!existingConversion) return [ ...customConversions, newConversion ]

    return customConversions.map(aConversion => {
      if (aConversion.to === newConversion.to) return newConversion
      return aConversion
    })
  }

  static removeConversionByUnit = (device, streamId, unit) => {
    const customConversions = CustomConversions.getCustomConversions(device, streamId) || []
    return customConversions.filter(aConversion => aConversion.to !== unit)
  }

  static convertWithRatio(value, { ratio }) {
    return roundToTheNthDecimal(value * ratio, UI_CONSTANTS.NUMBER_INPUT_DECIMALS)
  }

  static revertWithRatio(value, { ratio }) {
    return value / ratio
  }
}

export default CustomConversions
