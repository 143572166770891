import React from 'react'
import { Table } from 'semantic-ui-react'

const renderStreamValueByExpectedValueType = stream => {
  switch (stream.expectedValueType) {
    case 'boolean': {
      return stream.default ? 'on' : 'off'
    }
    default:
      return stream.default || '-'
  }
}

const CalibrationTable = props => {

  const { streams, streamNameAliases } = props.activeDevice

  const streamData = streams.map(stream => {
    const streamNameAlias = streamNameAliases.find(alias => alias.streamId === stream.id)
    if (streamNameAlias) {
      return {...stream, name: streamNameAlias.alias}
    }
    return stream
  })

  return (
    <Table className='ospin-red' celled collapsing>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>
            Name
          </Table.HeaderCell>
          <Table.HeaderCell>
            Type
          </Table.HeaderCell>
          <Table.HeaderCell>
            Group
          </Table.HeaderCell>
          <Table.HeaderCell>
            Unit
          </Table.HeaderCell>
          <Table.HeaderCell>
            Device Default
          </Table.HeaderCell>
          <Table.HeaderCell>
            Min
          </Table.HeaderCell>
          <Table.HeaderCell>
            Max
          </Table.HeaderCell>
          <Table.HeaderCell>
            Description
          </Table.HeaderCell>
          <Table.HeaderCell>
            ID
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {streamData
          .filter(stream => !stream.controller || (stream.controller && props.showControllerParameters))
          .sort((a, b) => a.groupName.localeCompare(b.groupName))
          .map((stream, i) =>
            <Table.Row key={stream.id} warning={stream.name === -1}>
              <Table.Cell>
                {stream.name}
              </Table.Cell>
              <Table.Cell>
                {stream.type}
              </Table.Cell>
              <Table.Cell>
                {stream.groupName}
              </Table.Cell>
              <Table.Cell>
                {stream.unit || '-'}
              </Table.Cell>
              <Table.Cell>
                {renderStreamValueByExpectedValueType(stream)}
              </Table.Cell>
              <Table.Cell>
                {stream.min !== undefined ? stream.min : '-'}
              </Table.Cell>
              <Table.Cell>
                {stream.max !== undefined ? stream.max : '-'}
              </Table.Cell>
              <Table.Cell>
                {stream.controller ? `${stream.controller.name} (${stream.controller.type})` : '-'}
              </Table.Cell>
              <Table.Cell>
                {stream.id}
              </Table.Cell>
            </Table.Row>
          )}
      </Table.Body>
    </Table>
  )
}

export default CalibrationTable
