import DataManager from 'utils/DataManager'

export default (state, action) => {
  const { data, streamId, processId } = action.payload

  const { processes } = state

  const updatedProcesses = processes.map(process => {
    if (process.id === processId && process.data.some(entry => entry.id === streamId)) {
      return { ...process, data: DataManager.parseHistoricData(process, streamId, data) }
    }
    return process
  })
  return { ...state, processes: updatedProcesses }
}
